import FetchAPI from "../../../../api/FetchAPI";
import ikeConstants from "./constants";
import { toast } from "react-toastify";

import {
  toastMultipleResults,
  checkResourceProperties,
  toastError,
} from "../../../../app_shared_functions";

export const updateIke = (ike, objectToSend) => (dispatch) => {
  const invalid_ike_Msg = checkResourceProperties(ike, "ike policy");
  if (invalid_ike_Msg) {
    toastError(invalid_ike_Msg);
    return Promise.reject();
  }

  dispatch({ type: ikeConstants.IKE_UPDATE_INIT, payload: { id: ike.id } });
  FetchAPI.Networking.IKEPolicies.modify({
    ike,
    objectToSend,
  })
    .then((response) => {
      dispatch({
        type: ikeConstants.IKE_UPDATE_STARTED,
        payload: { id: ike.id },
      });
      toast.success("Ike update started...");
    })
    .catch((err) => {
      toastError(err, "Modifying ike policy failed!");
      dispatch({
        type: ikeConstants.IKE_UPDATE_FAILED,
        payload: { id: ike.id, err },
      });
    });
};

export const deleteIke = (ike) => (dispatch) => {
  const invalid_ike_Msg = checkResourceProperties(ike, "ike policy");
  if (invalid_ike_Msg) {
    toastError(invalid_ike_Msg);
    return Promise.reject();
  }

  dispatch({ type: ikeConstants.IKE_DELETE_INIT, payload: { id: ike.id } });
  return new Promise((resolve, reject) => {
    FetchAPI.Networking.IKEPolicies.delete(ike)
      .then((response) => {
        toast.success("Ike delete started...");
        dispatch({
          type: ikeConstants.IKE_DELETE_STARTED,
          payload: { id: ike.id },
        });
        resolve(response.data);
      })
      .catch((err) => {
        toastError(err, "Deleting ike policy failed!");
        dispatch({
          type: ikeConstants.IKE_DELETE_FAILED,
          payload: { id: ike.id },
        });
        reject(err);
      });
  });
};
export const deleteMultipleIkes = (ikes) => (dispatch) => {
  toast.success(
    `About to delete ${ikes.length} ike${ikes.length > 1 ? "s" : ""}...`,
  );
  dispatch({ type: ikeConstants.IKE_DELETE_MULTIPLE_INIT, payload: ikes });
  const promises = ikes.map((ike) => {
    return new Promise((resolve, reject) =>
      FetchAPI.Networking.IKEPolicies.delete(ike)
        .then((response) => resolve({ status: "resolved", id: ike.id }))
        .catch((err) => {
          dispatch({
            type: ikeConstants.IKE_DELETE_FAILED,
            payload: { id: ike.id },
          });
          resolve({
            status: "rejected",
            id: ike.id,
            desc:
              err.response.data.error.description ||
              err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "ike",
      action: "delete",
      dispatch,
    }),
  );
};

export const createIke =
  (region, currentProjectID, objectToSend) => (dispatch) => {
    dispatch({ type: ikeConstants.IKE_CREATE_INIT });
    return new Promise((resolve, reject) => {
      FetchAPI.Networking.IKEPolicies.create({
        region,
        currentProjectID,
        objectToSend,
      })
        .then((response) => {
          toast.success("Ike creation started...");
          dispatch({
            type: ikeConstants.IKE_CREATE_STARTED,
            payload: {
              ...response.data.ikepolicy,
              region: region.toLowerCase(),
              status: "creating",
            },
          });
          resolve();
        })
        .catch((err) => {
          dispatch({ type: ikeConstants.IKE_CREATE_FAILED });
          toastError(err, "Creating ike policy failed!");
          reject(err);
        });
    });
  };
