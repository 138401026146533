import React from "react";
import FancyHeader from "../../../components/shared/FancyHeader";

import { connect } from "react-redux";
import { modifySchedule } from "./actions";
import {
  Grid,
  Icon,
  Input,
  Select,
  Popup,
  Ref,
  Label,
  Loader,
  Checkbox,
} from "semantic-ui-react";

import monitoringValues from "../monitoring-values";
import {
  convertArrayToSelectOptions,
  get_FormItem_ClassName,
  handleScrollToItem,
  toastMultipleResults,
  toggleArrayItem,
  checkMissingArrayEntries,
} from "../../../app_shared_functions";

import {
  removeSubscription,
  addSubscription,
} from "../../../actions/connectivity";

import FetchAPI from "../../../api/FetchAPI";

class ModifySchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdating: false,
      name: this.props.schedule.name,
      type: this.props.schedule.type.toUpperCase(),
      target: this.props.schedule.target,
      interval_minutes: Number(this.props.schedule.interval_minutes),
      streak_failures: Number(this.props.schedule.fail_limit),
      streak_checks: Number(this.props.schedule.fail_interval),
      receivers: this.props.schedule.notifications.map((x) => x.uuid),
    };
  }

  checkAndSet_ResponseTime = (meta, schedules) => {
    // This function gets the meta data from the scheudle (in props)
    // and figures out if the respose_time is a predefined value / an option of the select box (a value in monitoringValues.schedules.response_time)
    // or it's custom value
    // or not defined
    let response_time, response_time_custom;

    if (meta?.loadtime) {
      const user_loadtime = meta.loadtime.substring(3);
      const index = schedules.response_time
        .map((x) => x.split(" ")[0])
        .indexOf(user_loadtime);

      if (index === -1) {
        response_time =
          schedules.response_time[schedules.response_time.length - 1];
        response_time_custom = user_loadtime;
      } else {
        response_time = schedules.response_time[index];
      }
    } else {
      response_time = schedules.response_time[0];
    }
    this.setState({ response_time, response_time_custom });
  };

  checkAndSet_Httpcode = (meta, schedules) => {
    // This function gets the meta data from the scheudle (in props)
    // and figures out if the http code is a predefined value / an option of the select box (a value in monitoringValues.schedules.http_code)
    // or it's custom value entered by user
    // or is diabled/not defined
    let http_code, http_code_custom;

    if (meta?.code) {
      const user_code = meta.code.slice(3).slice(0, -1);
      const index = schedules.http_code
        .map((x) => x.split(" ")[0])
        .indexOf(user_code);

      if (index === -1) {
        http_code = schedules.http_code[schedules.http_code.length - 1];
        http_code_custom = user_code;
      } else {
        http_code = schedules.http_code[index];
      }
    } else {
      http_code = schedules.http_code[0];
    }
    this.setState({ http_code, http_code_custom });
  };

  checkAndSet_Response = (meta, schedules) => {
    // This function gets the meta data from the scheudle (in props)
    // and figures out if the response is a predefined value entered by user
    // or is diabled/not defined
    let response, response_custom;

    if (meta?.content) {
      const user_response = meta.content.slice(3).slice(0, -1);
      const index = schedules.response
        .map((x) => x.split(" ")[0])
        .indexOf(user_response);

      if (index === -1) {
        response = schedules.response[schedules.response.length - 1];
        response_custom = user_response;
      } else {
        response = schedules.response[index];
      }
    } else {
      response = schedules.response[0];
    }
    this.setState({ response, response_custom });
  };

  componentDidMount() {
    let subscriptionsToStart = checkMissingArrayEntries(
      this.props.connectivity.activeSubscriptions,
      ["MONITORING_CONTACTS_LIST"],
    );
    subscriptionsToStart.forEach((x) => this.props.addSubscription(x));
    this.setState({ subscriptionsStarted: subscriptionsToStart });

    const { schedules } = monitoringValues;
    const { meta } = this.props.schedule;

    this.checkAndSet_ResponseTime(meta, schedules);
    this.checkAndSet_Httpcode(meta, schedules);
    this.checkAndSet_Response(meta, schedules);
  }

  componentWillUnmount() {
    this.state.subscriptionsStarted.forEach((subscriptionName) => {
      this.props.removeSubscription(subscriptionName);
    });
  }

  check_required_fields = () => {
    let returnValue = null;
    const { response_time, http_code, response } = monitoringValues.schedules;

    if (this.state.formChanged) {
      if (!this.state.name) {
        returnValue = {
          text: "Please provide a name for your Contact",
          ref: "nameRef",
        };
      } else if (!this.state.target) {
        returnValue = {
          text: "Please provide a valid target value",
          ref: "targetRef",
        };
      } else if (this.state.type !== "PING") {
        if (
          this.state.response_time ===
            response_time[response_time.length - 1] &&
          (!this.state.response_time_custom ||
            Number(this.state.response_time_custom) < 200 ||
            Number(this.state.response_time_custom) > 60000)
        ) {
          returnValue = {
            text: "Please provide a valid response time between 200 and 60000",
            ref: "response_time_customRef",
          };
        } else if (
          this.state.http_code === http_code[http_code.length - 1] &&
          !this.state.http_code_custom
        ) {
          returnValue = {
            text: "Please provide a valid http code",
            ref: "http_code_customRef",
          };
        } else if (
          this.state.response === response[response.length - 1] &&
          !this.state.response_custom
        ) {
          returnValue = {
            text: "Please provide a valid response",
            ref: "response_customRef",
          };
        }
      }
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  updateform = (name, data) => {
    // While shaking don't let the values in
    if (this.state.shake) return;

    let focusElement = null;
    const { response_time, http_code, response } = monitoringValues.schedules;

    // Upon changes in some form elements, we need to auto focus to another one.
    // example is when "type" is changed, we need to auto focus to "target"
    // or when "response time" is set to custom, we need to auto focus to the input below it
    // this focusElement is saved in state, and then checked in the check_required_fields to do the focus
    if (name === "type") {
      focusElement = "targetRef";
    } else if (
      name === "response_time" &&
      data === response_time[response_time.length - 1]
    ) {
      focusElement = "response_time_customRef";
    } else if (
      name === "http_code" &&
      data === http_code[http_code.length - 1]
    ) {
      focusElement = "http_code_customRef";
    } else if (name === "response" && data === response[response.length - 1]) {
      focusElement = "response_customRef";
    }

    // Check if custom response_time and/or custom http_code are numbers
    if (name === "response_time_custom" && !Number.isInteger(Number(data)))
      data = this.state.response_time_custom || "";
    if (name === "http_code_custom" && !Number.isInteger(Number(data)))
      data = this.state.http_code_custom || "";

    this.setState({
      [name]: data,
      invalidForm: false,
      formChanged: true,
      streak_checks:
        // If streak_failures is bigger than streak_checks, or if the streak_checks is chnaged, set the new value
        // otherwise let streak_checks be whatever it was
        (name === "streak_failures" && this.state.streak_checks < data) ||
        name === "streak_checks"
          ? data
          : this.state.streak_checks,
      focusElement,
    });
  };

  async removeNotifications(dispatch) {
    const removables = this.props.schedule.notifications
      .map((x) => x.uuid)
      .filter((x) => !this.state.receivers.includes(x));

    if (removables.length) {
      const promises = removables.map((notification_id) => {
        return new Promise((resolve, reject) =>
          FetchAPI.Monitoring.Schedule.deleteNotification({
            schedule: this.props.schedule,
            notification_id,
          })
            .then((response) =>
              resolve({ status: "resolved", id: notification_id }),
            )
            .catch((err) => {
              resolve({
                status: "rejected",
                id: notification_id,
                desc:
                  err.response?.data?.error?.description ||
                  err.response?.data?.error?.message,
              });
            }),
        );
      });
      await Promise.all(promises).then((responses) =>
        toastMultipleResults({
          responses,
          resource_name: "Notifications",
          action: "delete",
          dispatch,
        }),
      );
    }
    return true;
  }

  async addNotifications(dispatch) {
    const addables = this.state.receivers.filter(
      (x) => !this.props.schedule.notifications.find((y) => y.uuid === x),
    );

    if (addables.length) {
      const promises = addables.map((notification_id) => {
        return new Promise((resolve, reject) =>
          FetchAPI.Monitoring.Schedule.addNotification({
            schedule: this.props.schedule,
            notification_id,
          })
            .then((response) =>
              resolve({ status: "resolved", id: notification_id }),
            )
            .catch((err) => {
              resolve({
                status: "rejected",
                id: notification_id,
                desc:
                  err.response?.data?.error?.description ||
                  err.response?.data?.error?.message,
              });
            }),
        );
      });
      await Promise.all(promises).then((responses) =>
        toastMultipleResults({
          responses,
          resource_name: "Notifications",
          action: "create",
          dispatch,
        }),
      );
    }
    return true;
  }

  async update() {
    const { schedule } = this.props;
    const { schedules } = monitoringValues;

    let objectToSend = {
      schedule: {
        name: this.state.name,
        type: this.state.type.toLowerCase(),
        interval_minutes: this.state.interval_minutes,
        fail_limit: this.state.streak_failures,
        fail_interval: this.state.streak_checks,
      },
    };

    if (this.state.target !== schedule.target) {
      let target;
      if (
        this.state.type.toLowerCase() === "http" &&
        !this.state.target.startsWith("http://")
      ) {
        target = `http://${this.state.target}`;
      } else if (
        this.state.type.toLowerCase() === "https" &&
        !this.state.target.startsWith("https://")
      ) {
        target = `https://${this.state.target}`;
      } else {
        target = this.state.target;
      }
      objectToSend.schedule.target = target;
    }

    let meta = {};
    // If response_time is changed, include it in the object to be sent
    if (
      schedules.response_time.includes(this.state.response_time) &&
      this.state.response_time !== schedules.response_time[0] &&
      this.state.type !== "PING"
    ) {
      // If "custom value" is selected, get the input value
      if (
        this.state.response_time ===
        schedules.response_time[schedules.response_time.length - 1]
      ) {
        meta.loadtime = Number(this.state.response_time_custom);
      }
      // else get the selectbox value, slice it and get the beginning number
      else {
        meta.loadtime = Number(this.state.response_time.split(" ")[0]);
      }
    }

    // If http_code is changed (not disabled), include it in the object to be sent
    if (
      schedules.http_code.includes(this.state.http_code) &&
      this.state.http_code !== schedules.http_code[0] &&
      this.state.type !== "PING"
    ) {
      // If "other" is selected, get the input value
      if (
        this.state.http_code ===
        schedules.http_code[schedules.http_code.length - 1]
      ) {
        meta.code = `${this.state.http_code_custom}`;
      }
      // else get the selectbox value, slice it and get the beginning number
      else {
        meta.code = `${this.state.http_code.split(" ")[0]}`;
      }
    }

    // If response is changed (not disabled), include it in the object to be sent
    if (
      schedules.response.includes(this.state.response) &&
      this.state.response !== schedules.response[0] &&
      this.state.type !== "PING"
    ) {
      // If "Must contain text" is selected, get the input value
      if (
        this.state.response ===
        schedules.response[schedules.response.length - 1]
      ) {
        meta.content = this.state.response_custom;
      }
    }

    // if any of the "Conditions" is set, include meta in the object
    if (Object.keys(meta).length) {
      objectToSend.schedule.meta = { ...meta };
    }

    this.setState({
      isUpdating: true,
    });

    // Prepare and Send a request for each contact to be removed
    // Wait until all the remove notif requests are finished then move to next set of requests (add notifications)
    await this.removeNotifications();

    // Prepare and Send a request for each contact to be added
    // Wait until all the add notif requests are finished then move to next request (the actuall modify )
    await this.addNotifications();

    this.props
      .modifySchedule(schedule, objectToSend)
      .then()
      .catch()
      .finally(() =>
        this.setState({
          isUpdating: false,
          formChanged: false,
        }),
      );
  }

  toggleNotificationReceivers = (contact) => {
    let { receivers } = this.state;

    this.setState({
      receivers: toggleArrayItem(receivers, contact.id),
      formChanged: true,
    });
  };

  render() {
    const { invalidForm } = this.state;
    const form_status = this.check_required_fields();

    const contacts = Object.values(this.props.contacts || {});

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Modify Monitoring Schedule" />
          <p></p>
          <Grid>
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <Ref innerRef={(x) => (this.nameRef = x)}>
                  <Input
                    value={this.state.name}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "nameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("name", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={5}
                className="flex vcenter margin-top-30"
              >
                <h5>Target</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={3}
                className="flex vcenter margin-top-30"
              >
                <Select
                  icon="chevron circle down"
                  className={`min-width-100 ${get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    "typeRef",
                    this.state.shake,
                    "error-form-item",
                  )}`}
                  options={convertArrayToSelectOptions(
                    monitoringValues.schedules.type,
                  )}
                  onChange={(e, d) => this.updateform("type", d.value)}
                  value={this.state.type}
                />{" "}
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Ref innerRef={(x) => (this.targetRef = x)}>
                  <Input
                    value={this.state.target}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "targetRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("target", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>Check interval</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Ref innerRef={(x) => (this.intervalRef = x)}>
                  <Select
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "intervalRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    options={convertArrayToSelectOptions(
                      monitoringValues.schedules.interval_minutes,
                      "Every ",
                      " minutes",
                    )}
                    onChange={(e, d) =>
                      this.updateform("interval_minutes", d.value)
                    }
                    value={this.state.interval_minutes}
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>

            {/* ADVANCED */}
            <Grid.Row className="separator">
              {/* STREAK */}
              <Grid.Column
                textAlign="left"
                width={16}
                className="flex vcenter margin-top"
              >
                <h5>
                  Streak threshold
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        className="grey margin-left-half"
                        size="small"
                      />
                    }
                    content={
                      <p>
                        Threshold values controlling how many failed checks that
                        must be made over the defined interval before a state
                        change is triggered.
                        <br />
                        <br />A setting of <Label>1 failure</Label> over the
                        last <Label>1 interval</Label>, would mean a service is
                        considered down immediately if a check fails.
                        <br />
                        <br />A setting of <Label>2 fails</Label> over the last{" "}
                        <Label>2 intervals</Label>, would mean there must be two
                        consecutive failures before the schedule is considered
                        'down'.
                        <br />
                        <br />A setting of <Label>2 fails</Label> over the last{" "}
                        <Label>3 intervals</Label>, would mean there must be at
                        least 2 failures during the last 3 checks before service
                        is considered 'down'.
                      </p>
                    }
                  />
                </h5>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30 flex-wrap"
              >
                <h5 className="padding-right margin-bottom-00 default-height line-height-formitem">
                  State changes after a limit of
                </h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30 flex-wrap"
              >
                <Select
                  icon="chevron circle down"
                  className={`min-width-70 width-70 ${get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    "peerRef",
                    this.state.shake,
                    "error-form-item",
                  )}`}
                  options={convertArrayToSelectOptions(
                    monitoringValues.schedules.streak_failures,
                  )}
                  onChange={(e, d) =>
                    this.updateform("streak_failures", d.value)
                  }
                  value={this.state.streak_failures}
                />
                <h5 className="padding-left default-height line-height-formitem margin-top-00">
                  failures has been reached
                </h5>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-00 flex-wrap"
              >
                <h5 className="padding-right margin-bottom-00 default-height line-height-formitem  ">
                  during the last
                </h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top flex-wrap"
              >
                <Select
                  icon="chevron circle down"
                  className={`min-width-70 width-70 ${get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    "peerRef",
                    this.state.shake,
                    "error-form-item",
                  )}`}
                  options={convertArrayToSelectOptions(
                    monitoringValues.schedules.streak_checks.filter(
                      (x) => x >= this.state.streak_failures,
                    ),
                  )}
                  onChange={(e, d) => this.updateform("streak_checks", d.value)}
                  value={this.state.streak_checks}
                />
                <h5 className="padding-left default-height line-height-formitem margin-top-00">
                  checks made
                </h5>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={16}
                className="separator padding-bottom-20 margin-bottom-30"
              />
              <Grid.Column
                textAlign="left"
                width={16}
                className="flex vcenter margin-top-30"
              >
                <h5>Conditions</h5>
              </Grid.Column>

              {/* RESPONSE TIME*/}
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>
                  Response time
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        className="grey margin-left-half"
                        size="small"
                      />
                    }
                    content={
                      <p>
                        If <Label>Custom value</Label> is selected, the provide
                        value should be a number between <Label>200</Label> and{" "}
                        <Label>60000</Label>
                      </p>
                    }
                  />
                </h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Select
                  icon="chevron circle down"
                  disabled={this.state.type === "PING"}
                  className={get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    "response_timeRef",
                    this.state.shake,
                    "error-form-item",
                  )}
                  options={convertArrayToSelectOptions(
                    monitoringValues.schedules.response_time,
                  )}
                  onChange={(e, d) => this.updateform("response_time", d.value)}
                  value={this.state.response_time}
                />
              </Grid.Column>
              {this.state.response_time ===
              monitoringValues.schedules.response_time[
                monitoringValues.schedules.response_time.length - 1
              ] ? (
                <React.Fragment>
                  <Grid.Column width={8} />
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top"
                  >
                    <Ref innerRef={(x) => (this.response_time_customRef = x)}>
                      <Input
                        placeholder="Custom Value..."
                        disabled={this.state.type === "PING"}
                        value={this.state.response_time_custom}
                        className={get_FormItem_ClassName(
                          form_status,
                          invalidForm,
                          "response_time_customRef",
                          this.state.shake,
                          "error-form-item",
                        )}
                        onChange={(e) =>
                          this.updateform(
                            "response_time_custom",
                            e.currentTarget.value,
                          )
                        }
                      />
                    </Ref>
                  </Grid.Column>
                </React.Fragment>
              ) : null}

              {/* HTTP CODE */}
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>
                  HTTP code
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        className="grey margin-left-half"
                        size="small"
                      />
                    }
                    content={
                      <p>
                        If <Label>Other</Label> is selected, the provide value
                        should be a <Label>valid http_code</Label> in form of
                        number
                      </p>
                    }
                  />
                </h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Select
                  icon="chevron circle down"
                  disabled={this.state.type === "PING"}
                  className={get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    "http_codeRef",
                    this.state.shake,
                    "error-form-item",
                  )}
                  options={convertArrayToSelectOptions(
                    monitoringValues.schedules.http_code,
                  )}
                  onChange={(e, d) => this.updateform("http_code", d.value)}
                  value={this.state.http_code}
                />
              </Grid.Column>
              {this.state.http_code ===
              monitoringValues.schedules.http_code[
                monitoringValues.schedules.http_code.length - 1
              ] ? (
                <React.Fragment>
                  <Grid.Column width={8} />
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top"
                  >
                    <Ref innerRef={(x) => (this.http_code_customRef = x)}>
                      <Input
                        placeholder={monitoringValues.schedules.http_code[1]}
                        disabled={this.state.type === "PING"}
                        value={this.state.http_code_custom}
                        className={get_FormItem_ClassName(
                          form_status,
                          invalidForm,
                          "http_code_customRef",
                          this.state.shake,
                          "error-form-item",
                        )}
                        onChange={(e) =>
                          this.updateform(
                            "http_code_custom",
                            e.currentTarget.value,
                          )
                        }
                      />
                    </Ref>
                  </Grid.Column>
                </React.Fragment>
              ) : null}

              {/* RESPONSE */}
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>Response</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Select
                  icon="chevron circle down"
                  disabled={this.state.type === "PING"}
                  className={get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    "responseRef",
                    this.state.shake,
                    "error-form-item",
                  )}
                  options={convertArrayToSelectOptions(
                    monitoringValues.schedules.response,
                  )}
                  onChange={(e, d) => this.updateform("response", d.value)}
                  value={this.state.response}
                />
              </Grid.Column>
              {this.state.response ===
              monitoringValues.schedules.response[
                monitoringValues.schedules.response.length - 1
              ] ? (
                <React.Fragment>
                  <Grid.Column width={8} />
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter margin-top"
                  >
                    <Ref innerRef={(x) => (this.response_customRef = x)}>
                      <Input
                        placeholder="Custom Text..."
                        disabled={this.state.type === "PING"}
                        value={this.state.response_custom}
                        className={get_FormItem_ClassName(
                          form_status,
                          invalidForm,
                          "response_customRef",
                          this.state.shake,
                          "error-form-item",
                        )}
                        onChange={(e) =>
                          this.updateform(
                            "response_custom",
                            e.currentTarget.value,
                          )
                        }
                      />
                    </Ref>
                  </Grid.Column>
                </React.Fragment>
              ) : null}
            </Grid.Row>

            {/* NOTIFICATION RECEIVERS */}
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Notification receivers</h5>
              </Grid.Column>

              {this.props.contacts_loading !== false && (
                <Grid.Column
                  textAlign="left"
                  width={16}
                  className="flex vcenter"
                >
                  <div className="loader-wrapper">
                    <Loader size="mini" active className="one-liner">
                      Fetching subscription settings...
                    </Loader>
                  </div>
                </Grid.Column>
              )}

              {this.props.contacts_loading === false &&
                (contacts.length === 0 ? (
                  <Grid.Column
                    textAlign="left"
                    width={16}
                    className="flex vcenter"
                  >
                    No contacts found!
                  </Grid.Column>
                ) : (
                  contacts.map((contact, key) => (
                    <Grid.Column
                      key={key}
                      textAlign="left"
                      width={16}
                      className="flex vcenter"
                    >
                      <Checkbox
                        toggle
                        className="float-right margin-top-10 "
                        checked={this.state.receivers.includes(contact.id)}
                        label={contact.name}
                        onChange={() =>
                          this.toggleNotificationReceivers(contact)
                        }
                      />
                    </Grid.Column>
                  ))
                ))}
            </Grid.Row>

            {/* CREATE BUTTONS */}
            <Grid.Row className="">
              <Grid.Column textAlign="left" width={16}>
                {!form_status ? (
                  // if form fields are correctly filled
                  this.state.isUpdating ? (
                    // is updating now....
                    <button className="float-right button button--green overflow-hidden button--icon__right">
                      <Icon loading name="spinner" />
                      <span>Updating</span>
                    </button>
                  ) : // if form is changed, provide the button with click function
                  this.state.formChanged ? (
                    <button
                      className="float-right button button--green"
                      onClick={() => this.update()}
                    >
                      <span>Update</span>
                    </button>
                  ) : (
                    <button className="float-right button button--green button--disabled">
                      <span>Update</span>
                    </button>
                  )
                ) : (
                  // form is not filled correctly
                  <Popup
                    trigger={
                      <button
                        className="float-right button button--green button--disabled button--icon__left"
                        onClick={() => {
                          this.setState({ invalidForm: true, shake: true });
                          handleScrollToItem(this[form_status.ref]);
                        }}
                      >
                        <Icon name="exclamation circle" />
                        <span>Update</span>
                      </button>
                    }
                  >
                    {form_status?.text}
                  </Popup>
                )}
                <button
                  className="float-left button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}></Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    connectivity: state.connectivity,
    contacts: state.monitoring_contacts.MONITORING_CONTACTS_LIST,
    contacts_loading:
      state.monitoring_contacts.MONITORING_CONTACTS_LIST_LOADING,
  };
};

const mapDispatchToProps = (dispatch) => ({
  modifySchedule: (schedule, obj) => dispatch(modifySchedule(schedule, obj)),
  removeSubscription: (name) => dispatch(removeSubscription(name)),
  addSubscription: (name) => dispatch(addSubscription(name)),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModifySchedule);
