import React, { useEffect } from "react";
import { Grid, Input, Popup, Icon, Checkbox, Ref } from "semantic-ui-react";
import PropTypes from "prop-types";

const LoginCredentials = React.forwardRef(
  ({ config, handleChange, errorClassName, credentials }, ref) => {
    const update = (name, value) => {
      handleChange({
        name: "credentials",
        value: {
          ...(credentials || {}),
          [name]: value,
        },
      });
    };

    useEffect(() => {
      if (config.password_required)
        handleChange({
          name: "credentials",
          value: {
            manually: true,
          },
        });
    }, [config.password_required, handleChange]);

    const getLabel = () => {
      if (config.password_required) return "Required";

      return `${credentials?.manually ? "Enabled" : "Disabled"}`;
    };

    return (
      <Ref innerRef={ref}>
        <Grid.Row className="separator">
          <Grid.Column className="" textAlign="left" width={16}>
            <Popup
              trigger={
                <h5>
                  Login Credentials
                  <Icon
                    name="exclamation circle"
                    color="grey"
                    className="margin-left-10"
                  />
                </h5>
              }
              content={
                <p>
                  To log in to a server being created, either an SSH keypair or
                  a password is necessary.
                  <ul>
                    <li>
                      If a keypair is provided, it will be possible to log in as
                      a given user with a private key.
                    </li>
                    <li>
                      If neither a keypair nor password is provided, it will{" "}
                      <i>not</i> be possible to SSH into the server.
                    </li>
                  </ul>
                </p>
              }
            />
            <p>
              Set your own username and password. Password needs at least 6
              characters.
            </p>
          </Grid.Column>

          <Grid.Column
            className="flex vcenter margin-top-20"
            textAlign="left"
            width={8}
          >
            <h5>Username</h5>
          </Grid.Column>
          <Grid.Column
            className="flex vcenter margin-top-20"
            textAlign="left"
            width={8}
          >
            <Input
              value={
                credentials?.username == null
                  ? config?.default_username
                  : credentials?.username
              }
              disabled={!config.username_changeable}
              className={`select-box full`}
              onChange={(e, d) => update("username", d.value)}
            />
          </Grid.Column>

          <Grid.Column
            className="flex vbottom margin-top-30 height-formitem"
            textAlign="left"
            width={8}
          >
            <h5>Set password</h5>
          </Grid.Column>
          <Grid.Column
            className="flex padding-top-half vcenter margin-top-30 height-formitem"
            textAlign="left"
            width={8}
          >
            <Checkbox
              toggle
              checked={credentials?.manually || config.password_required}
              disabled={config.password_required}
              label={getLabel()}
              className=""
              onChange={(e, d) => update("manually", !credentials?.manually)}
            />
          </Grid.Column>

          {(credentials?.manually || config.password_required) && (
            <React.Fragment>
              <Grid.Column
                className="flex vcenter margin-top-30"
                textAlign="left"
                width={8}
              >
                <Popup
                  trigger={
                    <h5>
                      Password
                      <Icon
                        name="exclamation circle"
                        className="margin-left-half"
                        color="grey"
                      />
                    </h5>
                  }
                  content="Password must be at least 6 characters long."
                />
              </Grid.Column>
              <Grid.Column
                className="flex vcenter margin-top-30"
                textAlign="left"
                width={8}
              >
                <Input
                  type="password"
                  className={`margin-bottom select-box full ${errorClassName}`}
                  disabled={!config.password_changeable}
                  value={credentials?.password}
                  onChange={(e, d) => update("password", d.value)}
                />
              </Grid.Column>

              <Grid.Column
                className="flex vcenter margin-top-00"
                textAlign="left"
                width={8}
              >
                <h5>Confirm Password</h5>
              </Grid.Column>
              <Grid.Column
                className="flex vcenter margin-top-00"
                textAlign="left"
                width={8}
              >
                <Input
                  type="password"
                  className={`margin-bottom select-box full ${errorClassName}`}
                  disabled={!config.password_changeable}
                  value={credentials?.password2}
                  onChange={(e, d) => update("password2", d.value)}
                />
              </Grid.Column>
            </React.Fragment>
          )}
        </Grid.Row>
      </Ref>
    );
  },
);

LoginCredentials.propTypes = {
  handleChange: PropTypes.func.isRequired,
  errorClassName: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
  credentials: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default LoginCredentials;
