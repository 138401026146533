import React from "react";
import { Tab, Grid } from "semantic-ui-react";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { filterSecurityGroups } from "../../utils";

import QuickView from "../../../../components/shared/quickview/QuickView";
import Rules from "../../../securitygroups/detailedview/rules/Rules";

const SecurityGroups = ({ server }) => {
  const ports = useSelector((state) => state.ports.PORTS_LIST);
  const security_groups = useSelector(
    (state) => state.securitygroups.SECURITYGROUPS_LIST,
  );

  const list = filterSecurityGroups(server, ports, security_groups);

  const dispatch = useDispatch();

  return (
    <Tab.Pane className="">
      {list.map((securitygroup, i) => (
        <Grid className={i === 0 ? "padding-top-20" : "padding-top-00"} key={i}>
          <Grid.Row className="">
            <Grid.Column
              textAlign="left"
              width={16}
              className="flex vcenter justify-left"
            >
              <h5 className="height-triple flex vbottom padding-left-30 padding-right-20">
                Security Group:
              </h5>
              <QuickView>
                <QuickView.Trigger className="justify-left">
                  {securitygroup?.name || securitygroup?.id}
                </QuickView.Trigger>
                <QuickView.Content>
                  <QuickView.Item>{`Security Group ID: ${securitygroup?.id}`}</QuickView.Item>
                  <QuickView.Item>{`Security Group Name: ${securitygroup?.name}`}</QuickView.Item>
                  <QuickView.Modify
                    onClick={() =>
                      dispatch(
                        toggleSlidingMenu(
                          "modify",
                          "Security Group",
                          securitygroup,
                        ),
                      )
                    }
                  >
                    Modify Security Group
                  </QuickView.Modify>
                  <QuickView.Copy copy={securitygroup?.id}>
                    Copy Security Group ID
                  </QuickView.Copy>
                  <QuickView.ViewIn
                    type="Security Group"
                    resource={securitygroup}
                  />
                  <QuickView.Details
                    type="Security Group"
                    resource={securitygroup}
                  />
                </QuickView.Content>
              </QuickView>
            </Grid.Column>
            <Grid.Column
              textAlign="left"
              width={16}
              className="relative padding-top-00"
            >
              <Rules securitygroup={securitygroup} header="Rules" />
            </Grid.Column>
            {i + 1 < list.length && (
              <Grid.Column
                textAlign="left"
                width={16}
                className=" separator separator--simple padding-top-00 padding-bottom-00 margin-top-00 margin-bottom-00 margin-left-30 margin-right-30 clear"
              />
            )}
          </Grid.Row>
        </Grid>
      ))}
    </Tab.Pane>
  );
};

SecurityGroups.propTypes = {
  server: PropTypes.object.isRequired,
};

export default SecurityGroups;
