import { defaultValues } from "../app_constants";

/**
 * This will return the city (location) of the user's browser
 * NOTE: If user has disabled GPS or have third party app to change the timezone, this may not work properly
 * @returns either the user city location or an empty string
 */
export const getUserCityLocation = () => {
  if (!Intl) return "";

  let zone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || "";
  if (zone) {
    zone = zone?.split("/")?.[1] || "";
  }
  return zone;
};

/**
 * This will randomize a value to start the maintenance window start time
 * @returns //one of :  0,1,2,3,4,5,22,23
 */
export const getRandomStartTime = () =>
  (Math.floor(Math.random() * 8) + 22) % 24;

/**
 * This will take a start time and returns an end time
 * @param {*} begin //one of :  0,1,2,3,4,5,22,23
 * @returns //one of :  0,1,2,...7
 */
export const getRandomEndTime = (begin) => (begin + 2) % 24; //one of : 0,1,2,...7

/**
 * @param {number} h can be a number between 0-59
 */
export const convertToMilitary = (h) => {
  return (h > 9 ? "" : "0") + h;
};

/**
 * Gets hour, minute and list of days and convert it to a cron time
 * https://en.wikipedia.org/wiki/Cron
 * @param {*} hh
 * @param {*} mm
 * @param {*} days
 * @returns
 */
export const createCronTime = (hh, mm, days) => {
  const daysIndex = days
    .map((day) => defaultValues.days.indexOf(day))
    .join(",");

  return `${mm} ${hh} * * ${daysIndex}`;
};

/**
 * This function gets a cron-time string and returns list of days and hours and minutes in an array
 * https://en.wikipedia.org/wiki/Cron
 * @param {*} a "45 14 * * 2,5"
 * @returns [['Tuesday','Friday'] ,14 ,45]
 */
export const destructureCronTime = (a) => {
  const mm = a.substring(0, 2);
  const hh = a.substring(3, 5);

  const d = a.split("* ");
  let dd;

  if (!d[d.length - 1]) {
    dd = [];
  } else {
    dd = d[d.length - 1]
      .split(",")
      .map((x) => Number(x))
      .map((x) => defaultValues.days[x]);
  }

  return [dd, hh, mm];
};

/**
 * Adjust the time string to a valid JS time object
 * @param {String} timeString
 * @returns {Date}
 */
export const convertToJSExpectedTime = (timeString) => {
  // slice the input time string so that we could adjust the month value
  const time = timeString.replace(/-/g, " ").replace(/:/g, " ").split(" ");

  // set month to a value between 0-11 (input is between 1-12)
  time[1]--;

  return new Date(...time);
};

/**
 * Gets a time in milliseconds and returns a human readable string consists of months, days, minutes, seconds (if they are not zero)
 * @param {Number} milliseconds
 * @returns {String} 'X months, Y minutes' is a valid return string
 */
export const millisecondsToTimeChunks = (milliseconds) => {
  const include = (name, num) => `${num} ${name}${num > 1 ? "s" : ""}, `;

  const second = milliseconds % 60;
  const minute = Math.floor((milliseconds / 60) % 60);
  const hour = Math.floor((milliseconds / 60 / 60) % 24);
  const day = Math.floor((milliseconds / 60 / 60 / 24) % 30);
  const month = Math.floor(milliseconds / 60 / 60 / 24 / 30);

  let duration = "";

  if (month) {
    duration += include("month", month);
  }
  if (day) {
    duration += include("day", day);
  }
  if (hour) {
    duration += include("hour", hour);
  }
  if (minute) {
    duration += include("minute", minute);
  }
  if (second) {
    duration += include("second", second);
  }

  if (duration) {
    duration = duration.slice(0, -2);
  }

  return duration;
};

/**
 * Gets a date in string format in the form of => YYYY-MM-DD HH:MM:SS (2022-02-12 05:22:28)
 * @param {String} dateString
 * @returns {Object}
 */
const getMinimumDate = (dateString) => {
  return {
    year: Number(dateString.split("-")[0]),
    month: Number(dateString.split("-")[1]),
    day: Number(dateString.split("-")[2].slice(0, 2)),
  };
};

/**
 * @param {Date} date object
 * @returns {Object}
 */
const getMaximumDate = () => {
  const now = new Date();

  return {
    year: now.getFullYear(),
    month: now.getMonth() + 1,
    day: now.getDate(),
  };
};

/**
 * Returns the last week object in the form of an object
 * @returns {Object}
 */
const getLastWeekDate = () => {
  let lastWeek = new Date();
  lastWeek.setDate(lastWeek.getDate() - 7);

  return {
    year: lastWeek.getFullYear(),
    month: lastWeek.getMonth() + 1,
    day: lastWeek.getDate(),
  };
};

/**
 * Takes a date in string format YYYY-MM-DD HH:MM:SS (2022-02-12 05:22:28)
 * and returns a big object that includes different time objects
 * @param {Object} dateString
 * @returns {Object}
 */
export const getInitialScheduleInterval = (dateString) => {
  const minimumDate = getMinimumDate(dateString);

  const maximumDate = getMaximumDate();

  const lastWeek = getLastWeekDate();

  const range = {
    from: lastWeek,
    to: maximumDate,
  };

  return {
    range,
    maximumDate,
    minimumDate,
  };
};

export const getTimeFromDate = (date) => {
  return `${("0" + date.getHours()).slice(-2)}:${(
    "0" + date.getMinutes()
  ).slice(-2)}:${("0" + date.getSeconds()).slice(-2)}`;
};
