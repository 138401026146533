import getFetch, { SerializableObject } from "../../getFetch";
import config from "../../../config";
import { State } from "../../../selectors/state";

const Base = {
  checkAppVersion: function () {
    return getFetch({
      url: `/static/version_build.json`,
      type: "get",
    });
  },

  getAppSettings: function () {
    return getFetch<State["connectivity"]["app_settings"]>({
      url: `${config.backend}/app_settings`,
      type: "get",
    });
  },

  verifyClient: function () {
    return getFetch({
      url: `${config.api_url}/alive/v1/alive`,
      type: "get",
    });
  },

  getCountriesList: function () {
    return getFetch({
      url: `${config.api_url}/base/v1/countries`,
      type: "get",
    });
  },

  getOpenstackSettings: function () {
    return getFetch({
      url: `${config.backend}/openstack_settings`,
      type: "get",
    });
  },

  setOpenstackSettings: function (objectToSend: SerializableObject) {
    return getFetch({
      url: `${config.backend}/openstack_settings`,
      type: "post",
      params: objectToSend,
    });
  },
};

export default Base;
