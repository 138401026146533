import { useEffect } from "react";
import { Icon, Loader, Popup, Table } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import { deleteEmail, getEmails } from "./actions";
import { confirmbox_open } from "../../../confirmbox/actions";
import CircularButton from "../../../shared/circularbutton/CircularButton";

const EmailList = () => {
  const dispatch = useDispatch();
  const emails = useSelector((state) => state.user_emails.emails);
  const userHasUnverifiedEmails = emails?.some(
    (email) => email.confirmed === "f",
  );
  const userHasOnlyOneEmail = emails?.length === 1;

  useEffect(() => {
    dispatch(getEmails());
  }, [dispatch]);

  const renderDeleteButton = (email) => (
    <CircularButton
      onClick={() => handleDeleteConfirmation(email)}
      className={`button button--circular margin-right-half button--circular__danger ${
        userHasOnlyOneEmail ? "button--disabled" : ""
      }`}
      icon="trash"
      popupContent={
        userHasOnlyOneEmail
          ? "You cannot delete your last email."
          : "Delete email"
      }
    />
  );

  const handleDeleteConfirmation = (email) => {
    if (!userHasOnlyOneEmail) {
      dispatch(
        confirmbox_open({
          entity: "email",
          operation: "delete",
          resources: { name: email },
          onConfirm: (resource) => deleteEmail(resource.name),
        }),
      );
    }
  };

  const renderRoleIcon = (role) => (
    <Icon
      className={`margin-right-half font-L margin-top-half ${
        role ? "green" : "red"
      }`}
      name={`${role ? "check" : "times"} circle`}
    />
  );

  const renderEmailRow = (item, key) => {
    const isEmailConfirmed = item.confirmed === "t";

    return (
      <Table.Row key={key}>
        <Table.Cell>
          {item.email}
          {!isEmailConfirmed && (
            <Popup
              trigger={
                <Icon
                  name="warning sign"
                  color="orange"
                  className="margin-left"
                />
              }
              position="top center"
              hoverable={false}
              content='Not Verified. Click "Modify email" to finish verification.'
            />
          )}
        </Table.Cell>
        <Table.Cell collapsing>
          {renderRoleIcon(item.roles.includes("billing"))}
        </Table.Cell>
        <Table.Cell collapsing>
          {renderRoleIcon(item.roles.includes("tech"))}
        </Table.Cell>
        <Table.Cell collapsing>
          {renderRoleIcon(item.roles.includes("dpo"))}
        </Table.Cell>
        <Table.Cell collapsing>
          <CircularButton
            onClick={() =>
              dispatch(toggleSlidingMenu("modify", "Email", item.email))
            }
            className="button button--circular margin-right-half"
            icon={isEmailConfirmed ? "edit" : "checkmark"}
            popupContent={isEmailConfirmed ? "Modify email" : "Verify email"}
          />
          {renderDeleteButton(item.email)}
        </Table.Cell>
      </Table.Row>
    );
  };

  if (Array.isArray(emails)) {
    if (emails.length) {
      return (
        <div className="relative padding-top-00">
          <div className="margin-bottom-20">
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Email Address</Table.HeaderCell>
                  <Table.HeaderCell collapsing>Billing</Table.HeaderCell>
                  <Table.HeaderCell collapsing>Tech</Table.HeaderCell>
                  <Table.HeaderCell collapsing>DPO</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>
              <Table.Body>{emails.map(renderEmailRow)}</Table.Body>
            </Table>
          </div>

          {userHasUnverifiedEmails ? (
            <Popup
              trigger={
                <button className="float-right button button--green button--disabled button--icon__left ">
                  <Icon name="exclamation circle" />
                  <span>Create new Email</span>
                </button>
              }
              content="Finish verification of existing emails before creating a new one"
            />
          ) : (
            <button
              className="float-right button button--green margin-top"
              onClick={() => dispatch(toggleSlidingMenu("create", "Email"))}
            >
              <span>Create new Email</span>
            </button>
          )}
        </div>
      );
    } else {
      return (
        <div className="relative padding-top">
          <div className="relative padding-top padding-left-20 padding-bottom-40 ">
            <p>Email List</p>
            <p>
              <Icon name="warning sign" className="margin-right-half" />
              This account has no emails defined
            </p>
          </div>
        </div>
      );
    }
  }

  if (typeof emails === "string") {
    return (
      <div className="relative ">
        <div className="relative padding-top padding-left-20 padding-bottom-40 ">
          <p>Email List</p>
          <p>
            <Icon name="warning sign" className="margin-right-half" />
            {emails}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="relative ">
      <Loader size="mini" active className="one-liner margin-top-3quarter">
        Fetching Email list...
      </Loader>
    </div>
  );
};

export default EmailList;
