import React from "react";
import SnapshotsTable from "./SnapshotsTable";
import FancyHeader from "../../components/shared/FancyHeader";
import FetchAPI from "../../api/FetchAPI";
import WrapTextWithPopup from "../../components/shared/WrapTextWithPopup";
import Fallback from "../../components/slidingpanel/Fallback";

import {
  Grid,
  Input,
  Checkbox,
  Icon,
  Dropdown,
  Loader,
  Table,
  Popup,
  Modal,
  Header,
  Label,
} from "semantic-ui-react";
import { connect } from "react-redux";
import {
  keyExistsInList,
  checkMissingArrayEntries,
  getCurrentProjectID,
  isResourceUnAvailable,
  toastError,
} from "../../app_shared_functions";
import { getSelectItemClassName } from "../../shared-functions/string";
import {
  removeSubscription,
  addSubscription,
} from "../../actions/connectivity";
import {
  detachVolume,
  attachVolumeToServer,
  createSnapshotFromVolume,
} from "../cinder/volumes/actions";

import { withTranslation } from "react-i18next";

import {
  attachInterfaceToServer,
  detachInterfaceFromServer,
  renameServer,
  resizeServer,
  resizeConfirm,
  listSnapShots,
  createSnapShotFromServer,
  addSecurityGroupToServer,
  removeSecurityGroupFromServer,
  attachVolume_StateUpdate,
  detachVolume_StateUpdate,
  snapshotCreate_StateUpdate,
  updateServerDisasterRecoveryStatus,
  resizeCancel,
} from "./actions";

import { deleteServer_snapshot } from "./snapshots/actions";
import { toggleSlidingMenu } from "../../actions/toggleSlidingMenu";
import { toast } from "react-toastify";

import {
  getPowerState,
  calculateLicensePrice,
  isResizing,
  isResizeWaiting,
  processFlavors,
} from "./utils";

import { confirmbox_open } from "../../components/confirmbox/actions";

import Flavors from "./flavors/Flavors";
import Resizing from "./bits/Resizing";
import ResizeWaiting from "./bits/ResizeWaiting";

import BootFromVolumeFlavors from "./create/boot-target/BootFromVolumeFlavors";
import CreateSnapshot from "./detailedview/snapshots/bits/CreateSnapshot";
import CreateSnapshotFromVolume from "../cinder/volumes/CreateSnapshotFromVolume";
import { getQuotaExceedingResources } from "../../shared-functions/quota";
import CircularButton from "../../components/shared/circularbutton/CircularButton";
import MonthlyPrice from "./create/price/MonthlyPrice";

class ModifyServer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      volumeList: null,
      loading: true,
      resourceLoaded: false,
      name: this.props.server?.name,
      flavorProfiles: this.props.server?.flavor?.profiles,
      selectedFlavorId: this.props.server?.flavor?.id,
      volumeSnapshotCreateApproval: true,
      serverSnapshotCreateApproval: false,
      subscriptionsStarted: [],
    };
  }

  // load more data of the server
  componentDidMount() {
    let subscriptionsToStart = checkMissingArrayEntries(
      this.props.connectivity.activeSubscriptions,
      [
        "SERVERS_LIST",
        "VOLUMES_LIST",
        "NETWORKS_LIST",
        "SERVER_SNAPSHOTS_LIST",
        "SECURITYGROUPS_LIST",
      ],
    );
    subscriptionsToStart.forEach((x) => this.props.addSubscription(x));
    this.setState({
      subscriptionsStarted: subscriptionsToStart,
    });
    this.checkComponentResources();

    FetchAPI.Prices.getOpenStackPrices().then((res) =>
      this.setState({
        priceList: res.data,
      }),
    );

    const isOn = getPowerState(this.props?.server);
    this.setState({
      license_cost: calculateLicensePrice(
        this.props?.server?.flavor?.vcpus,
        this.props?.server?.image?.license,
        isOn,
      ),
    });
    this.getRegionQuota(this.props?.server.region);
    this.getFlavorsList(this.props?.server.region, this.state.flavorProfiles);
    this.setDisasterRecoveryPossible(this.state.flavorProfiles);
  }

  setDisasterRecoveryPossible(flavorProfiles) {
    FetchAPI.Compute.FlavorProfiles.getList().then(({ data: profiles }) => {
      const disasterRecoveryPossible = Boolean(
        flavorProfiles?.every(
          (ip) => profiles.find((p) => p.name === ip)?.disasterRecoverSupported,
        ),
      );

      const disasterRecovery =
        this.state.disasterRecovery && disasterRecoveryPossible;

      this.setState({
        disasterRecoveryPossible,
        disasterRecovery,
      });
    });
  }

  getFlavorsList(region, profileFilter) {
    FetchAPI.Compute.Flavors.getList({
      region,
      project_id: this.props.server.project_id,
    }).then((res) => {
      const flavors = processFlavors(
        res.data,
        this.props.server,
        profileFilter,
      );
      this.setState({ flavors });
    });
  }

  componentWillUnmount() {
    this.state.subscriptionsStarted.forEach((subscriptionName) => {
      this.props.removeSubscription(subscriptionName);
    });
  }

  getRegionQuota(region) {
    FetchAPI.Usage.getQuota({
      region,
      project_id: getCurrentProjectID(this.props.projects, region),
    }).then((res) => {
      this.setState({ quota: res.data });
    });
  }

  checkComponentResources() {
    if (
      !this.state.resourceLoaded &&
      keyExistsInList(this.props.servers.SERVERS_LIST, this.props.server.id)
    ) {
      this.setState({
        resourceLoaded: true,
        name: this.props.servers.SERVERS_LIST[this.props.server.id].name,
        flavorProfiles:
          this.props.servers.SERVERS_LIST[this.props.server.id].flavor.profiles,
        selectedFlavorId:
          this.props.servers.SERVERS_LIST[this.props.server.id].flavor.id,
        disasterRecovery: Boolean(
          this.props.server.disasterRecoverService?.status ||
            this.props.servers.SERVERS_LIST[this.props.server.id]
              .disasterRecoverService?.status,
        ),
      });
    }
  }

  updateInput(name, value) {
    this.setState({
      [name]: value,
      form_renamed: true,
    });
  }

  createServerSnapshot(server) {
    if (!this.state.newServerSnapshotName) {
      toastError("Please provide a name!");
      return;
    }
    const objectToSend = {
      snapshot: {
        name: this.state.newServerSnapshotName,
      },
    };
    this.setState({
      createServerSnapshotStatus: "inprogress",
      newServerSnapshotName: "",
    });
    toast.success("Snapshot creation started...");

    this.props.snapshotCreate_StateUpdate(server);
    this.props
      .createSnapShotFromServer(server, objectToSend)
      .catch((err) => {
        toastError(err, "Creating server snapshot failed!");
      })
      .finally(() =>
        this.setState({
          createServerSnapshotStatus: false,
        }),
      );
  }

  /**
   * For use with ephemeral selection where ram and cores are inputted manually
   * `input.core` is a misnomer: it represents the number of cores, not a single one. It is called this for compatibility.
   * @param {{ ram: number, core: number }} input
   */
  selectFlavorByRamAndCores = ({ ram, core: cores }) => {
    const server = this.getCurrentServer();
    this.searchFlavor(server.region, cores, ram, server.flavor.disk).then(
      (flavor) => this.selectFlavor(flavor),
    );
  };

  getCurrentServer() {
    return this.props.servers.SERVERS_LIST[this.props.server.id];
  }

  renameServer(server) {
    const objectToSend = {
      server: {
        name: this.state.name,
      },
    };
    this.props
      .renameServer(server, objectToSend)
      .then(() => this.setState({ form_renamed: false }));
  }

  // Send the region/core/ram/disk and get a flavor object for that. Input ram is in GB
  async searchFlavor(region, cores, ram, disk) {
    const response = await FetchAPI.Compute.Flavors.search({
      region: region,
      project_id: getCurrentProjectID(this.props.projects, region),
      searchTerm: { search: { cores, ram, disk } },
    });

    return response.data;
  }

  resize(server) {
    this.props
      .resizeServer(server, { id: this.state.selectedFlavorId })
      .finally(() => {
        this.setState({ form_resized: false });
      });
  }

  attachVolume = (server, volume) => {
    this.setState({ attachVolume_State: "Initialized" });
    this.props.attachVolume_StateUpdate(server);
    this.props
      .attachVolumeToServer(server, volume)
      .then(() => this.setState({ attachVolume_State: "Started" }))
      .catch(() => this.setState({ attachVolume_State: "" }));
  };
  detachVolume = (server, volume) => {
    this.props.detachVolume_StateUpdate(server);
    this.props.detachVolume(volume);
  };

  attachInterface = (server, network) => {
    this.setState({ attachInterface_State: "Started" });
    this.props
      .attachInterfaceToServer(server, network)
      .finally(() => this.setState({ attachInterface_State: "" }));
  };

  componentDidUpdate(prevProps) {
    this.checkComponentResources();

    // Detect a volume attach
    const prevVolumes = prevProps.volumes.VOLUMES_LIST;
    const currVolumes = this.props.volumes.VOLUMES_LIST;
    if (
      Object.values(prevVolumes).filter(
        (vol) =>
          vol.attachments[0] &&
          vol.attachments[0].server_id === this.props.server.id &&
          vol.status === "in-use",
      ).length <
      Object.values(currVolumes).filter(
        (vol) =>
          vol.attachments[0] &&
          vol.attachments[0].server_id === this.props.server.id &&
          vol.status === "in-use",
      ).length
    )
      this.setState({ attachVolume_State: "" });
  }

  addSecurityGroup = (sg) => {
    const objectToSend = {
      security_group: {
        name: sg.name,
      },
    };
    this.props.addSecurityGroupToServer(
      sg,
      this.props.servers.SERVERS_LIST[this.props.server.id],
      objectToSend,
    );
  };

  removeSecurityGroup = (sg) => {
    this.props.dispatch(
      confirmbox_open({
        entity: "security group",
        operation: "remove",
        resources: {
          ...sg,
          server: this.props.servers.SERVERS_LIST[this.props.server.id],
        },
        onConfirm: removeSecurityGroupFromServer,
      }),
    );
  };

  updateDisasterRecovery(server) {
    const objectToSend = {
      server: {
        dr_service_enabled: this.state.disasterRecovery,
      },
    };
    this.props
      .updateServerDisasterRecoveryStatus(server, objectToSend)
      .then(() => this.setState({ form_disaster_recovery: false }));
  }

  isInRescueMode = () => {
    const thisServer = this.props.servers.SERVERS_LIST[this.props.server.id];
    return (
      thisServer.status === "RESCUE" ||
      thisServer.task_state === "rescuing" ||
      thisServer.task_state === "unrescuing"
    );
  };

  isBootVolume = (server, volume) => volume.id === server?.rootDevice?.id;

  isBootingFromVolume = (server) =>
    server?.rootDevice?.type === "volume" &&
    server?.rootDevice?.id !== server?.id;

  showLegacyInputs = (server) =>
    server?.flavor?.profiles?.includes("ephemeral") &&
    server?.rootDevice?.type === "local";

  //Updating flavor on BFV server
  selectFlavor = ({ id, cores, ram }) => {
    this.setState({
      form_resized: true,
      selectedFlavorId: id,
    });

    // Flavors have ram in MB, quota calculation is in GB
    this.updateExceedingQuota(cores, ram / 1024);
    this.setDisasterRecoveryPossible(this.state.flavorProfiles);
  };

  updateExceedingQuota = (cores, ram) => {
    const requested = {
      compute: {
        ram_gb: ram - this.props.server.flavor.ramGB,
        cores: cores - this.props.server.flavor.vcpus,
      },
    };
    const isExceeding = getQuotaExceedingResources(
      this.state?.quota?.available || {},
      requested,
    );
    this.setState({ isExceeding });
  };

  render() {
    if (
      isResourceUnAvailable({
        list: this.props.servers,
        id: this.props.server.id,
        name: "server",
      })
    )
      return <Fallback component="Server" />;

    if (!this.state.resourceLoaded) {
      return (
        <Loader size="mini" active>
          Fetching data...
        </Loader>
      );
    }

    const { priceList } = this.state;

    const thisServer = this.props.servers.SERVERS_LIST[this.props.server.id];

    if (!thisServer) {
      return <Fallback component="Server" />;
    }

    const { volumes, networks, server_snapshots, securitygroups_List } =
      this.props;

    const { name } = thisServer;

    // get the list of attached/detached volumes
    const attachedVolumes = volumes.VOLUMES_LIST
      ? Object.keys(volumes.VOLUMES_LIST)
          .filter(
            (item) =>
              volumes.VOLUMES_LIST[item].attachments[0] &&
              volumes.VOLUMES_LIST[item].attachments[0].server_id ===
                thisServer.id &&
              volumes.VOLUMES_LIST[item].status !== "available" &&
              volumes.VOLUMES_LIST[item].availability_zone ===
                thisServer["OS-EXT-AZ:availability_zone"],
          )
          .sort((volume) => (this.isBootVolume(thisServer, volume) ? 1 : -1))
      : [];
    const unattachedVolumes =
      volumes.VOLUMES_LIST &&
      Object.keys(volumes.VOLUMES_LIST).filter(
        (item) =>
          attachedVolumes.indexOf(item) === -1 &&
          volumes.VOLUMES_LIST[item].status === "available" &&
          volumes.VOLUMES_LIST[item].region === thisServer.region &&
          volumes.VOLUMES_LIST[item].availability_zone ===
            thisServer["OS-EXT-AZ:availability_zone"],
      );

    // I need to make an array of networks, where each network has both fixed and floating ip in one object (if it has both)
    // 1. I flat the networks object into a flat array
    const flattenArray = networks.NETWORKS_LIST
      ? Object.keys(thisServer.addresses).reduce((acc, item) => {
          const obj = thisServer.addresses[item].map((x) => ({
            ...x,
            name: item,
          }));
          acc = [...acc, ...obj];
          return acc;
        }, [])
      : [];
    // 2. Then I merge duplicates into one item!
    const attachedNetworks = flattenArray.reduce((acc, item) => {
      const index = acc.findIndex(
        (val) =>
          val["OS-EXT-IPS-MAC:mac_addr"] === item["OS-EXT-IPS-MAC:mac_addr"],
      );
      if (index > -1)
        acc[index] = { ...acc[index], hasBoth: true, addr2: item.addr };
      else acc = [...acc, item];
      return acc;
    }, []);

    const unattachedNetworks =
      networks.NETWORKS_LIST &&
      Object.keys(networks.NETWORKS_LIST).filter(
        (net) =>
          !networks.NETWORKS_LIST[net]["router:external"] &&
          networks.NETWORKS_LIST[net].region === thisServer.region,
      );

    const current_Securitygroups = Object.values(
      securitygroups_List.SECURITYGROUPS_LIST,
    )
      .filter((sg) => sg.region === thisServer.region)
      .filter(
        (sg) =>
          thisServer.security_groups &&
          thisServer.security_groups.find((thisSG) => thisSG.name === sg.name),
      );

    const available_Securitygroups = Object.values(
      securitygroups_List.SECURITYGROUPS_LIST,
    )
      .filter((sg) => sg.region === thisServer.region)
      .filter((sg) => !current_Securitygroups.find((cur) => cur.id === sg.id));

    const currentServer = this.getCurrentServer();

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader
            title="Modify Server"
            subtitle={name}
            region={thisServer.region}
            knowledgeBase
          />
          <MonthlyPrice
            flavorId={this.state.selectedFlavorId}
            regionId={currentServer.region}
            imageId={currentServer?.image?.id}
            volumeSize={
              currentServer?.rootDevice?.type === "volume"
                ? currentServer.rootDevice.size
                : 0
            }
            disasterRecovery={this.state.disasterRecovery}
          />

          <p className={`${priceList ? "padding-top-50" : ""}`}></p>

          <Grid>
            {/* NAME */}
            <Grid.Row className="separator">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Server name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Input
                  value={this.state.name}
                  placeholder="Server name..."
                  className="select-box full"
                  onChange={(e) =>
                    this.updateInput("name", e.currentTarget.value)
                  }
                />
              </Grid.Column>
              {this.state.form_renamed && (
                <Grid.Column textAlign="left" width={16}>
                  <div className="padding-top-30 flexbox row-reverse">
                    <button
                      className="button button--green "
                      onClick={() => this.renameServer(thisServer)}
                    >
                      <span>Rename</span>
                    </button>
                  </div>
                </Grid.Column>
              )}
            </Grid.Row>

            {/* RAM CORE */}
            <Grid.Row className="separator padding-top-00">
              {this.showLegacyInputs(thisServer) ? (
                <React.Fragment>
                  <Grid.Column textAlign="left" width={16}>
                    <h4 className="margin-bottom-10">Flavor</h4>
                  </Grid.Column>

                  {
                    // show the sliders for the stpped or active servers
                    thisServer.status &&
                      (thisServer.status.toLowerCase() === "shutoff" ||
                        thisServer.status.toLowerCase() === "stopped" ||
                        thisServer.status.toLowerCase() === "active") &&
                      (thisServer["OS-EXT-STS:task_state"] === null ||
                        thisServer["OS-EXT-STS:task_state"] === "" ||
                        !thisServer["OS-EXT-STS:task_state"]
                          .toLowerCase()
                          .includes("resize")) && (
                        <Grid.Column textAlign="left" width={16}>
                          <Flavors
                            minRam={(thisServer?.image?.min_ram || 0) / 1024}
                            defaultRam={(thisServer?.flavor?.ram || 0) / 1024}
                            defaultCores={thisServer?.flavor?.vcpus}
                            updateFlavors={this.selectFlavorByRamAndCores}
                            discardItems={["volume"]}
                          />
                        </Grid.Column>
                      )
                  }
                </React.Fragment>
              ) : this.state.flavors ? (
                <BootFromVolumeFlavors
                  disabled={
                    isResizeWaiting(thisServer) || isResizing(thisServer)
                  }
                  flavor={{ id: this.state.selectedFlavorId }}
                  onFlavorUpdate={this.selectFlavor}
                  flavors={this.state.flavors}
                />
              ) : (
                <div className="loader-wrapper">
                  <Loader size="mini" active className="float-left one-liner">
                    Fetching flavors...
                  </Loader>
                </div>
              )}

              {this.state.form_resized && (
                <Grid.Column textAlign="left" width={16}>
                  <div className="flexbox row-reverse margin-top-20">
                    {this?.state?.isExceeding ? (
                      <Popup
                        trigger={
                          <button className="button button--green button--disabled button--icon__left">
                            <Icon name="exclamation circle" />
                            <span>Resize</span>
                          </button>
                        }
                        content={this.state.isExceeding}
                      />
                    ) : (
                      <button
                        className="button button--green "
                        onClick={() => this.resize(thisServer)}
                      >
                        <span>Resize</span>
                      </button>
                    )}
                  </div>
                </Grid.Column>
              )}

              {thisServer.status.toLowerCase() !== "shutoff" &&
                thisServer.status.toLowerCase() !== "stopped" &&
                thisServer.status.toLowerCase() !== "resized" &&
                thisServer.status.toLowerCase() !== "verify_resize" &&
                thisServer.status.toLowerCase() !== "active" &&
                (thisServer["OS-EXT-STS:task_state"] === null ||
                  thisServer["OS-EXT-STS:task_state"] === "" ||
                  !thisServer["OS-EXT-STS:task_state"]
                    .toLowerCase()
                    .includes("resize")) && (
                  <p className="margin-left margin-top-30">
                    Resizing is only available for servers in{" "}
                    <Label as="span">SHUTOFF</Label> or{" "}
                    <Label as="span">ACTIVE</Label> state.
                  </p>
                )}

              {isResizeWaiting(thisServer) && (
                <Grid.Column
                  textAlign="left"
                  width={16}
                  className="margin-top-30"
                >
                  <ResizeWaiting
                    server={thisServer}
                    confirmbox_open={this.props.confirmbox_open}
                    resizeConfirm={this.props.resizeConfirm}
                    resizeCancel={resizeCancel}
                  />
                </Grid.Column>
              )}

              {
                // resize is in progress
                isResizing(thisServer) && (
                  <Grid.Column
                    textAlign="left"
                    width={16}
                    className="margin-top-30"
                  >
                    <Resizing server={thisServer} />
                  </Grid.Column>
                )
              }
            </Grid.Row>

            {/* DISASTER RECOVERY */}
            <Grid.Row className="separator">
              <Grid.Column textAlign="left" width={8} className="flex vbottom">
                <h5>Disaster Recovery </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Popup
                  trigger={
                    <Checkbox
                      toggle
                      disabled={
                        !this.state.disasterRecoveryPossible ||
                        this.isInRescueMode()
                      }
                      checked={this.state.disasterRecovery}
                      label={
                        this.state.disasterRecovery ? "Enabled" : "Disabled"
                      }
                      onChange={() =>
                        this.setState({
                          disasterRecovery: !this.state.disasterRecovery,
                          form_disaster_recovery: true,
                        })
                      }
                    />
                  }
                  disabled={this.state.disasterRecoveryPossible}
                  position="top center"
                >
                  Disaster recovery not available for this profile
                </Popup>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={16}
                className="margin-top-20"
              >
                {this.isInRescueMode() && (
                  <p>
                    <Icon name="warning circle" /> Requesting Disaster recovery
                    is not available when server is in rescue mode!
                  </p>
                )}
                {this.state.form_disaster_recovery &&
                  !this.isInRescueMode() && (
                    <div className="flexbox row-reverse">
                      <button
                        className="button button--green "
                        onClick={() => this.updateDisasterRecovery(thisServer)}
                      >
                        <span>Save</span>
                      </button>
                    </div>
                  )}
              </Grid.Column>
            </Grid.Row>

            {/* SECURITY GROUPS */}
            <Grid.Row className="separator">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Security Groups </h5>
              </Grid.Column>
              <Grid.Column textAlign="right" width={8}>
                <Dropdown
                  scrolling
                  text="Add a security group"
                  icon="chevron circle down"
                  className="select-box full"
                >
                  <Dropdown.Menu className="width-250 overflow-auto">
                    {available_Securitygroups &&
                      available_Securitygroups.map((sg, key) => (
                        <Dropdown.Item
                          onClick={() => this.addSecurityGroup(sg)}
                          className={getSelectItemClassName(sg.name)}
                          key={key}
                        >
                          <span>{sg.name}</span>
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Grid.Column>

              <Grid.Column textAlign="left" width={16}>
                {current_Securitygroups.length ? (
                  <div className="curve-table">
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Name</Table.HeaderCell>
                          <Table.HeaderCell>Rules</Table.HeaderCell>
                          <Table.HeaderCell>Revison number</Table.HeaderCell>
                          <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {current_Securitygroups.map((sg, key) => (
                          <Table.Row key={key}>
                            <Table.Cell title={sg.name}>
                              <WrapTextWithPopup data={sg.name} trimSize={12} />
                            </Table.Cell>
                            <Table.Cell>
                              {sg.security_group_rules.length}
                            </Table.Cell>
                            <Table.Cell>{sg.revision_number}</Table.Cell>
                            <Table.Cell>
                              {sg.task_status === "deleting" ? (
                                <div className="flex vcenter">
                                  <Icon name="spinner" loading size="large" />
                                </div>
                              ) : (
                                <div className="flex vcenter">
                                  <CircularButton
                                    onClick={() =>
                                      this.props.modifySecurityGroup(sg)
                                    }
                                    className="button button--circular margin-right-half "
                                    icon="edit"
                                    popupContent="Modify"
                                  />
                                  <CircularButton
                                    onClick={() => this.removeSecurityGroup(sg)}
                                    className="button button--circular margin-right-half button--circular__danger"
                                    icon="trash"
                                    popupContent="Remove security group"
                                  />
                                </div>
                              )}
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </div>
                ) : null}
                <button
                  className="float-right  margin-bottom-20 margin-top-10 clear-after button button--green"
                  onClick={() => this.props.createSecurityGroup()}
                >
                  <span>Create a Security group</span>
                </button>
              </Grid.Column>
            </Grid.Row>

            {/* VOLUMES */}
            <Grid.Row className="separator">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>
                  Volumes
                  {this.state.attachVolume_State &&
                    this.state.attachVolume_State === "Initialized" && (
                      <Label
                        className="margin-left"
                        basic
                        color="blue"
                        size="medium"
                      >
                        <Icon name="attach" size="small" color="blue" />
                        {this.state.attachVolume_State}
                      </Label>
                    )}
                  {this.state.attachVolume_State &&
                    this.state.attachVolume_State === "Started" && (
                      <Label
                        className="margin-left"
                        basic
                        color="blue"
                        size="large"
                      >
                        <Icon
                          loading
                          name="spinner"
                          size="small"
                          color="blue"
                        />
                        {this.state.attachVolume_State}
                      </Label>
                    )}
                </h5>
              </Grid.Column>
              <Grid.Column textAlign="right" width={8}>
                <Dropdown
                  scrolling
                  text="Attach a volume"
                  icon="chevron circle down"
                  className="select-box full"
                >
                  <Dropdown.Menu className=" overflow-auto">
                    {unattachedVolumes &&
                      unattachedVolumes.map((volume, key) => (
                        <Dropdown.Item
                          onClick={() =>
                            this.attachVolume(
                              thisServer,
                              volumes.VOLUMES_LIST[volume],
                            )
                          }
                          className={getSelectItemClassName(
                            `${volumes.VOLUMES_LIST[volume].name} (${volumes.VOLUMES_LIST[volume].size}GB)`,
                          )}
                          key={key}
                        >
                          <span>
                            {volumes.VOLUMES_LIST[volume].name} (
                            {volumes.VOLUMES_LIST[volume].size}GB)
                          </span>
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Grid.Column>
              <Grid.Column textAlign="left" width={16}>
                {volumes.VOLUMES_LIST ? (
                  attachedVolumes.length ? (
                    <div className="curve-table">
                      <Table celled>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell>Size</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {attachedVolumes.map((volume, key) => (
                            <Table.Row key={key}>
                              <Table.Cell
                                title={volumes.VOLUMES_LIST[volume].name}
                                className={
                                  this.isBootVolume(
                                    thisServer,
                                    volumes.VOLUMES_LIST[volume],
                                  ) && "italic"
                                }
                              >
                                <WrapTextWithPopup
                                  data={
                                    volumes.VOLUMES_LIST[volume].name ||
                                    "- No Name -"
                                  }
                                  trimSize={12}
                                />
                              </Table.Cell>
                              <Table.Cell
                                className={
                                  this.isBootVolume(
                                    thisServer,
                                    volumes.VOLUMES_LIST[volume],
                                  ) && "italic"
                                }
                              >
                                {this.isBootVolume(
                                  thisServer,
                                  volumes.VOLUMES_LIST[volume],
                                )
                                  ? "{- Boot Volume -}"
                                  : volumes.VOLUMES_LIST[volume].status}
                              </Table.Cell>
                              <Table.Cell>
                                {volumes.VOLUMES_LIST[volume].size} GB
                              </Table.Cell>
                              <Table.Cell>
                                <div className="flex vcenter">
                                  <CircularButton
                                    onClick={() =>
                                      this.props.modifyVolume(
                                        volumes.VOLUMES_LIST[volume],
                                      )
                                    }
                                    className="button button--circular margin-right-half"
                                    icon="edit"
                                    popupContent="Edit volume"
                                  />
                                  <CircularButton
                                    onClick={() =>
                                      this.setState({
                                        createVolumeSnapshotStatus: "approve",
                                        selectedVolume:
                                          volumes.VOLUMES_LIST[volume],
                                      })
                                    }
                                    className="button button--circular margin-right-half"
                                    icon="camera"
                                    popupContent="Create Snapshot"
                                  />
                                  {!this.isBootVolume(
                                    thisServer,
                                    volumes.VOLUMES_LIST[volume],
                                  ) ? (
                                    <CircularButton
                                      onClick={() =>
                                        this.detachVolume(
                                          thisServer,
                                          volumes.VOLUMES_LIST[volume],
                                        )
                                      }
                                      className="button button--circular margin-right-half button--circular__danger"
                                      icon="lightning"
                                      popupContent="Detach volume"
                                    />
                                  ) : (
                                    <CircularButton
                                      className="button button--circular margin-right-half button--disabled"
                                      icon="lightning"
                                      popupContent="Cannot detach a Boot Volume"
                                    />
                                  )}
                                </div>
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                    </div>
                  ) : (
                    "No volume attached yet"
                  )
                ) : (
                  <Loader size="mini" active>
                    Fetching Volumes List...
                  </Loader>
                )}

                <button
                  className="float-right  margin-bottom-20 margin-top-10 clear-after button button--green"
                  onClick={() =>
                    this.props.createVolume({
                      zone: {
                        value: thisServer.region,
                        text: "",
                        key: "1",
                      },
                    })
                  }
                >
                  <span>Create a Volume</span>
                </button>
              </Grid.Column>

              {this.state.createVolumeSnapshotStatus &&
                this.state.createVolumeSnapshotStatus === "approve" && (
                  <CreateSnapshotFromVolume
                    resource={this.state.selectedVolume}
                    setClose={() =>
                      this.setState({ createVolumeSnapshotStatus: false })
                    }
                    createSnapshotFromVolume={
                      this.props.createSnapshotFromVolume
                    }
                  />
                )}
            </Grid.Row>

            {/* NETWORKS */}
            <Grid.Row className="separator">
              {attachedNetworks.some((n) => n.version === 6) && (
                <Grid.Column width={16} className="flex vcenter padding-bottom">
                  <div className="animateDown bold-section warning-section padding-top padding-right padding-left padding-bottom">
                    <h5>Warning</h5>
                    <p>
                      Your server is attached to IPv6 Network and has externally
                      available IP address. Be sure to configure security groups
                      properly.
                    </p>
                  </div>
                </Grid.Column>
              )}
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>
                  Networks
                  {this.state.attachInterface_State &&
                    this.state.attachInterface_State === "Initialized" && (
                      <Label
                        className="margin-left-half"
                        basic
                        color="blue"
                        size="medium"
                        pointing="left"
                      >
                        <Icon name="attach" size="small" color="blue" />
                        {this.state.attachInterface_State}
                      </Label>
                    )}
                  {this.state.attachInterface_State &&
                    this.state.attachInterface_State === "Started" && (
                      <Label
                        className="margin-left-half"
                        basic
                        color="blue"
                        size="large"
                        pointing="left"
                      >
                        <Icon
                          loading
                          name="spinner"
                          size="small"
                          color="blue"
                        />
                        {this.state.attachInterface_State}
                      </Label>
                    )}
                </h5>
              </Grid.Column>
              <Grid.Column textAlign="right" width={8}>
                <Dropdown
                  scrolling
                  text="Attach an interface"
                  icon="chevron circle down"
                  className="select-box full"
                >
                  <Dropdown.Menu className="overflow-auto">
                    {unattachedNetworks &&
                      unattachedNetworks.map((network, key) => (
                        <Dropdown.Item
                          onClick={() =>
                            this.attachInterface(
                              thisServer,
                              networks.NETWORKS_LIST[network],
                            )
                          }
                          className={getSelectItemClassName(
                            `${networks.NETWORKS_LIST[network].name}`,
                          )}
                          key={key}
                        >
                          <span>{networks.NETWORKS_LIST[network].name}</span>
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Grid.Column>
              <Grid.Column textAlign="left" width={16}>
                {networks.NETWORKS_LIST ? (
                  attachedNetworks.length ? (
                    <div className="curve-table">
                      <Table celled>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Network</Table.HeaderCell>
                            <Table.HeaderCell>MAC</Table.HeaderCell>
                            <Table.HeaderCell>IP</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {attachedNetworks.map((network, key) => (
                            <Table.Row key={key}>
                              <Table.Cell title={network.name}>
                                <WrapTextWithPopup
                                  data={network.name}
                                  trimSize={12}
                                />
                              </Table.Cell>
                              <Table.Cell
                                title={network["OS-EXT-IPS-MAC:mac_addr"]}
                              >
                                {network["OS-EXT-IPS-MAC:mac_addr"]}
                              </Table.Cell>
                              <Table.Cell>
                                {network.hasBoth
                                  ? network["OS-EXT-IPS:type"] === "fixed"
                                    ? `${network.addr} (Fixed) ${network.addr2} (Floating)`
                                    : `${network.addr2} (Floating) ${network.addr} (Fixed)`
                                  : `${network.addr} (${network["OS-EXT-IPS:type"]})`}
                              </Table.Cell>
                              <Table.Cell>
                                <div className="flex vcenter">
                                  <CircularButton
                                    onClick={() =>
                                      this.props.detachInterfaceFromServer(
                                        thisServer,
                                        network.name,
                                        network["OS-EXT-IPS-MAC:mac_addr"],
                                      )
                                    }
                                    className="button button--circular margin-right-half button--circular__danger"
                                    icon="lightning"
                                    popupContent="Detach Interface"
                                  />
                                </div>
                              </Table.Cell>
                            </Table.Row>
                          ))}
                          {thisServer.task_state === "attach_interface" && (
                            <Table.Row warning>
                              <Table.Cell colSpan="4">
                                <Icon loading name="spinner" />
                                Attaching an interface....
                              </Table.Cell>
                            </Table.Row>
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  ) : (
                    "No network attached yet"
                  )
                ) : (
                  <Loader size="mini" active>
                    Fetching networks List...
                  </Loader>
                )}
                <button
                  className="float-right  margin-bottom-20 margin-top-10 clear-after button button--green"
                  onClick={() =>
                    this.props.createNetwork({
                      zone: {
                        value: thisServer.region,
                        text: "",
                        key: Number(thisServer.zone.zoneId),
                      },
                    })
                  }
                >
                  <span>Create a Network</span>
                </button>
              </Grid.Column>
            </Grid.Row>

            {/* SNAPSHOTS */}
            <React.Fragment>
              <Grid.Row className="separator padding-bottom-20">
                <Grid.Column textAlign="left" width={16}>
                  <SnapshotsTable
                    server={thisServer}
                    showActions={true}
                    createVolume={this.props.createVolume.bind(this)}
                    server_snapshots={
                      server_snapshots.SERVER_SNAPSHOTS_LIST_LOADING_ZONES_LEFT ===
                      0
                        ? Object.values(
                            server_snapshots.SERVER_SNAPSHOTS_LIST,
                          ).filter((sn) => sn.instance_uuid === thisServer.id)
                        : "Loading"
                    }
                    deleteServer_snapshot={this.props.deleteServer_snapshot}
                    zonesLeft={
                      server_snapshots.SERVER_SNAPSHOTS_LIST_LOADING_ZONES_LEFT
                    }
                  />

                  <CreateSnapshot
                    status={this.state.createServerSnapshotStatus}
                    onClick={() =>
                      this.setState({ createServerSnapshotStatus: "approve" })
                    }
                    hasCRUDAccess={true}
                    isBFV={this.isBootingFromVolume(thisServer)}
                  />
                </Grid.Column>
              </Grid.Row>
              {this.state.createServerSnapshotStatus &&
                this.state.createServerSnapshotStatus === "approve" && (
                  <Modal
                    open={
                      this.state.createServerSnapshotStatus &&
                      this.state.createServerSnapshotStatus === "approve"
                    }
                    size="small"
                    centered={true}
                    onClose={() =>
                      this.setState({
                        createServerSnapshotStatus: false,
                        serverSnapshotCreateApproval: false,
                      })
                    }
                  >
                    <Header className="header-center">
                      Create a Server Snapshot
                    </Header>
                    <Modal.Content>
                      <div className="">
                        <h5>Name</h5>
                        <br />
                        <Input
                          disabled={this.state.isSubmittingNewSnapshot}
                          placeholder="name"
                          value={this.state.newServerSnapshotName}
                          className="select-box full"
                          onChange={(e) =>
                            this.updateInput(
                              "newServerSnapshotName",
                              e.currentTarget.value,
                            )
                          }
                        />
                        <br />
                        <br />
                        <Checkbox
                          toggle
                          className="multi-line"
                          checked={this.state.serverSnapshotCreateApproval}
                          label={
                            "Creating snapshots from servers will result in unresponsive server in the meantime.  Do you want to continue?"
                          }
                          onChange={() =>
                            this.setState({
                              serverSnapshotCreateApproval:
                                !this.state.serverSnapshotCreateApproval,
                            })
                          }
                        ></Checkbox>

                        <br />
                      </div>
                    </Modal.Content>
                    <Modal.Actions>
                      <button
                        className="float-left button button--bordered"
                        onClick={() =>
                          this.setState({
                            createServerSnapshotStatus: false,
                            serverSnapshotCreateApproval: false,
                          })
                        }
                      >
                        <span>Back</span>
                      </button>
                      {this.state.serverSnapshotCreateApproval && (
                        <button
                          className="float-right button button--green "
                          disabled={!this.state.serverSnapshotCreateApproval}
                          onClick={() => this.createServerSnapshot(thisServer)}
                        >
                          <span>Create</span>
                        </button>
                      )}
                    </Modal.Actions>
                  </Modal>
                )}
            </React.Fragment>
            <Grid.Row>
              <Grid.Column textAlign="left" width={16}>
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    servers: state.servers,
    server_snapshots: state.server_snapshots,
    volumes: state.volumes,
    networks: state.networks,
    projects: state.projects,
    connectivity: state.connectivity,
    securitygroups_List: state.securitygroups,
  };
};

const mapDispatchToProps = (dispatch) => ({
  detachVolume: (volume) => dispatch(detachVolume(volume)),
  attachVolumeToServer: (server, volume) =>
    dispatch(attachVolumeToServer(server, volume)),
  createSnapshotFromVolume: (volume, currentProjectID, obj) =>
    dispatch(createSnapshotFromVolume(volume, currentProjectID, obj)),
  modifyVolume: (volume) =>
    dispatch(toggleSlidingMenu("modify", "Volume", volume)),

  attachVolume_StateUpdate: (server) =>
    dispatch(attachVolume_StateUpdate(server)),
  detachVolume_StateUpdate: (server) =>
    dispatch(detachVolume_StateUpdate(server)),
  snapshotCreate_StateUpdate: (server) =>
    dispatch(snapshotCreate_StateUpdate(server)),

  createSnapShotFromServer: (server, obj) =>
    dispatch(createSnapShotFromServer(server, obj)),
  deleteServer_snapshot: (snapshot) =>
    dispatch(deleteServer_snapshot(snapshot)),

  attachInterfaceToServer: (server, network) =>
    dispatch(attachInterfaceToServer(server, network)),
  detachInterfaceFromServer: (server, networkName, networkMAC) =>
    dispatch(detachInterfaceFromServer(server, networkName, networkMAC)),

  renameServer: (server, objectToSend) =>
    dispatch(renameServer(server, objectToSend)),

  resizeServer: (server, flavor) => dispatch(resizeServer(server, flavor)),
  resizeConfirm: (server) => dispatch(resizeConfirm(server)),

  // create from within modify
  createVolume: (params) =>
    dispatch(toggleSlidingMenu("create", "Volume", params)),
  createNetwork: (params) =>
    dispatch(toggleSlidingMenu("create", "Network", params)),

  removeSubscription: (name) => dispatch(removeSubscription(name)),
  addSubscription: (name) => dispatch(addSubscription(name)),

  connectFloatingIP: (params) =>
    dispatch(toggleSlidingMenu("create", "Floatingip", params)),

  listSnapShots: (server) => dispatch(listSnapShots(server)),

  modifySecurityGroup: (securitygroup) =>
    dispatch(toggleSlidingMenu("modify", "Security Group", securitygroup)),
  createSecurityGroup: (securitygroup) =>
    dispatch(toggleSlidingMenu("create", "Security Group", securitygroup)),

  addSecurityGroupToServer: (securitygroup, server, obj) =>
    dispatch(addSecurityGroupToServer(securitygroup, server, obj)),

  updateServerDisasterRecoveryStatus: (server, objectToSend) =>
    dispatch(updateServerDisasterRecoveryStatus(server, objectToSend)),

  confirmbox_open: (type, act, c, msg) =>
    dispatch(confirmbox_open(type, act, c, msg)),
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ModifyServer));
