import { Domain, Region } from "../openstack/types";

export const getRegionsFromDomains = (domains: Domain[]) => {
  return domains.reduce((acc: Region[], domain) => {
    acc.push(
      ...domain.area.regions.map((region) => ({
        ...region,
        domain_status: domain.status,
      })),
    );

    return acc;
  }, []);
};

export const getInactiveRegions = (
  allRegions: Region[],
  activeRegions: Region[],
) => {
  return allRegions.filter(
    (region) =>
      !activeRegions.find(
        (activeRegion) => activeRegion.zone_id === region.zone_id,
      ),
  );
};
