import FetchAPI from "../../../../api/FetchAPI";
import { toast } from "react-toastify";
import poolConstants from "./constants";
import {
  toastMultipleResults,
  checkResourceProperties,
  toastError,
} from "../../../../app_shared_functions";

export const createPool = (loadbalancer, objectToSend) => (dispatch) => {
  const invalid_loadbalancer_Msg = checkResourceProperties(
    loadbalancer,
    "loadbalancer",
  );
  if (invalid_loadbalancer_Msg) {
    toastError(invalid_loadbalancer_Msg);
    return Promise.reject();
  }

  toast.success("Pool creation initialized.");
  return new Promise((resolve, reject) => {
    FetchAPI.LoadBalancer.Pools.create({
      loadbalancer,
      objectToSend,
    })
      .then((response) => {
        dispatch({
          type: poolConstants.POOL_CREATE_STARTED,
          payload: {
            ...response.data,
            region: loadbalancer.region.toLowerCase(),
          },
        });
        toast.success("Pool creation started...");
        resolve(response);
      })
      .catch((err) => {
        dispatch({ type: poolConstants.POOL_CREATE_FAILED });
        toastError(err, "Creating pool failed!");
        reject();
      });
  });
};

export const deletePool = (pool) => (dispatch) => {
  const invalid_pool_Msg = checkResourceProperties(pool, "pool");
  if (invalid_pool_Msg) {
    toastError(invalid_pool_Msg);
    return Promise.reject();
  }

  dispatch({ type: poolConstants.POOL_DELETE_INIT, payload: pool.id });
  return new Promise((resolve, reject) => {
    FetchAPI.LoadBalancer.Pools.delete(pool)
      .then((response) => {
        if (response) {
          dispatch({
            type: poolConstants.POOL_DELETE_STARTED,
            payload: pool.id,
          });
          resolve(response.data);
        } else {
          dispatch({
            type: poolConstants.POOL_DELETE_FAILED,
            payload: pool.id,
          });
          reject();
        }
      })
      .catch((err) => {
        dispatch({ type: poolConstants.POOL_DELETE_FAILED, payload: pool.id });
        toastError(err, "Deleting pool failed!");
        reject();
      });
  });
};

export const updatePool = (pool, objectToSend) => (dispatch) => {
  const invalid_pool_Msg = checkResourceProperties(pool, "pool");
  if (invalid_pool_Msg) {
    toastError(invalid_pool_Msg);
    return Promise.reject();
  }

  dispatch({ type: poolConstants.POOL_MODIFY_INIT, payload: pool.id });
  return new Promise((resolve, reject) => {
    FetchAPI.LoadBalancer.Pools.modify({
      pool,
      objectToSend,
    })
      .then((response) => {
        if (response) {
          dispatch({
            type: poolConstants.POOL_MODIFY_STARTED,
            payload: pool.id,
          });
          resolve(response.data);
        } else {
          dispatch({
            type: poolConstants.POOL_MODIFY_FAILED,
            payload: pool.id,
          });
          reject();
        }
      })
      .catch((err) => {
        dispatch({ type: poolConstants.POOL_MODIFY_FAILED, payload: pool.id });
        toastError(err, "Modifying pool failed!");
        reject();
      });
  });
};

export const updateMembers = (pool, members) => (dispatch) => {
  const invalid_pool_Msg = checkResourceProperties(pool, "pool");
  if (invalid_pool_Msg) {
    toastError(invalid_pool_Msg);
    return Promise.reject();
  }

  dispatch({ type: poolConstants.POOL_MODIFY_MEMBERS_INIT, payload: pool.id });
  return new Promise((resolve, reject) => {
    FetchAPI.LoadBalancer.Pools.Members.modify({
      pool,
      members,
    })
      .then((response) => {
        if (response) {
          dispatch({
            type: poolConstants.POOL_MODIFY_MEMBERS_STARTED,
            payload: pool.id,
          });
          resolve(response.data);
        } else {
          dispatch({
            type: poolConstants.POOL_MODIFY_MEMBERS_FAILED,
            payload: pool.id,
          });
          reject();
        }
      })
      .catch((err) => {
        dispatch({
          type: poolConstants.POOL_MODIFY_MEMBERS_FAILED,
          payload: pool.id,
        });
        toastError(err, "Modifying members failed!");
        reject();
      });
  });
};

export const deleteMembers = (pool, members) => (dispatch) => {
  const invalid_pool_Msg = checkResourceProperties(pool, "pool");
  if (invalid_pool_Msg) {
    toastError(invalid_pool_Msg);
    return Promise.reject();
  }

  dispatch({ type: poolConstants.POOL_MODIFY_MEMBERS_INIT, payload: pool.id });
  toast.success(
    `About to delete ${members.length} member${
      members.length > 1 ? "s" : ""
    }...`,
  );
  const promises = members.map((member) => {
    return new Promise((resolve, reject) =>
      FetchAPI.LoadBalancer.Pools.Members.delete({
        pool,
        member,
      })
        .then((response) => resolve({ status: "resolved", id: member.id }))
        .catch((err) => {
          resolve({
            status: "rejected",
            id: member.id,
            desc:
              err.response.data.error.description ||
              err.response.data.error.message,
          });
        }),
    );
  });
  return new Promise((resolve, reject) => {
    Promise.all(promises).then((responses) => {
      toastMultipleResults({
        responses,
        resource_name: "members",
        action: "delete",
        dispatch,
      });
      resolve();
    });
  });
};
