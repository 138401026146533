import { useMemo } from "react";
import { Region } from "../../../../openstack/types";
import { useSelector } from "react-redux";
import { mapResourceNameToReduxSelector } from "../../../../selectors/resources";
import { mapResourceTypeToReduxList } from "../../../../components/shared/grid-bits/mapResourceTypeToReduxList";
import { countResources } from "../../../process-resources/count-resources/useCountResources";
import { SerializableObject } from "../../../../api/getFetch";

export const sortActiveRegions = (
  regions: Region[],
  sortOrder = "ascending",
  totalCount: SerializableObject,
) => {
  const compare = (a: Region, b: Region) => {
    return a.region.toLowerCase() > b.region.toLowerCase()
      ? sortingDirection
      : -1 * sortingDirection;
  };

  const sortingDirection = sortOrder.toLowerCase() === "ascending" ? 1 : -1;

  const loaded = regions
    .filter((region) => totalCount[region.region.toLowerCase()])
    .sort(compare);

  const unloaded = regions
    .filter((region) => !totalCount[region.region.toLowerCase()])
    .sort(compare);

  return [...loaded, ...unloaded];
};

const useSortActiveRegions = (
  activeRegions: Region[],
  sortOrder = "ascending",
  resourceType: keyof typeof mapResourceTypeToReduxList,
) => {
  const { resourcesList } = useSelector(
    mapResourceNameToReduxSelector[resourceType],
  );

  const totalCount = useMemo(() => {
    return activeRegions.reduce((acc, region) => {
      acc = {
        ...acc,
        [region.region.toLowerCase()]: countResources(
          Object.values(resourcesList),
          region,
        ),
      };
      return acc;
    }, {});
  }, [resourcesList, activeRegions]);

  return useMemo(() => {
    return sortActiveRegions(activeRegions, sortOrder, totalCount);
  }, [sortOrder, activeRegions, totalCount]);
};

export default useSortActiveRegions;
