import React, { useRef } from "react";
import { Tab, Menu, Ref } from "semantic-ui-react";
import { defaultValues } from "../../../app_constants";

import Details from "./Details";
import Rules from "./rules/Rules";
import Ports from "./Ports";
import { useSelector } from "react-redux";
import useSubscribe from "../../../custom-hooks/useSubscribe";
import LoadError from "../../../components/detailedviewmodal/bits/LoadError";
import Loading from "../../../components/detailedviewmodal/bits/Loading";
const MoreData = ({ securitygroup }) => {
  const domElementRef = useRef();

  const ports = useSelector((state) => state.ports);
  const portsList = Object.keys(ports.PORTS_LIST)
    .map((x) => ports.PORTS_LIST[x])
    .filter(
      (port) =>
        port.project_id === securitygroup.project_id &&
        port.security_groups.includes(securitygroup.id),
    );
  const securityGroups = useSelector((state) => state.securitygroups);

  useSubscribe("PORTS_LIST");

  const generatePanes = () => {
    const components = {
      Details: Details,
      Rules: Rules,
      Ports: Ports,
    };

    if (securityGroups.SECURITYGROUPS_LIST[securitygroup.id]) {
      securitygroup = securityGroups.SECURITYGROUPS_LIST[securitygroup.id];
    }

    return defaultValues.securitygroup.tabs.map((tab, key) => {
      const Component = components[tab];
      let redux_actions_as_props = {};
      if (tab === "Ports") {
        redux_actions_as_props.ports = portsList;
      }
      return {
        menuItem: (
          <Menu.Item key={key}>
            <span>{tab}</span>
          </Menu.Item>
        ),
        render: () =>
          React.createElement(
            Component,
            { securitygroup, ...redux_actions_as_props },
            null,
          ),
      };
    });
  };

  if (
    securityGroups.SECURITYGROUPS_LIST[securitygroup.id] ||
    securitygroup.domain_id
  ) {
    return (
      <Ref innerRef={domElementRef}>
        <div
          onClick={(e) => e.stopPropagation()}
          className="more-data  more-data--tabs"
        >
          <Tab
            className="horizontal-tabs padding-top-20 margin-bottom "
            panes={generatePanes()}
          />
        </div>
      </Ref>
    );
  }
  if (securityGroups.SECURITYGROUPS_LIST_LOADING_ZONES_LEFT === 0) {
    return (
      <Ref innerRef={domElementRef}>
        <LoadError type="Security Groups" />
      </Ref>
    );
  }
  return (
    <Ref innerRef={domElementRef}>
      <Loading type="Security Groups" />
    </Ref>
  );
};

export default MoreData;
