import { Grid } from "semantic-ui-react";
import SimpleTable from "../../../components/shared/SimpleTable";
import QuickView from "../../../components/shared/quickview/QuickView";

const MoreData = ({ keypair }) => {
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="more-data more-data--padding"
    >
      <Grid className="columns-3">
        <Grid.Row stackable="true">
          <Grid.Column>
            <SimpleTable
              className="striped-table"
              content={[
                [
                  "Public Key :",
                  <QuickView>
                    <QuickView.Trigger>{keypair.public_key}</QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Item>{`Public Key: ${keypair.public_key}`}</QuickView.Item>
                      <QuickView.Copy copy={keypair.public_key}>
                        Copy Public Key
                      </QuickView.Copy>
                    </QuickView.Content>
                  </QuickView>,
                ],
              ]}
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleTable
              className="striped-table"
              content={[["Name :", keypair.name]]}
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleTable
              className="striped-table reverse-strip-order"
              content={[
                [
                  "Fingerprint :",
                  <QuickView>
                    <QuickView.Trigger>{keypair.fingerprint}</QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Item>{`Fingerprint: ${keypair.fingerprint}`}</QuickView.Item>
                      <QuickView.Copy copy={keypair.fingerprint}>
                        Copy Fingerprint
                      </QuickView.Copy>
                    </QuickView.Content>
                  </QuickView>,
                ],
              ]}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default MoreData;
