import React from "react";
import TableWrapper from "../../../../../components/shared/react-table/TableWrapper";
import { Icon } from "semantic-ui-react";
import { createTableHeaderObject } from "../../../../../app_shared_functions";
import { defaultValues } from "../../../../../app_constants";
import { filterActionColumn } from "../../../../../shared-functions/authenticate";
import { displaySecurityGroupRulePortRange } from "../../../helper";
import { useSelector } from "react-redux";

import Remote from "./Remote";
import Status from "./Status";
import Action from "./Action";

import PropTypes from "prop-types";
import { addCustomSortType } from "../../../../../components/shared/react-table/sortOn";

const RulesTable = ({ securitygroup, hasCRUDAccess, header }) => {
  const securitygroups = useSelector((state) =>
    Object.values(state.securitygroups.SECURITYGROUPS_LIST),
  );

  const columns = createTableHeaderObject(
    header || "__Hidden__",
    filterActionColumn(defaultValues.securitygroup.rule_columns, hasCRUDAccess),
  );

  // If we are to sort a component (like here 'Remote')
  // we need to set a 'Custom Sort' for it and send to ReactTable.
  // Here we just send the columns that are rendered above along with the Component
  // that we want to have the custom sort and the rest is done behind the scenes!
  columns[0].columns = addCustomSortType(columns[0].columns, "Remote");

  const renderRules = () => {
    return securitygroup.security_group_rules.map((rule) => ({
      status: <Status rule={rule} />,
      ip_protocol:
        rule.protocol && rule.protocol !== "0" ? rule.protocol : "Any",
      remote: (
        <Remote
          remote={
            securitygroups?.find((x) => x.id === rule.remote_group_id)?.name ||
            rule?.remote_group_id ||
            rule.remote_ip_prefix ||
            "*"
          }
          rule={rule}
        />
      ),
      direction: rule.direction,
      ether_type: rule.ethertype,
      port_range: displaySecurityGroupRulePortRange(rule),
      action: <Action rule={rule} securitygroup={securitygroup} />,
    }));
  };

  return Array.isArray(securitygroup.security_group_rules) &&
    securitygroup.security_group_rules.length > 0 ? (
    <TableWrapper data={renderRules()} columns={columns} />
  ) : (
    <div className="margin-top-20  margin-left float-left">
      <Icon name="warning circle" className="padding-right-20 " />
      No Rules defined for this security group.
    </div>
  );
};

RulesTable.propTypes = {
  securitygroup: PropTypes.object.isRequired,
  hasCRUDAccess: PropTypes.bool.isRequired,
  header: PropTypes.string,
};

export default RulesTable;
