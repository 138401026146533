import { useEffect, useState } from "react";
import FetchAPI from "../api/FetchAPI";
import { getCurrentProjectID } from "../app_shared_functions";
import { RootStateOrAny, useSelector } from "react-redux";
import { Flavor } from "../api/resources/Compute/Flavors";

function useFetchFlavors(region: string) {
  const projects = useSelector((state: RootStateOrAny) => state.projects);
  const project_id = getCurrentProjectID(projects, region) || "";

  const [flavors, setFlavors] = useState<Flavor[]>([]);

  useEffect(() => {
    FetchAPI.Compute.Flavors.getList({
      region,
      project_id,
    }).then((res) => {
      setFlavors(res.data);
    });
  }, [region, project_id]);

  return flavors;
}
export default useFetchFlavors;
