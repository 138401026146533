import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SortableClusterTemplateList from "./SortableClusterTemplateList";
import { useTranslation } from "react-i18next";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import {
  PageToolbar,
  PageToolbarButtonsPane,
} from "../../../components/PageToolbar";
import Breadcrumbs from "../../../components/shared/breadcrumbs/Breadcrumbs";
import { Icon, Input, Segment, Sidebar } from "semantic-ui-react";
import { KnowledgeBaseButton } from "../../../components/knowledgebase/KnowledgeBaseButton";
import { showMoreResources } from "../../../actions/shared";
import LoadMoreSensor from "../../../components/shared/LoadMoreSensor";
import { canLoadMore } from "../../../app_shared_functions";
import { debounceInput } from "../../../shared-functions/environment";
import { mapResourceTypeToReduxList } from "../../../components/shared/grid-bits/mapResourceTypeToReduxList";
import useSubscribe from "../../../custom-hooks/useSubscribe";
import useHasCRUDAccess from "../../../custom-hooks/useHasCRUDAccess";
import { mapResourceNameToReduxSelector } from "../../../selectors/resources";
import { valuesOf } from "../../../shared-functions/objects";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import RegionsLoadingSpinner from "../../../components/shared/RegionsLoadingSpinner";

const ClusterTemplateList = () => {
  const resourceType: keyof typeof mapResourceTypeToReduxList =
    "clustertemplates";

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const memoizedItems = useMemo(
    () => [mapResourceTypeToReduxList[resourceType], "KEYPAIRS_LIST"],
    [],
  );
  useSubscribe(memoizedItems);

  const hasCRUDAccess = useHasCRUDAccess();

  const {
    resourcesList: clustersList,
    currentPage,
    zonesLeft,
    hiddenRegions,
  } = useSelector(mapResourceNameToReduxSelector[resourceType]);

  const breadcrumbs = useMemo(() => {
    return [
      {
        title: "menu.containers.containers",
      },
      {
        title: "cluster_templates.cluster_templates",
        popup: { "Cluster Templates": valuesOf(clustersList).length },
      },
    ];
  }, [clustersList]);

  const [filteringText, setFilteringText] = useState("");

  const [loadingMore, setLoadingMore] = useState(false);

  const onCreateClusterTemplate = useCallback(() => {
    dispatch(toggleSlidingMenu("create", "Cluster Template"));
  }, [dispatch]);

  const checkAndLoadMore = useCallback(
    (isVisible) => {
      if (isVisible && canLoadMore(clustersList, currentPage, hiddenRegions)) {
        setLoadingMore(true);
        dispatch(showMoreResources("cluster template"));
      }
    },
    [hiddenRegions, currentPage, dispatch, clustersList],
  );

  useEffect(() => {
    setLoadingMore(false);
  }, [currentPage]);

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      return debounceInput(() => setFilteringText(val))();
    },
    [],
  );

  const gridContextValues = useMemo(() => {
    return {
      filteringText,
      resourceType,
      hasCRUDAccess,
    };
  }, [resourceType, hasCRUDAccess, filteringText]);

  return (
    <div>
      <Sidebar.Pushable as={Segment}>
        <Sidebar.Pusher>
          <PageToolbar>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <KnowledgeBaseButton />
            {hasCRUDAccess && (
              <PageToolbarButtonsPane>
                <button
                  className="button button--transparent"
                  onClick={onCreateClusterTemplate}
                >
                  {t(`cluster_templates.actions.add`)}
                  <Icon name="plus circle" />
                </button>
              </PageToolbarButtonsPane>
            )}
          </PageToolbar>

          <div className="page-content">
            <div className="top-section">
              <div></div>
              <div className="pos_right">
                <div className="ui input input-white">
                  <Input
                    minLength={2}
                    onChange={onFilterChange}
                    placeholder="Enter filter text..."
                  />
                </div>
              </div>
            </div>
            <GridContext.Provider value={gridContextValues}>
              <SortableClusterTemplateList />
            </GridContext.Provider>
            <LoadMoreSensor
              checkAndLoadMore={checkAndLoadMore}
              loading_more={loadingMore}
            />
          </div>
          <RegionsLoadingSpinner zonesLeft={zonesLeft} />
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
};

export default ClusterTemplateList;
