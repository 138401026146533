import config from "../../../config";
import getFetch, { SerializableObject } from "../../getFetch";

export type Project = {
  id: string;
  name: string;
  domain_id: string;
  description: string;
  enabled: boolean;
  parent_id: string;
  is_domain: boolean;
  tags: string[];
  options: SerializableObject;
  links: { self: string };
  region: OpenStackRegion;
};

export type OpenStackRegion = {
  id: number;
  name: string;
  status: string;
  tag: string;
  objectStorageEnabled: boolean;
  nameservers_ipv4: string | null;
  nameservers_ipv6: string | null;
};

type DomainIdAndProjectId = {
  domain_id: string;
  project_id: string;
};

const Projects = {
  create: function ({
    domain_id,
    objectToSend,
  }: {
    domain_id: string;
    objectToSend: SerializableObject;
  }) {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/openstack/${domain_id}/projects`,
      type: "post",
      params: objectToSend,
    });
  },

  edit: function ({
    domain_id,
    project_id,
    objectToSend,
  }: DomainIdAndProjectId & { objectToSend: SerializableObject }) {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/openstack/${domain_id}/projects/${project_id}`,
      type: "put",
      params: objectToSend,
    });
  },

  getList: function (domain_id: string) {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/openstack/${domain_id}/projects`,
      type: "get",
    });
  },

  getCurrentUserProjects: function () {
    return getFetch<Project[]>({
      url: `${config.api_url}/accesscontrol/v1/openstack/current_user/projects`,
      type: "get",
    });
  },

  getQuota: function ({
    domain_id,
    project_id,
    region,
  }: DomainIdAndProjectId & { region: string }) {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/openstack/${domain_id}/projects/${project_id}/quota/${region}`,
      type: "get",
    });
  },

  getUsersList: function ({ domain_id, project_id }: DomainIdAndProjectId) {
    return getFetch({
      url: `${config.api_url}/accesscontrol/v1/openstack/${domain_id}/projects/${project_id}/users`,
      type: "get",
    });
  },
};

export default Projects;
