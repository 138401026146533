import React, { useCallback, useEffect, useState } from "react";
import { Accordion, Icon, Loader, Popup, Grid } from "semantic-ui-react";
import { defaultValues } from "../../../../app_constants";
import WrapTextWithPopup from "../../../../components/shared/WrapTextWithPopup";
import FetchAPI from "../../../../api/FetchAPI";
import SimpleTable from "../../../../components/shared/SimpleTable";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";

const Quota = ({ region, index, activeIndices, handleClick }) => {
  const [quota, setQuota] = useState();
  const [loaded, setLoaded] = useState(false);

  const dispatch = useDispatch();

  const getQuotas = useCallback(() => {
    FetchAPI.AccessControlOpenStack.Projects.getQuota(region)
      .then((res) => {
        setQuota({ data: res.data });
      })
      .catch((err) => {
        setQuota({ err });
      })
      .finally(() => setLoaded(true));
  }, [region]);

  useEffect(() => {
    getQuotas();
  }, [getQuotas]);

  return (
    <React.Fragment>
      <Accordion.Title
        active={activeIndices.includes(index)}
        index={index}
        onClick={handleClick}
      >
        <Icon
          className="margin-right color-blue"
          name={`chevron circle ${
            activeIndices.includes(index) ? "down" : "right"
          }`}
          size="large"
        />

        <span>
          {region.name} ({region.region.toUpperCase()})
        </span>

        <span className="margin-left-30 subtitle">
          {
            <WrapTextWithPopup
              data={region.project_id}
              popupTitle="Click to Copy Project ID: "
              canCopy
            />
          }
        </span>

        {quota?.err && (
          <Popup
            trigger={
              <Icon className="margin-left color-red" name="warning circle" />
            }
          >
            {quota.err?.response?.data?.error?.description ||
              quota.err?.response?.data?.error?.message ||
              defaultValues.fetch.simple_error}
          </Popup>
        )}

        {!loaded && (
          <div className="loader-wrapper display-inline margin-left-20">
            <Loader size="mini" active className="one-liner"></Loader>
          </div>
        )}
      </Accordion.Title>

      <Accordion.Content active={activeIndices.includes(index)} index={index}>
        {quota?.err && (
          <p className="color-red padding-left-30 italic">
            <Icon className={`margin-right color-red`} name="warning circle" />
            {quota.err?.response?.data?.error?.description ||
              quota.err?.response?.data?.error?.message ||
              defaultValues.fetch.simple_error}
          </p>
        )}

        {quota?.data && (
          <div className="flex">
            <div className="accordion-quota-wrapper--text">
              <h3>Quota</h3>
            </div>
            <div className="accordion-quota-wrapper--quotas">
              <Grid columns={3}>
                <Grid.Row className="margin-left-20">
                  <Grid.Column>
                    <h5>Block Storage</h5>
                    <SimpleTable
                      content={[
                        [
                          "backup gigabytes",
                          quota.data.blockstorage.backup_gigabytes,
                        ],
                        ["backups", quota.data.blockstorage.backups],
                        ["gigabytes", quota.data.blockstorage.gigabytes],
                        ["snapshots", quota.data.blockstorage.snapshots],
                        ["volumes", quota.data.blockstorage.volumes],
                      ]}
                    />
                  </Grid.Column>

                  <Grid.Column>
                    <h5>Networking</h5>
                    <SimpleTable
                      content={[
                        ["floatingip", quota.data.networking.floatingip],
                        ["network", quota.data.networking.network],
                        ["port", quota.data.networking.port],
                        ["router", quota.data.networking.router],
                        [
                          "security_group",
                          quota.data.networking.security_group,
                        ],
                      ]}
                    />
                  </Grid.Column>

                  <Grid.Column>
                    <h5>Compute</h5>
                    <SimpleTable
                      content={[
                        ["cores", quota.data.compute.cores],
                        ["instances", quota.data.compute.instances],
                        ["ram", quota.data.compute.ram],
                      ]}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <div className="margin-left-20 margin-top-10">
                * Need more quota?{" "}
                <button
                  className="color-blue"
                  onClick={() => dispatch(push("/support"))}
                >
                  Contact support
                </button>
              </div>
            </div>
          </div>
        )}

        {!quota && (
          <div>
            <Icon name="spinner" loading className="margin-right-half" />
            loading
          </div>
        )}
      </Accordion.Content>
    </React.Fragment>
  );
};

export default Quota;
