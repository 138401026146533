import { useDispatch, useSelector } from "react-redux";
import { mapResourceTypeToReduxList } from "../../../components/shared/grid-bits/mapResourceTypeToReduxList";
import useSubscribe from "../../../custom-hooks/useSubscribe";
import useHasCRUDAccess from "../../../custom-hooks/useHasCRUDAccess";
import { mapResourceNameToReduxSelector } from "../../../selectors/resources";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { canLoadMore } from "../../../app_shared_functions";
import { showMoreResources } from "../../../actions/shared";
import { debounceInput } from "../../../shared-functions/environment";
import { Icon, Input, Segment, Sidebar } from "semantic-ui-react";
import {
  PageToolbar,
  PageToolbarButtonsPane,
} from "../../../components/PageToolbar";
import Breadcrumbs from "../../../components/shared/breadcrumbs/Breadcrumbs";
import { KnowledgeBaseButton } from "../../../components/knowledgebase/KnowledgeBaseButton";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import LoadMoreSensor from "../../../components/shared/LoadMoreSensor";
import RegionsLoadingSpinner from "../../../components/shared/RegionsLoadingSpinner";
import { valuesOf } from "../../../shared-functions/objects";
import { generateBreadcrumbsCount } from "./helpers";
import { confirmbox_open } from "../../../components/confirmbox/actions";
import { deleteMultipleFloatingips } from "./actions";
import { toast } from "react-toastify";
import CircularButton from "../../../components/shared/circularbutton/CircularButton";
import { getButtonCountPopup } from "../../../components/shared/circularbutton/util";
import { useTranslation } from "react-i18next";
import SortableFloatingipList from "./SortableFloatingipList";

const SubnetList = () => {
  const resourceType: keyof typeof mapResourceTypeToReduxList = "floatingips";

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const memoizedItems = useMemo(
    () => [
      mapResourceTypeToReduxList[resourceType],
      "NETWORKS_LIST",
      "PORTS_LIST",
      "ROUTERS_LIST",
    ],
    [],
  );
  useSubscribe(memoizedItems);

  const hasCRUDAccess = useHasCRUDAccess();

  const {
    resourcesList: floatingipsList,
    currentPage,
    zonesLeft,
    hiddenRegions,
    selectedResources: selectedFloatingIPs,
  } = useSelector(mapResourceNameToReduxSelector[resourceType]);

  const breadcrumbs = useMemo(() => {
    return [
      {
        title: "menu.networks.networking",
      },
      {
        title: "menu.networks.floatingip",
        popup: generateBreadcrumbsCount(valuesOf(floatingipsList)),
      },
    ];
  }, [floatingipsList]);

  const [filteringText, setFilteringText] = useState("");

  const [loadingMore, setLoadingMore] = useState(false);

  const onCreateFloatingIP = useCallback(() => {
    dispatch(toggleSlidingMenu("create", "Floating IP"));
  }, [dispatch]);

  const toggleDeleteDialog = useCallback(() => {
    if (!selectedFloatingIPs.length) {
      toast.warn("No Floating IPs are selected for delete.");
    } else {
      const resources = selectedFloatingIPs.map((x) => floatingipsList[x]);
      dispatch(
        confirmbox_open({
          entity: "floating ip",
          operation: "delete",
          resources: resources,
          onConfirm: deleteMultipleFloatingips,
        }),
      );
    }
  }, [dispatch, floatingipsList, selectedFloatingIPs]);

  const checkAndLoadMore = useCallback(
    (isVisible) => {
      if (
        isVisible &&
        canLoadMore(floatingipsList, currentPage, hiddenRegions)
      ) {
        setLoadingMore(true);
        dispatch(showMoreResources("floatingip"));
      }
    },
    [hiddenRegions, currentPage, dispatch, floatingipsList],
  );

  useEffect(() => {
    setLoadingMore(false);
  }, [currentPage]);

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      return debounceInput(() => setFilteringText(val))();
    },
    [],
  );

  const gridContextValues = useMemo(() => {
    return {
      filteringText,
      resourceType,
      hasCRUDAccess,
    };
  }, [resourceType, hasCRUDAccess, filteringText]);

  return (
    <div>
      <Sidebar.Pushable as={Segment}>
        <Sidebar.Pusher>
          <PageToolbar>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <KnowledgeBaseButton />
            {hasCRUDAccess && (
              <PageToolbarButtonsPane>
                <button
                  className="button button--transparent"
                  onClick={onCreateFloatingIP}
                >
                  Create new Floating IP
                  <Icon name="plus circle" />
                </button>
              </PageToolbarButtonsPane>
            )}
          </PageToolbar>

          <div className="page-content">
            <div className="top-section">
              {hasCRUDAccess && (
                <div className="margin-bottom">
                  <CircularButton
                    onClick={toggleDeleteDialog}
                    className="button button--circular margin-right-half"
                    icon="trash"
                    count={selectedFloatingIPs.length}
                    popupContent={`${t(
                      `floatingip.actions.delete`,
                    )} ${getButtonCountPopup(
                      selectedFloatingIPs.length,
                      "floating ip",
                    )}`}
                  />
                </div>
              )}
              <div className="pos_right">
                <div className="ui input input-white">
                  <Input
                    minLength={2}
                    onChange={onFilterChange}
                    placeholder="Enter filter text..."
                  />
                </div>
              </div>
            </div>
            <GridContext.Provider value={gridContextValues}>
              <SortableFloatingipList />
            </GridContext.Provider>
            <LoadMoreSensor
              checkAndLoadMore={checkAndLoadMore}
              loading_more={loadingMore}
            />
          </div>
          <RegionsLoadingSpinner zonesLeft={zonesLeft} />
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
};

export default SubnetList;
