import axios, { AxiosResponse } from "axios";

export type SerializableObject = { [key: string]: Serializable };
export type SerializableArray = Serializable[];
export type SerializableValue = string | number | boolean | null;
export type Serializable =
  | SerializableObject
  | SerializableArray
  | SerializableValue;

export type GetFetchProps = {
  url: string;
  queryParams?: Record<string, string>;
  debug?: boolean;
} & (
  | {
      type: "get" | "delete";
    }
  | {
      type: "post" | "put" | "patch";
      // TODO: Rename to body, or just delete this file and use axios directly.
      params?: Serializable | { config?: { [key: string]: any } };
    }
);

const getFetch = <T extends Serializable | unknown = unknown>(
  props: GetFetchProps,
): Promise<AxiosResponse<T>> => {
  let url = addQueryParameters(props.url, props.queryParams, props.debug);

  switch (props.type) {
    case "get":
    case "delete":
      return axios[props.type](url);
    case "post":
    case "put":
    case "patch":
      return axios[props.type](url, props.params);
    default:
      throw new Error(`Unrecognized request type: ${(props as any).type}`);
  }
};

function addQueryParameters(
  url: string,
  params: Record<string, string> | undefined,
  debug: boolean | undefined | string,
): string {
  if (debug || debug === "") {
    params = { XDEBUG_SESSION: String(debug), ...params };
  }

  const queryParamString = new URLSearchParams(params).toString();
  const completeUrl = queryParamString ? `${url}?${queryParamString}` : url;

  return completeUrl;
}

export default getFetch;
