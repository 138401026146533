import { useCallback, useContext } from "react";
import QuickView from "../../components/shared/quickview/QuickView";
import MoreData from "./detailedview/MoreData";
import { Icon } from "semantic-ui-react";
import { includeFloatingIPSList } from "./utils";
import { toggleSlidingMenu } from "../../actions/toggleSlidingMenu";
import GridContext from "../../components/shared/grid-bits/context/GridContext";
import { useDispatch, useSelector } from "react-redux";
import { mapResourceTypeToColumns } from "../../components/shared/grid-bits/mapResourceTypeToColumns";
import SelectResource from "../../components/shared/grid-bits/select/SelectResource";
import { valuesOf } from "../../shared-functions/objects";
import FloatingIPsQuickView from "./bits/FloatingIPsQuickView";
import StatusIconProgress from "./status/StatusIconProgress";
import ServerMenu from "./bits/ServerMenu";
import useDetailedView from "../../custom-hooks/detailed-view/useDetailedView";
import { mapResourceNameToReduxSelector } from "../../selectors/resources";

const CustomizedRow = ({ resource }) => {
  const { resourceType } = useContext(GridContext);

  const diskSize = resource?.rootDevice?.size || resource?.flavor?.disk || "";

  const { resourcesList: floatingips } = useSelector(
    mapResourceNameToReduxSelector.floatingips,
  );

  const floating_ips_list = includeFloatingIPSList(
    resource,
    valuesOf(floatingips),
  );

  const Item = ({ item }) => {
    switch (item) {
      case "checkbox":
        return <SelectResource id={resource.id} resourceType={resourceType} />;

      case "status":
        return <StatusIconProgress resource={resource} />;

      case "hamburger_menu":
        return (
          <ServerMenu
            resource={resource}
            floating_ips_list={floating_ips_list}
            isDetailedViewOn={isDetailedViewOn}
            toggleDetailedView={toggleDetailedView}
          />
        );

      case "id":
      case "name":
        return (
          <QuickView>
            <QuickView.Trigger>{resource[item]}</QuickView.Trigger>
            <QuickView.Content>
              <QuickView.Item>{`Server ID: ${resource.id}`}</QuickView.Item>
              <QuickView.Item>{`Server Name: ${resource.id}`}</QuickView.Item>
              <QuickView.Modify onClick={onModify}>
                Modify Server
              </QuickView.Modify>
              <QuickView.Copy copy={resource.id}>Copy ID</QuickView.Copy>
              <QuickView.Copy copy={resource.name}>Copy Name</QuickView.Copy>
            </QuickView.Content>
          </QuickView>
        );

      case "cores":
        return <>{resource.flavor?.vcpus || "-"}</>;

      case "ram":
        return (
          <>{resource?.flavor?.ramGB ? `${resource.flavor.ramGB} GB` : "-"}</>
        );

      case "floating_ip":
        return <FloatingIPsQuickView list={floating_ips_list} />;

      case "disk":
        return <>{diskSize ? `${diskSize} GB` : "-"}</>;

      case "disasterRecoverService": {
        return resource.disasterRecoverService &&
          resource.disasterRecoverService.status === "active" ? (
          <Icon name="check" color="green"></Icon>
        ) : (
          <Icon name="close" color="red"></Icon>
        );
      }

      default:
        return <>{resource[item]}</>;
    }
  };

  const dispatch = useDispatch();

  const { isDetailedViewOn, toggleDetailedView, detailedClassNames } =
    useDetailedView({ resource, resourceType });

  const onModify = useCallback(() => {
    dispatch(toggleSlidingMenu("modify", "Server", resource));
  }, [dispatch, resource]);

  const columns = mapResourceTypeToColumns[resourceType];
  return (
    <>
      {columns.map((col) => (
        <div
          className={detailedClassNames}
          key={col.name}
          onClick={toggleDetailedView}
        >
          <Item item={col.name} />
        </div>
      ))}

      {isDetailedViewOn && (
        <div className="grid__detailed-view">
          <div className="grid__close-detailed-view" />
          <div className="grid__detailed-view-content">
            <MoreData server={resource} />
          </div>
        </div>
      )}
    </>
  );
};

export default CustomizedRow;
