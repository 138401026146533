import user_emails from "./constants";
import FetchAPI from "../../../../api/FetchAPI";
import { toastError } from "../../../../app_shared_functions";
import { toast } from "react-toastify";
import {
  closeSlidingMenuLayer,
  toggleSlidingMenu,
} from "../../../../actions/toggleSlidingMenu";

export const getEmails = () => (dispatch) => {
  dispatch({ type: user_emails.EMAILS_GET_INIT });

  return new Promise((resolve, reject) => {
    FetchAPI.Account.emailContactList()
      .then((res) => {
        dispatch({ type: user_emails.EMAILS_GET_SUCCESS, payload: res.data });
        resolve();
      })
      .catch((err) => {
        dispatch({ type: user_emails.EMAILS_GET_FAILED });
        toastError(err, "Email list load failed!");
        reject();
      });
  });
};

export const createEmail = (email) => (dispatch) => {
  dispatch({ type: user_emails.EMAIL_CREATE_INIT });
  const objectToSend = {
    contact: { email: email },
  };

  return new Promise((resolve, reject) => {
    FetchAPI.Account.addEmailContact(objectToSend)
      .then((res) => {
        dispatch({ type: user_emails.EMAIL_CREATE_SUCCESS });
        dispatch(getEmails());
        dispatch(closeSlidingMenuLayer());
        dispatch(toggleSlidingMenu("modify", "Email", email));
        resolve();
      })
      .catch((err) => {
        dispatch({ type: user_emails.EMAIL_CREATE_FAILED });
        toastError(err, "Adding email failed!");
        reject();
      });
  });
};

export const deleteEmail = (email) => (dispatch) => {
  dispatch({ type: user_emails.EMAIL_DELETE_INIT });
  const objectToSend = {
    email: email,
  };
  return new Promise((resolve, reject) => {
    FetchAPI.Account.deleteEmailContact(objectToSend)
      .then((res) => {
        dispatch({ type: user_emails.EMAIL_DELETE_SUCCESS });
        dispatch(getEmails());
        toast.success("Email deleted.");
        resolve();
      })
      .catch((err) => {
        dispatch({ type: user_emails.EMAIL_DELETE_FAILED });
        toastError(err, "Deleting email failed!");
        reject();
      });
  });
};

export const updateEmailRoles = (email, roles) => (dispatch) => {
  dispatch({ type: user_emails.EMAIL_UPDATE_ROLES_INIT });
  const objectToSend = { email, roles };

  return new Promise((resolve, reject) => {
    FetchAPI.Account.updateEmailContactRoles(objectToSend)
      .then((res) => {
        dispatch({ type: user_emails.EMAIL_UPDATE_ROLES_SUCCESS });
        dispatch(getEmails());
        resolve();
      })
      .catch((err) => {
        dispatch({ type: user_emails.EMAIL_UPDATE_ROLES_FAILED });
        toastError(err, "Updating email roles failed!");
        reject();
      });
  });
};

export const verifyEmail = (email, verificationCode) => (dispatch) => {
  dispatch({ type: user_emails.EMAIL_VERIFY_INIT });
  const objectToSend = {
    email: email,
    code: verificationCode,
  };

  return new Promise((resolve, reject) => {
    FetchAPI.Account.verifyEmailContact(objectToSend)
      .then((res) => {
        dispatch({ type: user_emails.EMAIL_VERIFY_SUCCESS });
        dispatch(getEmails());
        toast.success("Verification successful!");
        resolve();
      })
      .catch((err) => {
        dispatch({ type: user_emails.EMAIL_VERIFY_FAILED });
        toastError(err, "Verifying email failed!");
        reject();
      });
  });
};

export const resendEmailVerification = (email) => {
  const objectToSend = { email: email };
  return () => {
    return FetchAPI.Account.resendEmailContactVerification(objectToSend)
      .then(() => {
        toast.success("Verification email sent!");
      })
      .catch((err) => {
        toastError(err, "Resending email verification failed!");
      });
  };
};
