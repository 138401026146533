import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageToolbar } from "../../../components/PageToolbar";
import Breadcrumbs from "../../../components/shared/breadcrumbs/Breadcrumbs";
import SortablePublicImagesList from "./SortablePublicImagesList";
import { KnowledgeBaseButton } from "../../../components/knowledgebase/KnowledgeBaseButton";
import { showMoreResources } from "../../../actions/shared";
import { Loader, Sidebar, Segment, Input } from "semantic-ui-react";
import LoadMoreSensor from "../../../components/shared/LoadMoreSensor";
import { canLoadMore } from "../../../app_shared_functions";
import { debounceInput } from "../../../shared-functions/environment";
import useSubscribe from "../../../custom-hooks/useSubscribe";
import { mapResourceTypeToReduxList } from "../../../components/shared/grid-bits/mapResourceTypeToReduxList";
import useHasCRUDAccess from "../../../custom-hooks/useHasCRUDAccess";
import { mapResourceNameToReduxSelector } from "../../../selectors/resources";
import { generateBreadcrumbs } from "./helper";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";

const PublicImages = () => {
  const resourceType = "publicimages";

  const dispatch = useDispatch();

  useSubscribe(mapResourceTypeToReduxList[resourceType]);

  const hasCRUDAccess = useHasCRUDAccess();

  const {
    resourcesList: imagesList,
    currentPage,
    zonesLeft,
    hiddenRegions,
  } = useSelector(mapResourceNameToReduxSelector[resourceType]);

  const breadcrumbs = useMemo(
    () => generateBreadcrumbs(imagesList),
    [imagesList],
  );

  const [filteringText, setFilteringText] = useState("");

  const [loadingMore, setLoadingMore] = useState(false);

  const checkAndLoadMore = useCallback(
    (isVisible) => {
      if (isVisible && canLoadMore(imagesList, currentPage, hiddenRegions)) {
        setLoadingMore(true);
        dispatch(showMoreResources("publicimage"));
      }
    },
    [hiddenRegions, currentPage, dispatch, imagesList],
  );

  useEffect(() => {
    setLoadingMore(false);
  }, [currentPage]);

  const onFilterChange = useCallback((e) => {
    const val = e.target.value;
    return debounceInput(() => setFilteringText(val))();
  }, []);

  const gridContextValues = useMemo(() => {
    return {
      filteringText,
      resourceType,
      hasCRUDAccess,
    };
  }, [filteringText, resourceType, hasCRUDAccess]);

  return (
    <div>
      <Sidebar.Pushable as={Segment}>
        <Sidebar.Pusher>
          <PageToolbar>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <KnowledgeBaseButton />
          </PageToolbar>
          <div className="page-content" key="content">
            <div className="top-section">
              <div className="margin-bottom"></div>
              <div className="pos_right">
                <div className="ui input input-white">
                  <Input
                    minLength={2}
                    onChange={onFilterChange}
                    placeholder="Enter filter text..."
                  />
                </div>
              </div>
            </div>
            <GridContext.Provider value={gridContextValues}>
              <SortablePublicImagesList />
            </GridContext.Provider>
            <LoadMoreSensor
              checkAndLoadMore={checkAndLoadMore}
              loading_more={loadingMore}
            />
          </div>
          {zonesLeft > 0 ? (
            <Loader size="tiny" active className="loading-fixed">
              {zonesLeft + " regions loading..."}
            </Loader>
          ) : (
            ""
          )}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
};

export default PublicImages;
