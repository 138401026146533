import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  DropdownProps,
  Grid,
  Icon,
  Input,
  InputOnChangeData,
  Popup,
  Select,
} from "semantic-ui-react";

import {
  convertArrayToSelectOptions,
  toastError,
} from "../../../app_shared_functions";
import FetchAPI from "../../../api/FetchAPI";
import { defaultValues } from "../../../app_constants";
import {
  Network,
  NetworkList,
  Subnet,
} from "../../../api/resources/Networking/Networks";
import { UseFormEvent } from "../../../custom-hooks/form/useForm";
import Cidr from "../../../shared-functions/Cidr";

type InternalNetworkProps = {
  region: string;
  project: string;
  handleChange: (event: UseFormEvent | Array<UseFormEvent>) => void;
};

const InternalNetwork = ({
  region,
  project,
  handleChange,
}: InternalNetworkProps) => {
  const { default_cidr } = defaultValues.gardener.shoots;
  const createNetworkString = "Create new network";

  const [networkListData, setNetworkListData] = useState<Array<any>>([]);
  const [networkList, setNetworkList] = useState<Array<string>>([]);
  const [selectedNetwork, setSelectedNetwork] = useState<string | any>(
    createNetworkString,
  );
  const [cidr, setCidr] = useState<string>(default_cidr);
  const [cidrError, setCidrError] = useState<string>("");
  const [networksLoading, setNetworksLoading] = useState<boolean>(false);

  const isMounted = useRef(true);

  const hasExternalRouter = useCallback((network: Network) => {
    return (
      network.router?.external_gateway_info?.external_fixed_ips?.length > 0
    );
  }, []);

  const getSelectedNetworkData = useCallback(() => {
    if (selectedNetwork) {
      const networkData = networkListData.filter(
        (networkItem) => networkItem["name"] === selectedNetwork,
      );
      return networkData[0] ?? null;
    }
    return null;
  }, [selectedNetwork, networkListData]);

  const checkCidr = useCallback(() => {
    setCidrError("");
    if (!Cidr.isValidCidr(cidr)) {
      setCidrError("Incorrect CIDR format");
      return false;
    }
    if (selectedNetwork !== createNetworkString) {
      const network = getSelectedNetworkData();
      if (network) {
        network.subnets.forEach((subnet: Subnet) => {
          const cidrMask = new Cidr(cidr);
          const subnetMask = new Cidr(subnet.cidr);
          if (
            subnetMask.contains(cidrMask.network) ||
            subnetMask.contains(cidrMask.broadcast)
          ) {
            setCidrError(
              `CIDR overlaps with subnet ${subnetMask.cidr} in this network`,
            );
            return false;
          }
        });
      }
    }
    return true;
  }, [cidr, selectedNetwork, getSelectedNetworkData]);

  const fetchNetworks = useCallback(() => {
    setNetworksLoading(true);
    FetchAPI.Networking.Networks.getList({
      region,
      project_id: project,
    })
      .then((res: { data: NetworkList }) => {
        const networksWithRouters = res.data.filter(
          (networkItem: Network) =>
            networkItem["routerId"] &&
            !networkItem["router:external"] &&
            hasExternalRouter(networkItem),
        );
        const selectOptions = networksWithRouters.map(
          (networkItem) => networkItem["name"],
        );
        selectOptions.unshift(createNetworkString);
        if (isMounted.current) {
          setNetworkListData(networksWithRouters);
          setNetworkList(selectOptions);
        }
      })
      .catch((error) => {
        toastError(error);
      })
      .finally(() => {
        isMounted.current && setNetworksLoading(false);
      });
    return () => {
      isMounted.current = false;
    };
  }, [hasExternalRouter, project, region]);

  const handleSelectedNetworkChange = useCallback(() => {
    checkCidr();
    const network = getSelectedNetworkData();
    handleChange([
      { name: "network", value: network?.id ?? null },
      { name: "router", value: network?.routerId ?? null },
    ]);
  }, [checkCidr, getSelectedNetworkData, handleChange]);

  const handleCidrChange = useCallback(() => {
    if (checkCidr()) {
      handleChange({ name: "cidr", value: cidr });
    }
  }, [checkCidr, cidr, handleChange]);

  const onSelectNetworkChange = (
    _e: React.SyntheticEvent,
    d: DropdownProps,
  ): void => setSelectedNetwork(d.value);
  const onCidrChange = (_e: React.ChangeEvent, d: InputOnChangeData) =>
    setCidr(d.value);

  useEffect(fetchNetworks, [fetchNetworks]);
  useEffect(handleSelectedNetworkChange, [handleSelectedNetworkChange]);
  useEffect(handleCidrChange, [handleCidrChange]);

  return (
    <React.Fragment>
      <Grid.Row>
        <Grid.Column textAlign="left" width={8} className="flex vcenter">
          <h5>Network for worker nodes</h5>
        </Grid.Column>
        <Grid.Column textAlign="left" width={8} className="flex vcenter">
          {networksLoading ? (
            <div className="default-height flex vcenter">
              <Icon name="spinner" loading className="margin-right-half" />
              Loading
            </div>
          ) : (
            <Select
              name="network"
              icon="chevron circle down"
              className={`select-box full`}
              options={convertArrayToSelectOptions(networkList)}
              onChange={onSelectNetworkChange}
              value={selectedNetwork}
            />
          )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="separator">
        <Grid.Column textAlign="left" width={8} className="flex vcenter">
          <Popup
            trigger={
              <h5>
                CIDR for the workers
                <Icon
                  name="exclamation circle"
                  className="margin-left-half"
                  color="grey"
                />
              </h5>
            }
            content='One CIDR can be defined to use for workers. Please make sure the provided internal network is large enough for your workers to "fit"'
          />
        </Grid.Column>
        <Grid.Column textAlign="left" width={8} className="flex vcenter">
          <Input
            name="cidr"
            className={`select-box full ${cidrError && "error-form-item"}`}
            onChange={onCidrChange}
            value={cidr}
          />
        </Grid.Column>
        <Grid.Column textAlign="right" width={16} className="margin-top-10">
          {cidrError && (
            <div style={{ color: "red", fontSize: ".9rem" }}>{cidrError}</div>
          )}
        </Grid.Column>
      </Grid.Row>
    </React.Fragment>
  );
};

export default InternalNetwork;
