import React from "react";
import { useTable, useSortBy } from "react-table";
import { defaultValues } from "../../../app_constants";

function Table({ columns, data }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  // This function will only be triggered when a click on a row of a table, that is in a 'moredetail' view is clicked,

  // 'expanded', and the trigger function 'toggleExpand' will be passed as properties on each row
  //
  // For now, this functionality is only used in the 'PoolsList component' which is part of 'MoreData' in 'Loadbalancer' list
  const rowClick = (row, toggleExpand, trigger, e) => {
    if (
      trigger !== undefined &&
      !(
        e.target.tagName?.toLowerCase() === "button" ||
        e.target.parentNode.tagName?.toLowerCase() === "button"
      )
    ) {
      toggleExpand(row.original.id);
    }
  };

  // Color object can be a part of each row sent to TabbleWrapper
  // __color__ : {frankfurt: "green", karlskrona: "red"}
  // where frankfurt and karlskrona are the columns that the color should be assigned to
  // in this case, a sample row can be something like
  // {
  //    __color__ : {frankfurt: "green", karlskrona: "red"},
  //    frankfurt : ....,
  //    karlskrona : ....,
  //    rest of columns
  // }
  const renderCellColor = (cell) =>
    cell.row?.original?.__color__?.[cell?.column?.id];

  const renderRowClassName = (cell) => {
    let className = "";

    const color = renderCellColor(cell);
    if (color) {
      className = `color-${color}`;
    }
    if (cell?.column?.className) {
      className = `${className} ${cell.column.className}`;
    }
    return className;
  };

  const renderRow_Simple = (row) => {
    return (
      <tr
        {...row.getRowProps()}
        className={row.original.className}
        onClick={(e) =>
          rowClick(row, row.original.toggleExpand, row.original.expanded, e)
        }
      >
        {row.cells.map((cell) => {
          return (
            <td {...cell.getCellProps()} className={renderRowClassName(cell)}>
              {cell.render("Cell")}
            </td>
          );
        })}
      </tr>
    );
  };

  const renderRow_Expanded = (row) => {
    return (
      <tr
        {...row.getRowProps()}
        style={{
          height: 30 + (row.original.height || 30) + "px",
        }}
        className={`react-table--expanded-tr ${row.original.className}`}
        onClick={(e) =>
          rowClick(row, row.original.toggleExpand, row.original.expanded, e)
        }
      >
        {row.cells.map((cell, i) => {
          return (
            <td {...cell.getCellProps()} className={renderRowClassName(cell)}>
              {cell.render("Cell")}
              {i === row.cells.length - 1
                ? row.original.ComponentToRender
                : null}
            </td>
          );
        })}
      </tr>
    );
  };

  const renderHeaderClassName = (column) => {
    let className = "";
    if (column.Header === defaultValues.react_table.hidden_header_column) {
      className = "display-none ";
    }
    if (column.className) {
      className = `${className} ${column.className} `;
    }
    if (column.width) {
      className = `${className} ${column.width} `;
    }
    return className;
  };

  return (
    <table className="react-table" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                className={renderHeaderClassName(column)}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render("Header")}
                <span
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "sort--desc"
                        : "sort--asc"
                      : "sort--disabled"
                  }
                ></span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          // Based upon the 'expanded property' passed on in the row object,
          // We will render either a simple-row or an expanded-row
          return row.original.expanded
            ? renderRow_Expanded(row)
            : renderRow_Simple(row);
        })}
      </tbody>
    </table>
  );
}

function TableWrapper(props) {
  const columns = React.useMemo(() => props.columns, [props.columns]);

  const data = React.useMemo(() => props.data, [props.data]);

  return <Table columns={columns} data={data} />;
}

export default TableWrapper;
