import gardenerConstants from "./constants";
import FetchAPI from "../../api/FetchAPI";

import { toast } from "react-toastify";
import { toastError, toastMultipleResults } from "../../app_shared_functions";

export const showViewMore = (id) => (dispatch) => {
  dispatch({
    type: gardenerConstants.GARDENER_SHOOTS_SHOW_VIEWMORE,
    payload: id,
  });
};

export const hideViewMore = (id) => (dispatch) => {
  dispatch({
    type: gardenerConstants.GARDENER_SHOOTS_HIDE_VIEWMORE,
    payload: id,
  });
};

// Disable the animation on the 'What is Gardener' button on top of Gardener list page
// this will trigger when user leaves the gardener
export const disableAnimation = () => (dispatch) => {
  dispatch({ type: gardenerConstants.GARDENER_HIDE_TIP });
};

export const createShoot =
  ({ region, project_id, gardenDomain, objectToSend }) =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      FetchAPI.Gardener.Shoots.create({
        region,
        project_id,
        gardenDomain,
        objectToSend,
      })
        .then((response) => {
          toast.success("Gardener Shoot creation started...");
          dispatch({
            type: gardenerConstants.GARDENER_SHOOTS_CREATE_STARTED,
            payload: {
              ...response.data,
              region: region.toLowerCase(),
              project_id,
            },
          });
          resolve(response);
        })
        .catch((err) => {
          toastError(err, "Creating Gardener Shoot failed!");
          reject(err);
        });
    });
  };

export const hibernateShoot = (shoot) => (dispatch) => {
  dispatch({
    type: gardenerConstants.GARDENER_SHOOTS_HIBERNATE_INIT,
    payload: shoot,
  });

  return new Promise((resolve, reject) => {
    FetchAPI.Gardener.Shoots.hibernate(shoot)
      .then((response) => {
        toast.success("Gardener Shoot hibernation started...");
        resolve(response);
      })
      .catch((err) => {
        dispatch({
          type: gardenerConstants.GARDENER_SHOOTS_HIBERNATE_FAILED,
          payload: shoot,
        });
        toastError(err, "Creating Gardener hibernation failed!");
        reject(err);
      });
  });
};
export const hibernateMultipleShoots = (shoots) => (dispatch) => {
  toast.success(
    `About to hibernate ${shoots.length} shoot${
      shoots.length > 1 ? "s" : ""
    }...`,
  );
  dispatch({
    type: gardenerConstants.GARDENER_SHOOTS_HIBERNATE_MULTIPLE_INIT,
    payload: shoots,
  });
  const promises = shoots.map((shoot) => {
    return new Promise((resolve) =>
      FetchAPI.Gardener.Shoots.hibernate(shoot)
        .then(() => resolve({ status: "resolved", id: shoot.id }))
        .catch((err) => {
          dispatch({
            type: gardenerConstants.GARDENER_SHOOTS_HIBERNATE_FAILED,
            payload: shoot,
          });
          resolve({
            status: "rejected",
            id: shoot.id,
            desc:
              err.response.data.error.description ||
              err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "shoot",
      action: "hibernate",
      dispatch,
    }),
  );
};

export const wakeupShoot = (shoot) => (dispatch) => {
  dispatch({
    type: gardenerConstants.GARDENER_SHOOTS_WAKEUP_INIT,
    payload: shoot,
  });

  return new Promise((resolve, reject) => {
    FetchAPI.Gardener.Shoots.wakeup(shoot)
      .then((response) => {
        toast.success("Gardener Shoot wake up started...");
        resolve(response);
      })
      .catch((err) => {
        dispatch({
          type: gardenerConstants.GARDENER_SHOOTS_WAKEUP_FAILED,
          payload: shoot,
        });
        reject(err);
      });
  });
};
export const wakeupMultipleShoots = (shoots) => (dispatch) => {
  toast.success(
    `About to wake up ${shoots.length} shoot${shoots.length > 1 ? "s" : ""}...`,
  );
  dispatch({
    type: gardenerConstants.GARDENER_SHOOTS_WAKEUP_MULTIPLE_INIT,
    payload: shoots,
  });
  const promises = shoots.map((shoot) => {
    return new Promise((resolve) =>
      FetchAPI.Gardener.Shoots.wakeup(shoot)
        .then(() => resolve({ status: "resolved", id: shoot.id }))
        .catch((err) => {
          dispatch({
            type: gardenerConstants.GARDENER_SHOOTS_WAKEUP_FAILED,
            payload: shoot,
          });
          resolve({
            status: "rejected",
            id: shoot.id,
            desc:
              err.response.data.error.description ||
              err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "shoot",
      action: "wake up",
      dispatch,
    }),
  );
};

export const deleteShoot = (shoot) => (dispatch) => {
  dispatch({
    type: gardenerConstants.GARDENER_SHOOTS_DELETE_INIT,
    payload: shoot,
  });

  return new Promise((resolve, reject) => {
    FetchAPI.Gardener.Shoots.delete(shoot)
      .then((response) => {
        toast.success("Gardener Shoot delete started...");
        resolve(response);
      })
      .catch((err) => {
        dispatch({
          type: gardenerConstants.GARDENER_SHOOTS_DELETE_FAILED,
          payload: shoot,
        });
        reject(err);
      });
  });
};
export const deleteMultipleShoots = (shoots) => (dispatch) => {
  toast.success(
    `About to delete ${shoots.length} shoot${shoots.length > 1 ? "s" : ""}...`,
  );
  dispatch({
    type: gardenerConstants.GARDENER_SHOOTS_DELETE_MULTIPLE_INIT,
    payload: shoots,
  });
  const promises = shoots.map((shoot) => {
    return new Promise((resolve) =>
      FetchAPI.Gardener.Shoots.delete(shoot)
        .then(() => resolve({ status: "resolved", id: shoot.id }))
        .catch((err) => {
          dispatch({
            type: gardenerConstants.GARDENER_SHOOTS_DELETE_FAILED,
            payload: shoot,
          });
          resolve({
            status: "rejected",
            id: shoot.id,
            desc:
              err.response.data.error.description ||
              err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "shoot",
      action: "delete",
      dispatch,
    }),
  );
};

export const rotatekubeconfigShoot = (shoot) => (dispatch) => {
  dispatch({
    type: gardenerConstants.GARDENER_SHOOTS_ROTATE_INIT,
    payload: shoot,
  });

  return new Promise((resolve, reject) => {
    FetchAPI.Gardener.Shoots.rotateKubeconfig(shoot)
      .then((response) => {
        toast.success("Rotating Kubeconfig started...");
        resolve(response);
      })
      .catch((err) => {
        dispatch({
          type: gardenerConstants.GARDENER_SHOOTS_ROTATE_FAILED,
          payload: shoot,
        });
        reject(err);
      });
  });
};

export const reconcileShoot = (shoot) => (dispatch) => {
  dispatch({
    type: gardenerConstants.GARDENER_SHOOTS_RECONCILE_INIT,
    payload: shoot,
  });

  return new Promise((resolve, reject) => {
    FetchAPI.Gardener.Shoots.reconcile(shoot)
      .then((response) => {
        toast.success("Reconciling shoot started...");
        resolve(response);
      })
      .catch((err) => {
        dispatch({
          type: gardenerConstants.GARDENER_SHOOTS_RECONCILE_FAILED,
          payload: shoot,
        });
        reject(err);
      });
  });
};
export const reconcileMultipleShoots = (shoots) => (dispatch) => {
  toast.success(
    `About to reconcile ${shoots.length} shoot${
      shoots.length > 1 ? "s" : ""
    }...`,
  );
  dispatch({
    type: gardenerConstants.GARDENER_SHOOTS_RECONCILE_MULTIPLE_INIT,
    payload: shoots,
  });
  const promises = shoots.map((shoot) => {
    return new Promise((resolve) =>
      FetchAPI.Gardener.Shoots.reconcile(shoot)
        .then(() => resolve({ status: "resolved", id: shoot.id }))
        .catch((err) => {
          dispatch({
            type: gardenerConstants.GARDENER_SHOOTS_RECONCILE_FAILED,
            payload: shoot,
          });
          resolve({
            status: "rejected",
            id: shoot.id,
            desc:
              err.response.data.error.description ||
              err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "shoot",
      action: "reconcil",
      dispatch,
    }),
  );
};

export const retry = (shoot) => (dispatch) => {
  dispatch({
    type: gardenerConstants.GARDENER_SHOOTS_RETRY_INIT,
    payload: shoot,
  });

  return new Promise((resolve, reject) => {
    FetchAPI.Gardener.Shoots.retry(shoot)
      .then((response) => {
        toast.success("Retrying last failed operation...");
        resolve(response);
      })
      .catch((err) => {
        dispatch({
          type: gardenerConstants.GARDENER_SHOOTS_RETRY_FAILED,
          payload: shoot,
        });
        reject(err);
      });
  });
};
export const retryMultipleShoots = (shoots) => (dispatch) => {
  toast.success(
    `About to retry ${shoots.length} shoot${shoots.length > 1 ? "s" : ""}...`,
  );
  dispatch({
    type: gardenerConstants.GARDENER_SHOOTS_RETRY_MULTIPLE_INIT,
    payload: shoots,
  });
  const promises = shoots.map((shoot) => {
    return new Promise((resolve) =>
      FetchAPI.Gardener.Shoots.retry(shoot)
        .then(() => resolve({ status: "resolved", id: shoot.id }))
        .catch((err) => {
          dispatch({
            type: gardenerConstants.GARDENER_SHOOTS_RETRY_FAILED,
            payload: shoot,
          });
          resolve({
            status: "rejected",
            id: shoot.id,
            desc:
              err.response.data.error.description ||
              err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "shoot",
      action: "retry",
      dispatch,
    }),
  );
};

export const updateShoot = (shoot, objectToSend) => (dispatch) => {
  dispatch({
    type: gardenerConstants.GARDENER_SHOOTS_UPDATE_INIT,
    payload: shoot,
  });

  return new Promise((resolve, reject) => {
    FetchAPI.Gardener.Shoots.update(shoot, objectToSend)
      .then((response) => {
        toast.success("Updating shoot...");
        resolve(response);
      })
      .catch((err) => {
        dispatch({
          type: gardenerConstants.GARDENER_SHOOTS_UPDATE_FAILED,
          payload: shoot,
        });
        reject(err);
      });
  });
};

export const maintainShoot = (shoot) => (dispatch) => {
  dispatch({
    type: gardenerConstants.GARDENER_SHOOTS_MAINTAIN_INIT,
    payload: shoot,
  });

  return new Promise((resolve, reject) => {
    FetchAPI.Gardener.Shoots.maintain(shoot)
      .then((response) => {
        toast.success("Immediate maintenance started");
        resolve(response);
      })
      .catch((err) => {
        dispatch({
          type: gardenerConstants.GARDENER_SHOOTS_MAINTAIN_FAILED,
          payload: shoot,
        });
        reject(err);
      });
  });
};
export const maintainMultipleShoots = (shoots) => (dispatch) => {
  toast.success(
    `About to maintain ${shoots.length} shoot${
      shoots.length > 1 ? "s" : ""
    }...`,
  );
  dispatch({
    type: gardenerConstants.GARDENER_SHOOTS_MAINTAIN_MULTIPLE_INIT,
    payload: shoots,
  });
  const promises = shoots.map((shoot) => {
    return new Promise((resolve) =>
      FetchAPI.Gardener.Shoots.maintain(shoot)
        .then(() => resolve({ status: "resolved", id: shoot.id }))
        .catch((err) => {
          dispatch({
            type: gardenerConstants.GARDENER_SHOOTS_MAINTAIN_FAILED,
            payload: shoot,
          });
          resolve({
            status: "rejected",
            id: shoot.id,
            desc:
              err.response.data.error.description ||
              err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "shoot",
      action: "maintain",
      dispatch,
    }),
  );
};

export const deleteWorker = (worker) => (dispatch) => {
  dispatch({
    type: gardenerConstants.GARDENER_SHOOTS_DELETE_WORKER_INIT,
    payload: worker.shoot,
  });

  return new Promise((resolve, reject) => {
    FetchAPI.Gardener.Workers.delete(worker)
      .then((response) => {
        toast.success("Worker delete started...");
        resolve(response);
      })
      .catch((err) => {
        dispatch({
          type: gardenerConstants.GARDENER_SHOOTS_DELETE_WORKER_FAILED,
          payload: worker.shoot,
        });
        reject(err);
      });
  });
};

export const createWorkerGroup =
  ({ gardenDomain, shoot, objectToSend }) =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      FetchAPI.Gardener.Workers.create({
        shoot,
        gardenDomain,
        objectToSend,
      })
        .then((response) => {
          toast.success("Worker Group creation started...");
          dispatch({
            type: gardenerConstants.GARDENER_SHOOTS_CREATE_WORKER_INIT,
            payload: { id: shoot.id },
          });
          resolve(response);
        })
        .catch((err) => {
          dispatch({
            type: gardenerConstants.GARDENER_SHOOTS_CREATE_WORKER_FAILED,
            payload: { id: shoot.id },
          });
          toastError(err, "Creating Worker Group failed!");
          reject(err);
        });
    });
  };

export const updateWorkerGroup = (urlParams, objectToSend) => (dispatch) => {
  dispatch({
    type: gardenerConstants.GARDENER_SHOOTS_UPDATE_INIT,
    payload: { id: urlParams.id },
  });

  return new Promise((resolve, reject) => {
    FetchAPI.Gardener.Workers.update(urlParams, objectToSend)
      .then((response) => {
        toast.success("Updating worker...");
        resolve(response);
      })
      .catch((err) => {
        dispatch({
          type: gardenerConstants.GARDENER_SHOOTS_UPDATE_FAILED,
          payload: { id: urlParams.id },
        });
        reject(err);
      });
  });
};
