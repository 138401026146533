import { useCallback, useState } from "react";
import { removeItemFromArrayWithIndex } from "../../shared-functions/array";

// This hook is the place to store erros of 'inner components' inside a 'wrapper component' in a form
// For each of the 'inner components' a slot is reserved for error.
//
// (A Wrapper Component in a form is a component that spits out a bunch of other components that have a small form inside)
// (Example : In ShootCreate , we have a WorkersList that renders a number of Workers
// ShootCreate is the main form component,
// WorkersList is a wrapper componet for Worker
// Worker is the inner component :)

const useErrorsManager = () => {
  const [errorsList, setErrorsList] = useState([]);

  const updateErrorsList = useCallback((index, error) => {
    setErrorsList((prevErrorsList) => {
      const newList = [...prevErrorsList];
      newList[index] = error;
      return newList;
    });
  }, []);

  const deleteFromErrors = (index) => {
    const newList = removeItemFromArrayWithIndex(errorsList, index);
    setErrorsList(newList);
  };

  return { errorsList, updateErrorsList, deleteFromErrors };
};

export default useErrorsManager;
