import React from "react";
import { Grid, Icon, Modal, Header } from "semantic-ui-react";
import FetchAPI from "../../../../../api/FetchAPI";
import { toast } from "react-toastify";

import { toastError } from "../../../../../app_shared_functions";
import { testPhone } from "../../../../../shared-functions/regex";

import SMSActive from "./SMSActive";
import SMSInactive from "./SMSInactive";
import SMSVerify from "./SMSVerify";
import { SMS } from "../../../../../ui/svgs/svgs";

class SMSAuthentication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disable_confirm_box: false,
      canRequest: true,
    };
  }

  check_required_fields = () => {
    let returnValue = null;
    if (!testPhone(this.state.phone)) {
      returnValue = {
        text: "Please provide a valid mobile number (+46XXXXXXXXX)",
        ref: "phoneRef",
      };
    }

    return returnValue;
  };

  showDisableConfirm = () => this.setState({ disable_confirm_box: true });

  handleDisable = () => {
    this.setState({
      isUpdating: "Disabling",
      disable_confirm_box: false,
    });

    FetchAPI.AccessControlPanel.CurrentUser.disable2FA()
      .then((res) => {
        toast.success("Two Factor login disabled ");
        this.props.fetchData();
      })
      .catch((err) => toastError(err, "Two factor login disable failed!"))
      .finally(() => {
        this.setState({
          isUpdating: false,
        });
      });
  };

  handleEnable = (phone) => {
    this.setState({ isUpdating: "Enabling" });
    const objectToSend = {
      "2FA": {
        type: "sms",
        receiver: phone,
      },
    };

    FetchAPI.AccessControlPanel.CurrentUser.enable2FA(objectToSend)
      .then((res) => {
        toast.success(
          "Two Factor login request sent! You will receive a code momentarily",
        );
        this.props.fetchData();
        this.handleRequestCode();
      })
      .catch((err) => toastError(err, "Two Factor login enable failed!"))
      .finally(() => this.setState({ isUpdating: false }));
  };

  handleRequestCode = () => {
    this.setState({
      canRequest: false,
    });
    const objectToSend = {
      request: {
        type: "sms",
      },
    };

    FetchAPI.AccessControlPanel.CurrentUser.requestEnable2FA(objectToSend)
      .then((res) => {})
      .catch((err) => toastError(err, "Two factor login request failed!"))
      .finally(() => {
        setTimeout(() => {
          this.setState({
            canRequest: true,
          });
        }, 5000);
      });
  };

  handleVerify = (code) => {
    this.setState({ isUpdating: "Verifying" });
    const objectToSend = {
      verify: {
        type: "sms",
        code: Number(code),
      },
    };

    FetchAPI.AccessControlPanel.CurrentUser.verify2FA(objectToSend)
      .then((res) => {
        toast.success("Two Factor login verified ");
        this.props.fetchData();
      })
      .catch((err) => toastError(err, "Two factor login enable failed!"))
      .finally(() => {
        this.setState({ isUpdating: false });
      });
  };

  render() {
    const { data } = this.props;

    return (
      <Grid.Row className="padding-top-20 ">
        <Grid.Column textAlign="left" width={16} className="flex vcenter">
          <SMS />
          <h5 className="margin-left margin-top-00">
            SMS Authentication
            {data === undefined ? (
              <span className="margin-left color-grey">
                <Icon name="spinner" loading />
              </span>
            ) : data.sms === undefined ? (
              <span className="margin-left color-red">
                <Icon name="times circle" color="red" /> Inactive
              </span>
            ) : data.sms.status === "active" ? (
              <span className="margin-left color-green">
                <Icon name="check circle" color="green" />
                {data.sms.status.replace(/_/g, " ")}
              </span>
            ) : (
              <span className="margin-left color-orange">
                <Icon name="clock" color="orange" />
                {data.sms.status.replace(/_/g, " ")}
              </span>
            )}
          </h5>
        </Grid.Column>

        {data === undefined ? null : data.sms === undefined ? (
          <SMSInactive
            isUpdating={this.state.isUpdating}
            handleEnable={this.handleEnable}
          />
        ) : data.sms.status === "active" ? (
          <SMSActive
            isUpdating={this.state.isUpdating}
            showDisableConfirm={this.showDisableConfirm}
            data={data.sms}
          />
        ) : (
          <SMSVerify
            isUpdating={this.state.isUpdating}
            handleVerify={this.handleVerify}
            handleRequestCode={this.handleRequestCode}
            showDisableConfirm={this.showDisableConfirm}
            canRequest={this.state.canRequest}
          />
        )}

        <Modal
          open={this.state.disable_confirm_box}
          centered={true}
          onClose={() => this.setState({ disable_confirm_box: false })}
        >
          <Header
            icon="warning circle"
            content={"About to disable SMS authentication"}
          />
          <Modal.Content>
            <p>Are you sure you want to disable SMS authentication?</p>
          </Modal.Content>
          <Modal.Actions>
            <button
              className="float-left button button--bordered"
              onClick={() => this.setState({ disable_confirm_box: false })}
            >
              <span>No</span>
            </button>

            <button
              className="float-right button button--red "
              onClick={() => this.handleDisable()}
            >
              <span>Disable</span>
            </button>
          </Modal.Actions>
        </Modal>
      </Grid.Row>
    );
  }
}

export default SMSAuthentication;
