import snapshotConstans from "./constants";
import FetchAPI from "../../../../api/FetchAPI";
import { toast } from "react-toastify";

import {
  toastMultipleResults,
  checkResourceProperties,
  toastError,
} from "../../../../app_shared_functions";

export const deleteVolume_snapshot = (snapshot) => (dispatch) => {
  const invalid_Snapshot_Msg = checkResourceProperties(snapshot, "snapshot");
  if (invalid_Snapshot_Msg) {
    toastError(invalid_Snapshot_Msg);
    return Promise.reject();
  }

  dispatch({
    type: snapshotConstans.VOLUME_SNAPSHOT_DELETE_INIT,
    payload: { id: snapshot.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.BlockStorage.Snapshots.delete(snapshot)
      .catch((err) => {
        dispatch({
          type: snapshotConstans.VOLUME_SNAPSHOT_DELETE_FAILED,
          payload: { id: snapshot.id },
        });
        toastError(err, "Deleting volume snapshot failed!");
        reject();
      })
      .then((response) => {
        if (response) {
          dispatch({
            type: snapshotConstans.VOLUME_SNAPSHOT_DELETE_STARTED,
            payload: { id: snapshot.id },
          });
          resolve();
        }
      });
  });
};

export const deleteMultipleVolume_snapshots = (snapshots) => (dispatch) => {
  if (snapshots.length === 0) return false;

  toast.success(
    `About to delete ${snapshots.length} Volume Snapshot${
      snapshots.length > 1 ? "s" : ""
    }...`,
  );
  dispatch({
    type: snapshotConstans.VOLUME_SNAPSHOT_DELETE_MULTIPLE_INIT,
    payload: snapshots,
  });
  const promises = snapshots.map((snapshot) => {
    return new Promise((resolve, reject) =>
      FetchAPI.BlockStorage.Snapshots.delete(snapshot)
        .then((response) => resolve({ status: "resolved", id: snapshot.id }))
        .catch((err) => {
          dispatch({
            type: snapshotConstans.VOLUME_SNAPSHOT_DELETE_FAILED,
            payload: { id: snapshot.id },
          });
          resolve({
            status: "rejected",
            id: snapshot.id,
            desc:
              err.response.data.error.description ||
              err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "volume snapshot",
      action: "delete",
      dispatch,
    }),
  );
};

export const editVolume_snapshot = (snapshot, objectToSend) => (dispatch) => {
  const invalid_Snapshot_Msg = checkResourceProperties(snapshot, "snapshot");
  if (invalid_Snapshot_Msg) {
    toastError(invalid_Snapshot_Msg);
    return Promise.reject();
  }

  dispatch({
    type: snapshotConstans.VOLUME_SNAPSHOT_MODIFY_INIT,
    payload: { id: snapshot.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.BlockStorage.Snapshots.update({ snapshot, objectToSend })
      .catch((err) => {
        dispatch({
          type: snapshotConstans.VOLUME_SNAPSHOT_MODIFY_FAILED,
          payload: { id: snapshot.id },
        });
        toastError(err, "Modifying snapshot failed!");
        reject();
      })
      .then((response) => {
        if (response) {
          dispatch({
            type: snapshotConstans.VOLUME_SNAPSHOT_MODIFY_STARTED,
            payload: { id: snapshot.id },
          });
          resolve();
        }
      });
  });
};
