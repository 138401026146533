import FetchAPI from "../../../../api/FetchAPI";
import { toast } from "react-toastify";
import ipsecConstants from "./constants";

import {
  toastMultipleResults,
  checkResourceProperties,
  toastError,
} from "../../../../app_shared_functions";

export const updateIpsec = (ipsec, objectToSend) => (dispatch) => {
  const invalid_ipsec_Msg = checkResourceProperties(ipsec, "ipsec policy");
  if (invalid_ipsec_Msg) {
    toastError(invalid_ipsec_Msg);
    return Promise.reject();
  }

  dispatch({
    type: ipsecConstants.IPSEC_UPDATE_INIT,
    payload: { id: ipsec.id },
  });
  FetchAPI.Networking.IPSec.modify({
    ipsec,
    objectToSend,
  })
    .then((response) => {
      dispatch({
        type: ipsecConstants.IPSEC_UPDATE_STARTED,
        payload: { id: ipsec.id },
      });
      toast.success("Ipsec update started...");
    })
    .catch((err) => {
      dispatch({
        type: ipsecConstants.IPSEC_UPDATE_FAILED,
        payload: { id: ipsec.id, err },
      });
      toastError(err, "Modifying ipsec policy failed!");
    });
};

export const deleteIpsec = (ipsec) => (dispatch) => {
  const invalid_ipsec_Msg = checkResourceProperties(ipsec, "ipsec policy");
  if (invalid_ipsec_Msg) {
    toastError(invalid_ipsec_Msg);
    return Promise.reject();
  }

  dispatch({
    type: ipsecConstants.IPSEC_DELETE_INIT,
    payload: { id: ipsec.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Networking.IPSec.delete(ipsec)
      .then((response) => {
        toast.success("Ipsec delete started...");
        dispatch({
          type: ipsecConstants.IPSEC_DELETE_STARTED,
          payload: { id: ipsec.id },
        });
        resolve(response.data);
      })
      .catch((err) => {
        toastError(err, "Deleting ipsec policy failed!");
        dispatch({
          type: ipsecConstants.IPSEC_DELETE_FAILED,
          payload: { id: ipsec.id, err },
        });
        reject(err);
      });
  });
};

export const deleteMultipleIpsecs = (ipsecs) => (dispatch) => {
  toast.success(
    `About to delete ${ipsecs.length} Ipsec${ipsecs.length > 1 ? "s" : ""}...`,
  );
  dispatch({
    type: ipsecConstants.IPSEC_DELETE_MULTIPLE_INIT,
    payload: ipsecs,
  });
  const promises = ipsecs.map((ipsec) => {
    return new Promise((resolve, reject) =>
      FetchAPI.Networking.IPSec.delete(ipsec)
        .then((response) => resolve({ status: "resolved", id: ipsec.id }))
        .catch((err) => {
          dispatch({
            type: ipsecConstants.IPSEC_DELETE_FAILED,
            payload: { id: ipsec.id },
          });
          resolve({
            status: "rejected",
            id: ipsec.id,
            desc:
              err.response.data.error.description ||
              err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "ipsec",
      action: "delete",
      dispatch,
    }),
  );
};

export const createIpsec =
  (region, currentProjectID, objectToSend) => (dispatch) => {
    dispatch({ type: ipsecConstants.IPSEC_CREATE_INIT });
    return new Promise((resolve, reject) => {
      FetchAPI.Networking.IPSec.create({
        region,
        currentProjectID,
        objectToSend,
      })
        .then((response) => {
          toast.success("Ipsec creation started...");
          dispatch({
            type: ipsecConstants.IPSEC_CREATE_STARTED,
            payload: {
              ...response.data.ipsecpolicy,
              region: region.toLowerCase(),
              status: "creating",
            },
          });
          resolve();
        })
        .catch((err) => {
          dispatch({ type: ipsecConstants.IPSEC_CREATE_FAILED });
          toastError(err, "Creating ipsec policy failed!");
          reject(err);
        });
    });
  };
