import { useState, useEffect, useMemo, useCallback } from "react";

import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { deleteMultipleIpsecs } from "./reducer/actions";
import { showMoreResources } from "../../../actions/shared";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";

import {
  PageToolbar,
  PageToolbarButtonsPane,
} from "../../../components/PageToolbar";
import Breadcrumbs from "../../../components/shared/breadcrumbs/Breadcrumbs";
import { Icon, Segment, Sidebar, Loader, Input } from "semantic-ui-react";

import SortableIpsecList from "./SortableIpsecList";

import { toast } from "react-toastify";
import { confirmbox_open } from "../../../components/confirmbox/actions";

import { KnowledgeBaseButton } from "../../../components/knowledgebase/KnowledgeBaseButton";

import LoadMoreSensor from "../../../components/shared/LoadMoreSensor";

import { canLoadMore } from "../../../app_shared_functions";

import { debounceInput } from "../../../shared-functions/environment";
import CircularButton from "../../../components/shared/circularbutton/CircularButton";
import { getButtonCountPopup } from "../../../components/shared/circularbutton/util";
import { mapResourceNameToReduxSelector } from "../../../selectors/resources";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import { mapResourceTypeToReduxList } from "../../../components/shared/grid-bits/mapResourceTypeToReduxList";
import useSubscribe from "../../../custom-hooks/useSubscribe";
import useHasCRUDAccess from "../../../custom-hooks/useHasCRUDAccess";

const IpsecList = () => {
  const resourceType = "ipsecs";

  const dispatch = useDispatch();

  useSubscribe(mapResourceTypeToReduxList[resourceType]);

  const { t } = useTranslation();

  const hasCRUDAccess = useHasCRUDAccess();

  const {
    resourcesList: ipsecsList,
    currentPage,
    zonesLeft,
    hiddenRegions,
    selectedResources: selectedIpsecs,
  } = useSelector(mapResourceNameToReduxSelector[resourceType]);

  const breadcrumbs = useMemo(() => {
    return [
      {
        title: "menu.networks.networking",
      },
      {
        title: "menu.networks.vpn",
        pathname: "/networking/vpn",
      },
      {
        title: `menu.networks.ipsec`,
        popup: Object.values(ipsecsList || {}).reduce(
          (acc, x, i) =>
            (acc = {
              ...acc,
              "IPSEC Policies": i + 1,
            }),
          {},
        ),
      },
    ];
  }, [ipsecsList]);

  const [filteringText, setFilteringText] = useState("");

  const [loadingMore, setLoadingMore] = useState(false);

  const toggleDeleteDialog = useCallback(() => {
    if (!selectedIpsecs.length) {
      toast.warn("No Ipsec policies are selected for delete.");
    } else {
      const resources = selectedIpsecs.map((x) => ipsecsList[x]);
      dispatch(
        confirmbox_open({
          entity: "ipsec policy",
          operation: "delete",
          resources: resources,
          onConfirm: deleteMultipleIpsecs,
        }),
      );
    }
  }, [dispatch, ipsecsList, selectedIpsecs]);

  const checkAndLoadMore = useCallback(
    (isVisible) => {
      if (isVisible && canLoadMore(ipsecsList, currentPage, hiddenRegions)) {
        setLoadingMore(true);
        dispatch(showMoreResources("ipsec"));
      }
    },
    [hiddenRegions, currentPage, dispatch, ipsecsList],
  );

  useEffect(() => {
    setLoadingMore(false);
  }, [currentPage]);

  const onFilterChange = debounceInput((text) => setFilteringText(text));

  return (
    <div>
      <Sidebar.Pushable as={Segment}>
        <Sidebar.Pusher>
          <PageToolbar>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <KnowledgeBaseButton />
            {hasCRUDAccess && (
              <PageToolbarButtonsPane>
                <button
                  className="button button--transparent"
                  onClick={() =>
                    dispatch(toggleSlidingMenu("create", "IPSEC Policy"))
                  }
                >
                  Create new IPSEC Policy
                  <Icon name="plus circle" />
                </button>
              </PageToolbarButtonsPane>
            )}
          </PageToolbar>

          <div className="page-content" key="content">
            {/* Multi-select actions*/}
            <div className="top-section">
              {hasCRUDAccess && (
                <div className="margin-bottom">
                  <CircularButton
                    onClick={toggleDeleteDialog}
                    className={`button button--circular margin-right-half `}
                    icon="trash"
                    count={selectedIpsecs.length}
                    popupContent={`${t(
                      `ipsec.actions.delete`,
                    )} ${getButtonCountPopup(selectedIpsecs.length, "Ipsec")}`}
                  />
                </div>
              )}
              <div className="pos_right">
                <div className="ui input input-white">
                  <Input
                    minLength={2}
                    onChange={(e) => onFilterChange(e.target.value)}
                    placeholder="Enter filter text..."
                  />
                </div>
              </div>
            </div>
            <GridContext.Provider
              value={{
                filteringText,
                resourceType,
                hasCRUDAccess,
              }}
            >
              <SortableIpsecList />
            </GridContext.Provider>
            <LoadMoreSensor
              checkAndLoadMore={checkAndLoadMore}
              loading_more={loadingMore}
            />
          </div>

          {zonesLeft ? (
            <Loader
              size="tiny"
              active
              className="loading-fixed"
            >{`${zonesLeft} region(s) loading...`}</Loader>
          ) : (
            ""
          )}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
};

export default IpsecList;
