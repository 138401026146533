import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import SortableServerList from "./SortableServerList";
import {
  PageToolbar,
  PageToolbarButtonsPane,
} from "../../components/PageToolbar";
import Breadcrumbs from "../../components/shared/breadcrumbs/Breadcrumbs";
import { KnowledgeBaseButton } from "../../components/knowledgebase/KnowledgeBaseButton";
import { showMoreResources } from "../../actions/shared";
import { toggleSlidingMenu } from "../../actions/toggleSlidingMenu";
import { Loader, Icon, Segment, Sidebar, Input } from "semantic-ui-react";
import LoadMoreSensor from "../../components/shared/LoadMoreSensor";
import { canLoadMore } from "../../app_shared_functions";
import { debounceInput } from "../../shared-functions/environment";
import { mapResourceTypeToReduxList } from "../../components/shared/grid-bits/mapResourceTypeToReduxList";
import useSubscribe from "../../custom-hooks/useSubscribe";
import useHasCRUDAccess from "../../custom-hooks/useHasCRUDAccess";
import { mapResourceNameToReduxSelector } from "../../selectors/resources";
import { generateBreadcrumbs } from "./bits/helper";
import GridContext from "../../components/shared/grid-bits/context/GridContext";
import MultiActionButtons from "./bits/MultiActionButtons";
import ConsoleModal from "./console/ConsoleModal";

const ServersList = () => {
  const resourceType = "servers";

  const dispatch = useDispatch();

  const hasCRUDAccess = useHasCRUDAccess();

  const memoizedItems = useMemo(
    () => [
      mapResourceTypeToReduxList[resourceType],
      "VOLUMES_LIST",
      "SERVER_SNAPSHOTS_LIST",
      "PORTS_LIST",
      "SECURITYGROUPS_LIST",
      "FLOATINGIPS_LIST",
    ],
    [],
  );
  useSubscribe(memoizedItems);

  const {
    resourcesList: serversList,
    currentPage,
    zonesLeft,
    hiddenRegions,
  } = useSelector(mapResourceNameToReduxSelector[resourceType]);

  const breadcrumbs = useMemo(
    () => generateBreadcrumbs(serversList),
    [serversList],
  );

  const [filteringText, setFilteringText] = useState("");

  const [loadingMore, setLoadingMore] = useState(false);

  const checkAndLoadMore = useCallback(
    (isVisible) => {
      if (isVisible && canLoadMore(serversList, currentPage, hiddenRegions)) {
        setLoadingMore(true);
        dispatch(showMoreResources("server"));
      }
    },
    [hiddenRegions, currentPage, dispatch, serversList],
  );

  const onCreateServer = useCallback(
    () => dispatch(toggleSlidingMenu("create", "Server")),
    [dispatch],
  );

  useEffect(() => {
    setLoadingMore(false);
  }, [currentPage]);

  const onFilterChange = useCallback((e) => {
    const val = e.target.value;
    return debounceInput(() => setFilteringText(val))();
  }, []);

  const gridContextValues = useMemo(() => {
    return {
      filteringText,
      resourceType,
      hasCRUDAccess,
    };
  }, [filteringText, resourceType, hasCRUDAccess]);

  return (
    <div>
      <Sidebar.Pushable as={Segment}>
        <Sidebar.Pusher>
          {/* Top Toolbar */}
          <PageToolbar>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <KnowledgeBaseButton />

            {hasCRUDAccess && (
              <PageToolbarButtonsPane>
                <button
                  className="button button--transparent"
                  onClick={onCreateServer}
                >
                  Create new server
                  <Icon name="plus circle" />
                </button>
              </PageToolbarButtonsPane>
            )}
          </PageToolbar>

          {/* The actual list */}
          <div className="page-content">
            {/* Multi-select actions*/}
            <div className="top-section">
              <MultiActionButtons />

              <div className="pos_right">
                <div className="ui input input-white">
                  <Input
                    minLength={2}
                    onChange={onFilterChange}
                    placeholder="Enter filter text..."
                  />
                </div>
              </div>
            </div>
            <GridContext.Provider value={gridContextValues}>
              <SortableServerList />
            </GridContext.Provider>
            <LoadMoreSensor
              checkAndLoadMore={checkAndLoadMore}
              loading_more={loadingMore}
            />
          </div>
          {zonesLeft ? (
            <Loader
              size="tiny"
              active
              className="loading-fixed"
            >{`${zonesLeft} region(s) loading...`}</Loader>
          ) : (
            ""
          )}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
      <ConsoleModal />
    </div>
  );
};

export default ServersList;
