import { Icon } from "semantic-ui-react";
import { safeToLowerCase } from "../../../../shared-functions/string";
import { OpenStackResourceOrOther } from "../../../types";

export type BootableIconProps = {
  resource?: OpenStackResourceOrOther;
};
const BootableIcon = ({ resource }: BootableIconProps) => {
  if (
    safeToLowerCase(resource?.bootable) === "false" ||
    resource?.bootable === false
  ) {
    return <Icon name="close" color="red" />;
  }
  return <Icon name="check" color="green" />;
};

export default BootableIcon;
