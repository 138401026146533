import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Menu, Ref } from "semantic-ui-react";
import { defaultValues } from "../../../app_constants";
import Details from "./Details";
import Projects from "./Projects";

const MoreData = ({ user }) => {
  const domains = useSelector(
    (state) => state?.usersettings?.selectedDomains || state.domains.list,
  );

  const domElementRef = useRef();

  const dispatch = useDispatch();

  const generatePanes = () => {
    const components = {
      Details: Details,
      Projects: Projects,
    };

    return defaultValues.openstack.user.tabs.map((tab, key) => {
      const Component = components[tab];

      let propsToSend = {};
      if (tab === "Details") {
        propsToSend.user = user;
        propsToSend.dispatch = dispatch;
        propsToSend.domain = domains.find((x) => x.id === user.domain_id);
      }
      if (tab === "Projects") {
        propsToSend.user = user;
        propsToSend.domain = domains.find((x) => x.id === user.domain_id);
      }

      return {
        menuItem: (
          <Menu.Item key={key}>
            <span>{tab}</span>
          </Menu.Item>
        ),
        render: () =>
          React.createElement(Component, { user, ...propsToSend }, null),
      };
    });
  };

  return (
    <Ref innerRef={domElementRef}>
      <div
        onClick={(e) => e.stopPropagation()}
        className="more-data more-data--tabs"
      >
        <Tab
          className="horizontal-tabs padding-top-20 margin-bottom "
          panes={generatePanes()}
        />
      </div>
    </Ref>
  );
};

export default MoreData;
