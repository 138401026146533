// Here we store a list of resources that are not dependent on regions.
// 1. This list is gonna be used in sortablelist to filter out regionFilter

import { ToastContainerProps } from "react-toastify";
import { convertTimestampToTime } from "./app_shared_functions";

// 2. Also 'toggle all' function needs this list to do the toggle all functionality properly
export const resources_without_region = [
  "CLEURA_USERS_LIST",
  "MONITORING_CONTACTS_LIST",
  "MONITORING_SCHEDULES_LIST",
  "PROJECTS_LIST",
];

export const fileTypes = {
  yaml: [".yml", ".yaml"],
  json: [".json"],
};

export const statusIcons = [
  {
    status: ["awaiting-transfer"],
    icon: "wait",
    color: "red",
  },
  {
    status: [
      "maintenance",
      "in-use",
      "reserved",
      "private template",
      "disabled",
    ],
    icon: "lock",
    color: "red",
  },
  {
    status: ["booting volume"],
    icon: "power",
    color: "red",
  },
  {
    status: ["public template"],
    icon: "lock open",
    color: "green",
  },
  {
    status: ["backing-up", "saving"],
    icon: "save margin-left-half ",
    color: "grey",
    circular: "orange",
  },
  {
    status: ["retyping"],
    icon: "random",
    color: "orange",
  },
  {
    status: ["restoring", "queued"],
    icon: "history margin-left-half",
    color: "grey",
    circular: "orange",
  },
  {
    status: ["download", "downloading"],
    icon: "download",
    color: "green",
    circular: "green",
  },
  {
    status: ["upload", "uploading"],
    icon: "upload",
    color: "green",
    circular: "green",
  },
  {
    status: [
      "available",
      "active",
      "enabled",
      "paid",
      "create_complete",
      "update_complete",
      "snapshot_complete",
      "resume_complete",
      "check_complete",
      "complete",
      "restore_complete",
    ],
    icon: "check circle",
    color: "green",
  },
  {
    status: [
      "detaching",
      "delete_init",
      "delete_started",
      "deleting",
      "pending_delete",
      "restore_init",
      "restore_started",
      "delete init",
      "delete started",
      "Rename started",
      "Gateway switching",
    ],
    icon: "cog",
    color: "grey",
    circular: "red",
  },
  {
    status: ["suspend_complete"],
    icon: "pause circle",
    color: "orange",
  },
  {
    status: [
      "attaching",
      "rename_started",
      "update_init",
      "updating",
      "pending_update",
      "disaster_recovery_init",
      "disaster_recovery_started",
      "disaster_recovery_updating",
      "update_in_progress",
      "delete_in_progress",
      "in_progress",
      "resume_init",
      "resume_started",
      "resume_in_progress",
      "snapshot_in_progress",
      "suspend_init",
      "suspend_started",
      "suspend_in_progress",
    ],
    icon: "cog",
    color: "grey",
    circular: "orange",
  },
  {
    status: ["active - external connection"],
    icon: "external square alternate",
    color: "green",
  },
  {
    status: ["creating", "pending_create", "create_in_progress", "build"],
    icon: "wizard",
    color: "green",
    circular: "green",
    resource_state: "creating",
  },
  {
    status: [
      "down",
      "n/a",
      "error",
      "create_failed",
      "update_failed",
      "delete_failed",
      "snapshot_failed",
      "resume_failed",
      "delet_complete",
      "delete_complete",
      "suspend_failed",
    ],
    icon: "times circle",
    color: "red",
  },
  {
    status: ["resize_started", "extending"],
    icon: "long arrow alternate up",
    color: "grey",
    circular: "orange",
  },
  {
    status: ["gateway_switching"],
    icon: "plug",
    color: "grey",
    circular: "orange",
  },
  {
    status: ["connecting", "add_interface"],
    icon: "linkify",
    color: "grey",
    circular: "orange",
  },
  {
    status: ["disconnecting", "remove_interface"],
    icon: "unlinkify",
    color: "grey",
    circular: "orange",
  },
  {
    status: ["credited"],
    icon: "check circle",
    color: "grey",
  },
  {
    status: ["partly_credited", "partly_paid", "created"],
    icon: "clock outline",
    color: "grey",
  },
  {
    status: ["user"],
    icon: "user",
    color: "green",
  },
  {
    status: ["admin_user"],
    icon: "user plus",
    color: "green",
  },
  {
    status: ["connected (port is down)"],
    icon: "linkify",
    color: "red",
  },
  {
    status: ["disconnected", "not connected"],
    icon: "unlinkify",
    color: "grey",
  },
  {
    status: ["connected"],
    icon: "linkify",
    color: "green",
  },
];

export const default_vpn_service = {
  enc_algorithm: ["3DES", "AES-128", "AES-196", "AES-256"],
  auth_algorithm: ["SHA1", "SHA256", "SHA384", "SHA512"],
  ike_version: ["V1", "V2"],
  phase1: ["main"],
  pfs: ["Group2", "Group5", "Group14"],
  encapsulation_mode: ["Tunnel", "Transport"],
  transform_protocol: ["ESP", "AH", "AH-ESP"],
};

// Storing a number of values that will be used in different components.
export const defaultValues = {
  // the width for select box items when the text overflows the item
  "select-box-width": 250,

  "quick-view-width": 300,

  // Maximum for detailed view height before enforcing scrollbar
  moredata__maxheight: 2000,

  max_layers: 5,
  page_size: 20,

  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],

  days: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],

  fetch: {
    simple_error: "Error fetching data!",
    error_in: "Error fetching data in ",
  },

  // colors for differnet components to use:
  // like active/stopped/paused state in a server
  colors: {
    debian: "#d70551",
    windows: "#0089d1",
    ubuntu: "#dd4815",
    freebsd: "#bc0001",
    coreos: "#519fd5",
    fedora: "#283f70",
    centos: "#8f2176",
    suse: "#70b525",

    error: "#cc0000",
    resized: "#ffa64c",

    active: "#21ba46", //active
    stopped: "#db2728", //stopped
    paused: "#767676", //paused
    rescued: "#fcbc0a", //rescued
    grey: "#999999",
    primary: "#FF6600",

    kna1: "#2085cf",
    fra1: "#1396c7",
    tky1: "#f60400",
    sto2: "#ffeb3b",
    lon1: "#7c00f6",
    buf1: "#f9a934",
    dx1: "#b22e6f",
    Kna1: "#2085cf",
    Fra1: "#1396c7",
    Tky1: "#f60400",
    Sto2: "#ffeb3b",
    Lon1: "#7c00f6",
    Buf1: "#f9a934",
    Dx1: "#b22e6f",

    up: "#21ba46", //active
    down: "#db2728", //stopped
    condition_down: "#fcbc0a", //paused
  },

  volumes: [
    20, 50, 100, 150, 200, 250, 300, 400, 500, 750, 1000, 1500, 2000, 2500,
    3000, 4000, 5000,
  ],
  rams: [0.5, 1, 2, 4, 6, 8, 10, 12, 14, 16, 24, 32, 48, 64, 128],
  cores: [1, 2, 4, 6, 8, 10, 12, 14, 16, 20, 24],

  server: {
    graphData: {
      from: [
        "-15 minutes",
        "-30 minutes",
        "-45 minutes",
        "-1 hours",
        "-2 hours",
        "-4 hours",
        "-6 hours",
        "-8 hours",
        "-12 hours",
        "-1 day",
        "-2 day",
        "-3 day",
        "-4 day",
        "-5 day",
        "-6 day",
        "-7 day",
      ],
      default: "-1 day",
      title: {
        cpu: "CPU Utilization",
        txbps: "Network Transmit Traffic",
        rxbps: "Network Receive Traffic",
        iorb: "IO Read Bytes",
        iowb: "IO Write Bytes",
        iorr: "IO Read Request",
        iowr: "IO Write Request",
      },
    },
    tabs: [
      "Details",
      "Addresses",
      "Snapshots",
      "Security Groups",
      "Disaster Recovery",
      "Charts",
      "Console Output",
    ],
    addresses: {
      columns: [
        "Network",
        "Network ID",
        "Type",
        "Mac Address",
        "Address",
        "Port",
      ],
    },
    resize_status: [
      "resize_migrating",
      "resize_migrated",
      "resize_finish",
      "resize_prep",
      "resize_reverting",
      "resize_confirming",
    ],
    boot_target: {
      volume: "Volume (Recommended)",
      ephemeral: "Ephemeral Disk",
    } as const,
    configDrive: ["Use metadata service (Default)", "Use configuration drive"],
    bootSourceTypes: ["Image", "Snapshot", "Private Image", "Volume"],
  },
  server_snapshots: {
    columns: ["Status", "Name", "Size", "Disk Format", "Visibility", "Action"],
  },

  volume: {
    tabs: ["Details", "Snapshots", "Disaster Recovery"],
    default_size: 50,
    maximum_size: 30000,
  },
  volume_snapshots: {
    columns: ["Status", "Name", "Size", "Progress", "Action"],
  },

  networks: {
    tabs: ["Details", "Ports", "Subnets", "Routers"],
    ports: ["Name", "ID", "Fixed IPs", "Attached Devices", "Action"],
    subnets: ["Name", "ID", "Gateway IP", "CIDR", "Action"],
    router: ["Name", "ID", "IP Addresses", "Subnets", "Action"],
  },

  floatingips: {
    assignOptions: ["None (Do Not Assign)", "Server", "Load Balancer"],
  },

  endpoints: {
    type: ["CIDR", "SUBNET"],
  },

  ike: {
    auth_algorithm: default_vpn_service.auth_algorithm,
    enc_algorithm: default_vpn_service.enc_algorithm,
    pfs: default_vpn_service.pfs,
    phase1: default_vpn_service.phase1,
    ike_version: default_vpn_service.ike_version,
  },

  ipsec: {
    auth_algorithm: default_vpn_service.auth_algorithm,
    enc_algorithm: default_vpn_service.enc_algorithm,
    pfs: default_vpn_service.pfs,
    encapsulation_mode: default_vpn_service.encapsulation_mode,
    transform_protocol: default_vpn_service.transform_protocol,
  },

  ipsecsc: {
    mtu: 1500,
    dpd_interval: 30,
    dpd_timeout: 120,
    initiator_state: ["Bi-Directional", "Response only"],
    dpd: ["Clear", "Hold", "Restart", "Disabled"],
  },

  keypair: {
    type_list: ["ssh", "x509", "none"],
  },

  cluster: {
    master_count: 1,
    node_count: 1,
    tabs: ["Details", "KubeConfig"],
  },

  listener: {
    port: 80,
    default_pool_id: " - None - ",
    protocol_data: ["HTTP", "HTTPS", "TCP"],
    columns: [
      "Status",
      "ID",
      "Name",
      "Protocol",
      "Port",
      "Connection Limit",
      "Default Pool",
      "Action",
    ],
    allowed_headers: [
      "X-Forwarded-For",
      "X-Forwarded-Port",
      "X-Forwarded-Proto",
      // "X-SSL-Client-Verify",
      // "X-SSL-Client-Has-Cert",
      // "X-SSL-Client-DN",
      // "X-SSL-Client-CN",
      // "X-SSL-Issuer",
      // "X-SSL-Client-SHA1",
      // "X-SSL-Client-Not-Before",
      // "X-SSL-Client-Not-After",
    ],
  },

  members: {
    columns: [
      "Name",
      "ID",
      "Address",
      "Operating Status",
      "Protocol Port",
      "Provisioning Status",
    ],
  },

  loadbalancer: {
    creationMethod: "network",
    tabs: ["Details", "Listeners", "Pools"],
  },

  pool: {
    algorithms: [
      "ROUND_ROBIN",
      "LEAST_CONNECTIONS",
      "SOURCE_IP",
      "SOURCE_IP_PORT",
    ],
    protocols: ["HTTP", "HTTPS", "PROXY", "TCP", "UDP"],
    session_persistence: [
      "No Session persistence",
      "SOURCE_IP",
      "HTTP_COOKIE",
      "APP_COOKIE",
    ],
    listener_id: " - None - ",
    columns: [
      "Status",
      "ID",
      "Name",
      "Algorithm",
      "Session Persistence",
      "Health Monitor",
      "Members/Listeners",
      "Action",
    ],
  },

  securitygroup: {
    rule_columns: [
      "Status",
      "Direction",
      "Ether type",
      "IP Protocol",
      "Remote",
      "Port range",
      "Action",
    ],
    port_columns: ["ID", "Name", "Fixed IPs", "Network ID", "Attached Device"],
    tabs: ["Details", "Rules", "Ports"],
  },

  securitygrouprule: {
    supported_protocols: [
      "Any",
      "TCP",
      "UDP",
      "ICMP",
      "VRRP",
      "AH",
      "DCCP",
      "EGP",
      "ESP",
      "GRE",
      "ICMPV6",
      "IGMP",
      "IPIP",
      "IPV6-ENCAP",
      "IPV6-FRAG",
      "IPV6-ICMP",
      "IPV6-NONXT",
      "IPV6-OPTS",
      "IPV6-ROUTE",
      "OSPF",
      "PGM",
      "RSVP",
      "SCTP",
      "UDPLITE",
    ],
    protocol: [
      {
        name: "Wildcard Incoming",
        type: 0,
        direction: "Ingress",
        direction_Selectable: false,
        port: null,
        from: null,
      },
      {
        name: "Wildcard Outgoing",
        type: 0,
        direction: "Egress",
        direction_Selectable: false,
        port: null,
        from: null,
      },
      {
        name: "Custom TCP",
        type: "tcp",
        direction: "Ingress",
        direction_Selectable: true,
        port: "",
        from: "selectable",
      },
      {
        name: "All TCP",
        type: "tcp",
        direction: "Ingress",
        direction_Selectable: false,
        port: "1-65535",
        from: "selectable",
      },
      {
        name: "Web",
        type: "tcp",
        direction: "Ingress",
        direction_Selectable: false,
        port: "80,443",
        from: "selectable",
      },
      {
        name: "SSH",
        type: "tcp",
        direction: "Ingress",
        direction_Selectable: false,
        port: "22",
        from: "selectable",
      },
      {
        name: "Custom UDP",
        type: "udp",
        direction: "Ingress",
        direction_Selectable: true,
        port: "",
        from: "selectable",
      },
      {
        name: "Custom ICMP",
        type: "icmp",
        direction: "Ingress",
        direction_Selectable: true,
        port: "",
        from: "selectable",
      },
    ],
    from: ["Network/IP", "Security Group"],
  },

  subnet: {
    ip_version: ["4", "6"],
    ipv6_addressing_mode: [
      { value: "dhcpv6-stateless", text: "DHCPv6 Stateless" },
      { value: "dhcpv6-stateful", text: "DHCPv6 Stateful" },
      { value: "slaac", text: "SLAAC" },
    ],
  },

  vpn: {
    ...default_vpn_service,
    dpd_action: ["Restart", "Hold", "Clear", "Disabled"],
    initiator_state: ["Bi directional", "Response only"],
    columns: [
      "Status",
      "Name",
      "Peer ID",
      "Peer CIDRs",
      "IKE name",
      "IPSEC name",
      "Action",
    ],
  },

  usersettings: {
    country: "se",
    payment_methods: [{ name: "swedbank" }],
    invoice_paging_size: 10,
    invoice_payable_status: ["created", "partly_paid", "partly_credited"],
    delete_reason: [
      "I had technical issues that I could not get solved",
      "Moving to another cloud provider",
      "I need other locations for my servers",
      "Other",
    ],
  },

  cleura_cloud: {
    monitoring: {
      schedules: {
        tabs: ["Details", "Load Time Graph", "Uptime Graph", "Alerts", "Logs"],
      },
    },
  },

  openstack: {
    user: {
      tabs: ["Details", "Projects"],
    },
  },

  orchestration: {
    hint: `{
    "heat_template_version": "2018-08-31",
    "description": "Simple template to deploy a single compute instance",
    "resources": {
        "my_instance": {
            "type": "OS::Nova::Server",
            "properties": {
                "key_name": "my-key",
                "image": "Ubuntu 20.04 Focal Fossa 20200423",
                "flavor": "1C-1GB",
                "networks": [
                    {
                        "network": "Default network"
                    }
                ]
            }
        }
    }
}`,
    columns: ["Status", "Resource type", "Resource name", "Action"],
    tabs: [
      "Details",
      "Resources",
      "Output",
      "Snapshots",
      "Environment",
      "Events",
    ],
    outputs: ["Output Key", "Description", "Action"],
    snapshots: ["Status", "ID", "Name", "Action"],
    environment: ["Property", "Value"],
    events: ["Status", "ID", "Resource Name", "Time"],
  },

  gardener: {
    shoots: {
      tabs: [
        "Details",
        "Status",
        "Worker Groups",
        "Lifecycle",
        "KubeConfig",
        "Monitoring",
      ],
      default_cidr: "10.250.0.0/16",
    },
    workers: {
      default_machine: {
        name: "b.2c4gb",
      },
      auto_scaler: {
        min: 3,
        max: 5,
      },
    },
  },

  projects: {
    tabs: ["Quotas", "Cleura Users", "Openstack Users"],
  },

  charts_default: {
    title: {
      style: {
        fontWeight: "normal",
      },
    },
    subtitle: {
      text: "Click and drag to zoom in",
      style: {
        fontWeight: "normal",
      },
    },

    tooltip: {
      shared: true,
      borderRadius: 15,
      shadow: true,
    },
    xAxis: {
      type: "datetime",
      labels: {
        formatter: function (): string {
          return convertTimestampToTime((this as any).value);
        },
      },
    },

    chart: {
      zoomType: "x",
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    tooltips: {
      backgroundColor: "rgba(255,255,255,0.9)",
      titleFontColor: "#000",
      titleFontFamily: "sans-serif",
      bodyFontColor: "#000",
      bodyFontFamily: "sans-serif",
      borderWidth: 1,
      borderColor: "rgba(32,133,207, .6)",
    },
  },

  react_table: {
    hidden_header_column: "__Hidden__",
  },

  ace_editor: {
    commonProps: {
      mode: "javascript",
      theme: "textmate",
      fontSize: 14,
      showPrintMargin: false,
      showGutter: true,
      highlightActiveLine: false,
      name: "ace-editor",
      setOptions: {
        enableBasicAutocompletion: false,
        enableLiveAutocompletion: false,
        enableSnippets: false,
        showLineNumbers: false,
        useWorker: false,
        tabSize: 2,
      },
    },
    width_100_percent: {
      width: "100%",
    },
    height_small: {
      height: "300px",
    },
    height_tiny: {
      height: "230px",
    },
  },

  image: {
    visibility: ["shared", "private"],
  },

  account_logs: {
    limit: 50,
  },

  openstack_logs: {
    limit: 50,
  },

  notifications: {
    tabs: ["Gardener"],
  },
};

export const toastContainerDefaults: ToastContainerProps & {
  pauseOnVisibilityChange: boolean;
} = {
  position: "bottom-center",
  autoClose: 5000,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnVisibilityChange: false,
  draggable: false,
  pauseOnHover: true,
  className: "toast-round",
};

export const conditions = {
  operational: {
    value: 0,
    icon: "✅",
    iconName: "check circle",
    iconColor: "green",
    summary: "All Systems Operational",
  },
  under_maintenance: {
    value: 1,
    icon: "🛠",
    iconName: "wrench",
    iconColor: "blue",
    summary: "Under Maintenance",
  },
  degraded_performance: {
    value: 2,
    icon: "🐢",
    iconName: "tachometer alternate",
    iconColor: "yellow",
    summary: "Degraded Performance",
  },
  partial_outage: {
    value: 3,
    icon: "🟠",
    iconName: "warning circle",
    iconColor: "orange",
    summary: "Partial Outage",
  },
  major_outage: {
    value: 4,
    icon: "🔴",
    iconName: "remove circle",
    iconColor: "red",
    summary: "Major Outage",
  },
  unknown: {
    value: -1,
    icon: "❓",
    iconName: "question circle",
    iconColor: "yellow",
    summary: "Unknown Status",
  },
};

/**
 * This array includes a list of features that are "unavailable" or "immutable" in certain zones.
 * as an example:
 *      the "MTU" value is "unavailable" when "creating a network" in one of the regions with id : 4 or 102
 *      the "MTU" value is "immutable" (or unchangeable) when "modifying a network" in one of the regions with id : 4 or 102
 */
export const unsupportedFeatures = [
  {
    feature: "MTU",
    zone_ids: [4, 102],
    component: "Create Network",
    degree: "unavailable",
    description: "MTU is not supported in this region!",
  },
  {
    feature: "MTU",
    zone_ids: [4, 102],
    component: "Modify Network",
    degree: "immutable",
    description: "Updating MTU value is not supported in this region!",
  },
];

export const temporaryTimezone = "(GMT+01:00) Central European Time";

export const timezonesList = [
  "(GMT-11:00) Niue Pago Pago",
  "(GMT-10:00) Hawaii Time",
  "(GMT-10:00) Rarotonga",
  "(GMT-10:00) Tahiti",
  "(GMT-09:30) Marquesas",
  "(GMT-09:00) Alaska Time",
  "(GMT-09:00) Gambier",
  "(GMT-08:00) Pacific Time",
  "(GMT-08:00) Pacific Time - Tijuana",
  "(GMT-08:00) Pacific Time - Vancouver",
  "(GMT-08:00) Pacific Time - Whitehorse",
  "(GMT-08:00) Pitcairn",
  "(GMT-07:00) Mountain Time - Dawson Creek",
  "(GMT-07:00) Mountain Time",
  "(GMT-07:00) Mountain Time - Edmonton",
  "(GMT-07:00) Mountain Time - Hermosillo",
  "(GMT-07:00) Mountain Time - Chihuahua, Mazatlan",
  "(GMT-07:00) Mountain Time - Arizona",
  "(GMT-07:00) Mountain Time - Yellowknife",
  "(GMT-06:00) Belize",
  "(GMT-06:00) Central Time",
  "(GMT-06:00) Costa Rica",
  "(GMT-06:00) El Salvador",
  "(GMT-06:00) Guatemala",
  "(GMT-06:00) Managua",
  "(GMT-06:00) Central Time - Mexico City",
  "(GMT-06:00) Central Time - Regina",
  "(GMT-06:00) Central Time - Tegucigalpa",
  "(GMT-06:00) Central Time - Winnipeg",
  "(GMT-06:00) Galapagos",
  "(GMT-05:00) Bogota",
  "(GMT-05:00) America Cancun",
  "(GMT-05:00) Cayman",
  "(GMT-05:00) Guayaquil",
  "(GMT-05:00) Havana",
  "(GMT-05:00) Eastern Time - Iqaluit",
  "(GMT-05:00) Jamaica",
  "(GMT-05:00) Lima",
  "(GMT-05:00) Nassau",
  "(GMT-05:00) Eastern Time",
  "(GMT-05:00) Panama",
  "(GMT-05:00) Port-au-Prince",
  "(GMT-05:00) Rio Branco",
  "(GMT-05:00) Eastern Time - Toronto",
  "(GMT-05:00) Easter Island",
  "(GMT-04:30) Caracas",
  "(GMT-03:00) Asuncion",
  "(GMT-04:00) Barbados",
  "(GMT-04:00) Boa Vista",
  "(GMT-03:00) Campo Grande",
  "(GMT-03:00) Cuiaba",
  "(GMT-04:00) Curacao",
  "(GMT-04:00) Grand Turk",
  "(GMT-04:00) Guyana",
  "(GMT-04:00) Atlantic Time - Halifax",
  "(GMT-04:00) La Paz",
  "(GMT-04:00) Manaus",
  "(GMT-04:00) Martinique",
  "(GMT-04:00) Port of Spain",
  "(GMT-04:00) Porto Velho",
  "(GMT-04:00) Puerto Rico",
  "(GMT-04:00) Santo Domingo",
  "(GMT-04:00) Thule",
  "(GMT-04:00) Bermuda",
  "(GMT-03:30) Newfoundland Time - St. Johns",
  "(GMT-03:00) Araguaina",
  "(GMT-03:00) Buenos Aires",
  "(GMT-03:00) Salvador",
  "(GMT-03:00) Belem",
  "(GMT-03:00) Cayenne",
  "(GMT-03:00) Fortaleza",
  "(GMT-03:00) Godthab",
  "(GMT-03:00) Maceio",
  "(GMT-03:00) Miquelon",
  "(GMT-03:00) Montevideo",
  "(GMT-03:00) Paramaribo",
  "(GMT-03:00) Recife",
  "(GMT-03:00) Santiago",
  "(GMT-02:00) Sao Paulo",
  "(GMT-03:00) Palmer",
  "(GMT-03:00) Rothera",
  "(GMT-03:00) Stanley",
  "(GMT-02:00) Noronha",
  "(GMT-02:00) South Georgia",
  "(GMT-01:00) Scoresbysund",
  "(GMT-01:00) Azores",
  "(GMT-01:00) Cape Verde",
  "(GMT+00:00) Abidjan",
  "(GMT+00:00) Accra",
  "(GMT+00:00) Bissau",
  "(GMT+00:00) Casablanca",
  "(GMT+00:00) El Aaiun",
  "(GMT+00:00) Monrovia",
  "(GMT+00:00) Danmarkshavn",
  "(GMT+00:00) Canary Islands",
  "(GMT+00:00) Faeroe",
  "(GMT+00:00) Reykjavik",
  "(GMT+00:00) GMT (no daylight saving)",
  "(GMT+00:00) Dublin",
  "(GMT+00:00) Lisbon",
  "(GMT+00:00) London",
  "(GMT+01:00) Central European Time - Belgrade",
  "(GMT+01:00) Algiers",
  "(GMT+01:00) Ceuta",
  "(GMT+01:00) Lagos",
  "(GMT+01:00) Ndjamena",
  "(GMT+01:00) Tunis",
  "(GMT+02:00) Windhoek",
  "(GMT+01:00) Amsterdam",
  "(GMT+01:00) Andorra",
  "(GMT+01:00) Berlin",
  "(GMT+01:00) Brussels",
  "(GMT+01:00) Budapest",
  "(GMT+01:00) Copenhagen",
  "(GMT+01:00) Gibraltar",
  "(GMT+01:00) Luxembourg",
  "(GMT+01:00) Madrid",
  "(GMT+01:00) Malta",
  "(GMT+01:00) Monaco",
  "(GMT+01:00) Oslo",
  "(GMT+01:00) Paris",
  "(GMT+01:00) Central European Time - Prague",
  "(GMT+01:00) Rome",
  "(GMT+01:00) Stockholm",
  "(GMT+01:00) Tirane",
  "(GMT+01:00) Vienna",
  "(GMT+01:00) Warsaw",
  "(GMT+01:00) Zurich",
  "(GMT+02:00) Cairo",
  "(GMT+02:00) Johannesburg",
  "(GMT+02:00) Maputo",
  "(GMT+02:00) Tripoli",
  "(GMT+02:00) Amman",
  "(GMT+02:00) Beirut",
  "(GMT+02:00) Damascus",
  "(GMT+02:00) Gaza",
  "(GMT+02:00) Jerusalem",
  "(GMT+02:00) Nicosia",
  "(GMT+02:00) Athens",
  "(GMT+02:00) Bucharest",
  "(GMT+02:00) Chisinau",
  "(GMT+02:00) Helsinki",
  "(GMT+02:00) Istanbul",
  "(GMT+02:00) Moscow-01 - Kaliningrad",
  "(GMT+02:00) Kiev",
  "(GMT+02:00) Riga",
  "(GMT+02:00) Sofia",
  "(GMT+02:00) Tallinn",
  "(GMT+02:00) Vilnius",
  "(GMT+03:00) Khartoum",
  "(GMT+03:00) Nairobi",
  "(GMT+03:00) Syowa",
  "(GMT+03:00) Baghdad",
  "(GMT+03:00) Qatar",
  "(GMT+03:00) Riyadh",
  "(GMT+03:00) Minsk",
  "(GMT+03:00) Moscow+00 - Moscow",
  "(GMT+03:30) Tehran",
  "(GMT+04:00) Baku",
  "(GMT+04:00) Dubai",
  "(GMT+04:00) Tbilisi",
  "(GMT+04:00) Yerevan",
  "(GMT+04:00) Moscow+01 - Samara",
  "(GMT+04:00) Mahe",
  "(GMT+04:00) Mauritius",
  "(GMT+04:00) Reunion",
  "(GMT+04:30) Kabul",
  "(GMT+05:00) Mawson",
  "(GMT+05:00) Aqtau",
  "(GMT+05:00) Aqtobe",
  "(GMT+05:00) Ashgabat",
  "(GMT+05:00) Dushanbe",
  "(GMT+05:00) Karachi",
  "(GMT+05:00) Tashkent",
  "(GMT+05:00) Moscow+02 - Yekaterinburg",
  "(GMT+05:00) Kerguelen",
  "(GMT+05:00) Maldives",
  "(GMT+05:30) India Standard Time",
  "(GMT+05:30) Colombo",
  "(GMT+05:45) Katmandu",
  "(GMT+06:00) Vostok",
  "(GMT+06:00) Almaty",
  "(GMT+06:00) Bishkek",
  "(GMT+06:00) Dhaka",
  "(GMT+06:00) Moscow+03 - Omsk, Novosibirsk",
  "(GMT+06:00) Thimphu",
  "(GMT+06:00) Chagos",
  "(GMT+06:30) Rangoon",
  "(GMT+06:30) Cocos",
  "(GMT+07:00) Davis",
  "(GMT+07:00) Bangkok",
  "(GMT+07:00) Hovd",
  "(GMT+07:00) Jakarta",
  "(GMT+07:00) Moscow+04 - Krasnoyarsk",
  "(GMT+07:00) Hanoi",
  "(GMT+07:00) Ho Chi Minh",
  "(GMT+07:00) Christmas",
  "(GMT+08:00) Casey",
  "(GMT+08:00) Brunei",
  "(GMT+08:00) Choibalsan",
  "(GMT+08:00) Hong Kong",
  "(GMT+08:00) Moscow+05 - Irkutsk",
  "(GMT+08:00) Kuala Lumpur",
  "(GMT+08:00) Macau",
  "(GMT+08:00) Makassar",
  "(GMT+08:00) Manila",
  "(GMT+08:00) China Time - Beijing",
  "(GMT+08:00) Singapore",
  "(GMT+08:00) Taipei",
  "(GMT+08:00) Ulaanbaatar",
  "(GMT+08:00) Western Time - Perth",
  "(GMT+08:30) Pyongyang",
  "(GMT+09:00) Dili",
  "(GMT+09:00) Jayapura",
  "(GMT+09:00) Seoul",
  "(GMT+09:00) Tokyo",
  "(GMT+09:00) Moscow+06 - Yakutsk",
  "(GMT+09:00) Palau",
  "(GMT+10:30) Central Time - Adelaide",
  "(GMT+09:30) Central Time - Darwin",
  "(GMT+10:00) Dumont D'Urville",
  "(GMT+10:00) Moscow+07 - Magadan",
  "(GMT+10:00) Moscow+07 - Yuzhno-Sakhalinsk",
  "(GMT+10:00) Eastern Time - Brisbane",
  "(GMT+11:00) Eastern Time - Hobart",
  "(GMT+11:00) Eastern Time - Melbourne, Sydney",
  "(GMT+10:00) Truk",
  "(GMT+10:00) Guam",
  "(GMT+10:00) Port Moresby",
  "(GMT+11:00) Efate",
  "(GMT+11:00) Guadalcanal",
  "(GMT+11:00) Kosrae",
  "(GMT+11:00) Norfolk",
  "(GMT+11:00) Noumea",
  "(GMT+11:00) Ponape",
  "(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy",
  "(GMT+13:00) Auckland",
  "(GMT+13:00) Fiji",
  "(GMT+12:00) Funafuti",
  "(GMT+12:00) Kwajalein",
  "(GMT+12:00) Majuro",
  "(GMT+12:00) Nauru",
  "(GMT+12:00) Tarawa",
  "(GMT+12:00) Wake",
  "(GMT+12:00) Wallis",
  "(GMT+14:00) Apia",
  "(GMT+13:00) Enderbury",
  "(GMT+13:00) Fakaofo",
  "(GMT+13:00) Tongatapu",
  "(GMT+14:00) Kiritimati",
];

export const reCaptchaURI = "https://eu-api.friendlycaptcha.eu/api/v1/puzzle";
