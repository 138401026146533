import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PageToolbar,
  PageToolbarButtonsPane,
} from "../../../components/PageToolbar";
import Breadcrumbs from "../../../components/shared/breadcrumbs/Breadcrumbs";
import SortableContactsList from "./SortableContactsList";
import { useTranslation } from "react-i18next";
import { Icon, Sidebar, Segment } from "semantic-ui-react";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { showMoreResources } from "../../../actions/shared";
import { canLoadMore } from "../../../app_shared_functions";
import { mapResourceTypeToReduxList } from "../../../components/shared/grid-bits/mapResourceTypeToReduxList";
import useSubscribe from "../../../custom-hooks/useSubscribe";
import useHasCRUDAccess from "../../../custom-hooks/useHasCRUDAccess";
import { mapResourceNameToReduxSelector } from "../../../selectors/resources";
import { valuesOf } from "../../../shared-functions/objects";
import { KnowledgeBaseButton } from "../../../components/knowledgebase/KnowledgeBaseButton";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import LoadMoreSensor from "../../../components/shared/LoadMoreSensor";
import RegionsLoadingSpinner from "../../../components/shared/RegionsLoadingSpinner";

const ContactsList = () => {
  const resourceType: keyof typeof mapResourceTypeToReduxList =
    "monitoring_contacts";

  const dispatch = useDispatch();

  const { t } = useTranslation();

  useSubscribe(mapResourceTypeToReduxList[resourceType]);

  const hasCRUDAccess = useHasCRUDAccess();

  const {
    resourcesList: contactsList,
    currentPage,
    zonesLeft,
    hiddenRegions,
  } = useSelector(mapResourceNameToReduxSelector[resourceType]);

  const breadcrumbs = useMemo(() => {
    return [
      {
        title: "menu.monitoring",
      },
      {
        title: "monitoring.contacts",
        popup: { Contacts: valuesOf(contactsList).length },
      },
    ];
  }, [contactsList]);

  const [loadingMore, setLoadingMore] = useState(false);

  const onCreateContact = useCallback(() => {
    dispatch(toggleSlidingMenu("create", "Contact"));
  }, [dispatch]);

  const checkAndLoadMore = useCallback(
    (isVisible) => {
      if (isVisible && canLoadMore(contactsList, currentPage, hiddenRegions)) {
        setLoadingMore(true);
        dispatch(showMoreResources("monitoring_contacts"));
      }
    },
    [hiddenRegions, currentPage, dispatch, contactsList],
  );

  useEffect(() => {
    setLoadingMore(false);
  }, [currentPage]);

  const gridContextValues = useMemo(() => {
    return {
      filteringText: "",
      resourceType,
      hasCRUDAccess,
    };
  }, [resourceType, hasCRUDAccess]);

  return (
    <div>
      <Sidebar.Pushable as={Segment}>
        <Sidebar.Pusher>
          <PageToolbar>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <KnowledgeBaseButton />
            {hasCRUDAccess && (
              <PageToolbarButtonsPane>
                <button
                  className="button button--transparent"
                  onClick={onCreateContact}
                >
                  {t(`monitoring.actions.contacts.create`)}
                  <Icon name="plus circle" />
                </button>
              </PageToolbarButtonsPane>
            )}
          </PageToolbar>

          <div className="page-content">
            <GridContext.Provider value={gridContextValues}>
              <SortableContactsList />
            </GridContext.Provider>
            <LoadMoreSensor
              checkAndLoadMore={checkAndLoadMore}
              loading_more={loadingMore}
            />
          </div>
          <RegionsLoadingSpinner zonesLeft={zonesLeft} />
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
};

export default ContactsList;
