import React from "react";
import { Icon } from "semantic-ui-react";
import { ValidOpenstackResource } from "../../../../types";

type StatusProps = {
  resource: ValidOpenstackResource;
};

const Status = ({ resource }: StatusProps) => {
  const status = resource.status || "";
  if (status.toLowerCase() === "pending_create")
    return (
      <React.Fragment>
        <Icon name="clock" className="margin-right-half" color="orange" />
        {status}
      </React.Fragment>
    );

  if (status.toLowerCase() === "active")
    return (
      <React.Fragment>
        <Icon name="check square" className="margin-right-half" color="green" />
        {status}
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <Icon name="check square" className="margin-right-half" color="green" />
      {status || "Unknown"}
    </React.Fragment>
  );
};

export default Status;
