import React from "react";
import {
  Checkbox,
  Grid,
  Icon,
  Input,
  Loader,
  Popup,
  Ref,
  Tab,
} from "semantic-ui-react";
import FetchAPI from "../../../api/FetchAPI";
import { toast } from "react-toastify";
import {
  get_FormItem_ClassName,
  handleScrollToItem,
  toastError,
} from "../../../app_shared_functions";

class CustomerInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  fetchAccountData = () => {
    FetchAPI.Account.showContact("billing")
      .then((res) => {
        res.data.type !== undefined
          ? this.setState({
              billingContact: { ...res.data },
              hasBillingContact: true,
              hadBillingContact: true,
            })
          : this.setState({
              billingContact: null,
              hasBillingContact: false,
              hadBillingContact: false,
            });
        return FetchAPI.Account.showContact("owner");
      })
      .then((res) =>
        this.setState({
          ownerContact: res.data,
          birthdate_Editable: !res.data.birthdate,
          orgno_Editable: !res.data.orgno,
        }),
      )
      .catch((err) => {
        this.setState({
          data:
            err.response.data?.error.description ||
            err.response.data?.error.message,
        });
        toastError(err, "Account data load failed!");
      });
  };

  updateform = (contactType, name, value) => {
    if (contactType === "owner") {
      this.setState({
        ownerContact: { ...this.state.ownerContact, [name]: value },
        ownerContactChanged: true,
        invalidForm: false,
        formChanged: true,
      });
    } else if (contactType === "billing") {
      this.setState({
        billingContact: { ...this.state.billingContact, [name]: value },
        billingContactChanged: true,
        invalidForm: false,
        formChanged: true,
      });
    }
  };

  handleUpdate = () => {
    const ownerContact = { ...this.state.ownerContact };
    const billingContact = { ...this.state.billingContact };

    if (this.state.ownerContact.birthdate) delete ownerContact.birthdate;

    if (this.state.ownerContact.orgno && !this.state.orgno_Editable)
      delete ownerContact.orgno;

    this.setState({ isUpdating: true });

    FetchAPI.Account.updateContact({ contact: { ...ownerContact } }, "owner")
      .then(() => {
        const { hadBillingContact, hasBillingContact, billingContactChanged } =
          this.state;

        if (hadBillingContact && hasBillingContact && billingContactChanged) {
          return FetchAPI.Account.updateContact(
            { contact: { ...billingContact } },
            "billing",
          );
        } else if (!hadBillingContact && hasBillingContact) {
          return FetchAPI.Account.createBillingContact({
            contact: { ...billingContact },
          });
        } else if (hadBillingContact && !hasBillingContact) {
          return FetchAPI.Account.deleteBillingContact();
        }
      })
      .then(() => {
        toast.success("Contact update saved successfully");
        this.fetchAccountData();
      })
      .catch((err) => toastError(err, "Contact update failed"))
      .finally(() => {
        this.setState({ isUpdating: false, formChanged: false });
      });
  };

  check_required_fields = () => {
    let returnValue = null;
    if (this.state.formChanged) {
      if (this.state?.ownerContact?.firstname === "") {
        returnValue = {
          text: "Please provide a firstname",
          ref: "firstnameRef",
        };
      } else if (this.state?.ownerContact?.lastname === "") {
        returnValue = {
          text: "Please provide a lastname",
          ref: "lastnameRef",
        };
      } else if (this.state?.ownerContact?.address === "") {
        returnValue = {
          text: "Please provide an address",
          ref: "addressRef",
        };
      } else if (this.state?.ownerContact?.city === "") {
        returnValue = {
          text: "Please provide a city",
          ref: "cityRef",
        };
      } else if (this.state?.ownerContact?.zipcode === "") {
        returnValue = {
          text: "Please provide a zipcode",
          ref: "zipcodeRef",
        };
      } else if (this.state?.ownerContact?.birthdate === "") {
        returnValue = {
          text: "Please provide a personal number",
          ref: "birthdayRef",
        };
      } else if (
        this.state?.ownerContact?.companyname === "" ||
        this.state?.ownerContact?.companyname?.length === 1
      ) {
        returnValue = {
          text: "Please provide a company name",
          ref: "companyRef",
        };
      } else if (this.state?.ownerContact?.orgno === "") {
        returnValue = {
          text: "Please provide an Organisation number",
          ref: "orgnoRef",
        };
      } else if (
        this.state.hasBillingContact &&
        (this.state.billingContact === null ||
          this.state.billingContact?.firstname === "")
      ) {
        returnValue = {
          text: "Please provide a firstname",
          ref: "billingFirstnameRef",
        };
      } else if (
        this.state.hasBillingContact &&
        (this.state.billingContact === null ||
          this.state.billingContact?.lastname === "")
      ) {
        returnValue = {
          text: "Please provide a lastname",
          ref: "billingLastnameRef",
        };
      } else if (
        this.state.hasBillingContact &&
        (this.state.billingContact === null ||
          this.state.billingContact?.address === "")
      ) {
        returnValue = {
          text: "Please provide an address",
          ref: "billingAddressRef",
        };
      } else if (
        this.state.hasBillingContact &&
        (this.state.billingContact === null ||
          this.state.billingContact?.city === "")
      ) {
        returnValue = {
          text: "Please provide a city",
          ref: "billingCityRef",
        };
      } else if (
        this.state.hasBillingContact &&
        (this.state.billingContact === null ||
          this.state.billingContact?.zipcode === "")
      ) {
        returnValue = {
          text: "Please provide a zipcode",
          ref: "billingZipcodeRef",
        };
      }
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  componentDidMount() {
    this.fetchAccountData();
  }

  render() {
    const { ownerContact, billingContact } = this.state;

    // loading
    if (!ownerContact) {
      return (
        <Tab.Pane>
          <div className="padding-top separator">
            <div className="relative padding-bottom-20  padding-left-00 ">
              <Loader
                size="mini"
                active
                className="one-liner margin-top-3quarter"
              >
                Fetching account data...
              </Loader>
            </div>
          </div>
        </Tab.Pane>
      );
    }

    // got some error
    else if (typeof ownerContact === "string") {
      return (
        <Tab.Pane>
          <div className="margin-left-half padding-top-20 padding-left-20 padding-right">
            <Icon name="warning sign" className="margin-right-half" />
            {ownerContact}
          </div>
        </Tab.Pane>
      );
    }

    const { invalidForm } = this.state;
    const form_status = this.check_required_fields();

    return (
      <Tab.Pane className="padding-left padding-right">
        <Grid className="margin-right-00 margin-left-00 padding-left-00 padding-right-00">
          <Grid.Row>
            <Grid.Column
              textAlign="left"
              width={7}
              className="flex vcenter margin-top-15   "
            >
              <h5 className="">Firstname</h5>
            </Grid.Column>
            <Grid.Column textAlign="left" width={9} className="margin-top-15">
              <Ref innerRef={(x) => (this.firstnameRef = x)}>
                <Input
                  className={get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    "firstnameRef",
                    this.state.shake,
                    "error-form-item",
                  )}
                  value={ownerContact.firstname}
                  onChange={(e) =>
                    this.updateform("owner", "firstname", e.currentTarget.value)
                  }
                />
              </Ref>
            </Grid.Column>

            <Grid.Column
              textAlign="left"
              width={7}
              className="flex vcenter margin-top-15"
            >
              <h5 className="">Lastname</h5>
            </Grid.Column>
            <Grid.Column textAlign="left" width={9} className="margin-top-15">
              <Ref innerRef={(x) => (this.lastnameRef = x)}>
                <Input
                  className={get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    "lastnameRef",
                    this.state.shake,
                    "error-form-item",
                  )}
                  value={ownerContact.lastname}
                  onChange={(e) =>
                    this.updateform("owner", "lastname", e.currentTarget.value)
                  }
                />
              </Ref>
            </Grid.Column>

            <Grid.Column
              textAlign="left"
              width={7}
              className="flex vcenter margin-top-15"
            >
              <h5 className="">Address</h5>
            </Grid.Column>
            <Grid.Column textAlign="left" width={9} className="margin-top-15">
              <Ref innerRef={(x) => (this.addressRef = x)}>
                <Input
                  className={get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    "addressRef",
                    this.state.shake,
                    "error-form-item",
                  )}
                  value={ownerContact.address}
                  onChange={(e) =>
                    this.updateform("owner", "address", e.currentTarget.value)
                  }
                />
              </Ref>
            </Grid.Column>

            <Grid.Column
              textAlign="left"
              width={7}
              className="flex vcenter margin-top-15"
            >
              <h5 className="">City</h5>
            </Grid.Column>
            <Grid.Column textAlign="left" width={9} className="margin-top-15">
              <Ref innerRef={(x) => (this.cityRef = x)}>
                <Input
                  className={get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    "cityRef",
                    this.state.shake,
                    "error-form-item",
                  )}
                  value={ownerContact.city}
                  onChange={(e) =>
                    this.updateform("owner", "city", e.currentTarget.value)
                  }
                />
              </Ref>
            </Grid.Column>

            <Grid.Column
              textAlign="left"
              width={7}
              className="flex vcenter margin-top-15"
            >
              <h5 className="">Zipcode</h5>
            </Grid.Column>
            <Grid.Column textAlign="left" width={9} className="margin-top-15">
              <Ref innerRef={(x) => (this.zipcodeRef = x)}>
                <Input
                  className={get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    "zipcodeRef",
                    this.state.shake,
                    "error-form-item",
                  )}
                  value={ownerContact.zipcode}
                  onChange={(e) =>
                    this.updateform("owner", "zipcode", e.currentTarget.value)
                  }
                />
              </Ref>
            </Grid.Column>

            <Grid.Column
              textAlign="left"
              width={7}
              className="flex vcenter margin-top-15"
            >
              <h5 className="">
                {ownerContact.customer_type === "private"
                  ? "Personal number"
                  : "Organisation number"}
                {ownerContact.birthdate || ownerContact.orgno ? (
                  <Popup
                    on="hover"
                    hoverable
                    open={this.state.isOpen}
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left-half"
                      />
                    }
                  >
                    You can setup this field once only. To change it, please
                    contact{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://cleura.com/support"
                    >
                      Cleura Support Team
                    </a>
                    .
                  </Popup>
                ) : null}
              </h5>
            </Grid.Column>
            <Grid.Column textAlign="left" width={9} className="margin-top-15">
              {ownerContact.customer_type === "private" ? (
                ownerContact.birthdate ? (
                  <Input value={ownerContact.birthdate} disabled={true} />
                ) : (
                  <Ref innerRef={(x) => (this.birthdayRef = x)}>
                    <Input
                      className={get_FormItem_ClassName(
                        form_status,
                        invalidForm,
                        "birthdayRef",
                        this.state.shake,
                        "error-form-item",
                      )}
                      value={this.state.birthdate}
                      onChange={(e) =>
                        this.updateform(
                          "owner",
                          "birthdate",
                          e.currentTarget.value,
                        )
                      }
                    />
                  </Ref>
                )
              ) : !this.state.orgno_Editable ? (
                <Input value={ownerContact.orgno} disabled={true} />
              ) : (
                <Ref innerRef={(x) => (this.orgnoRef = x)}>
                  <Input
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "orgnoRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    value={ownerContact.orgno}
                    onChange={(e) =>
                      this.updateform("owner", "orgno", e.currentTarget.value)
                    }
                  />
                </Ref>
              )}
            </Grid.Column>

            {ownerContact.companyname !== undefined && (
              <React.Fragment>
                <Grid.Column
                  textAlign="left"
                  width={7}
                  className="flex vcenter margin-top-15"
                >
                  <h5 className="">Company name</h5>
                </Grid.Column>
                <Grid.Column
                  textAlign="left"
                  width={9}
                  className="margin-top-15"
                >
                  <Ref innerRef={(x) => (this.companyRef = x)}>
                    <Input
                      className={get_FormItem_ClassName(
                        form_status,
                        invalidForm,
                        "companyRef",
                        this.state.shake,
                        "error-form-item",
                      )}
                      value={ownerContact.companyname}
                      onChange={(e) =>
                        this.updateform(
                          "owner",
                          "companyname",
                          e.currentTarget.value,
                        )
                      }
                    />
                  </Ref>
                </Grid.Column>
              </React.Fragment>
            )}

            <Grid.Column
              textAlign="left"
              width={7}
              className="flex vcenter margin-top-15"
            >
              <h5 className="">
                Purchase order
                <Popup
                  trigger={
                    <Icon
                      name="warning circle"
                      color="grey"
                      size="small"
                      className="margin-left-half"
                    />
                  }
                >
                  You can specify a purchase order for invoices. If a purchase
                  order is defined it will be set on newly created invoices. If
                  a purchase order is changed, it will only affect new invoices.
                  Existing invoices will keep the purchase order that was
                  defined when it was created.
                </Popup>
              </h5>
            </Grid.Column>
            <Grid.Column textAlign="left" width={9} className="margin-top-15">
              <Input
                className="select-box full"
                value={ownerContact.purchase_order || ""}
                onChange={(e) =>
                  this.updateform(
                    "owner",
                    "purchase_order",
                    e.currentTarget.value,
                  )
                }
              />
            </Grid.Column>

            <Grid.Column
              textAlign="left"
              width={7}
              className="flex vcenter margin-top-15"
            >
              <h5 className="">Care of</h5>
            </Grid.Column>
            <Grid.Column textAlign="left" width={9} className="margin-top-15">
              <Input
                className="select-box full"
                value={ownerContact.care_of || ""}
                onChange={(e) =>
                  this.updateform("owner", "care_of", e.currentTarget.value)
                }
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column
              textAlign="left"
              width={12}
              className="flex vcenter margin-top-15"
            >
              <Checkbox
                className="simple-checkbox float-right margin-top-10"
                label="Use different address for billing"
                checked={this.state.hasBillingContact}
                onChange={() =>
                  this.setState({
                    hasBillingContact: !this.state.hasBillingContact,
                    invalidForm: false,
                    formChanged: true,
                  })
                }
              />
            </Grid.Column>
          </Grid.Row>

          {this.state.hasBillingContact && (
            <Grid.Row>
              <Grid.Column
                textAlign="left"
                width={7}
                className="flex vcenter margin-top-15"
              >
                <h5 className="">Firstname</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={9} className="margin-top-15">
                <Ref innerRef={(x) => (this.billingFirstnameRef = x)}>
                  <Input
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "billingFirstnameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    value={billingContact?.firstname}
                    onChange={(e) =>
                      this.updateform(
                        "billing",
                        "firstname",
                        e.currentTarget.value,
                      )
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={7}
                className="flex vcenter margin-top-15"
              >
                <h5 className="">Lastname</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={9} className="margin-top-15">
                <Ref innerRef={(x) => (this.billingLastnameRef = x)}>
                  <Input
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "billingLastnameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    value={billingContact?.lastname}
                    onChange={(e) =>
                      this.updateform(
                        "billing",
                        "lastname",
                        e.currentTarget.value,
                      )
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={7}
                className="flex vcenter margin-top-15"
              >
                <h5 className="">Address</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={9} className="margin-top-15">
                <Ref innerRef={(x) => (this.billingAddressRef = x)}>
                  <Input
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "billingAddressRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    value={billingContact?.address}
                    onChange={(e) =>
                      this.updateform(
                        "billing",
                        "address",
                        e.currentTarget.value,
                      )
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={7}
                className="flex vcenter margin-top-15"
              >
                <h5 className="">City</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={9} className="margin-top-15">
                <Ref innerRef={(x) => (this.billingCityRef = x)}>
                  <Input
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "billingCityRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    value={billingContact?.city}
                    onChange={(e) =>
                      this.updateform("billing", "city", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={7}
                className="flex vcenter margin-top-15"
              >
                <h5 className="">Zipcode</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={9} className="margin-top-15">
                <Ref innerRef={(x) => (this.billingZipcodeRef = x)}>
                  <Input
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "billingZipcodeRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    value={billingContact?.zipcode}
                    onChange={(e) =>
                      this.updateform(
                        "billing",
                        "zipcode",
                        e.currentTarget.value,
                      )
                    }
                  />
                </Ref>
              </Grid.Column>

              {ownerContact.companyname !== undefined && (
                <React.Fragment>
                  <Grid.Column
                    textAlign="left"
                    width={7}
                    className="flex vcenter margin-top-15"
                  >
                    <h5 className="">Company name</h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={9}
                    className="margin-top-15"
                  >
                    <Ref innerRef={(x) => (this.billingCompanyRef = x)}>
                      <Input
                        className={get_FormItem_ClassName(
                          form_status,
                          invalidForm,
                          "billingCompanyRef",
                          this.state.shake,
                          "error-form-item",
                        )}
                        value={billingContact?.companyname}
                        onChange={(e) =>
                          this.updateform(
                            "billing",
                            "companyname",
                            e.currentTarget.value,
                          )
                        }
                      />
                    </Ref>
                  </Grid.Column>
                </React.Fragment>
              )}

              <Grid.Column
                textAlign="left"
                width={7}
                className="flex vcenter margin-top-15"
              >
                <h5 className="">
                  Purchase order
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left-half"
                      />
                    }
                  >
                    You can specify a purchase order for invoices. If a purchase
                    order is defined it will be set on newly created invoices.
                    If a purchase order is changed, it will only affect new
                    invoices. Existing invoices will keep the purchase order
                    that was defined when it was created.
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={9} className="margin-top-15">
                <Input
                  className="select-box full"
                  value={billingContact?.purchase_order || ""}
                  onChange={(e) =>
                    this.updateform(
                      "billing",
                      "purchase_order",
                      e.currentTarget.value,
                    )
                  }
                />
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={7}
                className="flex vcenter margin-top-15"
              >
                <h5 className="">Care of</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={9} className="margin-top-15">
                <Input
                  className="select-box full"
                  value={billingContact?.care_of || ""}
                  onChange={(e) =>
                    this.updateform("billing", "care_of", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>
          )}

          <Grid.Row>
            <Grid.Column textAlign="left" width={16}>
              {!form_status ? (
                this.state.isUpdating ? (
                  <button className="float-right button button--green overflow-hidden button--icon__right">
                    <Icon loading name="spinner" className="margin-top-10" />
                    <span>Updating</span>
                  </button>
                ) : this.state.formChanged ? (
                  <button
                    className="float-right button button--green"
                    onClick={() => this.handleUpdate()}
                  >
                    <span>Update</span>
                  </button>
                ) : (
                  <button className="float-right button button--green button--disabled ">
                    <span>Update</span>
                  </button>
                )
              ) : (
                <Popup
                  trigger={
                    <button
                      className="float-right button button--green button--disabled button--icon__left"
                      onClick={() => {
                        this.setState({ invalidForm: true, shake: true });
                        handleScrollToItem(this[form_status.ref]);
                      }}
                    >
                      <Icon name="exclamation circle" />
                      <span>Update</span>
                    </button>
                  }
                >
                  {form_status?.text}
                </Popup>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Tab.Pane>
    );
  }
}

export default CustomerInfo;
