import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Segment, Sidebar, Loader } from "semantic-ui-react";
import { PageToolbar } from "../../../../components/PageToolbar";
import Breadcrumbs from "../../../../components/shared/breadcrumbs/Breadcrumbs";
import { toast } from "react-toastify";
import SortableVolumeSnapshotsList from "./SortableVolumeSnapshotsList";
import { useTranslation } from "react-i18next";
import { confirmbox_open } from "../../../../components/confirmbox/actions";
import { deleteMultipleVolume_snapshots } from "./actions";
import { showMoreResources } from "../../../../actions/shared";
import LoadMoreSensor from "../../../../components/shared/LoadMoreSensor";
import { canLoadMore } from "../../../../app_shared_functions";
import CircularButton from "../../../../components/shared/circularbutton/CircularButton";
import { getButtonCountPopup } from "../../../../components/shared/circularbutton/util";
import useSubscribe from "../../../../custom-hooks/useSubscribe";
import { mapResourceTypeToReduxList } from "../../../../components/shared/grid-bits/mapResourceTypeToReduxList";
import useHasCRUDAccess from "../../../../custom-hooks/useHasCRUDAccess";
import { mapResourceNameToReduxSelector } from "../../../../selectors/resources";
import { debounceInput } from "../../../../shared-functions/environment";
import GridContext from "../../../../components/shared/grid-bits/context/GridContext";

const VolumeSnapshotsList = () => {
  const resourceType = "volume_snapshots";

  const dispatch = useDispatch();

  const memoizedItems = useMemo(
    () => [
      mapResourceTypeToReduxList[resourceType],
      mapResourceTypeToReduxList.volumes,
    ],
    [],
  );
  useSubscribe(memoizedItems);

  const { t } = useTranslation();

  const hasCRUDAccess = useHasCRUDAccess();

  const {
    resourcesList: snapshotsList,
    currentPage,
    zonesLeft,
    hiddenRegions,
    selectedResources: selectedSnapshots,
  } = useSelector(mapResourceNameToReduxSelector[resourceType]);

  const breadcrumbs = useMemo(() => {
    return [
      {
        title: "menu.storage.storage",
      },
      {
        title: "menu.storage.snapshots",
        popup: Object.values(snapshotsList).reduce(
          (acc, x, i) =>
            (acc = {
              ...acc,
              "volume snapshots": i + 1,
              disk: (acc.disk || 0) + (x.size || 0),
            }),
          {},
        ),
      },
    ];
  }, [snapshotsList]);

  const [filteringText, setFilteringText] = useState("");

  const [loadingMore, setLoadingMore] = useState(false);

  const toggleDeleteDialog = useCallback(() => {
    if (!selectedSnapshots.length) {
      toast.warn("No Snapshots are selected for delete");
    } else {
      const resources = selectedSnapshots.map((x) => snapshotsList[x]);
      dispatch(
        confirmbox_open({
          entity: "volume snapshot",
          operation: "delete",
          resources: resources,
          onConfirm: deleteMultipleVolume_snapshots,
        }),
      );
    }
  }, [dispatch, snapshotsList, selectedSnapshots]);

  const checkAndLoadMore = useCallback(
    (isVisible) => {
      if (isVisible && canLoadMore(snapshotsList, currentPage, hiddenRegions)) {
        setLoadingMore(true);
        dispatch(showMoreResources("volume snapshot"));
      }
    },
    [hiddenRegions, currentPage, dispatch, snapshotsList],
  );

  useEffect(() => {
    setLoadingMore(false);
  }, [currentPage]);

  // After moving to react 18 we can use useDeferredValue instead
  // [3498]
  const onFilterChange = useCallback((e) => {
    const val = e.target.value;
    return debounceInput(() => setFilteringText(val))();
  }, []);

  const gridContextValues = useMemo(() => {
    return {
      filteringText,
      resourceType,
      hasCRUDAccess,
    };
  }, [filteringText, resourceType, hasCRUDAccess]);

  return (
    <div>
      <Sidebar.Pushable as={Segment}>
        <Sidebar.Pusher>
          <PageToolbar>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </PageToolbar>
          <div className="page-content" key="content">
            {/* Multi-select actions*/}
            <div className="top-section">
              {hasCRUDAccess && (
                <div className="margin-bottom">
                  <CircularButton
                    className={`button button--circular margin-right-half`}
                    onClick={toggleDeleteDialog}
                    icon="trash"
                    count={selectedSnapshots.length}
                    popupContent={`${t(
                      `volumes.snapshots.delete`,
                    )} ${getButtonCountPopup(
                      selectedSnapshots.length,
                      "snapshot",
                    )}`}
                  />
                </div>
              )}
              <div className="pos_right">
                <Input
                  minLength={2}
                  onChange={onFilterChange}
                  placeholder="Enter filter text..."
                />
              </div>
            </div>
            <GridContext.Provider value={gridContextValues}>
              <SortableVolumeSnapshotsList />
            </GridContext.Provider>
            <LoadMoreSensor
              checkAndLoadMore={checkAndLoadMore}
              loading_more={loadingMore}
            />
          </div>
          {zonesLeft ? (
            <Loader
              size="tiny"
              active
              className="loading-fixed"
            >{`${zonesLeft} region(s) loading...`}</Loader>
          ) : (
            ""
          )}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
};

export default VolumeSnapshotsList;
