import orchestrationStackConstant from "./constants";
import FetchAPI from "../../api/FetchAPI";
import { toast } from "react-toastify";
import {
  toastMultipleResults,
  checkResourceProperties,
  toastError,
} from "../../app_shared_functions";

export const showViewMore = (id) => (dispatch) => {
  dispatch({
    type: orchestrationStackConstant.ORCHESTRATION_STACK_SHOW_VIEWMORE,
    payload: id,
  });
};
export const hideViewMore = (id) => (dispatch) => {
  dispatch({
    type: orchestrationStackConstant.ORCHESTRATION_STACK_HIDE_VIEWMORE,
    payload: id,
  });
};

// create
export const createOrchestrationStack =
  (region, project_id, objectToSend) => (dispatch) => {
    dispatch({
      type: orchestrationStackConstant.ORCHESTRATION_STACK_CREATE_INIT,
    });
    return new Promise((resolve, reject) => {
      FetchAPI.Orchestration.Stacks.create({
        region,
        project_id,
        objectToSend,
      })
        .then((response) => {
          toast.success("Stack creation started...");
          dispatch({
            type: orchestrationStackConstant.ORCHESTRATION_STACK_CREATE_STARTED,
            payload: {
              ...response.data,
              project_id,
              region: region.toLowerCase(),
              stack_name: objectToSend.stack.stack_name,
              stack_status: "creating",
            },
          });
          resolve(response);
        })
        .catch((err) => {
          dispatch({
            type: orchestrationStackConstant.ORCHESTRATION_STACK_CREATE_FAILED,
          });
          reject(err);
          toastError(err, "Creating stack failed!");
        });
    });
  };

// delete
export const deleteOrchestration_stack = (stack) => (dispatch) => {
  const invalid_Msg = checkResourceProperties(stack, "stack");
  if (invalid_Msg) {
    toastError(invalid_Msg);
    return Promise.reject();
  }

  dispatch({
    type: orchestrationStackConstant.ORCHESTRATION_STACK_DELETE_INIT,
    payload: { id: stack.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Orchestration.Stacks.delete(stack)
      .then((response) => {
        if (response) {
          dispatch({
            type: orchestrationStackConstant.ORCHESTRATION_STACK_DELETE_STARTED,
            payload: { id: stack.id },
          });
          resolve(response.data);
        } else {
          dispatch({
            type: orchestrationStackConstant.ORCHESTRATION_STACK_DELETE_FAILED,
            payload: { id: stack.id },
          });
          reject();
        }
      })
      .catch((err) => {
        dispatch({
          type: orchestrationStackConstant.ORCHESTRATION_STACK_DELETE_FAILED,
          payload: { id: stack.id },
        });
        toastError(err, "Delete Stack failed!");
        reject();
      });
  });
};

export const deleteMultipleOrchestration_stacks = (stacks) => (dispatch) => {
  if (stacks.length === 0) return false;
  toast.success(
    `About to delete ${stacks.length} Orchestration Stack${
      stacks.length > 1 ? "s" : ""
    }...`,
  );
  dispatch({
    type: orchestrationStackConstant.ORCHESTRATION_STACK_DELETE_MULTIPLE_INIT,
    payload: stacks,
  });
  const promises = stacks.map((stack) => {
    return new Promise((resolve, reject) =>
      FetchAPI.Orchestration.Stacks.delete(stack)
        .then((response) => resolve({ status: "resolved", id: stack.id }))
        .catch((err) => {
          dispatch({
            type: orchestrationStackConstant.ORCHESTRATION_STACK_DELETE_FAILED,
            payload: { id: stack.id },
          });
          resolve({
            status: "rejected",
            id: stack.id,
            desc:
              err.response.data.error.description ||
              err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "orchestration stack",
      action: "delete",
      dispatch,
    }),
  );
};

// update
export const setUpdateInProgress = (id) => (dispatch) => {
  dispatch({
    type: orchestrationStackConstant.ORCHESTRATION_STACK_UPDATE_STARTED,
    payload: { id },
  });
};

export const setUpdateComplete = (id) => (dispatch) => {
  dispatch({
    type: orchestrationStackConstant.ORCHESTRATION_STACK_UPDATE_FINISHED,
    payload: { id },
  });
};

// suspend
export const suspendOrchestration_stack = (stack) => (dispatch) => {
  const invalid_Msg = checkResourceProperties(stack, "stack");
  if (invalid_Msg) {
    toastError(invalid_Msg);
    return Promise.reject();
  }

  dispatch({
    type: orchestrationStackConstant.ORCHESTRATION_STACK_SUSPEND_INIT,
    payload: { id: stack.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Orchestration.Stacks.suspend(stack)
      .then((response) => {
        if (response) {
          dispatch({
            type: orchestrationStackConstant.ORCHESTRATION_STACK_SUSPEND_STARTED,
            payload: { id: stack.id },
          });
          resolve(response.data);
        } else {
          dispatch({
            type: orchestrationStackConstant.ORCHESTRATION_STACK_SUSPEND_FAILED,
            payload: { id: stack.id },
          });
          reject();
        }
      })
      .catch((err) => {
        dispatch({
          type: orchestrationStackConstant.ORCHESTRATION_STACK_SUSPEND_FAILED,
          payload: { id: stack.id },
        });
        toastError(err, "Suspending Stack failed!");
        reject();
      });
  });
};

export const suspendMultipleOrchestration_stacks = (stacks) => (dispatch) => {
  if (stacks.length === 0) return false;
  toast.success(
    `About to suspend ${stacks.length} Orchestration Stack${
      stacks.length > 1 ? "s" : ""
    }...`,
  );
  dispatch({
    type: orchestrationStackConstant.ORCHESTRATION_STACK_SUSPEND_MULTIPLE_INIT,
    payload: stacks,
  });
  const promises = stacks.map((stack) => {
    return new Promise((resolve, reject) =>
      FetchAPI.Orchestration.Stacks.suspend(stack)
        .then((response) => resolve({ status: "resolved", id: stack.id }))
        .catch((err) => {
          dispatch({
            type: orchestrationStackConstant.ORCHESTRATION_STACK_SUSPEND_FAILED,
            payload: { id: stack.id },
          });
          resolve({
            status: "rejected",
            id: stack.id,
            desc:
              err.response.data.error.description ||
              err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "orchestration stack",
      action: "suspend",
      dispatch,
    }),
  );
};

// resume
export const resumedOrchestration_stack = (stack) => (dispatch) => {
  const invalid_Msg = checkResourceProperties(stack, "stack");
  if (invalid_Msg) {
    toastError(invalid_Msg);
    return Promise.reject();
  }

  dispatch({
    type: orchestrationStackConstant.ORCHESTRATION_STACK_RESUME_INIT,
    payload: { id: stack.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Orchestration.Stacks.resume(stack)
      .then((response) => {
        if (response) {
          dispatch({
            type: orchestrationStackConstant.ORCHESTRATION_STACK_RESUME_STARTED,
            payload: { id: stack.id },
          });
          resolve(response.data);
        } else {
          dispatch({
            type: orchestrationStackConstant.ORCHESTRATION_STACK_RESUME_FAILED,
            payload: { id: stack.id },
          });
          reject();
        }
      })
      .catch((err) => {
        dispatch({
          type: orchestrationStackConstant.ORCHESTRATION_STACK_RESUME_FAILED,
          payload: { id: stack.id },
        });
        toastError(err, "Delete Stack failed!");
        reject();
      });
  });
};

export const resumeMultipleOrchestration_stacks = (stacks) => (dispatch) => {
  if (stacks.length === 0) return false;

  toast.success(
    `About to resume ${stacks.length} Orchestration Stack${
      stacks.length > 1 ? "s" : ""
    }...`,
  );
  dispatch({
    type: orchestrationStackConstant.ORCHESTRATION_STACK_RESUME_MULTIPLE_INIT,
    payload: stacks,
  });
  const promises = stacks.map((stack) => {
    return new Promise((resolve, reject) =>
      FetchAPI.Orchestration.Stacks.resume(stack)
        .then((response) => resolve({ status: "resolved", id: stack.id }))
        .catch((err) => {
          dispatch({
            type: orchestrationStackConstant.ORCHESTRATION_STACK_RESUME_FAILED,
            payload: { id: stack.id },
          });
          resolve({
            status: "rejected",
            id: stack.id,
            desc:
              err.response.data.error.description ||
              err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "orchestration stack",
      action: "resume",
      dispatch,
    }),
  );
};
