import { useCallback, useMemo, useState } from "react";
import { Worker } from "../types";
import LicensePrice from "./bits/LicensePrice";
import WorkerPrice from "./bits/WorkerPrice";
import { findFlavorID } from "./helpers/findFlavorID";
import useFetchFlavors from "../../custom-hooks/useFetchFlavors";
import useIsLoading from "./hooks/useIsLoading";
import { valuesOf } from "../../shared-functions/objects";
import CostTitle from "./bits/CostTitle";

type CostEstimationProps = {
  region: string;
  regionKey: string;
  workers: Worker[];
  rounded?: boolean;
  excludeLicense?: boolean;
};

export type Prices = {
  license: number;
  workers: { [key: string]: number };
  currency: string;
};

const CostEstimation: React.FC<CostEstimationProps> = ({
  region,
  regionKey,
  workers,
  rounded,
  excludeLicense = false,
}) => {
  // STATES //
  const [prices, setPrices] = useState<Prices>({
    license: 0,
    workers: {},
    currency: "",
  });
  const [expanded, setExpanded] = useState(false);

  // CUSTOM HOOKS INVOCATION
  const flavors = useFetchFlavors(region);
  const isLoading = useIsLoading(prices, workers.length);

  // CALLBACKS
  const updatePrice = useCallback((key: string, newPrice: number) => {
    setPrices((prevPrices) => {
      const updatedPrices = { ...prevPrices };
      if (key === "license") {
        updatedPrices.license = newPrice;
      } else {
        updatedPrices.workers[key] = newPrice;
      }
      return updatedPrices;
    });
  }, []);

  const updateCurrency = useCallback((currency: string) => {
    setPrices((prevPrices) => {
      const updatedPrices = { ...prevPrices };
      updatedPrices.currency = currency;
      return updatedPrices;
    });
  }, []);

  const updateExpanded = useCallback(
    () => setExpanded((expanded) => !expanded),
    [],
  );

  // MEMOIZED VALUES
  const totalEstimation = useMemo(() => {
    const total = valuesOf(prices.workers)
      .reduce(
        (acc: number, val) => acc + val,
        excludeLicense ? 0 : prices.license,
      )
      .toFixed(2);
    return `${total} ${prices.currency}`;
  }, [prices, excludeLicense]);

  return (
    <div className="invoice_wrapper">
      <div
        className={`estimated-invoice estimated-invoice--${
          rounded ? "rounded" : ""
        }`}
      >
        <CostTitle
          isLoading={isLoading}
          totalEstimation={totalEstimation}
          expanded={expanded}
          updateExpanded={updateExpanded}
        />

        <div
          className={`estimated-invoice__body estimated-invoice__${
            expanded ? "expanded" : "shrunk"
          }`}
        >
          <LicensePrice
            excludeLicense={excludeLicense}
            regionKey={regionKey}
            updatePrice={updatePrice}
            updateCurrency={updateCurrency}
          />
          {workers.map((worker, key) => (
            <WorkerPrice
              worker={worker}
              region={region}
              key={key}
              index={key}
              flavorId={findFlavorID(flavors, worker.machine?.type)}
              updatePrice={updatePrice}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CostEstimation;
