import React, { useEffect, useMemo, useRef } from "react";
import { TShoot } from "../types";
import { useCallback, useState } from "react";
import SimpleTable from "../../components/shared/SimpleTable";
import { Grid, Icon, Tab } from "semantic-ui-react";
import MonitoringDashboardModal, {
  ModalData,
} from "../modals/MonitoringDashboardModal.";
import FetchAPI from "../../api/FetchAPI";
import { getGardenerDomain } from "../reducer/selectors";
import { useSelector } from "react-redux";
import { toastError } from "../../app_shared_functions";

type MonitoringProps = {
  shoot: TShoot;
};

export type MonitoringCredentials = {
  url: string;
  credentials: {
    username: string;
    password: string;
  };
};

type ModalCase = "prometheus" | "plutono";

const Monitoring = ({ shoot }: MonitoringProps) => {
  const defaultModalData = useMemo(() => {
    return {
      header: "Dashboard",
      username: "admin",
      password: "",
      url: "",
    };
  }, []);
  const gardenDomain: string = useSelector(getGardenerDomain);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ModalData>(defaultModalData);
  const [credentials, setCredentials] = useState<{
    [key: string]: MonitoringCredentials;
  }>({});
  const [credentialsLoading, setCredentialsLoading] = useState<boolean>(false);

  const isMounted = useRef(true);

  const openModal = useCallback(
    (type: ModalCase) => {
      switch (type) {
        case "prometheus":
          const { prometheus } = credentials;
          setModalData({
            header: "Prometheus Dashboard",
            url: prometheus.url,
            username: prometheus.credentials.username,
            password: prometheus.credentials.password,
          });
          setModalOpen(true);
          break;
        case "plutono":
          const { plutono } = credentials;
          setModalData({
            header: "Plutono Dashboard",
            url: plutono.url,
            username: plutono.credentials.username,
            password: plutono.credentials.password,
          });
          setModalOpen(true);
          break;
      }
    },
    [credentials],
  );

  const openPrometheusModal = useCallback(
    () => openModal("prometheus"),
    [openModal],
  );
  const openPlutonoModal = useCallback(() => openModal("plutono"), [openModal]);

  const closeModal = useCallback(() => {
    setModalData(defaultModalData);
    setModalOpen(false);
  }, [defaultModalData]);

  const fetchCredentials = () => {
    setCredentialsLoading(true);
    FetchAPI.Gardener.Shoots.getMonitoringCredentials({ gardenDomain, shoot })
      .then((response) => {
        isMounted.current && setCredentials(response.data.monitoring);
      })
      .catch((e) => {
        toastError(e.message);
      })
      .finally(() => {
        isMounted.current && setCredentialsLoading(false);
      });
    return () => {
      isMounted.current = false;
    };
  };

  useEffect(fetchCredentials, [gardenDomain, shoot]);

  return (
    <Tab.Pane>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <SimpleTable
              className="striped-table"
              content={[
                ["Dashboards: "],
                [
                  "Prometheus: ",
                  credentialsLoading ? (
                    <Icon name="spinner" loading />
                  ) : (
                    <button
                      className="button button--orange button--small margin-top-quarter"
                      onClick={openPrometheusModal}
                    >
                      Open Prometheus
                    </button>
                  ),
                ],
                [
                  "Plutono: ",
                  credentialsLoading ? (
                    <Icon name="spinner" loading />
                  ) : (
                    <button
                      className="button button--orange button--small margin-top-quarter"
                      onClick={openPlutonoModal}
                    >
                      Open Plutono (Grafana)
                    </button>
                  ),
                ],
              ]}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {modalOpen && (
        <MonitoringDashboardModal onClose={closeModal} modalData={modalData} />
      )}
    </Tab.Pane>
  );
};

export default Monitoring;
