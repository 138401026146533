import config from "../../../config";
import getFetch, { SerializableObject } from "../../getFetch";

type LimitAndOffset = {
  limit: number;
  offset: number;
};

type AccountServiceListItem<T extends ServiceIdentifier> = {
  requests: { [key: string]: string } | [];
  identifiers: T[];
};

type AccountServiceList = {
  openstack?: AccountServiceListItem<OpenStackServiceIdentifier>;
  citymonitor?: AccountServiceListItem<UnknownServiceIdentifier>;
  gardener?: AccountServiceListItem<UnknownServiceIdentifier>;
};

export type OpenStackServiceIdentifier = {
  regionId: number;
  regionFullName: string;
  regionName: string;
} & ServiceIdentifier;

export type UnknownServiceIdentifier = ServiceIdentifier & {
  [key: string]: unknown;
};

type ServiceIdentifier = {
  identifier: string;
  status: "init" | "active" | "closed";
};

const Account = {
  addEmailContact: function (objectToSend: SerializableObject) {
    return getFetch({
      url: `${config.api_url}/account/v1/contactlist/email`,
      type: "post",
      params: objectToSend,
    });
  },

  addPhoneContact: function (objectToSend: SerializableObject) {
    return getFetch({
      url: `${config.api_url}/account/v1/contactlist/phone`,
      type: "post",
      params: objectToSend,
    });
  },

  cancelDeleteRequest: function () {
    return getFetch({
      url: `${config.api_url}/account/v1/request_delete`,
      type: "delete",
    });
  },

  checkGardenerServices: function () {
    return getFetch({
      url: `${config.api_url}/account/v1/service/gardener/check`,
      type: "get",
    });
  },

  checkOpenstackServices: function () {
    return getFetch({
      url: `${config.api_url}/account/v1/service/openstack/check`,
      type: "get",
    });
  },

  getRebateCodes: function () {
    return getFetch({
      url: `${config.api_url}/account/v1/rebate_code`,
      type: "get",
    });
  },

  checkRebateCode: function (objectToSend: SerializableObject) {
    return getFetch({
      url: `${config.api_url}/account/v1/rebate_code`,
      type: "put",
      params: objectToSend,
    });
  },

  checkVerifications: function () {
    return getFetch({
      url: `${config.api_url}/account/v1/verifications`,
      type: "get",
    });
  },

  contactPhoneList: function () {
    return getFetch({
      url: `${config.api_url}/account/v1/contactlist/phone`,
      type: "get",
    });
  },

  createBillingContact: function (objectToSend: SerializableObject) {
    return getFetch({
      url: `${config.api_url}/account/v1/contact/billing`,
      type: "post",
      params: objectToSend,
    });
  },

  deleteBillingContact: function () {
    return getFetch({
      url: `${config.api_url}/account/v1/contact/billing`,
      type: "delete",
    });
  },

  deleteEmailContact: function (objectToSend: SerializableObject) {
    return getFetch({
      url: `${config.api_url}/account/v1/contactlist/email/delete`,
      type: "put",
      params: objectToSend,
    });
  },

  deletePhoneContact: function (number: string) {
    return getFetch({
      url: `${config.api_url}/account/v1/contactlist/phone/${encodeURIComponent(
        number,
      )}`,
      type: "delete",
    });
  },

  emailContactList: function () {
    return getFetch({
      url: `${config.api_url}/account/v1/contactlist/email`,
      type: "get",
    });
  },

  getAccountServicesList: function () {
    return getFetch<AccountServiceList>({
      url: `${config.api_url}/account/v1/service`,
      type: "get",
    });
  },

  getCountry: function () {
    return getFetch({
      url: `${config.api_url}/account/v1/country`,
      type: "get",
    });
  },

  getInvoiceList: function ({
    limit,
    offset,
  }: {
    limit: number;
    offset: number;
  }) {
    return getFetch({
      url: `${config.api_url}/account/v1/invoices?limit=${limit}&offset=${offset}`,
      type: "get",
    });
  },

  getInvoicePDF: function (pdf_id: string) {
    return getFetch({
      url: `${config.api_url}/account/v1/invoices/${pdf_id}/pdf`,
      type: "get",
    });
  },

  getInvoiceProviders: function () {
    return getFetch({
      url: `${config.api_url}/account/v1/invoice-providers`,
      type: "get",
    });
  },

  getLogsList: function ({ limit, offset }: LimitAndOffset) {
    return getFetch({
      url: `${config.api_url}/account/v1/logs?limit=${limit}&offset=${offset}`,
      type: "get",
    });
  },

  getNotificationSettings: function () {
    return getFetch({
      url: `${config.api_url}/account/v1/notification_settings`,
      type: "get",
    });
  },

  getSettings: function () {
    return getFetch({
      url: `${config.api_url}/account/v1/settings`,
      type: "get",
    });
  },

  requestDelete: function (objectToSend: SerializableObject) {
    return getFetch({
      url: `${config.api_url}/account/v1/request_delete`,
      type: "post",
      params: objectToSend,
    });
  },

  requestEInvoice: function (objectToSend: SerializableObject) {
    return getFetch({
      url: `${config.api_url}/account/v1/invoices`,
      type: "post",
      params: objectToSend,
    });
  },

  requestService: function (objectToSend: SerializableObject) {
    return getFetch({
      url: `${config.api_url}/account/v1/service`,
      type: "post",
      params: objectToSend,
    });
  },

  resendEmailContactVerification: function (objectToSend: SerializableObject) {
    return getFetch({
      url: `${config.api_url}/account/v1/contactlist/email/resend_verification`,
      type: "post",
      params: objectToSend,
    });
  },

  sendActivationEmail: function (objectToSend: SerializableObject) {
    return getFetch({
      url: `${config.api_url}/account/v1/verifications/email`,
      type: "put",
      params: objectToSend,
    });
  },

  showContact: function (type = "owner") {
    return getFetch({
      url: `${config.api_url}/account/v1/contact/${type}`,
      type: "get",
    });
  },

  perform_signup: function (objectToSend: SerializableObject) {
    return getFetch({
      url: `${config.backend}/perform_signup`,
      type: "post",
      params: objectToSend,
    });
  },

  updateContact: function (objectToSend: SerializableObject, type = "owner") {
    return getFetch({
      url: `${config.api_url}/account/v1/contact/${type}`,
      type: "put",
      params: objectToSend,
    });
  },

  updateEmailContactRoles: function (objectToSend: SerializableObject) {
    return getFetch({
      url: `${config.api_url}/account/v1/contactlist/email/update`,
      type: "put",
      params: objectToSend,
    });
  },

  updateNotificationSetting: function ({
    endpoint,
    objectToSend,
  }: {
    endpoint: string;
    objectToSend: SerializableObject;
  }) {
    return getFetch({
      url: `${config.api_url}/account/v1/notification_settings/${endpoint}`,
      type: "put",
      params: objectToSend,
    });
  },

  updateSettings: function (objectToSend: SerializableObject) {
    return getFetch({
      url: `${config.api_url}/account/v1/settings`,
      type: "put",
      params: objectToSend,
    });
  },

  verifyEmailContact: function (objectToSend: SerializableObject) {
    return getFetch({
      url: `${config.api_url}/account/v1/contactlist/email/verify`,
      type: "put",
      params: objectToSend,
    });
  },

  createKeycloakUser(clientId: string, redirectUri: string) {
    return getFetch<void>({
      url: `${config.api_url}/account/v1/keycloak`,
      type: "post",
      params: {
        redirect: {
          clientId,
          uri: redirectUri,
        },
      },
    });
  },

  deleteKeycloakUser() {
    return getFetch<void>({
      url: `${config.api_url}/account/v1/keycloak`,
      type: "delete",
    });
  },

  getKeycloakUserId() {
    return getFetch<{ id: string | null }>({
      url: `${config.api_url}/account/v1/keycloak`,
      type: "get",
    });
  },
};

export default Account;
