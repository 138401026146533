import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";
import { getGardenerDomain } from "../../reducer/selectors";

import Worker from "../bits/Workers/Worker";
import FancyHeader from "../../../components/shared/FancyHeader";

import { Grid, Icon, Input } from "semantic-ui-react";

import { getCloudProfile } from "../../helpers/cloudprofile";

import { mapWorker, generateSampleWorker } from "../../helpers/workergroups";

import useFormWarning from "../../../custom-hooks/form/useFormWarning";
import useIsMountedRef from "../../../custom-hooks/useIsMountedRef";

import { createWorkerGroup } from "../../reducer/actions";
import CreateButtons from "../../../components/shared/form/CreateButtons";

const WorkerGroupCreator = ({
  closeSlidingMenuLayer,
  predefined_params,
  createAnother,
  changeCreateAnother,
}) => {
  const isMountedRef = useIsMountedRef();

  const [fetchedData, setFetchedData] = useState();
  const [worker, setWorker] = useState();
  const [error, setError] = useState();
  const [isCreating, setIsCreating] = useState(false);

  const dispatch = useDispatch();

  const gardenDomain = useSelector(getGardenerDomain);

  const { formWarning, showFormWarning, hideFormWarning } = useFormWarning();

  useEffect(() => {
    getCloudProfile(gardenDomain)
      .then((res) => {
        if (isMountedRef.current) {
          setFetchedData(res);
        }
      })
      .catch((err) => {
        if (isMountedRef.current) {
          setFetchedData(null);
        }
      });
  }, [gardenDomain, isMountedRef]);

  useEffect(() => {
    if (fetchedData) {
      setWorker(generateSampleWorker(fetchedData));
    }
  }, [fetchedData]);

  const handleWorkerUpdate = (updatedWorker) => {
    setWorker(updatedWorker);
    hideFormWarning();
  };

  const updateErrorsList = (error) => {
    setError(error);
  };

  const create = () => {
    setIsCreating(true);

    dispatch(
      createWorkerGroup({
        shoot: { ...predefined_params },
        objectToSend: { worker: mapWorker(worker) },
        gardenDomain,
      }),
    )
      .then(() => {
        if (!createAnother) {
          setTimeout(() => closeSlidingMenuLayer(), 500);
        }
      })
      .finally(() => setIsCreating(false));
  };

  return (
    <div className={`creator-component-wrapper`}>
      <div className="">
        <FancyHeader title="Create Worker Group" />

        <p></p>
        <Grid>
          {worker && (
            <React.Fragment>
              <Grid.Row className="separator padding-top-30">
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter "
                >
                  <h5>Shoot Cluster</h5>
                </Grid.Column>
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter "
                >
                  <Input
                    value={predefined_params.metadata.name || ""}
                    disabled
                    className={`select-box full`}
                  />
                </Grid.Column>
              </Grid.Row>

              <Worker
                worker={worker}
                machineTypes={fetchedData.machineTypes}
                machineImages={fetchedData.machineImages}
                handleWorkerUpdate={handleWorkerUpdate}
                updateErrorsList={updateErrorsList}
                formWarning={formWarning}
              />

              <Grid.Row className="separator padding-top padding-bottom" />

              <CreateButtons
                error={error}
                showFormWarning={showFormWarning}
                action={create}
                isCreating={isCreating}
                closeSlidingMenuLayer={closeSlidingMenuLayer}
                createAnother={createAnother}
                changeCreateAnother={changeCreateAnother}
              />
            </React.Fragment>
          )}

          {/* Loading */}
          {fetchedData === undefined && (
            <Grid.Row>
              <Grid.Column textAlign="left" width={16}>
                <h5>
                  <Icon
                    name="spinner"
                    loading
                    className="margin-right margin-top-20"
                  />
                  Loading
                </h5>
              </Grid.Column>
            </Grid.Row>
          )}

          {/* Error getting cloud profile */}
          {fetchedData === null && (
            <Grid.Row>
              <Grid.Column textAlign="left" width={16}>
                <h5>
                  <Icon
                    name="warning circle"
                    className="margin-right margin-top-20"
                  />
                  Error Loading cloud profile
                </h5>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </div>
    </div>
  );
};

WorkerGroupCreator.propTypes = {
  closeSlidingMenuLayer: PropTypes.func.isRequired,
  predefined_params: PropTypes.object.isRequired,
  createAnother: PropTypes.bool.isRequired,
  changeCreateAnother: PropTypes.func.isRequired,
};

export default WorkerGroupCreator;
