import { useRef } from "react";
import { Grid, Input, Ref } from "semantic-ui-react";
import useForm from "../../../../custom-hooks/form/useForm";
import { testEmail } from "../../../../shared-functions/regex";
import FancyHeader from "../../../shared/FancyHeader";
import CreateButtons from "../../../shared/form/CreateButtons";
import useFormWarning from "../../../../custom-hooks/form/useFormWarning";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { createEmail } from "./actions";

const EmailCreator = ({ closeSlidingMenuLayer }) => {
  const dispatch = useDispatch();
  const emailRef = useRef();
  const emailIsCreating = useSelector(
    (state) => state.user_emails.emailIsCreating,
  );

  const { showFormWarning } = useFormWarning();
  const { formData, handleChange, error } = useForm({
    validations: [
      {
        field: "email",
        requiredMessage: "Email is required",
        ref: emailRef,
        custom: {
          validateFunction: (value) => testEmail(value),
          errorMessage: "Please provide a valid email.",
        },
      },
    ],
    initialState: {
      email: "",
    },
  });

  return (
    <>
      <FancyHeader title="Add Email" />
      <p />
      <Grid>
        <Grid.Row className="separator padding-top-30">
          <Grid.Column textAlign="left" width={8} className="flex vcenter ">
            <h5>Email</h5>
          </Grid.Column>
          <Grid.Column textAlign="left" width={8}>
            <Ref innerRef={emailRef}>
              <Input
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Ref>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <CreateButtons
        error={error}
        showFormWarning={showFormWarning}
        action={() => dispatch(createEmail(formData.email))}
        isCreating={emailIsCreating}
        closeSlidingMenuLayer={closeSlidingMenuLayer}
      />
    </>
  );
};

EmailCreator.propTypes = {
  closeSlidingMenuLayer: PropTypes.func.isRequired,
};

export default EmailCreator;
