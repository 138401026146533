import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PageToolbar,
  PageToolbarButtonsPane,
} from "../../../components/PageToolbar";
import Breadcrumbs from "../../../components/shared/breadcrumbs/Breadcrumbs";
import { Icon, Input, Loader, Segment, Sidebar } from "semantic-ui-react";
import SortableEndpointGroupList from "./SortableEndpointGroupList";
import { useTranslation } from "react-i18next";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { toast } from "react-toastify";

import { confirmbox_open } from "../../../components/confirmbox/actions";
import { KnowledgeBaseButton } from "../../../components/knowledgebase/KnowledgeBaseButton";
import { showMoreResources } from "../../../actions/shared";
import LoadMoreSensor from "../../../components/shared/LoadMoreSensor";

import { canLoadMore } from "../../../app_shared_functions";
import { deleteMultipleEndpointgroups } from "./actions";
import CircularButton from "../../../components/shared/circularbutton/CircularButton";
import { getButtonCountPopup } from "../../../components/shared/circularbutton/util";
import { mapResourceNameToReduxSelector } from "../../../selectors/resources";
import { debounceInput } from "../../../shared-functions/environment";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import useSubscribe from "../../../custom-hooks/useSubscribe";
import { mapResourceTypeToReduxList } from "../../../components/shared/grid-bits/mapResourceTypeToReduxList";
import useHasCRUDAccess from "../../../custom-hooks/useHasCRUDAccess";

const EndpointGroupList = () => {
  const dispatch = useDispatch();
  const resourceType = "endpointgroups";

  useSubscribe(mapResourceTypeToReduxList[resourceType]);

  const { t } = useTranslation();

  const hasCRUDAccess = useHasCRUDAccess();

  const {
    resourcesList: endpointgroupsList,
    currentPage,
    zonesLeft,
    hiddenRegions,
    selectedResources: selectedEndpointgroups,
  } = useSelector(mapResourceNameToReduxSelector[resourceType]);

  const breadcrumbs = useMemo(
    () => [
      {
        title: "menu.networks.networking",
      },
      {
        title: "menu.networks.vpn",
        pathname: "/networking/vpn",
      },
      {
        title: `endpointgroup.endpointgroup`,
        popup: Object.values(endpointgroupsList).reduce(
          (acc, x, i) =>
            (acc = {
              ...acc,
              "Endpoint Groups": i + 1,
              Endpoints: (acc["Endpoints"] || 0) + (x?.endpoints?.length || 0),
            }),
          {},
        ),
      },
    ],
    [endpointgroupsList],
  );

  const [filteringText, setFilteringText] = useState("");

  const [loadingMore, setLoadingMore] = useState(false);

  const toggleDeleteDialog = useCallback(() => {
    if (!selectedEndpointgroups.length) {
      toast.warn("No IKE policies are selected for delete");
    } else {
      const resources = selectedEndpointgroups.map(
        (x) => endpointgroupsList[x],
      );
      dispatch(
        confirmbox_open({
          entity: "endpoint group",
          operation: "delete",
          resources: resources,
          onConfirm: deleteMultipleEndpointgroups,
        }),
      );
    }
  }, [dispatch, endpointgroupsList, selectedEndpointgroups]);

  const checkAndLoadMore = useCallback(
    (isVisible) => {
      if (
        isVisible &&
        canLoadMore(endpointgroupsList, currentPage, hiddenRegions)
      ) {
        setLoadingMore(true);
        dispatch(showMoreResources("endpointgroup"));
      }
    },
    [currentPage, dispatch, endpointgroupsList, hiddenRegions],
  );

  useEffect(() => {
    setLoadingMore(false);
  }, [currentPage]);

  const onFilterChange = debounceInput((text) => setFilteringText(text));

  return (
    <div>
      <Sidebar.Pushable as={Segment}>
        <Sidebar.Pusher>
          <PageToolbar>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <KnowledgeBaseButton />

            {hasCRUDAccess && (
              <PageToolbarButtonsPane>
                <button
                  className="button button--transparent"
                  onClick={() =>
                    dispatch(toggleSlidingMenu("create", "Endpoint Group"))
                  }
                >
                  Create new Endpoint Group
                  <Icon name="plus circle" />
                </button>
              </PageToolbarButtonsPane>
            )}
          </PageToolbar>
          <div className="page-content" key="content">
            {/* Multi-select actions*/}
            <div className="top-section">
              {hasCRUDAccess && (
                <div className="margin-bottom">
                  <CircularButton
                    onClick={toggleDeleteDialog}
                    className={`button button--circular margin-right-half `}
                    icon="trash"
                    count={selectedEndpointgroups.length}
                    popupContent={`${t(
                      `endpointgroup.actions.delete`,
                    )} ${getButtonCountPopup(
                      selectedEndpointgroups.length,
                      "endpoint group",
                    )}`}
                  />
                </div>
              )}
              <div className="pos_right">
                <div className="ui input input-white">
                  <Input
                    minLength={2}
                    onChange={(e) => onFilterChange(e.target.value)}
                    placeholder="Enter filter text..."
                  />
                </div>
              </div>
            </div>
            <GridContext.Provider
              value={{ filteringText, resourceType, hasCRUDAccess }}
            >
              <SortableEndpointGroupList />
            </GridContext.Provider>
            <LoadMoreSensor
              checkAndLoadMore={checkAndLoadMore}
              loading_more={loadingMore}
            />
          </div>
          {zonesLeft ? (
            <Loader
              size="tiny"
              active
              className="loading-fixed"
            >{`${zonesLeft} region(s) loading...`}</Loader>
          ) : (
            ""
          )}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
};

export default EndpointGroupList;
