import volumeConstants from "./constants";
import snapshotConstans from "./snapshots/constants";
import FetchAPI from "../../../api/FetchAPI";
import { toast } from "react-toastify";
import {
  checkResourceProperties,
  toastError,
  toastMultipleResults,
} from "../../../app_shared_functions";

export const subscribe = () => ({
  type: volumeConstants.VOLUMES_LIST_SUBSCRIBE,
});
export const unsubscribe = () => ({
  type: volumeConstants.VOLUMES_LIST_UNSUBSCRIBE,
});

export const subscribeSnapshot = () => ({
  type: volumeConstants.SNAPSHOTS_LIST_SUBSCRIBE,
});
export const unsubscribeSnapshot = () => ({
  type: volumeConstants.SNAPSHOTS_LIST_UNSUBSCRIBE,
});

export const showViewMore = (id) => (dispatch) => {
  dispatch({ type: volumeConstants.VOLUME_SHOW_VIEWMORE, payload: id });
};
export const hideViewMore = (id) => (dispatch) => {
  dispatch({ type: volumeConstants.VOLUME_HIDE_VIEWMORE, payload: id });
};

export const detachVolume = (volume) => (dispatch) => {
  const invalid_Volume_Msg = checkResourceProperties(volume, "volume");
  if (invalid_Volume_Msg) {
    toastError(invalid_Volume_Msg);
    return Promise.reject();
  }

  dispatch({
    type: volumeConstants.VOLUMES_DETACH_INIT,
    payload: { id: volume.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Compute.Servers.detachVolume(volume)
      .then((response) => {
        if (response && response.status === 202) {
          dispatch({
            type: volumeConstants.VOLUMES_DETACH_STARTED,
            payload: { id: volume.id },
          });
          resolve(response.data);
        } else {
          reject();
        }
      })
      .catch((err) => {
        dispatch({
          type: volumeConstants.VOLUMES_DETACH_FAILED,
          payload: { id: volume.id },
        });
        toastError(err, "Detaching volume failed");
        reject();
      });
  });
};

export const detachMultipleVolumes = (volumes) => (dispatch) => {
  toast.success(
    `About to detach ${volumes.length} volume${
      volumes.length > 1 ? "s" : ""
    }...`,
  );
  dispatch({
    type: volumeConstants.VOLUME_DETACH_MULTIPLE_INIT,
    payload: volumes,
  });
  const promises = volumes.map((volume) => {
    return new Promise((resolve, reject) =>
      FetchAPI.Compute.Servers.detachVolume(volume)
        .then((response) => resolve({ status: "resolved", id: volume.id }))
        .catch((err) => {
          dispatch({
            type: volumeConstants.VOLUMES_DETACH_FAILED,
            payload: { id: volume.id },
          });
          resolve({
            status: "rejected",
            id: volume.id,
            desc:
              err.response.data.error.description ||
              err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "volume",
      action: "detach",
      dispatch,
    }),
  );
};

export const attachVolumeToServer = (server, volume) => (dispatch) => {
  const invalid_Volume_Msg = checkResourceProperties(volume, "volume");
  const invalid_Server_Msg = checkResourceProperties(server, "server");
  if (invalid_Volume_Msg) {
    toastError(invalid_Volume_Msg);
    return Promise.reject();
  }
  if (invalid_Server_Msg) {
    toastError(invalid_Server_Msg);
    return Promise.reject();
  }

  dispatch({
    type: volumeConstants.VOLUMES_ATTACH_INIT,
    payload: { id: volume.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.Compute.Servers.attachVolume({
      server,
      volume,
    })
      .then((response) => {
        if (response) {
          dispatch({
            type: volumeConstants.VOLUMES_ATTACH_STARTED,
            payload: { id: volume.id },
          });
          toast.success("Volume Attach started...");
          resolve(response.data);
        } else {
          reject();
        }
      })
      .catch((err) => {
        dispatch({
          type: volumeConstants.VOLUMES_ATTACH_FAILED,
          payload: { id: volume.id },
        });
        toastError(err, "Attach volume to server failed!");
        reject();
      });
  });
};

export const deleteVolume = (volume) => (dispatch) => {
  const invalid_Volume_Msg = checkResourceProperties(volume, "volume");
  if (invalid_Volume_Msg) {
    toastError(invalid_Volume_Msg);
    return Promise.reject();
  }

  dispatch({
    type: volumeConstants.VOLUME_DELETE_INIT,
    payload: { id: volume.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.BlockStorage.Volumes.delete(volume)
      .then((response) => {
        if (response && response.status === 202) {
          dispatch({
            type: volumeConstants.VOLUME_DELETE_STARTED,
            payload: { id: volume.id },
          });
          resolve(response.data);
        } else {
          dispatch({
            type: volumeConstants.VOLUME_DELETE_FAILED,
            payload: { id: volume.id },
          });
          reject();
        }
      })
      .catch((err) => {
        dispatch({
          type: volumeConstants.VOLUME_DELETE_FAILED,
          payload: { id: volume.id },
        });
        toastError(err, "Delete volume failed!");
        reject();
      });
  });
};

export const deleteMultipleVolumes = (volumes) => (dispatch) => {
  toast.success(
    `About to delete ${volumes.length} volume${
      volumes.length > 1 ? "s" : ""
    }...`,
  );
  dispatch({
    type: volumeConstants.VOLUME_DELETE_MULTIPLE_INIT,
    payload: volumes,
  });
  const promises = volumes.map((volume) => {
    return new Promise((resolve, reject) =>
      FetchAPI.BlockStorage.Volumes.delete(volume)
        .then((response) => resolve({ status: "resolved", id: volume.id }))
        .catch((err) => {
          dispatch({
            type: volumeConstants.VOLUME_DELETE_FAILED,
            payload: { id: volume.id },
          });
          resolve({
            status: "rejected",
            id: volume.id,
            desc:
              err.response.data.error.description ||
              err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "volume",
      action: "delete",
      dispatch,
    }),
  );
};

export const editVolume = (volume, objectToSend) => (dispatch) => {
  const invalid_Volume_Msg = checkResourceProperties(volume, "volume");
  if (invalid_Volume_Msg) {
    toastError(invalid_Volume_Msg);
    return Promise.reject();
  }

  dispatch({
    type: volumeConstants.VOLUME_RENAME_INIT,
    payload: { id: volume.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.BlockStorage.Volumes.modify({ volume, objectToSend })
      .then((response) => {
        if (response && response.status === 200) {
          dispatch({
            type: volumeConstants.VOLUME_RENAME_FINISHED,
            payload: { id: volume.id },
          });
          resolve(response.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: volumeConstants.VOLUME_RENAME_FAILED,
          payload: { id: volume.id },
        });
        toastError(err, "Volume update failed!");
        reject();
      });
  });
};

export const disableDisasterRecoveryVolume = (volume) => (dispatch) => {
  const invalid_Volume_Msg = checkResourceProperties(volume, "volume");
  if (invalid_Volume_Msg) {
    toastError(invalid_Volume_Msg);
    return Promise.reject();
  }

  dispatch({
    type: volumeConstants.VOLUME_DISASTERRECOVERY_UPDATE_STARTED,
    payload: { id: volume.id },
  });
  const objectToSend = {
    volume: {
      dr_service_enabled: false,
    },
  };
  return new Promise((resolve, reject) => {
    FetchAPI.BlockStorage.Volumes.toggleDisasterRecover({
      volume,
      objectToSend,
    })
      .then((response) => {
        if (response && response.status === 200) {
          dispatch({
            type: volumeConstants.VOLUME_DISASTERRECOVERY_UPDATE_FINISHED,
            payload: { id: volume.id },
          });
          resolve(response.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: volumeConstants.VOLUME_DISASTERRECOVERY_UPDATE_FAILED,
          payload: { id: volume.id },
        });
        toastError(err, "Disabling disaster recovery failed!");
        reject(err);
      });
  });
};
export const disableDisasterRecoveryMultipleVolumes =
  (volumes) => (dispatch) => {
    toast.success(
      `About to disable disaster recovery on ${volumes.length} volume${
        volumes.length > 1 ? "s" : ""
      }...`,
    );
    dispatch({
      type: volumeConstants.VOLUME_DISASTERRECOVERY_UPDATE_MULTI_INIT,
      payload: volumes,
    });
    const objectToSend = {
      volume: {
        dr_service_enabled: false,
      },
    };
    const promises = volumes.map((volume) => {
      return new Promise((resolve, reject) =>
        FetchAPI.BlockStorage.Volumes.toggleDisasterRecover({
          volume,
          objectToSend,
        })
          .then((response) => resolve({ status: "resolved", id: volume.id }))
          .catch((err) => {
            dispatch({
              type: volumeConstants.VOLUME_DISASTERRECOVERY_UPDATE_FAILED,
              payload: { id: volume.id },
            });
            resolve({
              status: "rejected",
              id: volume.id,
              desc:
                err.response.data.error.description ||
                err.response.data.error.message,
            });
          }),
      );
    });
    Promise.all(promises).then((responses) =>
      toastMultipleResults({
        responses,
        resource_name: "volume",
        action: "update disaster recovery",
        dispatch,
      }),
    );
  };

export const enableDisasterRecoveryVolume = (volume) => (dispatch) => {
  const invalid_Volume_Msg = checkResourceProperties(volume, "volume");
  if (invalid_Volume_Msg) {
    toastError(invalid_Volume_Msg);
    return Promise.reject();
  }

  dispatch({
    type: volumeConstants.VOLUME_DISASTERRECOVERY_UPDATE_STARTED,
    payload: { id: volume.id },
  });
  const objectToSend = {
    volume: {
      dr_service_enabled: true,
    },
  };
  return new Promise((resolve, reject) => {
    FetchAPI.BlockStorage.Volumes.toggleDisasterRecover({
      volume,
      objectToSend,
    })
      .then((response) => {
        if (response && response.status === 200) {
          dispatch({
            type: volumeConstants.VOLUME_DISASTERRECOVERY_UPDATE_FINISHED,
            payload: { id: volume.id },
          });
          resolve(response.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: volumeConstants.VOLUME_DISASTERRECOVERY_UPDATE_FAILED,
          payload: { id: volume.id },
        });
        toastError(err, "Enabling disaster recovery failed!");
        reject(err);
      });
  });
};
export const enableDisasterRecoveryMultipleVolumes =
  (volumes) => (dispatch) => {
    toast.success(
      `About to enable disaster recovery on ${volumes.length} volume${
        volumes.length > 1 ? "s" : ""
      }...`,
    );
    dispatch({
      type: volumeConstants.VOLUME_DISASTERRECOVERY_UPDATE_MULTI_INIT,
      payload: volumes,
    });
    const objectToSend = {
      volume: {
        dr_service_enabled: true,
      },
    };
    const promises = volumes.map((volume) => {
      return new Promise((resolve, reject) =>
        FetchAPI.BlockStorage.Volumes.toggleDisasterRecover({
          volume,
          objectToSend,
        })
          .then((response) => resolve({ status: "resolved", id: volume.id }))
          .catch((err) => {
            dispatch({
              type: volumeConstants.VOLUME_DISASTERRECOVERY_UPDATE_FAILED,
              payload: { id: volume.id },
            });
            resolve({
              status: "rejected",
              id: volume.id,
              desc:
                err.response.data.error.description ||
                err.response.data.error.message,
            });
          }),
      );
    });
    Promise.all(promises).then((responses) =>
      toastMultipleResults({
        responses,
        resource_name: "volume",
        action: "update disaster recovery",
        dispatch,
      }),
    );
  };

export const extendVolume = (volume, size) => (dispatch) => {
  const invalid_Volume_Msg = checkResourceProperties(volume, "volume");
  if (invalid_Volume_Msg) {
    toastError(invalid_Volume_Msg);
    return Promise.reject();
  }

  dispatch({
    type: volumeConstants.VOLUME_RESIZE_INIT,
    payload: { id: volume.id },
  });
  const objectToSend = {
    extend: {
      new_size: Number(size),
    },
  };
  return new Promise((resolve, reject) => {
    FetchAPI.BlockStorage.Volumes.extend({ volume, objectToSend })
      .then((response) => {
        if (response && response.status === 200) {
          dispatch({
            type: volumeConstants.VOLUME_RESIZE_STARTED,
            payload: { id: volume.id },
          });
          resolve(response.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: volumeConstants.VOLUME_RESIZE_FAILED,
          payload: { id: volume.id },
        });
        const errMsg = err?.response?.data?.error?.message;
        if (
          volume.status !== "available" &&
          errMsg.includes("status") &&
          errMsg.includes("available")
        ) {
          err = "For this region please detach the volume before changing size";
        }
        toastError(err, "Extending volume size failed!");
        reject();
      });
  });
};

export const createSnapshotFromVolume =
  (volume, currentProjectID, objectToSend) => (dispatch) => {
    const invalid_Volume_Msg = checkResourceProperties(volume, "volume");
    if (invalid_Volume_Msg) {
      toastError(invalid_Volume_Msg);
      return Promise.reject();
    }

    dispatch({
      type: volumeConstants.VOLUME_CREATE_SNAPSHOT_INIT,
      payload: { id: volume.id },
    });
    return new Promise((resolve, reject) => {
      FetchAPI.BlockStorage.Snapshots.create({
        volume,
        objectToSend,
      })
        .then((response) => {
          toast.success("Creating snapshot from volume started...");
          dispatch({
            type: snapshotConstans.VOLUME_SNAPSHOT_CREATE_STARTED,
            payload: {
              ...response.data,
              region: volume.region,
              project_id: volume.project_id,
            },
          });
          resolve(response.data);
        })
        .catch((err) => {
          dispatch({
            type: volumeConstants.VOLUME_CREATE_SNAPSHOT_FAILED,
            payload: { id: volume.id },
          });
          toastError(err, "Creating snapshot from volume failed!");
          reject(err);
        });
    });
  };

export const createVolumeFromSnapshot = (snapshot, volume) => (dispatch) => {
  const objectToSend = {
    volume: { ...volume },
  };

  const invalid_Snapshot_Msg = checkResourceProperties(snapshot, "snapshot");
  if (invalid_Snapshot_Msg) {
    toastError(invalid_Snapshot_Msg);
    return Promise.reject();
  }

  dispatch({ type: volumeConstants.VOLUME_CREATE_INIT });
  return new Promise((resolve, reject) => {
    FetchAPI.BlockStorage.Volumes.create({
      region: snapshot.region,
      project_id: snapshot.project_id,
      objectToSend,
    })
      .then((response) => {
        toast.success("Volume creation started...");
        dispatch({
          type: volumeConstants.VOLUME_CREATE_STARTED,
          payload: {
            ...response.data,
            region: snapshot.region,
            project_id: snapshot.project_id,
          },
        });
        resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: volumeConstants.VOLUME_CREATE_FAILED });
        toastError(err, "Creating volume from snapshot failed!");
        reject(err);
      });
  });
};

export const restoresnapshotVolume = (volume) => (dispatch) => {
  const invalid_Volume_Msg = checkResourceProperties(volume, "volume");
  if (invalid_Volume_Msg) {
    toastError(invalid_Volume_Msg);
    return Promise.reject();
  }

  dispatch({
    type: volumeConstants.VOLUME_DISASTERRECOVERY_INIT,
    payload: { id: volume.id },
  });
  const objectToSend = {
    dr_recover: {
      restore_date: volume.date,
    },
  };
  return new Promise((resolve, reject) => {
    FetchAPI.BlockStorage.Volumes.disasterRecoverInitiate({
      volume,
      objectToSend,
    })
      .then((response) => {
        if (response) {
          resolve(response.data);
          toast.success("Disaster recovery started...");
          dispatch({
            type: volumeConstants.VOLUME_DISASTERRECOVERY_STARTED,
            payload: { id: volume.id },
          });
        } else {
          reject();
        }
      })
      .catch((err) => {
        toastError(err, "Restoring snapshot failed!");
        reject(err);
        dispatch({
          type: volumeConstants.VOLUME_DISASTERRECOVERY_FAILED,
          payload: { id: volume.id },
        });
      });
  });
};

export const volumeDisasterRecovery_Cancel = (volume) => (dispatch) => {
  const invalid_Volume_Msg = checkResourceProperties(volume, "volume");
  if (invalid_Volume_Msg) {
    toastError(invalid_Volume_Msg);
    return Promise.reject();
  }

  dispatch({
    type: volumeConstants.VOLUME_DISASTERRECOVERY_CANCEL_INIT,
    payload: { id: volume.id },
  });
  return new Promise((resolve, reject) => {
    FetchAPI.BlockStorage.Volumes.disasterRecoverCancel(volume)
      .then((response) => {
        if (response) {
          resolve(response.data);
          toast.success("Disaster recovery canceled.");
          dispatch({
            type: volumeConstants.VOLUME_DISASTERRECOVERY_CANCEL_STARTED,
            payload: { id: volumeConstants.id },
          });
        } else {
          reject();
        }
      })
      .catch((err) => {
        toastError(err, "Disabling disaster recovery failed!");
        reject(err);
        dispatch({
          type: volumeConstants.VOLUME_DISASTERRECOVERY_CANCEL_FAILED,
          payload: { id: volumeConstants.id },
        });
      });
  });
};

export const createVolume =
  (region, project_id, objectToSend) => (dispatch) => {
    dispatch({ type: volumeConstants.VOLUME_CREATE_INIT });
    return new Promise((resolve, reject) => {
      FetchAPI.BlockStorage.Volumes.create({
        region,
        project_id,
        objectToSend,
      })
        .then((response) => {
          toast.success("Volume creation started...");
          dispatch({
            type: volumeConstants.VOLUME_CREATE_STARTED,
            payload: {
              ...response.data,
              region: region.toLowerCase(),
              task_status: "creating",
              project_id,
            },
          });
          resolve(response);
        })
        .catch((err) => {
          dispatch({ type: volumeConstants.VOLUME_CREATE_FAILED });
          toastError(err, "Creating volume failed!");
          reject(err);
        });
    });
  };
