import React from "react";
import FancyHeader from "../../../components/shared/FancyHeader";
import Invoice from "../../../components/shared/invoice/Invoice";

import { connect } from "react-redux";
import {
  Select,
  Grid,
  Input,
  Checkbox,
  Radio,
  Loader,
  TextArea,
  Icon,
  Table,
  Popup,
  Ref,
} from "semantic-ui-react";
import { createLoadBalancer } from "./actions";
import {
  renderZonesForSelectBox,
  get_FormItem_ClassName,
  getCurrentProjectID,
  debounced_API_Call,
  handleScrollToItem,
  toastError,
  convertArrayToDropDownList,
} from "../../../app_shared_functions";

import { defaultValues } from "../../../app_constants";

import Networks from "../../../components/shared/resources-list/Networks";

import config from "../../../config";
import { getLicensePrice } from "./licencePrice";

class LoadBalancerCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreating: false,
      zone: "",
      name: "",
      creationMethod: defaultValues.loadbalancer.creationMethod,
      // protocol            : 'HTTP'
    };
  }

  componentDidMount() {
    // TODO: When converted to functional component, this shall be moved to a custom hook that will be used by this component, the modify and the detailed view components
    getLicensePrice().then((res) => this.setState(res));

    this.setState({
      areasList: renderZonesForSelectBox(
        this.props.projects,
        this.props.userDomains,
      ),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.virtualip && this.state.virtualip) {
      this.virtualip_input.focus();
    }
  }

  updateform = (name, data) => {
    if (
      name === "zone" &&
      (!this.state.zone || this.state.zone.value !== data.value)
    ) {
      this.setState(
        {
          networkList: null,
          selectedNetwork: null,
          subnetList: null,
          selectedSubnet: null,
          portList: null,
          selectedPort: null,
          invalidForm: false,
          [name]: data,
        },
        () => {
          if (
            !this.state.creationMethod ||
            this.state.creationMethod === "network"
          )
            this.getNetworksList(this.state.zone.value.toLowerCase());
          else if (this.state.creationMethod === "subnet")
            this.getSubnetsList(this.state.zone.value.toLowerCase());
          else if (this.state.creationMethod === "port")
            this.getPortsList(this.state.zone.value.toLowerCase());
        },
      );
    } else if (name === "creationMethod") {
      this.setState({ [name]: data, invalidForm: false });
      if (data === "network" && !Array.isArray(this.state.networkList)) {
        this.getNetworksList(this.state.zone.value.toLowerCase());
      }
      if (data === "subnet" && !Array.isArray(this.state.subnetList)) {
        this.getSubnetsList(this.state.zone.value.toLowerCase());
      }
      if (data === "port" && !Array.isArray(this.state.portList)) {
        this.getPortsList(this.state.zone.value.toLowerCase());
      }
    } else {
      this.setState({ [name]: data, invalidForm: false });
    }
  };

  createLB = () => {
    const region = this.state.zone.value.toLowerCase();
    const currentProjectID = getCurrentProjectID(this.props.projects, region);

    const objectToSend = {
      loadbalancer: {
        name: this.state.name,
      },
    };

    if (this.state.description)
      objectToSend.loadbalancer.description = this.state.description;

    if (this.state.creationMethod === "network")
      objectToSend.loadbalancer.vip_network_id = this.state.selectedNetwork;
    else if (this.state.creationMethod === "subnet")
      objectToSend.loadbalancer.vip_subnet_id = this.state.selectedSubnet;
    else if (this.state.creationMethod === "port")
      objectToSend.loadbalancer.vip_port_id = this.state.selectedPort;

    if (this.state.virtualip && this.state.virtualip_input)
      objectToSend.loadbalancer.vip_address = this.state.virtualip_input;

    this.setState({ isCreating: true });

    this.props
      .createLoadBalancer(region, currentProjectID, objectToSend)
      .then((response) => {
        this.setState({
          isCreating: false,
        });
        if (!this.props.createAnother) this.props.closeSlidingMenuLayer();
      })
      .catch((err) => {
        this.setState({
          isCreating: false,
        });
      });
  };

  /* get list of networks */
  getNetworksList = (region) => {
    this.setState({ networksLoading: true });
    const currentProjectID = getCurrentProjectID(this.props.projects, region);

    const url = `${
      config.api_url
    }/networking/v1/networks/${region.toLowerCase()}/${currentProjectID}`;
    debounced_API_Call(url)
      .then((response) => {
        if (response && response.status === 200) {
          const validNetworks = response.data.filter(
            (x) => !x["router:external"],
          );
          const defaultNetwork = validNetworks.find(
            (x) => x.name === "Default network",
          );
          this.setState({
            networkList: validNetworks,
            networksLoading: false,
            selectedNetwork: defaultNetwork
              ? defaultNetwork.id
              : validNetworks.length
              ? validNetworks[0].id
              : null,
          });
        }
      })
      .catch((err) => {
        toastError(err, "Networks list load failed!");
      });
  };

  /* get list of subnets */
  getSubnetsList = (region) => {
    this.setState({ subnetsLoading: true });
    const currentProjectID = getCurrentProjectID(this.props.projects, region);

    const url = `${
      config.api_url
    }/networking/v1/subnets/${region.toLowerCase()}/${currentProjectID}`;
    debounced_API_Call(url)
      .then((response) => {
        this.setState({
          subnetList: response.data,
          subnetsLoading: false,
          selectedSubnet: response.data.length ? response.data[0].id : null,
        });
      })
      .catch((err) => {
        toastError(err, "Subnets list load failed!");
      });
  };

  /* get list of ports */
  getPortsList = (region) => {
    this.setState({ portsLoading: true });
    const currentProjectID = getCurrentProjectID(this.props.projects, region);

    const url = `${
      config.api_url
    }/networking/v1/ports/${region.toLowerCase()}/${currentProjectID}`;
    debounced_API_Call(url)
      .then((response) => {
        const filteredPorts = response.data.filter((x) => !x.device_id);
        this.setState({
          portList: filteredPorts,
          portsLoading: false,
          selectedPort: filteredPorts.length ? filteredPorts[0].id : null,
        });
      })
      .catch((err) => {
        toastError(err, "Ports list loading failed!");
      });
  };

  check_required_fields = () => {
    let returnValue = null;
    if (!this.state.name) {
      returnValue = {
        text: "Please provide a name for your Volume",
        ref: "nameRef",
      };
    } else if (!this.state.zone) {
      returnValue = {
        text: "Please choose a Region",
        ref: "zoneRef",
      };
    } else if (
      this.state.creationMethod === "network" &&
      !this.state.selectedNetwork
    ) {
      returnValue = {
        text: 'No network is defined for this region. Please select "Subnet" or "Port"',
        ref: "typeRef",
      };
    } else if (
      this.state.creationMethod === "subnet" &&
      !this.state.selectedSubnet
    ) {
      returnValue = {
        text: 'No subnet is defined for this region. Please select "Network" or "Port"',
        ref: "typeRef",
      };
    } else if (
      this.state.creationMethod === "port" &&
      !this.state.selectedPort
    ) {
      returnValue = {
        text: 'No port is defined for this region. Please select "Network" or "Subnet"',
        ref: "typeRef",
      };
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  handleScrollToItem = (item) => {
    this.setState({ invalidForm: true, shake: true });
    if (this[item]) {
      this[item].scrollIntoView({
        block: "center",
        behavior: "smooth",
        inline: "center",
      });
    }
  };

  createInvoiceItems = () => {
    const { license, zone } = this.state;
    const price_per_hour = license?.[zone?.key] || 0;

    return [
      {
        name: "Usage",
        count: "",
        unit: "",
        price: ((Number(price_per_hour) || 0) * 24 * 30).toFixed(2),
        popup: price_per_hour ? (
          <Popup
            trigger={
              <Icon className="padding-left-half" name="question circle" />
            }
          >
            <Table className="simple-table">
              <Table.Body>
                <Table.Row>
                  <Table.Cell className="allCaps">price per hour :</Table.Cell>
                  <Table.Cell className="allCaps">
                    {price_per_hour} {this.state?.currency}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Popup>
        ) : null,
      },
    ];
  };

  updateNetwork = (id) => this.setState({ selectedNetwork: id });

  render() {
    const {
      networkList,
      networksLoading,
      selectedNetwork,
      subnetList,
      subnetsLoading,
      selectedSubnet,
      portList,
      portsLoading,
      selectedPort,
      creationMethod,
      zone,
      areasList,
      invalidForm,
    } = this.state;

    const form_status = this.check_required_fields();

    const invoice_Items = this.createInvoiceItems();

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Create a Load Balancer" knowledgeBase />
          {this.state?.zone?.key &&
            (this.state?.license !== "Error" ? (
              <div className="invoice_wrapper">
                <Invoice
                  currency={this.state?.currency}
                  invoice_Items={invoice_Items}
                />
              </div>
            ) : (
              <div className="invoice_wrapper">
                <Invoice error="License prices failed to load!" />
              </div>
            ))}

          <p className={`${this.state?.zone?.key ? "padding-top-50" : ""}`}></p>

          <Grid>
            {/* NAME ZONE */}
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className=" flex vcenter">
                <h5>Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Ref innerRef={(x) => (this.nameRef = x)}>
                  <Input
                    value={this.state.name}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "nameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("name", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <h5>Region</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="margin-top-30">
                {areasList && (
                  <Select
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "zoneRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    placeholder="Choose Region"
                    options={areasList}
                    onChange={(e, d) => {
                      this.updateform(
                        "zone",
                        areasList.find((area) => area.value === d.value),
                      );
                    }}
                  />
                )}
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={16}
                className="margin-top-10"
              >
                <h5>Description</h5>
                <TextArea
                  style={{ maxHeight: 100 }}
                  rows={2}
                  value={this.state.description}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("description", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            {/* SELECT NETWORK OR SUBNET OR PORT */}
            {zone && (
              <Grid.Row
                className={`padding-top-30 ${
                  form_status && invalidForm && form_status.ref === "typeRef"
                    ? "error-form-group"
                    : ""
                }`}
              >
                <Grid.Column textAlign="left" width={16}>
                  <h5 ref={(x) => (this.typeRef = x)}>
                    Choose where to connect the loadbalancer
                  </h5>
                </Grid.Column>
                <Grid.Column textAlign="left" width={8}>
                  <Radio
                    toggle
                    label="Network"
                    name="creationMethod"
                    value="network"
                    checked={this.state.creationMethod === "network"}
                    onChange={(e, v) =>
                      this.updateform("creationMethod", v.value)
                    }
                  />
                </Grid.Column>
                <Grid.Column textAlign="left" width={8}>
                  <Radio
                    toggle
                    label="Subnet"
                    name="creationMethod"
                    value="subnet"
                    checked={this.state.creationMethod === "subnet"}
                    onChange={(e, v) =>
                      this.updateform("creationMethod", v.value)
                    }
                  />
                </Grid.Column>
                <Grid.Column textAlign="left" width={8}>
                  <Radio
                    toggle
                    label="Port"
                    name="creationMethod"
                    value="port"
                    checked={this.state.creationMethod === "port"}
                    onChange={(e, v) =>
                      this.updateform("creationMethod", v.value)
                    }
                  />
                </Grid.Column>
              </Grid.Row>
            )}

            {/* NETWORKS */}
            {networkList && creationMethod === "network" && (
              <Networks
                title="Network"
                loading={networksLoading}
                networks={convertArrayToDropDownList(networkList)}
                update={this.updateNetwork}
                className="select-box full"
                selectedNetworkList={selectedNetwork}
                wrapperClassName="separator padding-top-30"
              />
            )}
            {creationMethod === "network" &&
              networksLoading &&
              networkList === null && (
                <Grid.Row className="separator padding-top-30 ">
                  <Grid.Column textAlign="left" width={16}>
                    <div className="loader-wrapper">
                      <Loader size="mini" active className="one-liner">
                        Fetching networks list...
                      </Loader>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              )}
            {/* SUBNETS */}
            {subnetList !== null &&
              subnetList !== undefined &&
              creationMethod === "subnet" && (
                <Grid.Row className="separator padding-top-30">
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter"
                  >
                    <h5>Subnet</h5>
                  </Grid.Column>

                  {subnetList.length === 0 && (
                    <Grid.Column
                      textAlign="left"
                      width={8}
                      className="flex vcenter"
                    >
                      No subnet found
                    </Grid.Column>
                  )}

                  {subnetList.length > 0 && (
                    <Grid.Column textAlign="left" width={8}>
                      <Select
                        icon="chevron circle down"
                        placeholder="Select a Subnet"
                        className="select-box full"
                        options={convertArrayToDropDownList(subnetList)}
                        value={selectedSubnet}
                        onChange={(e, d) =>
                          this.setState({ selectedSubnet: d.value })
                        }
                      />
                    </Grid.Column>
                  )}
                  {subnetsLoading && (
                    <Grid.Column textAlign="left" width={8}>
                      <div className="loader-wrapper">
                        <Loader size="mini" active className="one-liner">
                          Fetching subnets list...
                        </Loader>
                      </div>
                    </Grid.Column>
                  )}
                </Grid.Row>
              )}
            {creationMethod === "subnet" &&
              subnetsLoading &&
              subnetList === null && (
                <Grid.Row className="separator padding-top-30  ">
                  <Grid.Column textAlign="left" width={16}>
                    <div className="loader-wrapper">
                      <Loader size="mini" active className="one-liner">
                        Fetching subnets list...
                      </Loader>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              )}
            {/* PORTS */}
            {portList !== null &&
              portList !== undefined &&
              creationMethod === "port" && (
                <Grid.Row className="separator padding-top-30  ">
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter"
                  >
                    <h5>Ports</h5>
                  </Grid.Column>

                  {portList.length === 0 && (
                    <Grid.Column
                      textAlign="left"
                      width={8}
                      className="flex vcenter"
                    >
                      No available port found
                    </Grid.Column>
                  )}
                  {portList.length > 0 && (
                    <Grid.Column textAlign="left" width={8}>
                      <Select
                        icon="chevron circle down"
                        placeholder="Select a Port"
                        className="select-box full"
                        options={convertArrayToDropDownList(portList)}
                        value={selectedPort}
                        onChange={(e, d) =>
                          this.setState({ selectedPort: d.value })
                        }
                      />
                    </Grid.Column>
                  )}
                  {portsLoading && (
                    <Grid.Column textAlign="left" width={8}>
                      <div className="loader-wrapper">
                        <Loader size="mini" active className="one-liner ">
                          Fetching ports list...
                        </Loader>
                      </div>
                    </Grid.Column>
                  )}
                </Grid.Row>
              )}
            {creationMethod === "port" && portsLoading && portList === null && (
              <Grid.Row className="separator padding-top-30  ">
                <Grid.Column textAlign="left" width={16}>
                  <div className="loader-wrapper">
                    <Loader size="mini" active className="one-liner ">
                      Fetching ports list...
                    </Loader>
                  </div>
                </Grid.Column>
              </Grid.Row>
            )}

            {/* Virtual IP */}
            {zone && creationMethod !== "port" && (
              <Grid.Row className="separator padding-top-30">
                <Grid.Column textAlign="left" width={16}>
                  <h5>
                    Virtual IP (optional)
                    <Popup
                      trigger={
                        <Icon
                          name="warning circle"
                          color="grey"
                          size="small"
                          className="margin-left-10"
                        />
                      }
                    >
                      You can choose a specific (internal) IP that you want the
                      loadbalancer to listen to. If you do not enter anything an
                      IP is automatically chosen for you.
                    </Popup>
                  </h5>
                </Grid.Column>
                <Grid.Column textAlign="left" width={16}>
                  <Radio
                    toggle
                    label="Specify custom virtual IP"
                    name="virtualip"
                    checked={this.state.virtualip}
                    onChange={(e, v) =>
                      this.updateform("virtualip", !this.state.virtualip)
                    }
                  />
                </Grid.Column>
                {this.state.virtualip && (
                  <Grid.Column
                    textAlign="left"
                    width={16}
                    className="margin-top-10"
                  >
                    <Input
                      ref={(v) => {
                        this.virtualip_input = v;
                      }}
                      value={this.state.virtualip_input}
                      className="select-box full"
                      placeholder="Specify IP for internal VIP port. ipv4 or ipv6. Example: 10.0.0.10"
                      onChange={(e) =>
                        this.updateform(
                          "virtualip_input",
                          e.currentTarget.value,
                        )
                      }
                    />
                  </Grid.Column>
                )}
              </Grid.Row>
            )}

            {/* CREATE BUTTONS */}
            <Grid.Row className=" padding-top-30">
              <Grid.Column textAlign="left" width={16}>
                {!form_status ? (
                  this.state.isCreating ? (
                    <button className="float-right button button--green overflow-hidden button--icon__right ">
                      <Icon loading name="spinner" />
                      <span>Creating</span>
                    </button>
                  ) : (
                    <button
                      className="float-right button button--green"
                      onClick={() => this.createLB()}
                    >
                      <span>Create</span>
                    </button>
                  )
                ) : (
                  <Popup
                    trigger={
                      <button
                        className="float-right button button--green button--disabled button--icon__left"
                        onClick={() => {
                          this.setState({ invalidForm: true, shake: true });
                          handleScrollToItem(this[form_status.ref]);
                        }}
                      >
                        <Icon name="exclamation circle" />
                        <span>Create</span>
                      </button>
                    }
                  >
                    {form_status?.text}
                  </Popup>
                )}
                <Checkbox
                  className="simple-checkbox float-right margin-top-half"
                  label="Create Another "
                  checked={this.props.createAnother}
                  onChange={this.props.changeCreateAnother}
                />
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domains: state.domains.list,
    projects: state.projects,
    userDomains: state.usersettings?.selectedDomains || null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createLoadBalancer: (rgn, pid, data) =>
    dispatch(createLoadBalancer(rgn, pid, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoadBalancerCreator);
