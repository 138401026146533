import React from "react";
import { Header, Tab, Loader, Icon, Modal } from "semantic-ui-react";
import QuickView from "../../../../components/shared/quickview/QuickView";
import { defaultValues } from "../../../../app_constants";
import TableWrapper from "../../../../components/shared/react-table/TableWrapper";
import {
  createTableHeaderObject,
  deep_Compare,
  getIconForResource,
  toggleArrayItem,
  toastError,
} from "../../../../app_shared_functions";

import FetchAPI from "../../../../api/FetchAPI";
import { toast } from "react-toastify";

import {
  deleteStack_snapshot,
  restoreStack_snapshot,
  updateSnapshotsList,
} from "./actions";

import DetailedItem from "./ModalContentItem";
import { connect } from "react-redux";
import { filterActionColumn } from "../../../../shared-functions/authenticate";
import CircularButton from "../../../../components/shared/circularbutton/CircularButton";

class Snapshots extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      requesting_Resources_List: [],
    };
  }

  componentDidMount() {
    this.getPaneData();
    this.observeChanges = setInterval(() => this.getPaneData(), 5000);
  }

  componentWillUnmount() {
    clearInterval(this.observeChanges);
  }

  getPaneData = () => {
    const { stack } = this.props;
    FetchAPI.Orchestration.Stacks.getProperty({
      stack,
      property: "snapshots",
    })
      .then((res) => this.checkAndUpdateList(res.data))
      .catch(() => this.setState({ data: null }));
  };

  mapDataToRequiredProperties = (data) => {
    return data.map((x) => ({
      ...x,
      region: this.props.stack.region,
      project_id: this.props.stack.project_id,
      stack_name: this.props.stack.stack_name,
      stack_id: this.props.stack.id,
    }));
  };

  filterSnapshots = (stacks_snapshots) => {
    return Object.values(stacks_snapshots).filter(
      (x) =>
        x.region === this.props.stack.region &&
        x.project_id === this.props.stack.project_id &&
        x.stack_name === this.props.stack.stack_name &&
        x.stack_id === this.props.stack.id,
    );
  };

  checkAndUpdateList = (fetchedSnapshots) => {
    const newSnapshots = this.mapDataToRequiredProperties(fetchedSnapshots);
    if (this.state.loading) {
      this.setState({ loading: false });
      this.props.updateSnapshotsList(
        newSnapshots,
        this.props.stack.id,
        this.props.stacks_snapshots,
      );
    }

    const oldSnapshots = this.filterSnapshots(this.props.stacks_snapshots);

    if (!deep_Compare(oldSnapshots, newSnapshots)) {
      this.props.updateSnapshotsList(
        newSnapshots,
        this.props.stack.id,
        this.props.stacks_snapshots,
      );
    }
  };

  setStatusToLoading = (resource) => {
    if (!this.state.requesting_Resources_List.includes(resource.id)) {
      this.setState({
        requesting_Resources_List: [
          ...this.state.requesting_Resources_List,
          resource.id,
        ],
      });
    }
  };

  resetStatus = (resource) => {
    const index = this.state.requesting_Resources_List.indexOf(resource.id);
    if (index !== -1) {
      this.setState({
        requesting_Resources_List: toggleArrayItem(
          this.state.requesting_Resources_List,
          resource.id,
        ),
      });
    }
  };

  getSnapshotData = (snapshot, index) => {
    const { stack } = this.props;

    this.setStatusToLoading(snapshot);
    FetchAPI.Orchestration.Snapshots.show({
      stack,
      snapshot,
    })
      .then((res) => this.displayDetailedData(res.data, snapshot))
      .catch((err) =>
        this.displayResult(
          "error",
          err?.response?.data?.error?.description ||
            err?.response?.data?.error?.message ||
            "Fetching metadata failed!",
          index,
        ),
      );
  };

  displayDetailedData = (detailedData, resource) => {
    this.resetStatus(resource);
    this.setState({
      detailedData,
    });
    this.toggleModal();
  };

  toggleModal = () => this.setState({ modalStatus: !this.state.modalStatus });

  displayResult = (type, text, index) => {
    const { data } = this.state;

    if (type === "success") {
      toast.success(text);
    } else {
      toastError(text);
    }

    if (data) {
      data[index].__status__ = text;
      data[index].__type__ = type;

      this.setState({ data }, () => {
        setTimeout(() => {
          const { data } = this.state;
          data[index].__status__ = "";
          data[index].__type__ = "";
          this.setState({ data });
        }, 2500);
      });
    }
  };

  renderActions = (resource, index) => {
    const { stack } = this.props;

    if (
      this.state.requesting_Resources_List.includes(resource.id) ||
      resource.status.includes("delete") ||
      resource.status.includes("restore")
    ) {
      return (
        <div>
          <Icon loading name="spinner" className="margin-right" />
          Requesting...
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <CircularButton
            onClick={() => this.getSnapshotData(resource, index)}
            className={`button button--circular margin-right-half `}
            icon="code"
            popupContent="Request Detailed Data"
          />
          <CircularButton
            onClick={() =>
              this.props.confirmbox_open({
                entity: "stack snapshot",
                operation: "restore",
                resources: {
                  ...resource,
                  region: stack.region,
                  project_id: stack.project_id,
                  stack_name: stack.stack_name,
                  stack_id: stack.id,
                },
                onConfirm: restoreStack_snapshot,
              })
            }
            className={`button button--circular margin-right-half`}
            icon="undo"
            popupContent="Restore Snapshot"
          />
          <CircularButton
            onClick={() =>
              this.props.confirmbox_open({
                entity: "stack snapshot",
                operation: "delete",
                resources: {
                  ...resource,
                  region: stack.region,
                  project_id: stack.project_id,
                  stack_name: stack.stack_name,
                  stack_id: stack.id,
                },
                onConfirm: deleteStack_snapshot,
              })
            }
            className={`button button--circular margin-right-half button--circular__danger`}
            icon="trash"
            popupContent="Delete"
          />
        </React.Fragment>
      );
    }
  };

  renderRow = (resource, index) => {
    return {
      status: getIconForResource(resource.status),
      id: (
        <QuickView>
          <QuickView.Trigger>{resource.id}</QuickView.Trigger>
          <QuickView.Content>
            <QuickView.Item>{`ID: ${resource.id}`}</QuickView.Item>
            <QuickView.Copy copy={resource.id}>Copy ID</QuickView.Copy>
            <QuickView.Copy copy={resource.name}>Copy Name</QuickView.Copy>
          </QuickView.Content>
        </QuickView>
      ),
      name: (
        <QuickView>
          <QuickView.Trigger>{resource.name}</QuickView.Trigger>
          <QuickView.Content>
            <QuickView.Item>{`Name: ${resource.name}`}</QuickView.Item>
            <QuickView.Copy copy={resource.id}>Copy ID</QuickView.Copy>
            <QuickView.Copy copy={resource.name}>Copy Name</QuickView.Copy>
          </QuickView.Content>
        </QuickView>
      ),
      action: this.renderActions(resource, index),
    };
  };

  render() {
    const { stack, hasCRUDAccess } = this.props;
    const { detailedData, modalStatus } = this.state;
    const data = this.filterSnapshots(this.props.stacks_snapshots || {});

    const columns = createTableHeaderObject(
      "__Hidden__",
      filterActionColumn(defaultValues.orchestration.snapshots, hasCRUDAccess),
      ["", "", "padding-left", ""],
      ["width-20p", "", "", "width-20p"],
    );

    if (data === undefined) {
      return (
        <Tab.Pane>
          <div className="loader-wrapper">
            <Loader size="mini" active className="one-liner">
              Fetching resources...
            </Loader>
          </div>
        </Tab.Pane>
      );
    }

    if (data === null || !data?.length) {
      return (
        <Tab.Pane className="padding-top-30">
          <p className="margin-left">No snapshots found!</p>
          {hasCRUDAccess && (
            <button
              className="float-right button button--green"
              onClick={() => this.props.createStackSnapshot(stack)}
            >
              Create a Snapshot
            </button>
          )}
          <div className="clear"></div>
        </Tab.Pane>
      );
    }

    return (
      <Tab.Pane className="">
        <TableWrapper
          data={data.map((x, i) => this.renderRow(x, i))}
          columns={columns}
        />
        {hasCRUDAccess && (
          <button
            className=" float-right button button--green"
            onClick={() => this.props.createStackSnapshot(stack)}
          >
            Create a Snapshot
          </button>
        )}
        <div className="clear"></div>

        <Modal
          open={modalStatus}
          centered={true}
          onClose={() => this.toggleModal()}
        >
          <Header icon="file alternate outline" content={"Snapshot Data"} />
          <Modal.Content>
            <DetailedItem
              title="Environment"
              detailedData={detailedData?.data?.environment}
            />
            <DetailedItem
              title="Template"
              detailedData={detailedData?.data?.template}
            />
            <DetailedItem
              title="Resources"
              detailedData={detailedData?.data?.resources}
            />
          </Modal.Content>
          <Modal.Actions>
            <button
              className="float-left button button--bordered"
              onClick={() => this.toggleModal()}
            >
              <span>Back</span>
            </button>
          </Modal.Actions>
        </Modal>
      </Tab.Pane>
    );
  }
}

const mapStateToProps = (state) => ({
  stacks_snapshots: state.stacks_snapshots,
});

const mapDispatchToProps = (dispatch) => ({
  updateSnapshotsList: (list, stackId, old) =>
    dispatch(updateSnapshotsList(list, stackId, old)),

  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Snapshots);
