import React, { useMemo } from "react";
import { PageToolbar } from "../../components/PageToolbar";
import Breadcrumbs from "../../components/shared/breadcrumbs/Breadcrumbs";
import { Grid, Tab, Menu } from "semantic-ui-react";
import CustomerInfo from "./customer/CustomerInfo";
import Contact from "./contact/Contact";
import FancyHeader from "../shared/FancyHeader";
import { RootStateOrAny, connect } from "react-redux";
import { getPrivileges } from "../../app_shared_functions";
import DeleteAccount from "./deleteaccount/DeleteAccount";
import { accountSettingsProps } from "./contact/types";
import { TabItem } from "./types";
import { InsufficientPrivileges } from "../shared/privileges/InsufficientPrivileges";

const AccountSettings: React.FC<accountSettingsProps> = ({
  userlogin,
  app_settings,
}) => {
  const privileges = useMemo(
    () => ({
      account: getPrivileges(userlogin, "account"),
      deleteAccount: app_settings.showDeleteAccount,
    }),
    [userlogin, app_settings],
  );

  const tabPanes = useMemo(() => {
    let tabs: TabItem[] = [
      {
        title: "Customer Info",
        privilege: privileges.account,
        Component: CustomerInfo,
      },
      {
        title: "Contact",
        privilege: privileges.account,
        Component: Contact,
      },
      {
        title: "Delete Account",
        privilege: privileges.deleteAccount,
        Component: DeleteAccount,
        position: "right",
        className: "menu-item--danger margin-right",
      },
    ];

    return tabs
      .filter((tab) => tab.privilege)
      .map((tab, key) => ({
        menuItem: (
          <Menu.Item
            position={tab.position}
            key={key}
            className={tab.className || ""}
          >
            <span>{tab.title}</span>
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            {tab.privilege ? <tab.Component /> : <InsufficientPrivileges />}
          </Tab.Pane>
        ),
      }));
  }, [privileges]);

  const breadcrumbs = [
    {
      title: "Account Settings",
    },
  ];

  return (
    <React.Fragment>
      <PageToolbar>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </PageToolbar>

      <div className="page-content padding-left-00 padding-right-00">
        <FancyHeader title="Account Settings" />
        <Grid>
          <Grid.Row>
            <Grid.Column
              textAlign="left"
              width={16}
              className="flex vcenter padding-left-20 padding-right-20"
            >
              <Tab
                className="horizontal-tabs width-100p with-padding padding-top-20 horizontal-tabs--white"
                panes={tabPanes}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: RootStateOrAny) => ({
  userlogin: state.login?.userlogin,
  app_settings: state.connectivity.app_settings,
});

export default connect(mapStateToProps)(AccountSettings);
