import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { Grid, Ref, Select } from "semantic-ui-react";

import { createArrayofSequentialNumbers } from "../../../../shared-functions/array";

import {
  normalizeSelectBoxEvent,
  formFieldWarningClassName,
} from "../../../../shared-functions/form";

import {
  destructureCronTime,
  createCronTime,
} from "../../../../shared-functions/datetime";

import { defaultValues } from "../../../../app_constants";

import {
  toggleArrayItem,
  convertArrayToSelectOptions,
} from "../../../../app_shared_functions";

import MultiSelect from "../../../../components/shared/form/MultiSelect";
import CircularButton from "../../../../components/shared/circularbutton/CircularButton";

const Schedule = ({
  index,
  schedule,
  deleteSchedule,
  handleScheduleUpdate,
  updateErrorsList,
  formWarning,
}) => {
  const hours = convertArrayToSelectOptions(createArrayofSequentialNumbers(24));
  const minutes = convertArrayToSelectOptions(
    createArrayofSequentialNumbers(60),
  );

  const scheduleRef = useRef();

  const [days, start_hour, start_minute] = destructureCronTime(schedule.start);
  const [, end_hour, end_minute] = destructureCronTime(schedule.end);

  const update = (event) => {
    const name = event?.target?.name || event?.name;
    const value = event?.target?.value || event?.value;

    if (name === "days") {
      const newDays = toggleArrayItem(days, value);
      handleScheduleUpdate(index, {
        start: createCronTime(start_hour, start_minute, newDays),
        end: createCronTime(end_hour, end_minute, newDays),
      });
    }
    if (name === "hibernatehour") {
      handleScheduleUpdate(index, {
        start: createCronTime(value, start_minute, days),
        end: schedule.end,
      });
    }
    if (name === "hibernateminute") {
      handleScheduleUpdate(index, {
        start: createCronTime(start_hour, value, days),
        end: schedule.end,
      });
    }
    if (name === "wakeuphour") {
      handleScheduleUpdate(index, {
        start: schedule.start,
        end: createCronTime(value, end_minute, days),
      });
    }
    if (name === "wakeupminute") {
      handleScheduleUpdate(index, {
        start: schedule.start,
        end: createCronTime(end_hour, value, days),
      });
    }
  };

  useEffect(() => {
    if (!days?.length) {
      updateErrorsList(index, {
        message: "At least one day should be selected!",
        ref: scheduleRef,
      });
    } else {
      updateErrorsList(index, null);
    }
  }, [schedule, days?.length, index, updateErrorsList]);

  return (
    <Grid
      className={`${
        index
          ? "separator separator--simple margin-top-30 padding-top-20"
          : "padding-top-20"
      }`}
    >
      <Grid.Row>
        <Grid.Column textAlign="left" width={16} className="flex vcenter ">
          <Grid className="width-100p">
            <Ref innerRef={scheduleRef}>
              <MultiSelect
                title="Weekdays"
                className={`select-box full margin-left-15 margin-right-half ${formFieldWarningClassName(
                  formWarning,
                  scheduleRef,
                  scheduleRef,
                )}`}
                icon="chevron circle down"
                selectedValues={days}
                options={convertArrayToSelectOptions(defaultValues.days)}
                update={(e) => update({ name: "days", value: e })}
                placeholder="Select a day"
                wrapperClassName="margin-bottom"
              />
            </Ref>
          </Grid>
          <CircularButton
            className="button button--circular margin-left-half button--circular__danger align-self-top margin-top-half"
            onClick={() => deleteSchedule(index)}
            icon="trash"
            popupContent="Delete this schedule"
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column
          textAlign="left"
          width={8}
          className="flex vcenter margin-bottom-30"
        >
          <h5>Hibernate at</h5>
        </Grid.Column>
        <Grid.Column
          textAlign="left"
          width={8}
          className="flex vcenter margin-bottom-30"
        >
          <div className="ui dropdown select-box flex vcenter no-hover justify-evenly padding-top-00 padding-bottom-00 flex-1">
            <Select
              name="hibernatehour"
              className="select-box select-box--compact text-right"
              value={start_hour}
              search
              options={hours}
              onChange={(e, d) => update(normalizeSelectBoxEvent(e, d))}
            />
            <span className="padding-bottom-half">:</span>
            <Select
              name="hibernateminute"
              className="select-box select-box--compact"
              value={start_minute}
              search
              options={minutes}
              onChange={(e, d) => update(normalizeSelectBoxEvent(e, d))}
            />
          </div>
        </Grid.Column>
        <Grid.Column
          textAlign="left"
          width={8}
          className="flex vcenter margin-bottom"
        >
          <h5>Wake up at</h5>
        </Grid.Column>
        <Grid.Column
          textAlign="left"
          width={8}
          className="flex vcenter margin-bottom"
        >
          <div className="ui dropdown select-box flex vcenter width-100p no-hover justify-evenly padding-top-00 padding-bottom-00 ">
            <Select
              name="wakeuphour"
              className="select-box select-box--compact text-right"
              value={end_hour}
              search
              options={hours}
              onChange={(e, d) => update(normalizeSelectBoxEvent(e, d))}
            />
            <span className="padding-bottom-half">:</span>
            <Select
              name="wakeupminute"
              className="select-box select-box--compact"
              value={end_minute}
              search
              options={minutes}
              onChange={(e, d) => update(normalizeSelectBoxEvent(e, d))}
            />
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

Schedule.propTypes = {
  index: PropTypes.number.isRequired,
  schedule: PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
  }).isRequired,
  deleteSchedule: PropTypes.func.isRequired,
  handleScheduleUpdate: PropTypes.func.isRequired,
  updateErrorsList: PropTypes.func.isRequired,
  formWarning: PropTypes.bool.isRequired,
};

export default Schedule;
