import React, { useEffect } from "react";
import PropTypes from "prop-types";

import useErrorsManager from "../../../../custom-hooks/form/useErrorsManager";
import Expandable from "../../../../components/shared/Expandable";

import Worker from "./Worker";

import { Grid, Icon, Popup } from "semantic-ui-react";
import { removeItemFromArrayWithIndex } from "../../../../app_shared_functions";
import { generateSampleWorker } from "../../../helpers/workergroups";
import CircularButton from "../../../../components/shared/circularbutton/CircularButton";

const WorkersList = ({
  workers,
  machineTypes,
  machineImages,
  handleChange,
  setWorkerError,
  formWarning,
}) => {
  const { errorsList, updateErrorsList, deleteFromErrors } = useErrorsManager();

  const addWorker = () => {
    const newList = [
      ...workers,
      generateSampleWorker({ machineTypes, machineImages }),
    ];
    handleChange({ name: "workers", value: newList });
  };

  const deleteWorker = (index) => {
    const newList = removeItemFromArrayWithIndex(workers, index);
    handleChange({ name: "workers", value: newList });

    deleteFromErrors(index);
  };

  const handleWorkerUpdate = (updatedWorker, index) => {
    const newWorkers = workers.map((worker, i) => {
      if (i === index) {
        return updatedWorker;
      } else {
        return worker;
      }
    });
    handleChange({ name: "workers", value: newWorkers });
  };

  useEffect(() => {
    setWorkerError(errorsList.find((x) => x) || undefined);
  }, [errorsList, workers, setWorkerError]);

  useEffect(() => {
    if (!workers?.length) {
      handleChange({
        name: "workers",
        value: [generateSampleWorker({ machineTypes, machineImages })],
      });
    }
  }, [handleChange, machineImages, machineTypes, workers?.length]);

  return (
    <React.Fragment>
      <Expandable
        defaultOpened={true}
        title="Worker Groups"
        subtitle={` (${workers?.length} worker group${
          workers?.length > 1 ? "s" : ""
        } defined.)`}
        titlePopup={
          <Popup
            trigger={
              <Icon name="question circle" className="margin-left-half" />
            }
            content={
              <div className="padding-top-half padding-right-half padding-bottom-half padding-left-half">
                <p>
                  Worker groups within the Gardener shoot are used to run
                  containerized applications.
                </p>
                <p>
                  They also support network configurations and provide secure
                  isolation of in-session and out-of-session connectivity.
                </p>
              </div>
            }
          />
        }
        button={
          <CircularButton
            className="float-right button button--circular button--orange margin-left-00"
            onClick={addWorker}
            icon="plus"
            popupContent="Add one more worker group to this shoot"
          />
        }
      >
        {workers.map((worker, index) => (
          <Worker
            key={index}
            index={index}
            worker={worker}
            showDeleteButton={workers.length !== 1}
            machineTypes={machineTypes}
            machineImages={machineImages}
            deleteWorker={deleteWorker}
            handleWorkerUpdate={handleWorkerUpdate}
            updateErrorsList={updateErrorsList}
            formWarning={!!(errorsList[index] && formWarning)}
          />
        ))}
      </Expandable>

      <Grid.Row className="separator padding-bottom-00" />
    </React.Fragment>
  );
};

WorkersList.propTypes = {
  workers: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        imagename: PropTypes.string,
        imageversion: PropTypes.string,
        machinetype: PropTypes.string,
        maximum: PropTypes.string,
        minimum: PropTypes.string,
        maxsurge: PropTypes.string,
        name: PropTypes.string,
        volume: PropTypes.string,
      }),
    ]),
  ).isRequired,
  machineTypes: PropTypes.arrayOf(
    PropTypes.shape({
      cpu: PropTypes.string,
      gpu: PropTypes.string,
      memory: PropTypes.string,
      name: PropTypes.string,
      storage: PropTypes.object,
      usable: PropTypes.bool,
    }),
  ).isRequired,
  machineImages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      preview: PropTypes.bool.isRequired,
      version: PropTypes.string.isRequired,
    }),
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
  setWorkerError: PropTypes.func.isRequired,
  formWarning: PropTypes.bool.isRequired,
};

export default WorkersList;
