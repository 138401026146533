import { Icon, Popup } from "semantic-ui-react";
import { safeToLowerCase } from "../../../../shared-functions/string";

type DisasterRecoverStatusIconProps = {
  status?: string;
};
const DisasterRecoverStatusIcon = ({
  status,
}: DisasterRecoverStatusIconProps) => {
  if (safeToLowerCase(status) === "active") {
    return (
      <Popup
        trigger={<Icon name="check" color="green" />}
        content="Disaster Recover Service is enabled."
      />
    );
  }
  return (
    <Popup
      trigger={<Icon name="close" color="red" />}
      content="Disaster Recover Service is disabled."
    />
  );
};

export default DisasterRecoverStatusIcon;
