import React, { useMemo } from "react";

import { Tab, Menu } from "semantic-ui-react";

import { useDispatch, useSelector } from "react-redux";

import { checkUserCRUDAccess } from "../../../shared-functions/authenticate";

import { defaultValues } from "../../../app_constants";

import Charts from "./charts/Charts";
import ConsoleOutput from "./ConsoleOutput";
import Details from "./Details";
import DisasterRecovery from "./DisasterRecovery";
import Snapshots from "./snapshots/Snapshots";
import Addresses from "./addresses/Addresses";
import SecurityGroups from "./securitygroups/SecurityGroups";
import useSubscribe from "../../../custom-hooks/useSubscribe";
import {
  filterSecurityGroups,
  filterAttachedVolumes,
  filterSnapshots,
} from "../utils";

import LoadError from "../../../components/detailedviewmodal/bits/LoadError";
import Loading from "../../../components/detailedviewmodal/bits/Loading";

const MoreData = ({ server, setMoreDataHeight }) => {
  const dispatch = useDispatch();

  const memoizedItems = useMemo(
    () => [
      "SERVERS_LIST",
      "SERVER_SNAPSHOTS_LIST",
      "VOLUMES_LIST",
      "SECURITYGROUPS_LIST",
      "PORTS_LIST",
    ],
    [],
  );
  useSubscribe(memoizedItems);

  const privileges = useSelector((state) => state.login.userlogin.privileges);
  const hasCRUDAccess = checkUserCRUDAccess(privileges);

  const snapshots = useSelector((state) => state.server_snapshots);
  const filteredSnapshots = filterSnapshots(snapshots, server);

  const volumes = useSelector((state) => state.volumes);
  const attachedVolumes = filterAttachedVolumes(volumes, server);

  const ports = useSelector((state) => state.ports.PORTS_LIST);

  const securitygroups = useSelector(
    (state) => state.securitygroups.SECURITYGROUPS_LIST,
  );
  const filteredSecurityGroups = filterSecurityGroups(
    server,
    ports,
    securitygroups,
  );

  const servers = useSelector((state) => state.servers);

  const generatePanes = () => {
    // Map each tab to the respective component
    const components = {
      Details: Details,
      Addresses: Addresses,
      Snapshots: Snapshots,
      "Security Groups": SecurityGroups,
      "Disaster Recovery": DisasterRecovery,
      Charts: Charts,
      "Console Output": ConsoleOutput,
    };

    if (!hasCRUDAccess) {
      delete components["Charts"];
      delete components["Console Output"];
    }

    return defaultValues.server.tabs
      .filter((tab) => components[tab])
      .map((tab, i) => {
        const Component = components[tab];

        if (servers.SERVERS_LIST[server.id]) {
          server = servers.SERVERS_LIST[server.id];
        }

        let propsToSend = {};
        if (tab === "Snapshots") {
          propsToSend.server_snapshots = filteredSnapshots;
          propsToSend.hasCRUDAccess = hasCRUDAccess;
        } else if (tab === "Details") {
          propsToSend.attachedVolumes = attachedVolumes;
          propsToSend.security_groups = filteredSecurityGroups;
        } else if (tab === "Disaster Recovery") {
          propsToSend.dispatch = dispatch;
          propsToSend.hasCRUDAccess = hasCRUDAccess;
        }
        return {
          menuItem: (
            <Menu.Item key={i}>
              <span>{tab}</span>
            </Menu.Item>
          ),
          render: () =>
            React.createElement(Component, { server, ...propsToSend }, null),
        };
      });
  };

  if (servers.SERVERS_LIST[server.id] || server.domain_id) {
    return (
      <div
        onClick={(e) => e.stopPropagation()}
        className="more-data more-data--tabs"
      >
        <Tab
          className="horizontal-tabs padding-top-20 margin-bottom  scrollable-menu"
          panes={generatePanes()}
        />
      </div>
    );
  }
  if (servers.SERVERS_LIST_LOADING_ZONES_LEFT === 0) {
    return <LoadError type="Server" />;
  }
  return <Loading type="Server" />;
};

export default MoreData;
