import { useRef } from "react";
import SimpleTable from "../../../components/shared/SimpleTable";
import QuickView from "../../../components/shared/quickview/QuickView";
import { Grid, Ref } from "semantic-ui-react";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { useDispatch, useSelector } from "react-redux";
import useSubscribe from "../../../custom-hooks/useSubscribe";
import LoadError from "../../../components/detailedviewmodal/bits/LoadError";
import Loading from "../../../components/detailedviewmodal/bits/Loading";
const MoreData = ({ subnet }) => {
  const dispatch = useDispatch();

  const domElementRef = useRef();

  const subnets = useSelector((state) => state.subnets);
  useSubscribe("SUBNETS_LIST");

  const projects = useSelector((state) => state.projects);
  const currentProjectName =
    projects.currentProjectName || Object.keys(projects.list)[0];

  if (subnets.SUBNETS_LIST[subnet.id]) {
    subnet = subnets.SUBNETS_LIST[subnet.id];

    return (
      <Ref innerRef={domElementRef}>
        <div
          onClick={(e) => e.stopPropagation()}
          className="more-data more-data--padding"
        >
          <Grid className="columns-3">
            <Grid.Row stackable="true">
              <Grid.Column>
                <SimpleTable
                  className="striped-table"
                  content={[
                    ["Name :", subnet.name],
                    ["Created at :", subnet.created_at],
                    ["Updated at :", subnet.updated_at],
                    [
                      "Enable DHCP :",
                      subnet.enable_dhcp
                        ? `${subnet.enable_dhcp}`
                        : "Not Found",
                    ],
                    ["CIDR :", subnet.cidr],
                  ]}
                />
              </Grid.Column>
              <Grid.Column>
                <SimpleTable
                  className="striped-table"
                  content={[
                    [
                      "ID :",
                      <QuickView>
                        <QuickView.Trigger>{subnet.id}</QuickView.Trigger>
                        <QuickView.Content>
                          <QuickView.Item>{`Subnet ID: ${subnet.id}`}</QuickView.Item>
                          <QuickView.Modify
                            onClick={() =>
                              dispatch(
                                toggleSlidingMenu("modify", "Subnet", {
                                  id: subnet.id,
                                }),
                              )
                            }
                          >
                            Modify Subnet
                          </QuickView.Modify>
                          <QuickView.Copy copy={subnet.id}>
                            Copy ID
                          </QuickView.Copy>
                          <QuickView.Copy copy={subnet.name}>
                            Copy Name
                          </QuickView.Copy>
                        </QuickView.Content>
                      </QuickView>,
                    ],
                    [
                      "Project id :",
                      <QuickView>
                        <QuickView.Trigger>
                          {subnet.project_id}
                        </QuickView.Trigger>
                        <QuickView.Content>
                          <QuickView.Item>{`Project ID: ${subnet.project_id}`}</QuickView.Item>
                          <QuickView.Copy copy={subnet.project_id}>
                            Copy Project ID
                          </QuickView.Copy>
                          <QuickView.ViewIn
                            type="Project"
                            resource={{ id: currentProjectName }}
                          />
                        </QuickView.Content>
                      </QuickView>,
                    ],
                    [
                      "Network id :",
                      <QuickView>
                        <QuickView.Trigger>
                          {subnet.network_id}
                        </QuickView.Trigger>
                        <QuickView.Content>
                          <QuickView.Item>{`Network ID: ${subnet.network_id}`}</QuickView.Item>
                          <QuickView.Modify
                            onClick={() =>
                              dispatch(
                                toggleSlidingMenu("modify", "Network", {
                                  id: subnet.network_id,
                                }),
                              )
                            }
                          >
                            Modify Network
                          </QuickView.Modify>
                          <QuickView.Copy copy={subnet.network_id}>
                            Copy ID
                          </QuickView.Copy>
                          <QuickView.ViewIn
                            type="Network"
                            resource={{ id: subnet.network_id }}
                          />
                          <QuickView.Details
                            type="Network"
                            resource={{
                              id: subnet.network_id,
                              region: subnet.region,
                              project_id: subnet.project_id,
                            }}
                          />
                        </QuickView.Content>
                      </QuickView>,
                    ],
                    [
                      "Tenant id :",
                      <QuickView>
                        <QuickView.Trigger>
                          {subnet.tenant_id}
                        </QuickView.Trigger>
                        <QuickView.Content>
                          <QuickView.Item>{`Tenant ID: ${subnet.tenant_id}`}</QuickView.Item>
                          <QuickView.Copy copy={subnet.tenant_id}>
                            Copy ID
                          </QuickView.Copy>
                        </QuickView.Content>
                      </QuickView>,
                    ],
                    [
                      "DNS nameservers :",
                      <div className="padding-top-00 padding-bottom-00">
                        {subnet.dns_nameservers.length
                          ? subnet.dns_nameservers.map((x, i) => (
                              <div key={i}>{x}</div>
                            ))
                          : "none"}
                      </div>,
                    ],
                  ]}
                />
              </Grid.Column>
              <Grid.Column>
                <SimpleTable
                  className="striped-table  reverse-strip-order"
                  content={[
                    [
                      "Allocation Pools :",
                      <div className="padding-top-00 padding-bottom-00">
                        <div>start: {subnet.allocation_pools[0].start}</div>
                        <div>end: {subnet.allocation_pools[0].end}</div>
                      </div>,
                    ],

                    ["IP version :", subnet.ip_version],
                  ]}
                />
                <SimpleTable
                  className="striped-table  reverse-strip-order "
                  content={[
                    [
                      "IPV6 ra mode :",
                      subnet.ipv6_ra_mode
                        ? `${subnet.ipv6_ra_mode}`
                        : "Not Found",
                    ],
                  ]}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Ref>
    );
  }
  if (subnets.SUBNETS_LIST_LOADING_ZONES_LEFT === 0) {
    return (
      <Ref innerRef={domElementRef}>
        <LoadError type="Subnet" />
      </Ref>
    );
  }
  return (
    <Ref innerRef={domElementRef}>
      <Loading type="Subnet" />
    </Ref>
  );
};

export default MoreData;
