import axios from "axios";
import React from "react";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { testPassword, testUsername } from "./shared-functions/regex";

import { toast } from "react-toastify";
import { Icon, Popup } from "semantic-ui-react";
import {
  defaultValues,
  fileTypes,
  statusIcons,
  unsupportedFeatures,
} from "./app_constants";

import CircularProgress from "./components/shared/CircularProgress";

import { getSelectItemClassName } from "./shared-functions/string";
import { getRegionsByProjectNameFromMergedProjectsByNameAndId } from "./shared-functions/projects";

export const translation_if_exists = (t, transStr, fallback) => {
  return t(transStr) === transStr ? fallback : t(transStr);
};

/**
 * This will lightens a color by the amount specified ,
 * @param {*} col hex value #123456 or 123456
 * @param {*} amt numeric value can be positive to lighten or negative to darken
 */
export const lighten_color = (col, amt) => {
  var usePound = false;

  if (!col) {
    const letters = "0123456789ABCDEF";
    col = "#";
    for (var i = 0; i < 6; i++) {
      col += letters[Math.floor(Math.random() * 16)];
    }
  }

  if (col[0] === "#") {
    col = col.slice(1);
    usePound = true;
  }

  const R =
    parseInt(col.substring(0, 2), 16) + amt > 255
      ? 255
      : parseInt(col.substring(0, 2), 16) + amt < 0
      ? 0
      : parseInt(col.substring(0, 2), 16) + amt;

  const G =
    parseInt(col.substring(2, 4), 16) + amt > 255
      ? 255
      : parseInt(col.substring(2, 4), 16) + amt < 0
      ? 0
      : parseInt(col.substring(2, 4), 16) + amt;

  const B =
    parseInt(col.substring(4, 6), 16) + amt > 255
      ? 255
      : parseInt(col.substring(4, 6), 16) + amt < 0
      ? 0
      : parseInt(col.substring(4, 6), 16) + amt;

  return (
    (usePound ? "#" : "") +
    (R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16)) +
    (G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16)) +
    (B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16))
  );
};

/**
 *
 * @param {*} domains get the domain list (the list array of the domains object in the redux should be sent here)
 * @param {*} name the short name you want to get the name for (kna1 => Karlskrona / Sweden)
 */
export const getFullRegionName = (domains, name) => {
  let found = "";

  if (!Array.isArray(domains)) return found;

  domains.forEach((domain) => {
    domain.area.regions.forEach((reg) => {
      if (reg.region && name && reg.region.toLowerCase() === name.toLowerCase())
        found = reg.name;
    });
  });
  return found;
};

/**
 * gets the user selected regions and all the available regions,
 * and map all the available regions with 'unselected' flag if not found in the user regions
 * @param {[]} allRegions
 * @param {[]} selectedRegions
 * @returns {[]}
 */
export const markUnselectedRegions = (allRegions, selectedRegions) =>
  allRegions.map((region) => {
    if (
      selectedRegions.find(
        (selectedRegion) => selectedRegion.zone_id === region.id,
      )
    )
      return region;
    else return { ...region, unselected: true };
  });

/**
 * This function gets the "projects" in redux and returns list of provisoned regions (suspended or available) that is renderable for selectbox
 * @param {*} projects  projects object stored in redux (must include a list of projects atleast)
 * @returns [
 *  {"key":"4", "value":"Buf1" , "disabled": undefined, "text":"Buffalo, NY/US" , "className":"" , },
 *  {"key":"2", "value":"Sto2" , "disabled":true,       "text":"Stockholm/Sweden (Suspended)" , "className":""}
 * ]
 */
export const renderZonesForSelectBox = (projects, userDomains) => {
  const regions = getRegionsByProjectNameFromMergedProjectsByNameAndId(
    projects.currentProjectName,
    projects.list,
  );
  if (!userDomains) return convertRegionsToSelectBox(regions);

  const selectedRegions = getAllRegionsFromDomains(userDomains);

  const mappedRegions = markUnselectedRegions(regions, selectedRegions);

  return convertRegionsToSelectBox(mappedRegions);
};

export const convertRegionsToSelectBox = (regions) => {
  const isSelectable = (item) => !item.suspended && !item.unselected;

  const renderText = (item) =>
    item.suspended
      ? item.name.replace(/ \/ /g, "/") + " (Suspended)"
      : item.unselected
      ? item.name.replace(/ \/ /g, "/") + " (Not Selected)"
      : item.name.replace(/ \/ /g, "/");

  const isItalic = (item) => (isSelectable(item) ? "" : "italic");

  return (regions || [])
    .filter((item) => item.status === "active")
    .sort((a, b) => {
      if (
        (isSelectable(a) && isSelectable(b)) ||
        (!isSelectable(a) && !isSelectable(b))
      )
        return a.name < b.name ? -1 : 1;
      if (isSelectable(a))
        // b is not selectable
        return -1;
      return 1; // a is not selectable
    })
    .map((item) => ({
      key: item.id,
      value: item.tag,
      disabled: !isSelectable(item),
      text: renderText(item),
      className: `${getSelectItemClassName(renderText(item))} ${isItalic(
        item,
      )}`,
    }));
};

/**
 * This function returns the projectID associated with the region passed
 * @param {*} projects projects object stored in redux (must include a list of projects atleast)
 * @param {*} regionName    the name of region (kna1,sto2.....)
 */
export const getCurrentProjectID = (projects, regionName) => {
  const currentProjectName =
    projects.currentProjectName || Object.keys(projects.list)[0];
  if (currentProjectName === undefined) {
    return null;
  }
  const currentProject = projects.list[currentProjectName];

  let id;
  Object.keys(currentProject).forEach((proj) => {
    Object.values(currentProject[proj].regions).forEach((region) => {
      if (region.tag.toLowerCase() === regionName?.toLowerCase()) id = proj;
    });
  });
  return id;
};

/**
 * This function returns just spits out the country name for the region
 * Used for the flags basically
 * @param {*} area the area name like buf1,sto2......
 */
export const getCountryName = (area) => {
  const country = {
    kna: "se",
    sto: "se",
    lon: "gb",
    buf: "us",
    los: "us",
    fra: "de",
    tky: "jp",
    dx: "ae",
  };

  for (const key in country) {
    if (area.includes(key)) {
      return country[key];
    }
  }
  return "";
};

/**
 * A simple function that checks the input and return an array of domains (the domains.list) array in the redux object
 * @param {*} domains  can be the 'domains' object or 'domains.list' array  in redux
 * @returns the domains.list
 */
export const getDomainsListFromRedux = (domains) =>
  Array.isArray(domains) ? domains : domains.list;

/**
 * This function checks if a given key exists in a list. Usable for checking if a resource has finished loading
 * @param {*} list The list to search in, for example SERVER_LIST
 * @param {*} key The key to search for, for example a server ID
 */
export const keyExistsInList = (list, key) => {
  return list && Object.keys(list).length && list[key];
};

/**
 * Filtering out those items that don't belong to this project
 * almost always the result is the same as input
 * Works as a secondary filter, in case redux list is not cleared after project change
 * or clicked on an item while redux is changing
 * @param {*} resources  the list of resources items like a server list
 * @param {*} currentProject the current project to filter resources on
 */
export const getCurrentProjectResources = (resources, currentProject) => {
  return Object.keys(resources).reduce((acc, item) => {
    if (Object.keys(currentProject).indexOf(resources[item]?.project_id) > -1)
      acc[item] = resources[item];
    return acc;
  }, {});
};

/**
 * Filtering out those items that don't belong to selected domains
 * This is where the user selected domains is filtering resources
 * @param {*} resources  the list of resources items like a server list [an array]
 * @param {*} inputDomains the current selected domains (if nothing selected, this will include the list of domains)
 */
export const getCurrentDomainResources = (resources, inputDomains) => {
  let domains = getDomainsListFromRedux(inputDomains);

  if (domains.length === 0) return resources;

  const domainsList = domains.reduce((acc, domain) => {
    const regs = domain.area.regions.map((reg) => reg.region.toLowerCase());
    acc = [...acc, ...regs];
    return acc;
  }, []);

  return Object.values(resources)
    .filter((res) => domainsList.includes(res.region))
    .reduce((acc, res) => (acc = { ...acc, [res.id]: res }), {});
};

/**
 * safely access deeply nested values
 * @param  {Object} obj
 * @param  {String} param - the nested path, seperated using . (dot)
 *
 * returns => value or null
 *
 * example :
 * let obj={first_level:{second_level:{third_level: 'value'}}}
 * getNestedValue(obj,"first_level.second_level.third_level") ==> 'value'
 * getNestedValue(obj,"first_level.second_level.some_other_value") ==> null
 */
export const getNestedValue = (obj, param) => {
  param = param.split(".");
  return param.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), obj);
};

/**
 * This function filters a list of resources based on eneterd text by user
 * @param {*} list  The list to do the filtering on, for example SERVER_LIST
 * @param {*} input The filtering text
 * @param {*} caseSensitive Set to true for making a caseSensitive search. Default is false = case insensitive.
 */
export const filterResourcesWithText = (list, input, caseSensitive) => {
  if (Object.keys(list).length < 1 || !input) return list;

  if (caseSensitive !== true) {
    caseSensitive = false;
    input = input.toLowerCase();
  }

  const newList = Object.keys(list).map((x) => list[x]);

  return newList
    .filter((item) => {
      const hasMatchingValue = getAllValues(item).find(
        (x) =>
          x &&
          (caseSensitive
            ? String(x).includes(input)
            : String(x).toLowerCase().includes(input)),
      );
      const hasmatchingKey = getAllKeys(item).find(
        (x) =>
          x &&
          (caseSensitive
            ? String(x).includes(input.replace(/ /g, "_"))
            : String(x).toLowerCase().includes(input.replace(/ /g, "_"))),
      );
      return hasMatchingValue || hasmatchingKey;
    })
    .reduce((acc, val) => (acc = { ...acc, [val.id]: val }), {});
};

/**
 * This function compares an existing flat array against another flat array  and returns the entries that are missing in the existing array
 * @param {*} existingArray  A flat array
 * @param {*} compareArray A flat array
 */
export const checkMissingArrayEntries = (existingArray, compareArray) => {
  return compareArray.filter((x) => existingArray.indexOf(x) === -1);
};

function getAllKeys(obj) {
  let all = {};
  let seen = [];
  checkValue(obj);
  return Object.keys(all);

  function checkValue(value) {
    if (Array.isArray(value)) return checkArray(value);
    if (value instanceof Object) return checkObject(value);
  }
  function checkArray(array) {
    if (seen.indexOf(array) >= 0) return;
    seen.push(array);
    for (var i = 0, l = array.length; i < l; i++) {
      checkValue(array[i]);
    }
  }
  function checkObject(obj) {
    if (seen.indexOf(obj) >= 0) return;
    seen.push(obj);
    var keys = Object.keys(obj);
    for (var i = 0, l = keys.length; i < l; i++) {
      var key = keys[i];
      all[key] = true;
      checkValue(obj[key]);
    }
  }
}

function getAllValues(obj) {
  if (!obj) return [];
  const vals = Object.values(obj).reduce((values, x) => {
    if (Array.isArray(x)) values = [...values, ...getAllValues(x)];
    else if (typeof x === "object") values = [...values, ...getAllValues(x)];
    else values = [...values, x];

    return values;
  }, []);
  return [...new Set(vals)];
}

/* sends the current url and receive what is the redux object related to that url */
export const getCurrentPageComponent = (url) => {
  switch (url) {
    case "/dashboard":
      return "";

    case "/compute/servers":
      return "SERVERS_LIST";

    case "/compute/keypairs":
      return "KEYPAIRS_LIST";

    case "/networking/networks":
      return "NETWORKS_LIST";

    case "/networking/routers":
      return "ROUTERS_LIST";

    case "/networking/floatingip":
      return "FLOATINGIPS_LIST";

    case "/networking/subnets":
      return "SUBNETS_LIST";

    case "/networking/ports":
      return "PORTS_LIST";

    case "/networking/vpn":
      return "VPNS_LIST";

    case "/networking/vpn/ike":
      return "IKES_LIST";

    case "/networking/vpn/ipsec":
      return "IPSECS_LIST";

    case "/networking/vpn/endpointgroup":
      return "ENDPOINTGROUPS_LIST";

    case "/storage/volumes":
      return "VOLUMES_LIST";

    case "/storage/objectstorage":
      return "";

    case "/securitygroups":
      return "SECURITYGROUPS_LIST";

    case "/monitor":
      return "";

    case "/orchestration":
      return "";

    case "/containers/magnum/clusters":
      return "CLUSTERS_LIST";

    case "/containers/magnum/templates":
      return "CLUSTER_TEMPLATES_LIST";

    case "/logs":
      return "";

    case "/users/openstack":
      return "OPENSTACK_USERS_LIST";

    case "/users/cleuracloud":
      return "CLEURA_USERS_LIST";

    case "/support":
      return "";

    case "settings/manageprojects":
      return "";

    case "settings/managedomains":
      return "";

    default:
      return "";
  }
};

/**
 * This is used for providing one or two toasts on mulitple actions like starting 10 servers
 * @param {*} responses         an array of responses each is an object with the id of the resource and the status: resolved, rejected
 *                              response for errors   would be : {status : "rejected" , id : server.id, desc : "error desc"}
 *                                   and for resolves would be : {status : "resolved" , id : server.id }
 * @param {*} resource_name     the name of the resource (in this case: server)
 * @param {*} action            the action to be performed on the resource (in this case: start)
 * @param {*} dispatch          redux dispatch function
 */
export const toastMultipleResults = ({
  responses,
  resource_name,
  action,
  dispatch,
}) => {
  const resolvedRequests = responses.filter((res) => res.status === "resolved");
  const rejectedRequests = responses.filter((res) => res.status === "rejected");

  if (resource_name === "user" && action === "modify") {
    //    toast.success (`Modifying user started...`);
  } else if (resolvedRequests.length > 0) {
    let data = action.slice(0, 1).toUpperCase();
    data += action.endsWith("e") ? action.slice(1, -1) : action.slice(1);
    data += action === "stop" ? "ping " : "ing ";
    data += resolvedRequests.length + " ";
    data += resource_name;
    data += resolvedRequests.length > 1 ? "s" : "";
    data += "...";
    toast.success(data);
  }

  if (rejectedRequests.length > 1) {
    toast.error(
      <div>
        {`Failed to ${action} ${rejectedRequests.length} ${resource_name}s.`}
        <br />
        {rejectedRequests[0].id ? (
          <button
            className="button button--white margin-left--auto margin-right--auto margin-top-10 margin-bottom-10"
            onClick={() =>
              dispatch({
                type: "ERROR_NOTIFICATION_OPEN",
                payload: rejectedRequests,
              })
            }
          >
            View Error Details.
          </button>
        ) : null}
      </div>,
    );
  }

  if (rejectedRequests.length === 1) {
    toast.error(
      <div>
        {`Failed to ${action} 1 ${resource_name}.`}
        <br />
        {rejectedRequests[0].desc}
      </div>,
    );
  }
};

/**
 * Just Uppercases the first letter of a string:
 * server => Server
 * security group => Security group
 * @param {*} str string
 */
export const capitalize = (str) =>
  str ? str.slice(0, 1).toUpperCase() + str.slice(1) : "";

export const isResourceCreating = (st) => {
  const currentStatus = (st || "").toLowerCase();
  if (!currentStatus) {
    return false;
  }

  return !!statusIcons
    ?.find((x) => x.resource_state === "creating")
    ?.status?.includes(currentStatus);
};

/**
 * Determines whether the resource with the id: 'id' and of type 'type' is being modified or not
 * @param {Array}   layers  the redux array : should be the state.sidebar.layers
 * @param {String}  type    the resource type (e.g. 'Server', 'Volume', ...)
 * @param {String}  id      the resource id
 */
export const isResourceModifying = ({ layers, type, id }) => {
  return !!layers.find((layer) => {
    if (Array.isArray(type)) {
      if (
        type.indexOf(layer.component_type) > -1 &&
        layer.component_data &&
        (layer.component_data.id === id || layer.component_data === id)
      ) {
        return true;
      }
    } else if (
      layer.component_type === type &&
      layer.component_data &&
      (layer.component_data.id === id || layer.component_data === id)
    ) {
      return true;
    }

    return (
      typeof layer.component_data === "string" && layer.component_data === id
    );
  });
};

/**
 * Determines whether the resource with the id: 'id' is available after loading all the zones
 * Used in the modify components and is useful when a resource is gonna be modifying before the list is loaded
 * like modifying a 'server' from the 'ports list'
 * @param {Array}   list  the redux list of the resource. it should include the resource_LIST and resource_LOADING_ZONES
 * @param {String}  id    the resource id (e.g. 'Server', 'Volume', ...)
 * @param {String}  name  the resource name (e.g server,volume.....)
 */
export const isResourceUnAvailable = ({ list, id, name }) => {
  return (
    !list[`${name.toUpperCase()}S_LIST`][id] &&
    list[`${name.toUpperCase()}S_LIST_LOADING_ZONES_LEFT`] === 0
  );
};

/**
 * Gets the list of projects (should be the redux value)
 * and returns list of active domains based on those projects
 * @param {*} projects
 * @param {*} currentProjectName
 */
export const getActiveZones = (projects, currentProjectName) => {
  return projects && currentProjectName && projects[currentProjectName]
    ? Object.values(projects[currentProjectName]).reduce((acc, item) => {
        return [...acc, ...Object.values(item.regions)];
      }, [])
    : [];
};

export const getProvisionedDomains = (domains) => {
  return domains.filter((domain) => domain.status === "provisioned");
};

export const get_projects_DomainIds_List = (projects) => {
  return Object.keys(projects)
    .map((name) => {
      return {
        [name]: Object.values(projects[name])
          .map((prj) => Object.keys(prj.domains))
          .reduce((acc, item) => [...acc, ...item], []),
      };
    })
    .reduce((acc, item) => ({ ...acc, ...item }), {});
};

/**
 * Gets the list of domains (should be the redux domains.list) which is an array
 * and returns list of zone_ids
 * the return will be something like :
 * [{'kna':'2'} , {'fra':'5'} , ....]
 * @param {*} domains
 */
export const get_ZoneIDs_From_Domains = (domains) => {
  return domains.reduce(
    (acc, domain) => [
      ...acc,
      ...domain.area.regions.map((x) => ({
        region: x.region.toLowerCase(),
        zone_id: x.zone_id,
      })),
    ],
    [],
  );
};

/**
 * Deep compare two objects or arrays
 * returns true if both values are the same
 */
export const deep_Compare = (value, other) => {
  // Get the value type
  const type = Object.prototype.toString.call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false;

  // If items are not an object or array, return false
  if (["[object Array]", "[object Object]"].indexOf(type) < 0) return false;

  // Compare the length of the length of the two items
  const valueLen =
    type === "[object Array]" ? value.length : Object.keys(value).length;
  const otherLen =
    type === "[object Array]" ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  // Compare two items
  const compare = function (item1, item2) {
    // Get the object type
    const itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (["[object Array]", "[object Object]"].indexOf(itemType) >= 0) {
      if (!deep_Compare(item1, item2)) return false;
    }

    // Otherwise, do a simple comparison
    else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false;

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === "[object Function]") {
        if (item1.toString() !== item2.toString()) return false;
      } else {
        if (item1 !== item2) return false;
      }
    }
  };

  // Compare properties
  if (type === "[object Array]") {
    for (let i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (let key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }

  // If nothing failed, return true
  return true;
};

/**
 * This function creates the react-table header
 * the output is an object like this
 * [{Header: 'Security Group Rules',
 *   columns: [{ Header: 'Status', accessor: 'status', sortType: 'basic'}]
 * }]
 * @param {*} title             title
 * @param {*} columns           array of strings to be transformed to objects
 * @param {*} classNames        array of strings for columns classnames (the same size as columns). It will be ignored if nort set
 * @param {*} widths            array of strings for columns widths . It will be ignored if nort set
 *                              ['width-50p','',''] will make the first column 50percent
 */
export const createTableHeaderObject = (title, columns, classNames, widths) => {
  // react-table always expects a header,
  // in order to not-show the table header,
  // we shall pass '__Hidden__' for title
  // and hack it using css
  return [
    {
      Header: title,
      columns: columns.map((x, i) => ({
        Header: x,
        accessor: x.replace(/[ /]/g, "_").toLowerCase(),
        sortType: "basic",

        // set classname for column if provided
        ...(classNames ? { className: classNames[i] } : []),

        // set width for column if provided
        ...(widths?.[i] ? { width: widths?.[i] } : []),
      })),
    },
  ];
};

/**
 * This function checks if the resource is valid or not
 * A valid resource is an object (not null), with id, project_id and region properties set to not null
 * if resource seems valid , just return null,
 * otherwise send back a string
 * @param {*} resource         the redource to be checked
 * @param {*} type              name of the resource e.g : volume,snapshot,server....
 */
export const checkResourceProperties = (resource, type) => {
  if (!resource) return `Invalid ${type}.`;

  if (!resource.id) return `Invalid ${type}. Missing 'id' property.`;

  if (!resource.region && type !== "openstack user")
    return `Invalid ${type}. Missing 'region' property.`;

  if (!resource.project_id && type !== "openstack user")
    return `Invalid ${type}. Missing 'project_id' property.`;

  return null;
};

/**
 * This function will debounce the api calls
 * useful for 'region select boxes' where we need to call an api based on currently
 * selected region
 * @param {*} url               the url that is sent
 * @param {*} method            axios method (get,post,put....), if none is sent, defaults to 'get'
 * @param {*} data              data to be send
 */
export const debounced_API_Call = AwesomeDebouncePromise(
  (url, method, data) =>
    axios({
      method: method || "get",
      url,
      data: data,
    }),
  200,
);

/**
 * This function is used to scroll to the form-item that is not set by user but is required
 * if the form is of type input, we will focus to it as well
 * @param {*} item                  the html element we need to scroll to.
 * @param {Boolean} scrollWindow    shall we scroll the window to the item, or scroll an element to get to the item
 *                                  * default is false
 *                                  * send true to scroll the window e.g. CustomizedRow in SortableLists
 */
export const handleScrollToItem = (item, scrollWindow) => {
  if (item) {
    if (item.type === "textarea") {
      item.focus();
    } else if (typeof item.scrollIntoView === "function") {
      item.scrollIntoView({ block: "center", behavior: "smooth" });
    } else if (item.inputRef) {
      // inputRef used in class components
      if (item.inputRef === "nameRef") {
        item.inputRef.scrollIntoView({ block: "end", behavior: "smooth" });
      } else {
        item.inputRef.scrollIntoView({ block: "center", behavior: "smooth" });
      }
    } else if (item.current) {
      if (scrollWindow) {
        // if we shall scroll the window to the item,
        // this happens when we want to scroll to a row in a resource list
        let y =
          item.current.getBoundingClientRect().top + window.pageYOffset - 250;
        if (y < 250) y = 20;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
      item.current?.focus?.();
      if (typeof item?.current?.firstChild?.focus === "function") {
        item.current.firstChild.focus();
      }
    }
  }
};

/**
 * This function provide a string of css-classes for form item
 * useful for when a field is required but not set by  user
 * @param {*} requiredField             is either an object or null, object means form has an error, null means no error
 * @param {*} invalidForm               will be activated when create button is clicked
 * @param {*} thisField_Ref             ref used for this form item
 * @param {*} shakingStatus             if this item is already 'shaking' (has the 'shake' class)
 * @param {*} thisField_Class           the css class to be included if this is the form item that we need to scroll to
 */
export const get_FormItem_ClassName = (
  requiredField,
  invalidForm,
  thisField_Ref,
  shakingStatus,
  thisField_Class,
) => {
  return `select-box full ${
    requiredField && invalidForm && requiredField.ref === thisField_Ref
      ? `${thisField_Class} ${shakingStatus ? "shake" : ""}`
      : ""
  }`;
};

/**
 * This function provide a string of css-classes for form row,
 * similar to the get_FormItem_ClassName function but only fot a row not a form item
 * useful for when a row is required but not set by  user
 * @param {*} form_status               is the status of the form, including ref to the error row and the error message (if any)
 * @param {*} rowRef                    the ref assigned to the row that we want to check for errors
 *                                      this will be comapred with the ref that has error to see if we are showing error on the row with error or not
 * @param {*} invalidForm               will be activated when create button is clicked
 * @param {*} shake                     if this item is already 'shaking' (has the 'shake' class)
 */
export const get_FormRow_ClassName = (
  form_status,
  rowRef,
  invalidForm,
  shake,
) => {
  let returnValue = "";
  if (form_status?.ref === rowRef && invalidForm) {
    returnValue += " error-form-group";
    if (shake) {
      returnValue += " shake";
    }
  }
  return returnValue;
};

/**
 * This function coverts an array of strings to an array of objects
 * This is basically used on 'Select' or 'Dropdown' components that require an array of objects
 * @param {*} arr                       an array of strings
 */
export const convertArrayToObject = (arr) => {
  return arr.map((item) => ({
    key: item,
    value: item,
    text: item,
  }));
};

/**
 * This function converts an array of strings to objects
 * where each item is value : count of the value in array
 * ["A","B","A"]  =>  {"A" : 2 , "B" : 1}}
 * @param {*} arr                       an array of strings
 */
export const convertArrayTo_Objects_With_Count = (arr) => {
  return arr
    .reduce((acc, v) => {
      const i = acc.findIndex((s) => s[v]);
      if (i === -1) {
        acc = [...acc, { [v]: 1 }];
      } else {
        acc[i] = { [v]: acc[i][v] + 1 };
      }
      return acc;
    }, [])
    .reduce((acc, v) => {
      acc = {
        ...acc,
        [Object.keys(v)[0]]: Object.values(v)[0],
      };
      return acc;
    }, {});
};

/**
 * This function coverts an array of strings to an array of objects used for select boxes
 * @param {*} arr                       an array of strings
 */
export const convertArrayToSelectOptions = (
  arr,
  prependPhrase = "",
  appendPhrase = "",
) => {
  return Array.isArray(arr)
    ? arr.map((item) => {
        const val = `${prependPhrase || ""}${item}${appendPhrase || ""}`;
        return {
          key: item,
          value: item,
          text: val,
          className: getSelectItemClassName(val),
        };
      })
    : [];
};

/**
 * This function coverts an array of objects to dropdown list items
 * basically maps to id and name
 * @param {*} arr                       an array of objects like openstack networks
 */
export const convertArrayToDropDownList = (arr) => {
  return Array.isArray(arr)
    ? arr.map((item) => {
        return {
          key: item.id,
          value: item.id,
          text: item.name,
          className: getSelectItemClassName(item.name),
        };
      })
    : [];
};

/**
 * This function returns list of regions that are inside the list of domains that are sent as input
 * No matter a domain or region is active or not, this function simply reduce the region array based on complicated domain array
 * @param {*} domains                    array of domains
 */
export const getAllRegionsFromDomains = (domains) => {
  return domains.reduce(
    (acc, domain) => [
      ...acc,
      ...(domain.area.regions.length
        ? domain.area.regions.map((region) => ({
            ...region,
            domain_status: domain.status,
          }))
        : // when no region can be found in domain list
          // this can happen when domain is available but has error
          // we just send domain data with empty region which will be picked up in render
          [
            {
              domain_status: domain.status,
              name: domain.area.name,
              region: null,
              status: "active",
              zone_id: null,
            },
          ]),
    ],
    [],
  );
};

/**
 * This function returns the name of the project based on the given projetc_id and projectslist object
 * @param {*} id            the project_id which we need to get the name
 * @param {*} list          the list of projects , projects.list object from redux
 */
export const getProjectName_from_ProjectList = (list, id) => {
  const found = Object.keys(list).find(
    (proj) => Object.keys(list[proj]).indexOf(id) !== -1,
  );

  return found || "Not Found";
};

/**
 * This function returns the 'name of the domain' based on the given 'domain_id' and 'domains list array'
 * @param {*} id            the domain_id which we need to get the name
 * @param {*} list          the list of domains , domains.list array from redux
 */
export const getDomainName_from_DomainList = (list, id) => {
  const found = list.find((domain) => domain.id && domain.id === id);

  return found?.area?.name || "Not Found";
};

/**
 * This function returns network IP family.
 * Each network can consist of several subnets, which can be either IPv4 or IPv6, but usually its one
 * version family per network.
 * We consider network a IPv6 network if at least one of its subnets is IPv6.
 * @param {*} network network object returned from API consisting of subnets list.
 * @returns {number} network IP family.
 */
export const getNetworkIpVersion = (network) => {
  return network?.subnets?.some((s) => s.ip_version === 6) ? 6 : 4;
};

export const hasOnlyIPv6Subnet = (network) => {
  return network?.subnets?.every((s) => s.ip_version === 6);
};

/**
 * This function checks for username,password and confirm password on a modify/create form in the app
 * the password is checked against 8-1024 characteres and username for 3-40 characters
 * NOTE that this is for users created in openstack user and cleura cloud user
 * NOT for the the user of this app when signs up
 * @param {*} username,         the username to be checked (it will be ignored if is falsy or require_username is false)
 * @param {*} require_username, check username if this is true
 * @param {*} password,         the password to be checked (it will be ignored if is falsy or require_passowrd is false)
 * @param {*} confirm,          the confirm password to be checked (it will be ignored if is falsy or confirm password is false)
 * @param {*} require_password, wheter to check the password and confirm password against the regex
 * @param {*} t                 the translation funtion
 */
export const testUsernameAndPassword = ({
  username,
  require_username,
  password,
  confirm,
  require_password,
  t,
}) => {
  if (require_username && !testUsername(username)) {
    return {
      text: t("messages.validation.username"),
      ref: "usernameRef",
    };
  }

  if (require_password || password || confirm) {
    if (!testPassword(password)) {
      return {
        text: t("messages.validation.password"),
        ref: "passwordRef",
      };
    }
    if (!testPassword(confirm)) {
      return {
        text: t("messages.validation.confirm"),
        ref: "confirmPasswordRef",
      };
    }
  }

  if (password !== confirm) {
    return {
      text: t("messages.validation.same_pass"),
      ref: "confirmPasswordRef",
    };
  }

  return null;
};

export const toastError = (errorObject, fallback) => {
  if (typeof errorObject === "string") {
    return toast.error(errorObject);
  }

  return toast.error(
    errorObject?.response?.data?.error?.description ||
      errorObject?.response?.data?.error?.message ||
      fallback,
  );
};

/**
 * This function returns a popup (including text and icon) for the current domain
 * @param {*} domain                the domain object that is being checked here
 * @param {*} openstackReq          this is the list of domains that are procesing now
 *                                  (redux : accountservice / LIST / openstack / requests)
 */
export const getDomainStatusIcon = (domain, openstackReq) => {
  const processingDomains = Object.keys(openstackReq || {}).map((x) => ({
    [x]: openstackReq[x],
  }));

  if (domain.suspended) {
    return (
      <Popup trigger={<Icon name="minus circle" size="large" color="red" />}>
        <span>Domain is currently suspended. </span>
        <p>Please contact the support team. </p>
      </Popup>
    );
  } else if (domain.status === "provisioned") {
    return (
      <Popup trigger={<Icon name="check" size="large" color="green" />}>
        Provisioned
      </Popup>
    );
  } else if (
    domain.status === "inactive" ||
    domain.error ||
    !domain.area.regions.length ||
    domain.area.regions.find((region) => region.status === "inactive")
  ) {
    return (
      <Popup trigger={<Icon name="times" size="large" color="red" />}>
        Domain is currently not active. Please try again in a while
      </Popup>
    );
  } else if (
    domain.status === "available" &&
    !processingDomains.map((x) => Object.keys(x)[0]).includes(domain.area.tag)
  ) {
    return null;
  } else if (
    processingDomains.map((x) => Object.keys(x)[0]).includes(domain.area.tag)
  ) {
    const d = processingDomains.find((x) => x[domain.area.tag])[
      domain.area.tag
    ];
    switch (d) {
      case "processing":
        return (
          <Popup trigger={<Icon name="cog" size="large" color="grey" />}>
            Processing
          </Popup>
        );

      case "complete":
        return (
          <Popup trigger={<Icon name="cog" size="large" color="grey" />}>
            All done, domain has been provisioned
          </Popup>
        );

      case "manual_approval":
        return (
          <Popup trigger={<Icon name="clock" size="large" color="grey" />}>
            <span>Your request is awaiting approval</span>
            <p>
              Admins will review your request and service will be available as
              soon as your request has been approved
            </p>
          </Popup>
        );

      case "manually_approved":
        return (
          <Popup trigger={<Icon name="cog" size="large" color="grey" />}>
            The request is approved manually and it will soon be provisioned.
          </Popup>
        );

      case "rejected":
        return (
          <Popup
            trigger={<Icon name="times circle" size="large" color="red" />}
          >
            The request has been rejected by admin.
          </Popup>
        );

      case "failed":
        return (
          <Popup trigger={<Icon name="times" size="large" color="red" />}>
            The request provisioning failed.
          </Popup>
        );

      case "init":
        return (
          <Popup
            trigger={<Icon name="warning circle" size="large" color="grey" />}
          >
            The request will be processed shortly.
          </Popup>
        );

      default:
        return (
          <Popup trigger={<Icon name="cog" size="large" color="grey" />}>
            available
          </Popup>
        );
    }
  }

  return null;
};

/**
 * This function checks whether it's possible to load more resources (in the listing)
 * (A closed region is a region that user has clicked on it)
 * @param {*} resources             the object that contain resources, got from redux
 * @param {*} currentPage           number of virtual currentpage
 * @param {*} closedRegions         the array of closed regions, got from redux
 */
export const canLoadMore = (resources, currentPage, closedRegions = []) => {
  if (!resources || !currentPage || !Array.isArray(closedRegions)) {
    return false;
  }

  const closedRegionResourceCount = closedRegions
    ? closedRegions.reduce((acc, region) => {
        acc += Object.values(resources).filter(
          (x) => x?.region === region,
        ).length;
        return acc;
      }, 0)
    : 0;

  return (
    Object.keys(resources).length >
    currentPage * defaultValues.page_size + closedRegionResourceCount
  );
};

export const getPrivileges = (login, item) => {
  const privileges = login?.userlogin?.privileges ?? login?.privileges;
  return privileges?.[item]?.type || "";
};

export const getHostOrigin = () => window.location.origin;
export const getPathname = () => window.location.pathname;

export const getUrlParameters = (inputUrl) => {
  const url = inputUrl || window.location.search;
  if (!url) {
    return {};
  }
  const query = url.substr(1);
  let result = {};
  query.split("&").forEach((part) => {
    const item = part.split("=");
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
};

export const getUserAgent = () => navigator.userAgent;

/**
 * We need to check if the component really needs a re-render or not
 * We check if any of the vital properties like id,region,status,... is changed in props
 * or if the state is changed
 * @param {*} nextProps
 * @param {*} nextState
 * @param {*} oldProps
 * @param {*} oldState
 */
export const shouldComponentRerender = (
  nextProps,
  nextState,
  oldProps,
  oldState,
) => {
  // If index is changed
  if (oldProps.index === oldProps.openedIndex) {
    return true;
  }
  // If index is changed
  if (nextProps.index === nextProps.openedIndex) {
    return true;
  }

  // If id is changed (sort happened)
  if (oldProps.resource?.id !== nextProps.resource?.id) {
    return true;
  }

  // If region is changed (sort happened)
  if (oldProps.resource?.region !== nextProps.resource?.region) {
    return true;
  }

  // Check if disaster recovery service is changed
  // Obviously it will only be checked if the resource is a server
  if (
    oldProps.resource?.disasterRecoverService !== undefined &&
    oldProps.resource?.disasterRecoverService !==
      nextProps.resource?.disasterRecoverService
  ) {
    return true;
  }

  // If selection array is changed (checkbox is clicked)
  if (
    oldProps.selectedResources?.length !== nextProps.selectedResources?.length
  ) {
    return true;
  }

  // If selection array is changed (checkbox is clicked)
  if (
    oldProps.selectedResources &&
    oldProps.selectedResources.length !== nextProps.selectedResources.length &&
    oldProps.selectedResources.includes(oldProps.resource?.id) &&
    !nextProps.selectedResources.includes(nextProps.resource?.id)
  ) {
    return true;
  }

  // If detailed view is triggered
  if (oldProps.viewmore !== nextProps.viewmore) {
    return true;
  }

  // If status is changed, reboot/stop/start/create....
  if (oldProps.statusText !== nextProps.statusText) {
    return true;
  }

  // If task state is changed
  if (oldProps.resource?.task_state !== nextProps.resource?.task_state) {
    return true;
  }

  // If visibility changed (on scroll down and load more)
  if (oldProps.isVisible !== nextProps.isVisible) {
    return true;
  }

  // If sidebar layers is added, deleted
  if (
    oldProps.sidebar?.layers &&
    oldProps.sidebar?.layers?.length !== nextProps.sidebar?.layers?.length
  ) {
    return true;
  }

  // If state is changed
  return oldState !== nextState;
};

/**
 * Calculating License price for servers,
 * can also be used for loadbalancers if the calculation formula is the same
 * @param {*} cores     :   number of cores that the server has
 * @param {*} license   :   the license object for the image used for the server
 *                          has two type : offline or online
 */
export const calculateLicensePrice = (cores, license, isOn) => {
  const license_cost = {};

  if (!cores || !license || !isOn)
    return {
      cost: 0,
      details: [],
    };

  if (license && Array.isArray(license)) {
    license_cost.cost = 0;
    license_cost.details = [];

    for (var i = 0; i < license.length; i++) {
      if (license[i].price_multiplier === "unit") {
        const cores_used =
          cores < license[i].dependencies?.cores?.minimum
            ? license[i].dependencies?.cores?.minimum
            : cores;
        const units_used = Math.ceil(
          cores_used / (license[i].dependencies?.cores?.unit || 1),
        );
        license_cost.cost = parseFloat(
          parseFloat(license_cost.cost) +
            units_used * (license[i]?.price_per_hour || 0) * 24 * 30,
        ).toFixed(2);
        license_cost.details.push({
          units: units_used,
          item: license[i].item_name,
          price: license[i]?.price_per_hour,
        });
      } else {
        // Regular license price = price_per_hour * hours
        const price_per_hour = Number(license[i]?.price_per_hour) || 0;
        license_cost.cost = parseFloat(
          parseFloat(license_cost.cost) + parseFloat(price_per_hour * 24 * 30),
        ).toFixed(2);
        license_cost.details.push({
          units: 1,
          item: license[i].item_name,
          price: license[i]?.price_per_hour,
        });
      }
    }
  }

  return license_cost;
};

/**
 * Converts a valid JS date object to a string
 * @param {*} date      :   Valid js date object
 * the return value is an string in the form of 'YYYY-MM-DD' like '2021-05-23'
 */
export const convertDateToString = (date) => {
  let x = "";

  x += date.getFullYear() + "-";

  if (date.getMonth() + 1 > 9) {
    x += date.getMonth() + 1 + "-";
  } else {
    x += "0" + (date.getMonth() + 1) + "-";
  }

  if (date.getDate() > 9) {
    x += date.getDate();
  } else {
    x += "0" + date.getDate();
  }

  return x;
};

export const timespan_Yesterday_to_Today = () => {
  const today = new Date();
  const yesterday = new Date(today);

  yesterday.setDate(yesterday.getDate() - 1);

  return {
    timespan: {
      from: convertDateToString(yesterday),
      to: convertDateToString(today),
    },
  };
};

const formatSingleDigitTime = (h) => (h < 10 ? `0${h}` : h);

export const convertTimestampToDate = (x) => {
  const date = new Date(x);
  return `${defaultValues.days[date.getDay()]}, ${date.getDate()} ${
    defaultValues.months[date.getMonth()]
  } ${date.getFullYear()}, ${formatSingleDigitTime(
    date.getHours(),
  )}:${formatSingleDigitTime(date.getMinutes())}`;
};

export const convertTimestampToTime = (x) => {
  const date = new Date(x);
  return `${formatSingleDigitTime(date.getHours())}:${formatSingleDigitTime(
    date.getMinutes(),
  )}`;
};

export const getCommonResourceRowClassName = ({
  viewmore,
  height,
  sidebar,
  type,
  id,
}) => {
  let classNames = "big-size";

  // If the resource has 'view more' (detailed view)
  if (viewmore !== undefined) {
    classNames += " cursor_pointer";
  } else {
    classNames += " cursor_default";
  }

  // we would check to see if the modify panel for this very resource is open or not
  if (
    type &&
    sidebar &&
    id &&
    isResourceModifying({
      layers: sidebar.layers,
      type,
      id,
    })
  ) {
    classNames += " active_Row";
  }

  return classNames;
};

export const getIconForResource = (s) => {
  let status = "";
  if (s?.length) {
    status = s.toLowerCase();
  }

  const foundStatus = statusIcons.find((x) => x.status.includes(status));
  if (foundStatus) {
    return (
      <React.Fragment>
        <Popup
          trigger={<Icon name={foundStatus.icon} color={foundStatus.color} />}
          content={status}
        />
        {foundStatus.circular ? (
          <CircularProgress color={foundStatus.circular} task_state={status} />
        ) : null}
      </React.Fragment>
    );
  } else {
    return (
      <Popup
        trigger={<Icon name="warning circle" color="grey" />}
        content={status}
      />
    );
  }
};

export const getIconForString = (s) => {
  const foundStatus = statusIcons.find((x) => x.status.includes(s));
  if (foundStatus) {
    return (
      <Popup
        trigger={<Icon name={foundStatus.icon} color={foundStatus.color} />}
        content={s}
      />
    );
  } else {
    return (
      <Popup
        trigger={<Icon name="warning circle" color="grey" />}
        content={s}
      />
    );
  }
};

/**
 * Removes an item from an array and returns back the array
 * @param {*} arr   an array of plain values like strings or digits
 * @param {*} item  the item that is to be deleted from the array
 * (NOT the index to be removed)
 * @returns         an array
 */
export const removeItemFromArray = (arr, item) => {
  const index = arr.indexOf(item);

  if (index === -1) return [...arr];

  return [...arr.slice(0, index), ...arr.slice(index + 1)];
};

/**
 * Removes the item at position x and returns the modiifed array
 * @param {*} arr   an array of plain values like strings or digits
 * @param {*} x     the position/index of the item
 * @returns         an array
 */
export const removeItemFromArrayWithIndex = (arr, x) => {
  return [...arr.slice(0, x), ...arr.slice(x + 1)];
};

/**
 * This function will 'toggle' an item in the array
 * It 'Pushes' the item, if not found in the array
 * or 'Removes' item from the array if found
 * @param {*} arr       an array of primitive values (number,strings....)
 * @param {*} item      the item to be added to / removed from an array
 * @returns             a new array
 */
export const toggleArrayItem = (arr, item) => {
  if (!Array.isArray(arr)) {
    return arr;
  }

  const index = arr.indexOf(item);
  let result;

  if (index !== -1) {
    result = [...arr.slice(0, index), ...arr.slice(index + 1)];
  } else {
    result = [...arr, item];
  }

  return result;
};

// gets a list of file types, and fetches the extensions available on each type,
// merge them and return
/**
 * gets a list of file types, and fetches the extensions available on each type,
 * merge them and return a comma seperated list
 * @param {*} arr   an array of fileTypes like : ['yaml','json']
 * @returns         a string of valid extensions like '.yml,.yaml,.json'
 */
export const generateFileExtensions = (arr) => {
  return arr
    .reduce((acc, item) => {
      acc += fileTypes[item].reduce((acc2, x) => (acc2 += x + ","), "");
      return acc;
    }, "")
    .slice(0, -1);
};

// Copies the data send to the clipboard, and toasts some text
export const copyToClipboard = (text) => {
  if (window?.clipboardData?.setData) {
    // this is for Internet Explorer
    window?.clipboardData?.setData?.("Text", text);
    toast.error("Your browser does not support copy to clipboard");
  } else {
    navigator.clipboard.writeText(text);
    toast.info("Copied to clipboard");
  }
};

/**
 * This function checks if a "feature" in a specific "zone" in a specific "component" is available, unavailable, or immutable
 * @param {*} an object including 3 values:
 *      feature     : (string) Name of the feature to be checked            (ex: 'MTU')
 *      zone_id     : (number) The id of the zone to perform the action on  (ex: 4)
 *      component   : (string) Name of the the current component            (ex: 'Create Network')
 * @returns either an object containing the data (unavailable, or immutable property), or null if all is well
 */
export const getFeatureAvailabilityStatus = ({
  feature,
  zone_id,
  component,
}) => {
  const unsupported = unsupportedFeatures.find(
    (item) =>
      item.zone_ids.includes(Number(zone_id)) &&
      item.component === component &&
      item.feature === feature,
  );

  return unsupported || null;
};

/**
 * Get the zone_id of a region
 * @param {*} regionName the name of the region (string) (eg:kna1)
 * @param {*} domains    the list of domains taken from the redux (array)
 * @returns either the zone_id if found, or undefined
 */
export const getZoneidFromRegionName = (regionName, domains) => {
  const e = domains
    .reduce(
      (regions, domain) => (regions = [...regions, ...domain.area.regions]),
      [],
    )
    .find((region) => region.region.toLowerCase() === regionName.toLowerCase());

  return e?.zone_id || undefined;
};

/**
 * Splits a CamelCase string including Abbrevations into an array of words
 * @param {*} str - APIServerAvailableID    , serverStatus
 * @returns an array of strings
 *   APIServerAvailableID  => ['API', 'Server', 'Available', 'ID']
 *   serverStatus => ['server', 'Status']
 */
export const splitCamelCaseStringToArray = (str) =>
  str.split(/([A-Z][a-z]+)/).filter((e) => e);
