import React, { useState, useRef } from "react";
import { Grid, Icon } from "semantic-ui-react";
import usePropUpdate from "../../custom-hooks/usePropUpdate";

const Expandable = ({
  defaultOpened = true,
  title,
  subtitle = "",
  titlePopup = null,
  button,
  closedIcon = "chevron circle right",
  openedIcon = "chevron circle down",
  children,
}) => {
  const [isOpened, setIsOpened] = useState(defaultOpened);
  const updateTimer = useRef(null);

  const isSubtitleChanged = usePropUpdate(subtitle, updateTimer);

  return (
    <>
      <Grid.Row className=" padding-bottom-00">
        <Grid.Column
          textAlign="left"
          width={16}
          className="flex vcenter margin-bottom"
        >
          <h5
            className="cursor_pointer flex-1"
            onClick={() => setIsOpened(!isOpened)}
          >
            <Icon
              name={isOpened ? openedIcon : closedIcon || openedIcon}
              className="advanced_icon"
            />
            {title}
            {subtitle ? (
              <span
                className={`italic ${isSubtitleChanged ? "color-orange" : ""}`}
              >
                {subtitle}
              </span>
            ) : null}
            {titlePopup}
          </h5>
          {button}
        </Grid.Column>
      </Grid.Row>

      {isOpened && children}
    </>
  );
};

export default Expandable;
