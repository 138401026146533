import { defaultValues } from "../../app_constants";
import { removeRepeatedValues } from "../../shared-functions/array";

export const getCoreRam = (worker) =>
  (worker?.machinetype || worker?.machine?.type || "")
    .match(/\d+/g)
    .map(Number);

export const mapWorker = (worker) => ({
  machine: {
    type: worker.machinetype,
    image: {
      name: worker.imagename,
      version: worker.imageversion,
    },
  },
  volume: {
    size: Number(worker.volume) + "Gi",
  },
  name: worker.name,
  minimum: Number(worker.minimum),
  maximum: Number(worker.maximum),
  maxSurge: Number(worker.maxsurge),
  labels: worker.labels,
  annotations: worker.annotations,
  taints: worker.taints,
});

export const flattenWorker = (worker) => ({
  name: worker.name,
  machinetype: worker.machine.type,
  volume: worker.volume.size.split("G")[0],
  imagename: worker.machine.image.name,
  imageversion: worker.machine.image.version,
  minimum: worker.minimum,
  maximum: worker.maximum,
  maxsurge: worker.maxSurge,
  labels: worker.labels ?? [],
  annotations: worker.annotations ?? [],
  taints: worker.taints ?? [],
});

// This function generates a random name for the worker that is x letters
// * starts with a lowercase letter or a number
// * ends with a lowercase letter or a number
// * the remaining chars are either a letter or a number or a hyphen '-'
export const generateRandomName = (x = 6) => {
  const chars = "abcdefghijklmnopqrstuvwxyz0123456789-";
  let result = "";

  for (let i = 0; i < x; i++) {
    if (i === 0 || i === x - 1) {
      result += chars.charAt(Math.floor(Math.random() * (chars.length - 1)));
    } else {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
  }
  return result;
};

// creates a new sample worker based on the fetched cloud profile data
// this will be used where we need to create a worker (WorkerCreator or ShootCreator)
export const generateSampleWorker = (cloudProfileData) => {
  const imageNames = removeRepeatedValues(
    cloudProfileData.machineImages.map((x) => x.name),
  );

  const defaultMachine = (cloudProfileData.machineTypes || []).find(
    (x) => x?.name === defaultValues.gardener.workers.default_machine.name,
  );

  return {
    name: generateRandomName(6),
    machinetype: (defaultMachine || cloudProfileData.machineTypes[0]).name,
    volume:
      cloudProfileData.machineTypes[0]?.storage?.size?.split("G")[0] || 50,
    imagename: imageNames[0],
    imageversion: cloudProfileData.machineImages.find(
      (image) => image.name === imageNames[0] && !image.preview,
    )?.version,
    minimum: defaultValues.gardener.workers.auto_scaler.min,
    maximum: defaultValues.gardener.workers.auto_scaler.max,
    maxsurge: "1",
    labels: [],
    annotations: [],
    taints: [],
  };
};

/**
 * Calculates the required quota for the workers list sent
 * @param {[]} workers an array of worker objects or null
 * @returns {{}} an object with the required quota
 */
export const generateRequestedQuota = (workers) => {
  if (!workers || !workers.length) return {};

  const requested = {
    compute: {
      num_servers: 0,
      ram_gb: 0,
      cores: 0,
    },
    block_storage: {
      num_volumes: 0,
      volume_gb: 0,
    },
  };

  return workers.reduce((acc, worker) => {
    const [core, ram] = getCoreRam(worker);
    const multipleFactor = Number(worker.minimum);

    acc.compute.num_servers += multipleFactor;
    acc.compute.ram_gb += Number(ram) * multipleFactor;
    acc.compute.cores += Number(core) * multipleFactor;
    acc.block_storage.num_volumes += multipleFactor;
    acc.block_storage.volume_gb +=
      (Number(worker.volume) ||
        worker?.volume?.size?.match(/\d+/g)?.map(Number)?.[0] ||
        0) * multipleFactor;

    return acc;
  }, requested);
};
