import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gt } from "semver";

import PropTypes from "prop-types";

import { Icon, Tab, Grid } from "semantic-ui-react";
import SimpleTable from "../../components/shared/SimpleTable";
import { toggleArrayItem } from "../../app_shared_functions";
import { confirmbox_open } from "../../components/confirmbox/actions";
import { getGardenerDomain } from "../reducer/selectors";
import { toggleSlidingMenu } from "../../actions/toggleSlidingMenu";
import { deleteWorker, updateWorkerGroup } from "../reducer/actions";
import CircularButton from "../../components/shared/circularbutton/CircularButton";

const WorkerGroups = ({ shoot, machineTypes, machineImages }) => {
  const { workers } = shoot?.spec?.provider;

  const isDeleting = shoot.task_state === "deleting worker";

  const gardenDomain = useSelector(getGardenerDomain);

  const [expandedMachines, setExpandedMachines] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const dispatch = useDispatch();

  const renderExtendedMachineType = (worker) => {
    const machine = machineTypes.find((x) => x.name === worker.machine.type);
    if (machine) {
      return (
        <SimpleTable
          className="striped-table padding-left"
          content={[
            ["CPU :", machine?.cpu || "Unknown"],
            ["GPU :", machine?.gpu || "Unknown"],
            ["Memory :", machine?.memory || "Unknown"],
            [
              "Volume Size :",
              machine?.storage?.size || worker?.volume?.size || "Unknown",
            ],
          ]}
        />
      );
    } else {
      return null;
    }
  };

  const renderDeleteButton = (worker) => {
    if (workers.length > 1) {
      if (isDeleting) {
        return (
          <CircularButton
            className="button button--circular margin-right-half float-right button--circular__danger"
            icon="spinner"
            popupContent="Deleting"
          />
        );
      } else {
        return (
          <CircularButton
            onClick={() =>
              dispatch(
                confirmbox_open({
                  entity: "worker group",
                  operation: "delete",
                  resources: { ...worker, shoot, gardenDomain },
                  onConfirm: deleteWorker,
                }),
              )
            }
            className="button button--circular margin-right-half float-right button--circular__danger"
            icon="trash"
            popupContent="Delete this worker"
          />
        );
      }
    }
    return null;
  };

  const updateWorkerImage = (worker, imageName, imageVersion) => {
    const urlParams = {
      worker: worker.name,
      region: shoot.region,
      project_id: shoot.project_id,
      name: shoot.metadata.name,
      id: shoot.id,
      gardenDomain,
    };

    const objectToSend = {
      worker: {
        machine: {
          type: worker.machine?.type || worker.machinetype,
          image: {
            name: imageName,
            version: imageVersion,
          },
        },
        name: worker.name,
      },
    };
    setIsUpdating(true);
    dispatch(updateWorkerGroup(urlParams, objectToSend)).finally(() =>
      setIsUpdating(false),
    );
  };

  const renderUpdateImage = (worker) => {
    const { name, version } = worker?.machine?.image;

    const recentMachine = machineImages
      .find((machine) => machine.name === name)
      .versions.sort((a, b) => (gt(b.version, a.version) ? 1 : -1))[0];

    if (gt(recentMachine.version, version)) {
      if (isUpdating) {
        return (
          <CircularButton
            className={`button button--circular margin-right-half float-right`}
            icon="spinner"
          />
        );
      } else {
        return (
          <CircularButton
            onClick={() =>
              updateWorkerImage(worker, name, recentMachine.version)
            }
            className="button button--circular margin-right-half button--enabled float-right color-blue"
            icon="arrow up"
            popupContent={`Upgrade Worker image from: ${version} to: ${recentMachine.version}`}
          />
        );
      }
    }

    return null;
  };

  return (
    <Tab.Pane className="">
      <Grid className={workers.length < 3 ? "columns-2" : "columns-3"}>
        <Grid.Row stackable="true">
          {workers?.length
            ? workers.map((worker, key) => (
                <Grid.Column key={key}>
                  <SimpleTable
                    className="striped-table"
                    content={[
                      [
                        <div className="padding-top-00 padding-bottom-00">
                          {worker.name}
                          {renderDeleteButton(worker)}
                          {!isDeleting && (
                            <CircularButton
                              onClick={() =>
                                dispatch(
                                  toggleSlidingMenu("modify", "Worker Group", {
                                    worker,
                                    shoot,
                                  }),
                                )
                              }
                              className="button button--circular margin-right-half button--circular__enabled float-right"
                              icon="edit"
                              popupContent="Modify this worker"
                            />
                          )}
                          {renderUpdateImage(worker)}
                        </div>,
                      ],
                    ]}
                  />
                  <SimpleTable
                    className="striped-table reverse-strip-order"
                    content={[
                      [
                        "Flavor : ",
                        <button
                          className="color-gray"
                          onClick={() =>
                            setExpandedMachines(
                              toggleArrayItem(expandedMachines, worker.name),
                            )
                          }
                        >
                          {worker?.machine?.type}
                          <Icon
                            name={`chevron ${
                              expandedMachines.includes(worker.name)
                                ? "up"
                                : "down"
                            }`}
                          />
                        </button>,
                      ],
                    ]}
                  />

                  {expandedMachines.includes(worker.name)
                    ? renderExtendedMachineType(worker)
                    : null}

                  <SimpleTable
                    className="striped-table"
                    content={[
                      [
                        "Image : ",
                        <div className="padding-top-00 padding-bottom-00">
                          {worker?.machine?.image?.name || "Unknown"} $
                          {worker?.machine?.image?.version || ""}
                          {renderUpdateImage(worker)}
                        </div>,
                      ],
                      [
                        "Autoscaler :",
                        `Min. ${worker?.minimum} / Max. ${worker?.maximum}`,
                      ],
                      ["Max. Surge :", worker?.maxSurge || "Unknown"],
                      [
                        "Zone :",
                        worker?.zones
                          ?.reduce((acc, x) => acc + x + ", ", "")
                          .slice(0, -2) || "Unknown",
                      ],
                    ]}
                  />
                </Grid.Column>
              ))
            : null}

          {workers.length % 3 === 1 && workers.length !== 1 && (
            <Grid.Column className="no-border" />
          )}
          {workers.length === 2 && <Grid.Column className="no-border" />}
          {workers.length % 3 === 0 && (
            <>
              <Grid.Column className="no-border" />
              <Grid.Column className="no-border" />
            </>
          )}

          <Grid.Column className="flex vbottom hend no-border">
            <button
              onClick={() =>
                dispatch(toggleSlidingMenu("create", "Worker Group", shoot))
              }
              className="float-right margin-bottom-00 margin-top-20 button button--green"
            >
              <span>Create a Worker Group</span>
            </button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Tab.Pane>
  );
};

WorkerGroups.propTypes = {
  machineTypes: PropTypes.arrayOf(
    PropTypes.shape({
      cpu: PropTypes.string.isRequired,
      gpu: PropTypes.string.isRequired,
      memory: PropTypes.string.isRequired,
      name: PropTypes.string,
      storage: PropTypes.object,
      usable: PropTypes.bool,
    }),
  ).isRequired,
  shoot: PropTypes.object.isRequired,
};

export default WorkerGroups;
