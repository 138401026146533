import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";
import { getGardenerDomain } from "../../reducer/selectors";

import Worker from "../bits/Workers/Worker";
import FancyHeader from "../../../components/shared/FancyHeader";

import UpdateButtons from "../../../components/shared/form/UpdateButtons";
import { Grid, Icon, Input } from "semantic-ui-react";

import { getCloudProfile } from "../../helpers/cloudprofile";

import {
  mapWorker,
  flattenWorker,
  getCoreRam,
} from "../../helpers/workergroups";

import useFormWarning from "../../../custom-hooks/form/useFormWarning";
import { updateWorkerGroup } from "../../reducer/actions";
import useIsMountedRef from "../../../custom-hooks/useIsMountedRef";
import { getQuotaExceedingResources } from "../../../shared-functions/quota";
import useFetchQuota from "../../../custom-hooks/useFetchQuota";
import CostEstimation from "../../cost/CostEstimation";
import { getZoneidFromRegionName } from "../../../app_shared_functions";

const ModifyWorkerGroup = ({ closeSlidingMenuLayer, worker_group }) => {
  const isMountedRef = useIsMountedRef();

  const [fetchedData, setFetchedData] = useState();
  const [worker, setWorker] = useState(flattenWorker(worker_group.worker));
  const [error, setError] = useState();
  const [isUpdating, setIsUpdating] = useState(false);

  const dispatch = useDispatch();

  const gardenDomain = useSelector(getGardenerDomain);

  const { formWarning, showFormWarning, hideFormWarning } = useFormWarning();

  const { region } = worker_group.shoot;
  const quota = useFetchQuota(region);

  const domains = useSelector((state) => state.domains.list);
  const regionKey = getZoneidFromRegionName(region, domains);

  useEffect(() => {
    if (worker.minimum < worker_group.worker.minimum) {
      updateErrorsList(null);
      return;
    }

    // get new values from state <worker>
    const num_servers = Number(worker.minimum);
    const [cores, ram_gb] = getCoreRam(worker).map((x) => x * num_servers);
    const volume = Number(worker.volume) * num_servers;

    // get old values from props <worker_group.worker>
    const old_num_servers = Number(worker_group.worker.minimum);
    const [old_cores, old_ram_gb] = getCoreRam(worker_group.worker).map(
      (x) => x * old_num_servers,
    );

    const old_volume =
      Number(worker_group.worker.volume.size.match(/\d+/g)) * old_num_servers;

    const requested = {
      compute: {
        num_servers: num_servers - old_num_servers,
        ram_gb: ram_gb - old_ram_gb,
        cores: cores - old_cores,
      },
      block_storage: {
        num_volumes: num_servers - old_num_servers,
        volume_gb: volume - old_volume,
      },
    };

    const isExceeding = getQuotaExceedingResources(
      quota?.available || {},
      requested,
    );
    if (isExceeding) {
      updateErrorsList({
        message: isExceeding,
      });
    } else {
      updateErrorsList(null);
    }
  }, [worker, quota?.available, worker_group.worker]);

  useEffect(() => {
    getCloudProfile(gardenDomain)
      .then((res) => {
        if (isMountedRef.current) {
          setFetchedData(res);
        }
      })
      .catch(() => {
        if (isMountedRef.current) {
          setFetchedData(null);
        }
      });
  }, [gardenDomain, isMountedRef]);

  const handleWorkerUpdate = (updatedWorker) => {
    setWorker(updatedWorker);
    hideFormWarning();
  };

  const updateErrorsList = (error) => {
    setError(error);
  };

  const update = () => {
    setIsUpdating(true);

    const objectToSend = {
      worker: mapWorker(worker),
    };

    const urlParams = {
      worker: worker_group.worker.name,
      region: worker_group.shoot.region,
      project_id: worker_group.shoot.project_id,
      name: worker_group.shoot.metadata.name,
      id: worker_group.shoot.id,
      gardenDomain,
    };

    dispatch(updateWorkerGroup(urlParams, objectToSend)).finally(() =>
      setIsUpdating(false),
    );
  };

  return (
    <div className={`creator-component-wrapper`}>
      <div className="">
        <FancyHeader title="Modify Worker Group" />

        <CostEstimation
          excludeLicense
          regionKey={regionKey}
          workers={[{ ...worker, machine: { type: worker.machinetype } }]}
          region={region.toLowerCase()}
        />
        <p></p>
        <Grid>
          {fetchedData && (
            <React.Fragment>
              <Grid.Row className="separator padding-top-30">
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter "
                >
                  <h5>Shoot Cluster</h5>
                </Grid.Column>
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter "
                >
                  <Input
                    value={worker_group?.shoot?.metadata?.name}
                    disabled
                    className={`select-box full`}
                  />
                </Grid.Column>
              </Grid.Row>

              <Worker
                worker={worker}
                machineTypes={fetchedData.machineTypes}
                machineImages={fetchedData.machineImages}
                handleWorkerUpdate={handleWorkerUpdate}
                updateErrorsList={updateErrorsList}
                formWarning={formWarning}
              />

              <Grid.Row className="separator padding-top padding-bottom" />

              <UpdateButtons
                error={error}
                action={update}
                isUpdating={isUpdating}
                showFormWarning={showFormWarning}
                closeSlidingMenuLayer={closeSlidingMenuLayer}
              />
            </React.Fragment>
          )}

          {/* Loading */}
          {fetchedData === undefined && (
            <Grid.Row>
              <Grid.Column textAlign="left" width={16}>
                <h5>
                  <Icon
                    name="spinner"
                    loading
                    className="margin-right margin-top-20"
                  />
                  Loading
                </h5>
              </Grid.Column>
            </Grid.Row>
          )}

          {/* Error getting cloud profile */}
          {fetchedData === null && (
            <Grid.Row>
              <Grid.Column textAlign="left" width={16}>
                <h5>
                  <Icon
                    name="warning circle"
                    className="margin-right margin-top-20"
                  />
                  Error Loading cloud profile
                </h5>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </div>
    </div>
  );
};

ModifyWorkerGroup.propTypes = {
  closeSlidingMenuLayer: PropTypes.func.isRequired,
  worker_group: PropTypes.shape({
    shoot: PropTypes.shape({
      id: PropTypes.string.isRequired,
      project_id: PropTypes.string.isRequired,
      region: PropTypes.string.isRequired,
      metadata: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    worker: PropTypes.shape({
      minimum: PropTypes.number.isRequired,
      maximum: PropTypes.number.isRequired,
      maxSurge: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      machine: PropTypes.shape({
        image: PropTypes.shape({
          name: PropTypes.string.isRequired,
          version: PropTypes.string.isRequired,
        }),
        type: PropTypes.string.isRequired,
      }).isRequired,
      volume: PropTypes.shape({
        size: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default ModifyWorkerGroup;
