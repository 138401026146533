import { defaultValues } from "../app_constants";

export const calculateTextWidth = (text: string, font: string) => {
  const canvas = document.createElement("canvas") as HTMLCanvasElement;
  const context = canvas.getContext("2d") as CanvasRenderingContext2D;
  if (context) {
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  }
  return 0;
};

export const getSelectItemClassName = (text: string): string => {
  const maxWidth = defaultValues["select-box-width"];
  if (calculateTextWidth(text, "bold 14px Roboto") + 10 > maxWidth)
    return "with-scrolling-text select-option-scrolling max-width-250";

  return "";
};

export const getQuickViewItemClassName = (text: string): string => {
  const maxWidth = defaultValues["quick-view-width"];
  if (calculateTextWidth(text, "bold 16px Roboto") + 10 > maxWidth)
    return "with-scrolling-text quick-view-option-scrolling";

  return "";
};

/**
 * Split string by Uppercase letters and Merges back using space
 * while preserving abbreviations as a single word
 * @param {string} str
 * @returns
 * DeleteVPNFromList  => Delete VPN From List
 */
export const convertToSpaceSeparated = (str: string) =>
  str.replace(/([a-z])([A-Z])|([A-Z])([A-Z][a-z])/g, "$1$3 $2$4");

export const safeToLowerCase = (str: unknown): string => {
  if (typeof str !== "string") {
    return "";
  }

  return str.toLowerCase();
};

export const reduceArrayOfObjectsToString = (
  arr: { [key: string]: unknown }[],
  prop: string,
) => {
  const mapped = arr.map((item) => item[prop] || "").join(", ");
  return mapped;
};
