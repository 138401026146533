import FetchAPI from "../../api/FetchAPI";
import { toast } from "react-toastify";
import userConstants from "./constants";
import {
  toastMultipleResults,
  checkResourceProperties,
  toastError,
} from "../../app_shared_functions";

export const createUser_Multiple_Domains = (data, list) => (dispatch) => {
  dispatch({ type: userConstants.OPENSTACK_USER_CREATE_INIT });
  toast.success(
    `About to create ${list.length} user${list.length > 1 ? "s" : ""}...`,
  );

  const promises = list.map((user) => {
    const objectToSend = { ...data, projects: user.projectList };
    return new Promise((resolve, reject) =>
      FetchAPI.AccessControlOpenStack.OpenStack.create({
        domain_id: user.domainId,
        objectToSend,
      })
        .then((response) => resolve({ status: "resolved" }))
        .catch((err) => resolve({ status: "rejected" })),
    );
  });
  return new Promise((resolve, reject) => {
    Promise.all(promises).then((responses) => {
      toastMultipleResults({
        responses,
        resource_name: "user",
        action: "create",
        dispatch,
      });
      resolve();
    });
  });
};

export const showViewMore = (id) => (dispatch) => {
  dispatch({ type: userConstants.OPENSTACK_USER_SHOW_VIEWMORE, payload: id });
};
export const hideViewMore = (id) => (dispatch) => {
  dispatch({ type: userConstants.OPENSTACK_USER_HIDE_VIEWMORE, payload: id });
};

export const updateUser = (user, objectToSend) => (dispatch) => {
  const invalid_user_Msg = checkResourceProperties(user, "user");
  if (invalid_user_Msg) {
    toastError(invalid_user_Msg);
    return Promise.reject();
  }
  return new Promise((resolve, reject) => {
    FetchAPI.AccessControlOpenStack.OpenStack.modify({
      user,
      objectToSend,
    })
      .then((response) => {
        if (response) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteOpenstack_user = (user) => (dispatch) => {
  const invalid_user_Msg = checkResourceProperties(user, "openstack user");
  if (invalid_user_Msg) {
    toastError(invalid_user_Msg);
    return Promise.reject();
  }
  dispatch({
    type: userConstants.OPENSTACK_USER_DELETE_INIT,
    payload: user.domain_id + "_" + user.id,
  });
  return new Promise((resolve, reject) => {
    FetchAPI.AccessControlOpenStack.OpenStack.delete(user)
      .then((res) => {
        if (res) {
          dispatch({
            type: userConstants.OPENSTACK_USER_DELETE_SUCCESS,
            payload: user.domain_id + "_" + user.id,
          });
          resolve(res.data);
        } else {
          dispatch({
            type: userConstants.OPENSTACK_USER_DELETE_FAILED,
            payload: user.domain_id + "_" + user.id,
          });
          reject(res);
        }
      })
      .catch((err) => {
        dispatch({
          type: userConstants.OPENSTACK_USER_DELETE_FAILED,
          payload: user.domain_id + "_" + user.id,
        });
        toastError(err, "Deleting openstack user failed!");
        reject(err);
      });
  });
};

export const deleteMultipleOpenstack_users = (users) => (dispatch) => {
  toast.success(
    `About to delete ${users.length} user${users.length > 1 ? "s" : ""}...`,
  );
  dispatch({
    type: userConstants.OPENSTACK_USER_DELETE_MULTIPLE_INIT,
    payload: users,
  });
  const promises = users.map((user) => {
    const newID = user.id.split("_")[1];
    return new Promise((resolve, reject) =>
      FetchAPI.AccessControlOpenStack.OpenStack.delete({ ...user, id: newID })
        .then((response) => resolve({ status: "resolved", id: user.id }))
        .catch((err) => {
          dispatch({
            type: userConstants.OPENSTACK_USER_DELETE_FAILED,
            payload: user.id,
          });
          resolve({
            status: "rejected",
            id: user.id,
            desc:
              err.response.data.error.description ||
              err.response.data.error.message,
          });
        }),
    );
  });
  Promise.all(promises).then((responses) =>
    toastMultipleResults({
      responses,
      resource_name: "user",
      action: "delete",
      dispatch,
    }),
  );
};

export const grantAccess = (user, objectToSend) => (dispatch) => {
  const invalid_user_Msg = checkResourceProperties(user, "user");
  if (invalid_user_Msg) {
    toastError(invalid_user_Msg);
    return Promise.reject();
  }

  return new Promise((resolve, reject) => {
    FetchAPI.AccessControlOpenStack.OpenStack.grantProjectAccess({
      user,
      objectToSend,
    })
      .then((response) => {
        if (response) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const revokeAccess = (user, objectToSend) => (dispatch) => {
  const invalid_user_Msg = checkResourceProperties(user, "user");
  if (invalid_user_Msg) {
    toastError(invalid_user_Msg);
    return Promise.reject();
  }

  return new Promise((resolve, reject) => {
    FetchAPI.AccessControlOpenStack.OpenStack.revokeProjectAccess({
      user,
      objectToSend,
    })
      .then((response) => {
        if (response) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateInit = (user) => (dispatch) => {
  dispatch({
    type: userConstants.OPENSTACK_USER_UPDATE_INIT,
    payload: user.id,
  });
};

export const updateFinished = (user) => (dispatch) => {
  dispatch({
    type: userConstants.OPENSTACK_USER_UPDATE_SUCCESS,
    payload: user.id,
  });
};
